import { styled } from '@mui/material/styles';
import { CHOSEN_TEST_QUIZ_COLOR } from 'src/theme/color';

export const ItemBox = styled('div')`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 300px;
  height: 70px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: ${(props) =>
    props.chosen === 'chosen' ? `4px solid ${CHOSEN_TEST_QUIZ_COLOR}` : '1px solid black'};

  .media-display {
    display: flex;
    align-items: center;
  }
`;

// mui components
import { Box, Typography, Fab } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ReplayIcon from '@mui/icons-material/Replay';

// react main components and other libraries
import { useEffect, useState } from 'react';

// logic function
import { gatherSyllableLines, gatherWords } from './index.logic';

// customed style
import { WISBox } from './index.style';
import PhonemeLine from './PhonemeLine';
import QuestionBox from '../../ToolParts/QuestionBox';
import Scrollbars from 'react-custom-scrollbars-2';

// redux
import { useDispatch } from 'react-redux';
import { setDisableNext } from 'src/redux/lesson/actions';

const WordInSentenceContainer = ({ data }) => {
  const [wordList, setWordList] = useState([]);
  const [syllables, setSyllables] = useState([]);
  const [replayNum, setReplayNum] = useState(3);
  const [isReset, setIsReset] = useState(false);
  const [chosenNum, setChosenNum] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    const slbs = gatherSyllableLines(data.quizParts);
    setSyllables(slbs);

    const clts = gatherWords(data.quizParts);
    setWordList(clts);
    setChosenNum(0);
  }, []);

  useEffect(() => {
    if (chosenNum >= data.quizParts[0].squareQuizParts.length) {
      dispatch(setDisableNext(false));
    }
  }, [chosenNum]);

  const reset = () => {
    if (replayNum !== 0) {
      setReplayNum(replayNum - 1);
      setIsReset(!isReset);
    } else {
      // print warning sounds
    }
  };

  const showInstruction = () => {};

  return (
    <WISBox image={data.backgroundUrl} opacity={data.backgroundOpacity}>
      <QuestionBox
        imgUrl={data.questionImageUrl}
        audioUrl={data.questionAudioUrl}
        question={data.question}
      />

      <Scrollbars autoHeight autoHeightMax={300}>
        <Box className="square-sector">
          {wordList.map((e, index) => {
            return (
              <Box key={index} sx={{ marginTop: '12px' }}>
                <Box>
                  <Typography variant="h2" textAlign="left" fontStyle="italic">
                    {syllables[index]}
                  </Typography>
                </Box>
                <Box className="top-sector">
                  <PhonemeLine
                    lineData={e}
                    isReset={isReset}
                    chosenNum={chosenNum}
                    setChosenNum={setChosenNum}
                  />
                </Box>
              </Box>
            );
          })}
        </Box>
      </Scrollbars>
      <Fab
        color="warning"
        size="small"
        sx={{ position: 'absolute', right: '10px', bottom: '60px' }}
        onClick={showInstruction}>
        <HelpOutlineIcon />
      </Fab>
      <Fab
        color="warning"
        size="small"
        sx={{ position: 'absolute', right: '10px', bottom: '10px' }}
        onClick={reset}>
        {replayNum}
        <ReplayIcon />
      </Fab>
    </WISBox>
  );
};

export default WordInSentenceContainer;

import { Box, Typography } from '@mui/material';
import DropdownBox from '../DropdownBox';
import { LineBox } from './index.style';

const PhonemeLine = ({ lineData, setWordList }) => {
  return (
    <LineBox>
      {lineData.words.map((e, index) => {
        return (
          <Box key={index} className="col">
            <Box className="word-text">
              <Typography variant="h1">{e.text}</Typography>
            </Box>

            <Box>
              <DropdownBox
                trueTone={e.trueTone}
                wordId={e.wordId}
                setWordList={setWordList}
                selectedTone={e.selectedTone}
              />
            </Box>
          </Box>
        );
      })}
    </LineBox>
  );
};

export default PhonemeLine;

import { useCallback, memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Box, Typography, Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

// components
import VoiceSpeaker from 'src/components/VoiceSpeaker';

// utils
import { formatNumber } from '../../../../utils/number';

// reducers
import { purchaseLuckyBoxes } from '../../../../redux/award-admin/luckyBox/luckyBox';
import { getUserStar } from '../../../../redux/award-admin/userAward/userAward';

// local
import LuckyBoxesCtrl from '../LuckyBoxes.ctrl';
import LuckyBoxItemCard from './LuckyBoxItemCard';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4
};

const LuckyBoxModal = () => {
  // -- ctrl --
  const {
    // selected box id
    selectedBoxId,
    // is show modal
    isShowModal,
    setIsShowModal
  } = LuckyBoxesCtrl.useContainer();

  // -- i18n --
  const { t } = useTranslation();

  // -- dispatch --
  const dispatch = useDispatch();

  // -- selected lucky box --
  const selectedBox = useSelector((state) => state.luckyBoxes.data[selectedBoxId]) || {};

  // -- received items --
  const [receivedItems, setReceivedItems] = useState([]);

  // -- is show box items --
  const [isShowBoxItems, setIsShowBoxItems] = useState(false);

  // -- user id --
  const userId = useSelector((state) => state.auth.user.result?._id);

  // -- handle purchase lucky box --
  const handlePurchase = useCallback(async () => {
    const { success: purchaseSuccess, openedLuckyBoxes } = await dispatch(
      purchaseLuckyBoxes({ userId, luckyBoxIds: [selectedBoxId] })
    );
    if (purchaseSuccess) {
      dispatch(getUserStar({ userId }));

      setReceivedItems(openedLuckyBoxes[0].items);
      setTimeout(() => setIsShowBoxItems(true), 200);
    }
  }, [selectedBoxId, userId]);

  if (!selectedBox) return null;

  return (
    <Modal
      open={isShowModal}
      onClose={() => {
        setIsShowModal(false);
        setIsShowBoxItems(false);
        setReceivedItems([]);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={style}>
        {isShowBoxItems ? (
          // lucky box items
          <>
            {/* name */}
            <Typography id="modal-modal-title" sx={{ textAlign: 'center' }} component="h1">
              {t('award')}
            </Typography>

            {/* list lucky box items */}
            <Grid container spacing={2} style={{ marginTop: '20px' }}>
              {receivedItems.map((boxItem) => (
                <Grid
                  key={boxItem.id}
                  item
                  xs={12}
                  md={receivedItems.length >= 3 ? 4 : 12 / receivedItems.length}>
                  <LuckyBoxItemCard boxItem={boxItem} />
                </Grid>
              ))}
            </Grid>

            {/* buttons */}
            <div style={{ display: 'flex', marginTop: '20px', justifyContent: 'center' }}>
              <Button
                variant="contained"
                style={{ marginLeft: '10px' }}
                onClick={() => {
                  setIsShowBoxItems(false);
                  setIsShowModal(false);
                  setReceivedItems([]);
                }}>
                {t('vietstudy-store_lucky_box_item_modal_get_rewards')}
              </Button>
            </div>
          </>
        ) : (
          // lucky box info
          <>
            {/* name */}
            <Typography
              id="modal-modal-title"
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              component="h1">
              {selectedBox.nameVoiceUrl && <VoiceSpeaker audioUrl={selectedBox.nameVoiceUrl} />}
              <span style={{ marginLeft: '5px' }}>{selectedBox.name}</span>
            </Typography>

            <div style={{ display: 'flex', marginTop: '20px' }}>
              {/* image */}
              <img src={selectedBox.imageUrl} height={300} />

              {/* description */}
              <p style={{ margin: 'auto', padding: '10px' }}>{selectedBox.description}</p>
            </div>

            {/* buttons */}
            <div style={{ display: 'flex', marginTop: '20px', justifyContent: 'center' }}>
              <Button
                variant="outlined"
                onClick={() => {
                  setIsShowModal(false);
                }}>
                {t('vietstudy-store_item_modal_cancel')}
              </Button>

              <Button variant="contained" style={{ marginLeft: '10px' }} onClick={handlePurchase}>
                {`${t('vietstudy-store_item_modal_buy')} ${formatNumber(selectedBox.price)} ${t(
                  'vietstudy-store_item_price'
                )}`}
              </Button>
            </div>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default memo(LuckyBoxModal);

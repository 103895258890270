// base React
import React, { useEffect, useRef, useState } from 'react';

// MUI components
import { IconButton } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

// import Swiper core and required modules
import { Navigation } from 'swiper';
import { Swiper } from 'swiper/react';
import { calculateSlidesPerViewForCourse } from './index.logic';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

const ARROW_COLOR = '#0D4DC9';
const DISABLE_COLOR = '#A9A9A9';

const VSSwiperCourse = ({ data, space = 0 }) => {
  const swiperRef = useRef(null);
  const [disabledPrev, setDisablePrev] = useState(false);
  const [disabledNext, setDisableNext] = useState(false);
  const [slidesPerView, setSlidesPerView] = useState(1);

  useEffect(() => {
    let len = calculateSlidesPerViewForCourse(data.length, setDisablePrev, setDisableNext);
    setSlidesPerView(len);
    setDisablePrev(true); // start time: previous disable
  }, []);

  const onClickPrev = () => {
    const oldActive = swiperRef.current.swiper.activeIndex;
    if (data.length > 4 && oldActive === data.length - 4) {
      setDisablePrev(true);
    }
    setDisableNext(false);
    swiperRef.current.swiper.slidePrev();
  };

  const onClickNext = () => {
    const oldActive = swiperRef.current.swiper.activeIndex;
    if (data.length > 4 && oldActive === data.length - 5) {
      // dataLen = M, slidesPerView = N (default 4) -> maxActiveIndex = M - N
      // if (oldActive === M - 5 -> activeIndex++ will be disable)
      setDisableNext(true);
    }
    setDisablePrev(false);
    swiperRef.current.swiper.slideNext();
  };

  return (
    <div style={{ display: 'flex' }}>
      <div className="prev-btn" style={{ display: 'flex', alignItems: 'center' }}>
        <IconButton disabled={disabledPrev} onClick={onClickPrev}>
          <ArrowBackIosIcon
            sx={{
              fontSize: '40px',
              color: disabledPrev === true ? DISABLE_COLOR : ARROW_COLOR
            }}
          />
        </IconButton>
      </div>
      <Swiper
        modules={[Navigation]}
        spaceBetween={space}
        slidesPerView={slidesPerView}
        ref={swiperRef}
        allowTouchMove={false}
        // breakpoints={{
        //     1536: {
        //         slidesPerView: slidesPerView + 1
        //     }
        // }}
      >
        {data}
      </Swiper>
      <div className="next-btn" style={{ display: 'flex', alignItems: 'center' }}>
        <IconButton disabled={disabledNext} onClick={onClickNext}>
          <ArrowForwardIosIcon
            sx={{
              fontSize: '40px',
              color: disabledNext === true ? DISABLE_COLOR : ARROW_COLOR
            }}
          />
        </IconButton>
      </div>
    </div>
  );
};

export default VSSwiperCourse;

import React, { useEffect, useState } from 'react';
import './RoadMap.scss';
import AppLayout from 'src/containers/Layout/AppLayout/AppLayout';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getAllPaths } from 'src/apis/path';
import { setAllPaths } from 'src/redux/path/actions';
import { Button, IconButton } from '@mui/material';
import styled from 'styled-components';
import { setCurrentPath } from 'src/redux/path/actions';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getExtractPath } from 'src/apis/path';
import { useNavigate } from 'react-router-dom';
import { setCurrentCourse } from 'src/redux/course/actions';
import { getExtractCourse } from 'src/apis/course';

const CustomColorButton = styled(Button)(() => ({
  color: 'rgb(132, 216, 255)',
  backgroundColor: 'white',
  width: '50%',
  boxShadow: '0px 0px 4px 0px #777777',
  borderRadius: '0.5rem',
  '&:hover': {
    backgroundColor: 'white'
  }
}));

const RoadMap = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const allPaths = useSelector((store) => store.path.paths);
  const isFetchAllPaths = useSelector((store) => store.path.isFetchAllPaths);
  const currentPath = useSelector((store) => store.path.currentPath);

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [pathList, setPathList] = useState([]);
  const [currentPathDetail, setCurrentPathDetail] = useState([]);
  const [imgList, setimgList] = useState([]);

  const initRoadmaps = async () => {
    const data = (await getAllPaths()).courses;
    dispatch(setAllPaths(data));

    setPathList(data);
  };

  useEffect(() => {
    if (allPaths.length === 0 && !isFetchAllPaths) {
      initRoadmaps();
    }

    return () => {
      dispatch(setCurrentPath(null));
    };
  }, []);

  useEffect(() => {
    setPathList(allPaths);
  }, [allPaths]);

  useEffect(() => {
    if (!currentPath) return;
    if (currentPathDetail.length === 0) return;

    switch (currentPathDetail.length) {
      case 2:
        setimgList([0, 7]);
        break;
      case 3:
        setimgList([0, 3, 7]);
        break;
      case 4:
        setimgList([0, 3, 6, 7]);
        break;
      case 5:
        setimgList([0, 2, 3, 6, 7]);
        break;
      case 6:
        setimgList([0, 2, 3, 4, 6, 7]);
        break;
      case 7:
        setimgList([0, 1, 2, 3, 5, 6, 7]);
        break;
      case 8:
        setimgList([0, 1, 2, 3, 4, 5, 6, 7]);
        break;
      case 9:
        setimgList([0, 1, 2, 3, 4, 4, 5, 6, 7]);
        break;
      case 10:
        setimgList([0, 1, 2, 3, 3, 4, 4, 5, 6, 7]);
        break;
      case 11:
        setimgList([0, 1, 2, 3, 3, 4, 4, 5, 5, 6, 7]);
        break;
      case 12:
        setimgList([0, 1, 2, 3, 3, 4, 4, 5, 5, 6, 6, 7]);
        break;

      default:
        setimgList([0, 1, 2, 3, 4, 5, 6, 7]);
        break;
    }
  }, [currentPath, currentPathDetail]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackBar(false);
  };

  const handleClickPath = async (pathId, course) => {
    // TODO: save path progress
    dispatch(setCurrentPath(null));
    await getExtractCourse(course.id).then((data) => dispatch(setCurrentCourse(data)));
    navigate(`/learn-course/${course.id}`);
  };

  const renderRoadmapDetail = () => {
    return (
      <div className="roadmap-detail animate__animated animate__slideInRight">
        <IconButton className="back-icon" onClick={() => dispatch(setCurrentPath(null))}>
          <ArrowBackIcon fontSize="large" />
        </IconButton>
        <div className="roadmap-title">{currentPath.roadmapTitle}</div>
        <div className="path">
          {currentPathDetail.map((course, index) => {
            return (
              <div
                className="section"
                id={`section-${index % 8}`}
                key={index}
                onClick={() => handleClickPath(currentPath._id, course)}>
                <img
                  src={process.env.PUBLIC_URL + `/icons/chapter/ic_chapter_${imgList[index]}.png`}
                  alt={course.title}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderRoadmapList = () => {
    return (
      <div className="roadmaps animate__animated animate__slideInLeft">
        {pathList.map((path, index) => {
          return (
            <div className="roadmap" key={index}>
              <div className="information">
                <h1>{path.title}</h1>
                <div className="coursesNum">
                  <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAErklEQVR4nO2YX2gcRRzH16pV8cGoIFXEvvhgEHyoFmpFg9Km95vLX9PdDaVQC6HiHyz6JMEaCAVR1OpLqRp6N7Ml0qgpmsRk71KTh4oPVm3986ItaZOZu7a5JDZRmja5jPz2dk9tdnt7lw3dg3xhYO729je/z/x+M/ObU5QVhVxj8chWQclRTskMZ/A3p/ALp7AnzTbfHtgYRuR+zmAf2hYMMoKRU4ISyhnZWLLR4x89erOgcFgwIt2aTOgLMqHLINqCqbuOwSlZ4AwOyKGqm4oGEJTstwxRmOQs8sLZzrr7Rj+pvisVB+AUTi+YmgwSgDPyNac1j3PacLeI1TwkGGnHiOdgIF6c80bNOqQXFC5j/+rnCJEdCMZ5mdDl/ICWHYlV3bpoHAqPcUouWhDxSLVvAE7JB/bsv+/2PBUnT833BxeBuX51znsyodX25VPfAILCCSsHGdko29pWYSSsiGBoac0DnJFjV/rUwAAu96lSGLBbdqk3cgY7c2kL3ejLKKt+MAdAxoqIAIzjS6e7Nt1hAy1azLM9TZeCArjU02QvWpjPj2FAC479+4eRW+zvJooBsAz92qWuxs8jsfoKQSHGGUyjIQxndkD97JqOffOclCfbpUx1Fk6hvqZhTslvuV2HXBCUvO74cj5G1tjZcMF/CjGYwpfOGFvu9fqNNLW9nk6d2i/l+JdSZnpyrWAUtHbLpst2iYvXTqHv/EeAke9zYYw+6Q2g7vB0KPOVlGcP+gcw1e3ek0netbfSd4oAgAN2HrZ6AiS0DZ4ODe+0QXwCJLX1bmNgCnMK55wNxTeAiEcb7UX1gydA77Y7CzrmF2CovsJtjBSL7rK30BNKMcJDhVNyHl8eY/CMJ0RCSy0ZwNS5m208kTmDdG72o2pRADkDsMfevk5iXeQKYOqfBwBweJHdtrZVWFrYqXNMSuWGogFGu9TbOCMjOSOwzx2g+dWlAzS/snjyyJt2MTeDB5lSqkQMnhCMXLFn4o1FAElt/ZIB+rX/1VqcwmvWeUDJApbyJTufhzCgxSojcun03n/Difu2NPWp0gG0SWfvR7uCwV6njBYMXlaCEqfkRed05oyYeAHJQyS0jsIHlWf+f4w20mzzPYJCr1NOYD0UmPN5CBaptW9LeKhMjcXJ87i45YD2dMkACbUK08TZbawizojWKcslLC04hf58wUXJH9yI7pCmxkuY/bRg0eF/bcG3qUNkrbLcwlzlLNqMzjuDT3c3/FUswHR3g1PZTnAGL5V0bVyKrDuzAS2Ckp/TRlRmB/xfcPC3gsEMp+RtvKYq11tY9F38onHQ9+wfaewMheNXSyb0Qz4ADCWskl3qamnqnddyXh7f5VqWhErSbN4629uQxVzHNtvTkJUJ/VmlnDRON6ScXQr7Srkpw9b96ABgXyk3TcYfOeIAYF8pN03FH37LAcC+Um7682Cl7gBgXyk3TXZUrs2nUEfl8tc4QWouuaU+m6w7ly8dknUT80c3bVPCLjlUVZFNwk8y4VYXadJ6NlTl+g9EKCQHazMFS4nB2owSVkmfxZwSNrU2RWUpTQmLVgDCEgF5pt1XC20KyXIHaF1ZxNdJZR+BFSml6x/J4dpjBAxK2QAAAABJRU5ErkJggg==" />
                  {path.chapterIds ? path.chapterIds.length : 0}{' '}
                  {t(path.chapterIds?.length === 1 ? 'course-individual-note' : 'course-note')}
                </div>
                {path.description}
                <CustomColorButton
                  onClick={async () => {
                    await getExtractPath(path._id).then((data) => {
                      setCurrentPathDetail(data);
                    });

                    dispatch(
                      setCurrentPath({
                        roadmapId: path._id,
                        roadmapTitle: path.title
                      })
                    );
                  }}>
                  {t('View')}
                </CustomColorButton>
              </div>
              <img src={path.imageUrl} alt={path.title} />
            </div>
          );
        })}
      </div>
    );
  };
  return (
    <AppLayout
      renderMain={currentPath ? renderRoadmapDetail : renderRoadmapList}
      activeMenu="Road map"
    />
  );
};

export default RoadMap;

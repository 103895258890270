import * as React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import {
  CircularProgress,
  Button,
  Dialog,
  Typography,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField
} from '@mui/material';

// redux
import { useDispatch } from 'react-redux';
import { updateUserInfo } from 'src/redux/auth/actions';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getMe } from 'src/apis/auth';
import { useEffect } from 'react';
import { updateWithoutKeycloak } from 'src/apis/auth';

// export const UpdatePasswordDialog = ({ updatePassword, open, setOpen }) => {
//     const theme = useTheme();
//     const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

//     const handleClose = () => {
//         setOpen(false);
//     };

//     const [oldSecure, setOldSecure] = React.useState(false);
//     const [newSecure, setNewSecure] = React.useState(false);

//     const showOldPw = () => setOldSecure(!oldSecure);
//     const showNewPw = () => setNewSecure(!newSecure);

//     const handleMouseDownPassword = (event) => {
//         event.preventDefault();
//     };

//     return (
//         <Dialog
//             fullScreen={fullScreen}
//             open={open}
//             onClose={handleClose}
//         >
//             <DialogContent>
//                 <DialogContentText textAlign="center" sx={{ marginBottom: '15px' }}>
//                     <Typography variant='h2'>Cập nhật mật khẩu</Typography>
//                 </DialogContentText>

//                 <FormControl variant="standard" fullWidth={true} sx={{ marginBottom: '20px' }}>
//                     <InputLabel htmlFor="old-pw">Password cũ</InputLabel>
//                     <Input
//                         id="old-pw"
//                         type={oldSecure ? 'text' : 'password'}
//                         endAdornment={
//                             <InputAdornment position="end">
//                                 <IconButton
//                                     aria-label="toggle password visibility"
//                                     onClick={showOldPw}
//                                     onMouseDown={handleMouseDownPassword}
//                                 >
//                                     {oldSecure ? <VisibilityOff /> : <Visibility />}
//                                 </IconButton>
//                             </InputAdornment>
//                         }
//                     />
//                 </FormControl>
//                 <FormControl variant="standard" fullWidth={true}>
//                     <InputLabel htmlFor="new-pw">Password mới</InputLabel>
//                     <Input
//                         id="new-pw"
//                         type={newSecure ? 'text' : 'password'}
//                         endAdornment={
//                             <InputAdornment position="end">
//                                 <IconButton
//                                     aria-label="toggle password visibility"
//                                     onClick={showNewPw}
//                                     onMouseDown={handleMouseDownPassword}
//                                 >
//                                     {newSecure ? <VisibilityOff /> : <Visibility />}
//                                 </IconButton>
//                             </InputAdornment>
//                         }
//                     />
//                 </FormControl>
//             </DialogContent>
//             <DialogActions>
//                 <Button autoFocus onClick={handleClose} >
//                     <Typography variant='h3'>Không đồng ý</Typography>
//                 </Button>
//                 <Button onClick={updatePassword} autoFocus>
//                     <Typography variant='h3'>Đồng ý</Typography>
//                 </Button>
//             </DialogActions>
//         </Dialog>
//     );
// }

export const UpdateSecureDialog = ({ open, setOpen }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const user = useSelector((state) => state.auth.user);
  const { t } = useTranslation();
  const dispatcher = useDispatch();
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      const fetchedUser = await getMe('v1');
      setPhoneNumber(fetchedUser.result.phoneNumber);
      dispatcher(updateUserInfo(fetchedUser.result));
    };

    if (Object.keys(user).length === 0) {
      fetchUser();
    } else {
      setPhoneNumber(user.phoneNumber);
    }
  }, []);

  const updateSecureInfo = async () => {
    setIsLoading(true);
    if (user.phoneNumber !== phoneNumber) {
      user.phoneNumber = phoneNumber;
      const res1 = await updateWithoutKeycloak('v1', { ...user, userId: user._id });
      if (res1.result === true) {
        dispatcher(updateUserInfo(user));
      }
    }
    setIsLoading(false);
    setOpen(false);
  };

  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={() => setOpen(false)}>
      <DialogContent>
        <DialogContentText textAlign="center" sx={{ marginBottom: '15px' }}>
          <Typography variant="h2">{t('update_contact')}</Typography>
        </DialogContentText>

        <TextField
          label="Số điện thoại"
          fullWidth
          variant="standard"
          defaultValue={
            user.phoneNumber === 'no_phone_number' ? t(`${user.phoneNumber}`) : phoneNumber
          }
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        {isLoading ? (
          <Button>
            <CircularProgress size="1.5rem" />
          </Button>
        ) : (
          <>
            <Button autoFocus onClick={() => setOpen(false)}>
              <Typography variant="h3">{t('dont_agree')}</Typography>
            </Button>
            <Button onClick={updateSecureInfo} autoFocus>
              <Typography variant="h3">{t('agree')}</Typography>
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

// mui, react
import { Checkbox, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';

// redux
import { useDispatch } from 'react-redux';
import { setDisableNext } from 'src/redux/lesson/actions';

// customed ui & util logic function
import MediaDisplayer from '../../ToolParts/MediaDisplayer';
import { BlinkArrow } from '../../ToolParts/BlinkArrow';
import { CheckboxAnswersBox, CheckboxOptBox } from './index.style';

// sound
import correct from '../../../../assets/audio/correct.mp3';
import useSound from 'use-sound';

const compareCheckboxLists = (trueAnswers, currentAnswers) => {
  let counter = 0;
  for (let i = 0; i < trueAnswers.length; i++) {
    if (currentAnswers.includes(trueAnswers[i])) {
      counter = counter + 1;
    }
  }
  return counter === trueAnswers.length;
};

const CheckboxOption = ({
  media,
  index,
  width,
  trueAnswers,
  currentChosenIndexes,
  setCurrentChosenIndexes,
  isHintCheckbox,
  randIndex
}) => {
  const dispatch = useDispatch();
  const [chosen, setChosen] = useState('unchosen');
  const [hintColor, setHintColor] = useState('transparent');
  const [counter, setCounter] = useState(0);
  const [playTrue] = useSound(correct);

  const handleChangeOption = (event) => {
    const value = parseInt(event.target.value);
    if (!currentChosenIndexes.includes(value)) {
      const newArray = [...currentChosenIndexes, value];
      setCurrentChosenIndexes(newArray);
      if (compareCheckboxLists(trueAnswers, newArray)) {
        dispatch(setDisableNext(false));
      }
    }
    if (trueAnswers.includes(value)) {
      setChosen('correct');
      playTrue();
    } else setChosen('incorrect');
  };

  useEffect(() => {
    if (trueAnswers.includes(index) && randIndex === index && counter !== 0) {
      setHintColor('green');
      setTimeout(() => setHintColor('transparent'), 2000);
    }
    setCounter(counter + 1);
  }, [isHintCheckbox]);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center' }}>
      <BlinkArrow color={hintColor} />
      <CheckboxOptBox chosen={chosen} compare={!currentChosenIndexes.includes(index)} width={width}>
        <Checkbox
          checked={currentChosenIndexes.includes(index)}
          value={index}
          onChange={handleChangeOption}
          color={chosen === 'incorrect' ? 'error' : 'success'}
        />
        <MediaDisplayer media={media} index={index} />
      </CheckboxOptBox>
    </Box>
  );
};

const CheckboxAnswers = ({
  options,
  trueAnswers,
  currentChosenIndexes,
  setCurrentChosenIndexes,
  isHintCheckbox,
  randIndex
}) => {
  return (
    <CheckboxAnswersBox>
      <Scrollbars autoHeight autoHeightMax={265}>
        {options.length % 2 === 0
          ? options.map((e, index) => {
              if (index % 2 === 0) {
                return (
                  <Box className="line" key={index}>
                    {[index, index + 1].map((ele) => {
                      return (
                        <CheckboxOption
                          media={options[ele].content}
                          trueAnswers={trueAnswers}
                          currentChosenIndexes={currentChosenIndexes}
                          setCurrentChosenIndexes={setCurrentChosenIndexes}
                          index={ele}
                          key={ele}
                          isHintCheckbox={isHintCheckbox}
                          randIndex={randIndex}
                        />
                      );
                    })}
                  </Box>
                );
              }
            })
          : options.map((e, index) => {
              return (
                <Box className="only-one-line" key={index}>
                  <CheckboxOption
                    media={e.content}
                    trueAnswers={trueAnswers}
                    currentChosenIndexes={currentChosenIndexes}
                    setCurrentChosenIndexes={setCurrentChosenIndexes}
                    index={index}
                    width={'65%'}
                    isHintCheckbox={isHintCheckbox}
                    randIndex={randIndex}
                  />
                </Box>
              );
            })}
      </Scrollbars>
    </CheckboxAnswersBox>
  );
};

export default CheckboxAnswers;

import { courseActionTypes } from './actions';

export const courseInitState = {
  courses: [],
  isFetchAllCourses: false,
  currentCourse: null,
  currentSection: null,

  // addition
  menuOpen: false,
  supportLanguage: 'vi'
};

const courseReducer = (state = courseInitState, action) => {
  switch (action.type) {
    case courseActionTypes.SET_ALL_COURSES:
      return {
        ...state,
        courses: action.payload,
        isFetchAllCourses: true
      };

    case courseActionTypes.SET_CURRENT_COURSE:
      return {
        ...state,
        currentCourse: action.payload
      };

    case courseActionTypes.MENU_OPEN:
      return {
        ...state,
        menuOpen: action.payload
      };

    case courseActionTypes.SUPPORT_LANG:
      return {
        ...state,
        supportLanguage: action.payload
      };
    case courseActionTypes.SET_CURRENT_SECTION:
      return {
        ...state,
        currentSection: action.payload
      };

    default:
      return state;
  }
};

export default courseReducer;

import { styled } from '@mui/material/styles';

export const BLANK_BOX_WIDTH = '106px';
export const BLANK_BOX_HEIGHT = '72px';

// BQ = blank quiz
// width: 950px;
const TestOVBox = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin-top: 7.5%;

  .test-purpose {
    margin-bottom: 5%;
    text-align: center;
  }

  .test-title {
    margin-bottom: 10px;
    text-align: center;
  }

  .star {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin-bottom: 5%;
  }

  .test-note {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-left: 20%;
    margin-bottom: 7.5px;
  }
`;

export default TestOVBox;

import React, { useState, useEffect } from 'react';
import Spinner from 'src/components/Spinner';

const IconPlayCircle = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.0001 1.66663C5.40008 1.66663 1.66675 5.39996 1.66675 9.99996C1.66675 14.6 5.40008 18.3333 10.0001 18.3333C14.6001 18.3333 18.3334 14.6 18.3334 9.99996C18.3334 5.39996 14.6001 1.66663 10.0001 1.66663ZM7.91675 12.225V7.77496C7.91675 7.11663 8.65008 6.71663 9.20008 7.07496L12.6584 9.29996C13.1667 9.62496 13.1667 10.375 12.6584 10.7L9.20008 12.925C8.65008 13.2833 7.91675 12.8833 7.91675 12.225Z"
      fill="black"
    />
  </svg>
);

const IconPauseCircle = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.99984 1.66663C5.39984 1.66663 1.6665 5.39996 1.6665 9.99996C1.6665 14.6 5.39984 18.3333 9.99984 18.3333C14.5998 18.3333 18.3332 14.6 18.3332 9.99996C18.3332 5.39996 14.5998 1.66663 9.99984 1.66663ZM9.1665 13.3333H7.49984V6.66663H9.1665V13.3333ZM12.4998 13.3333H10.8332V6.66663H12.4998V13.3333Z"
      fill="black"
    />
  </svg>
);

const VoiceSpeaker = ({ audioUrl }) => {
  const initAudioState = audioUrl ? new Audio(audioUrl) : '';
  const [audio] = useState(initAudioState);
  const [audioState, setAudioState] = useState('paused');

  useEffect(() => {
    let isCancelled = false;
    if (!audio || !audio.src) {
      setAudioState('loading');
      return () => {
        isCancelled = true;
      };
    }
    if (isCancelled == false) {
      audio.onplaying = () => {
        setAudioState('playing');
      };
      audio.onpause = () => {
        setAudioState('paused');
      };
      audio.onerror = () => {
        setAudioState('paused');
      };

      audio.onloadeddata = () => {
        setAudioState('paused');
      };
    }
    return () => {
      isCancelled = true;
    };
  }, [audio]);

  useEffect(() => {
    return () => {
      audio.pause();
    };
  }, []);

  const renderAudioIcon = () => {
    switch (audioState) {
      case 'loading':
        return <Spinner />;

      case 'playing':
        return <IconPauseCircle />;

      case 'paused':
        return <IconPlayCircle />;

      default:
        return <IconPlayCircle />;
    }
  };

  return (
    <div
      aria-hidden="true"
      style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center' }}
      onClick={() => {
        if (audio && audio.paused) {
          audio.play();
        } else audio.pause();
      }}>
      {renderAudioIcon()}
    </div>
  );
};

export default VoiceSpeaker;

// image spinning
import spinnerImg from '../../assets/images/modules/lesson/spinning_loading.png';
import { LoadingStyle } from './index.style';

const Spinner = () => {
  return (
    // <div style={{ position: 'absolute', left: '40%', top: '30%' }}>
    //     <img
    //         src={childrenImg}
    //         height={250} width={250}
    //         style={{
    //             display: 'inline-block',
    //             animation: 'rotation 2s infinite linear',
    //             '@keyframes rotation': {
    //                 from: { 'transform': 'rotate(0deg)' },
    //                 to: { 'transform': 'rotate(359deg)' }
    //             },
    //         }}
    //     />
    // </div>
    <LoadingStyle>
      <img
        style={{ width: '30%' }}
        src={spinnerImg}
        alt="Loading"
        //   onLoad={() => setLoading(true)}
      />
    </LoadingStyle>
  );
};

export default Spinner;

import { styled } from '@mui/material/styles';

// WIS: Word in Sentence
export const WISBox = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100%;
  width: 100%;

  ${(props) =>
    props.image === undefined || props.opacity === undefined
      ? ''
      : `
        background-image: linear-gradient(rgba(225,225,225,${props.opacity}), rgba(225,225,225,${props.opacity})), url(${props.image});
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
        `};

  .syllable-text {
    display: flex;
    flex-direction: row;
    align-items: start;
    margin-left: 15px;
    margin-top: 15px;
  }

  .square-sector {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 15px;
    margin-left: 7.5%;

    .top-sector {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 5.5px;
    }
  }
`;

// width: 950px;
// height: 500px;
// border: 1px solid black;
// position: relative;

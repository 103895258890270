import { Box, Typography } from '@mui/material';
import React from 'react';
import { ChapterTourStyle } from './index.style';
import StarIcon from '@mui/icons-material/Star';
import LockIcon from '@mui/icons-material/Lock';

const ChapterTour = () => {
  return (
    <ChapterTourStyle>
      <div className="Tour-TopBar">
        <div className="Tour-TopBar__Breadcrumb" data-tut="chapter__breadcrumb">
          <span>Các khóa học</span>
          <span>&gt;</span>
          <span>Tiếng việt cho trẻ em lớp 1</span>
        </div>
      </div>
      <div className="Tour-Main">
        <div className="Tour-SideBar" data-tut="chapter__sidebar">
          <Box data-tut="chapter__chapterbox__open">
            <div className="ChapterBoxStar">
              <StarIcon />
            </div>
            <div className="ChapterBoxNumber">1</div>
            <img
              src={process.env.PUBLIC_URL + '/icons/chapter/ic_chapter_1.png'}
              alt=""
              style={{ maxWidth: '3rem' }}
            />
            <div className="ChapterBoxTitle">
              <Typography variant="h1">Âm đầu của vần</Typography>
            </div>
          </Box>
          <Box data-tut="chapter__chapterbox__close" className="chapterbox-close">
            <div className="ChapterBoxStar">
              <StarIcon />
            </div>
            <div className="ChapterBoxNumber">2</div>
            <img
              src={process.env.PUBLIC_URL + '/icons/chapter/ic_chapter_1.png'}
              alt=""
              style={{ maxWidth: '3rem' }}
            />
            <div className="ChapterBoxTitle">
              <Typography variant="h1">Test</Typography>
            </div>
            <div className="lock-elm">
              <LockIcon />
            </div>
          </Box>
        </div>
        <div className="Tour-Content" data-tut="chapter__content">
          <div className="Tour-Content__Title" data-tut="chapter__title">
            Âm đầu của vần
          </div>
          <div className="Tour-Content__List">
            <div data-tut="chapter__lesson">
              <Typography variant="h1">Bài học</Typography>
              <img
                src={process.env.PUBLIC_URL + '/icons/lesson/ic_lesson_1.png'}
                alt=""
                style={{ maxWidth: '10rem', cursor: 'pointer' }}
              />
              <div className="Content">
                <Typography variant="h1">Làm quen với vần</Typography>
                <div className="ChapterBoxStar">
                  <StarIcon />
                </div>
              </div>
            </div>
            <div data-tut="chapter__test" className="chapter__test_close">
              <Typography variant="h1">Bài test</Typography>
              <img
                src={process.env.PUBLIC_URL + '/icons/ic_test.png'}
                alt=""
                style={{ maxWidth: '10rem', cursor: 'pointer' }}
              />
              <div className="Content">
                <Typography variant="h1">Luyện tập làm quen với vần</Typography>
                <div className="ChapterBoxStar">
                  <StarIcon />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ChapterTourStyle>
  );
};

export default ChapterTour;

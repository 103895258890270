// dnd, mui library
import { useDrop } from 'react-dnd';
import { Typography } from '@mui/material';

// written components
import { FLOATING_BOX_COLOR } from '../../../../../../theme/color';
import { DisplayTopBox } from './index.style';
import { OBJ_WIDTH } from '../../../XSpaceBox/index.style';
import { useEffect } from 'react';
import { useState } from 'react';

export const TextTopBox = ({ text, w }) => {
  return (
    <DisplayTopBox shadow={false} w={w}>
      <Typography variant="h2">{text}</Typography>
    </DisplayTopBox>
  );
};

export const BlankTopBox = ({ boxId, boxValue, children, chosen }) => {
  const [, drop] = useDrop({
    accept: 'ElementOfWord',
    drop: () => ({ boxId, boxValue })
  });

  return (
    <DisplayTopBox
      bgc={FLOATING_BOX_COLOR}
      radius={'10px'}
      isBorder={true}
      ref={drop}
      id={`${boxId}-top`}
      sx={{
        border:
          chosen === 'correct' ? '2px solid green' : chosen === 'incorrect' ? '2px solid red' : ''
      }}>
      {children}
    </DisplayTopBox>
  );
};

export const RhymeBox = ({ boxId, boxValue, children, chosen }) => {
  const [width, setWidth] = useState(0);
  const [, drop] = useDrop({
    accept: 'ElementOfWord',
    drop: () => ({ boxId, boxValue })
  });

  useEffect(() => {
    const widthString = OBJ_WIDTH.substring(0, 2);
    const rhymeWidth = parseInt(widthString) * 3 + 'px';
    setWidth(rhymeWidth);
  }, []);

  return (
    <DisplayTopBox
      w={width}
      bgc={FLOATING_BOX_COLOR}
      radius={'10px'}
      isBorder={true}
      ref={drop}
      id={`${boxId}-top`}
      sx={{
        border:
          chosen === 'correct' ? '2px solid green' : chosen === 'incorrect' ? '2px solid red' : ''
      }}>
      {children}
    </DisplayTopBox>
  );
};

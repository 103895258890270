import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export default function ResponsiveDialog({ showFullAnswers, open, setOpen }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog fullScreen={fullScreen} open={open} onClose={handleClose}>
        <DialogContent>
          <DialogContentText>
            Gợi ý toàn bộ đáp án ? Bạn sẽ không được điểm câu này
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            <Typography variant="h3">Không đồng ý</Typography>
          </Button>
          <Button onClick={showFullAnswers} autoFocus>
            <Typography variant="h3">Đồng ý</Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import { useNavigate } from 'react-router-dom';
import { getCookie, setCookie } from 'src/utils/cookie';
import { getStorage } from 'src/utils/storage';
import { useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';

const Protected = ({ children, requiredRole }) => {
  const navigate = useNavigate();
  const accessToken = getCookie('accessToken');
  const refreshToken = getCookie('refreshToken');
  const userRole = getStorage('userRole');
  const [valid, setValid] = useState(false);

  useEffect(() => {
    if (accessToken === '' || refreshToken === '' || userRole === null) {
      // tokens exist or not
      navigate('/', {
        state: { message: 'not_logged_in' }
      });
      return;
    } else {
      // refreshToken expired -> re-login
      const decodeResult = jwtDecode(refreshToken);
      const compareValue = new Date().getTime() > decodeResult.exp * 1000;
      if (compareValue) {
        setCookie('accessToken', '');
        setCookie('refreshToken', '');
        navigate('/', {
          state: { message: 'refresh_token_expired' }
        });
        return;
      } else {
        // role not match but logged in -> back to main default page
        if (userRole[0] !== requiredRole[0]) {
          navigate('/courses', {
            state: { message: 'not_allowed_role' }
          });
          return;
        }
      }
    }
    setValid(true);
  }, []);

  if (valid) {
    return children;
  }
};

export default Protected;

import { noteActionTypes } from './actions';

export const noteInitState = {
  isShowNote: false,
  noteLists: []
};

const noteReducer = (state = noteInitState, action) => {
  switch (action.type) {
    case noteActionTypes.SET_SHOW_NOTE:
      return {
        ...state,
        isShowNote: action.payload
      };

    case noteActionTypes.SET_NOTE:
      return {
        ...state,
        noteLists: [...state.noteLists, action.payload]
      };

    case noteActionTypes.SET_NEW_NOTES:
      console.log('action.payload', action.payload);
      return {
        ...state,
        noteLists: [...action.payload]
      };

    default:
      return state;
  }
};

export default noteReducer;

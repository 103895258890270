// import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';

// const logMiddleware = createLogger({
//   predicate: () => process.env.NODE_ENV !== 'production',
// });

// const middlewares = [thunk, logMiddleware];
const middlewares = [thunk];

export default middlewares;

// sounding
import useSound from 'use-sound';
import congrat from '../../assets/audio/congratulation.mp3';

// react + mui
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { SlideStyle, ArrowStyle } from '../VSSlide/index.style';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentTestSlide, setDisableTestNext } from 'src/redux/test/action';

const VSTestSlide = ({ data }) => {
  const dispatch = useDispatch();
  const slideRef = useRef();

  const currentSlideIndex = useSelector((state) => state.test.currentSlideIndex);
  const disableNext = useSelector((state) => state.test.disableNext);
  const disablePrev = useSelector((state) => state.test.disablePrev);
  const slideLen = useSelector((state) => state.test.slideLen);

  const navigate = useNavigate();
  const [playCongrat] = useSound(congrat);

  const settings = {
    arrows: false,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    touchMove: false
  };

  const onClickPrev = () => {
    slideRef.current.slickPrev();
  };

  const onClickNext = () => {
    const nextIndex = currentSlideIndex + 1;
    if (nextIndex >= slideLen) {
      playCongrat();
      navigate('/congratulations');
    }

    dispatch(setDisableTestNext(true));
    dispatch(setCurrentTestSlide(nextIndex));
    slideRef.current.slickNext();
  };

  const arrows = () => {
    return (
      <ArrowStyle className="slider-arrow" disableNext={disableNext} disablePrev={disablePrev}>
        <button className="arrow-btn prev-btn" onClick={onClickPrev} disabled={disablePrev}>
          <ArrowBackIosIcon />
        </button>

        <button className="arrow-btn next-btn" onClick={onClickNext} disabled={disableNext}>
          <ArrowForwardIosIcon />
        </button>
      </ArrowStyle>
    );
  };

  return (
    <>
      {arrows()}
      <SlideStyle ref={slideRef} {...settings}>
        {data}
      </SlideStyle>
    </>
  );
};

export default VSTestSlide;

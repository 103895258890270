import apiV2 from '../apiV2';

export const getAll = async ({
  page = 1,
  limit = 10,
  keyword,
  categoryId,
  rarity,
  userId,
  excludeOwnedItem
}) => {
  return await apiV2.get(
    `/award-user/collectionItem/all?page=${page}&limit=${limit}` +
      (keyword ? `&keyword=${keyword}` : '') +
      (categoryId ? `&categoryId=${categoryId}` : '') +
      (rarity ? `&rarity=${rarity}` : '') +
      (userId ? `&userId=${userId}` : '') +
      (excludeOwnedItem ? `&excludeOwnedItem=${excludeOwnedItem}` : '')
  );
};

export const getAllUserItems = async ({
  page = 1,
  limit = 10,
  keyword,
  categoryId,
  rarity,
  userId
}) => {
  return await apiV2.get(
    `/award-user/collectionItem/allUserItems?page=${page}&limit=${limit}` +
      (keyword ? `&keyword=${keyword}` : '') +
      (categoryId ? `&categoryId=${categoryId}` : '') +
      (rarity ? `&rarity=${rarity}` : '') +
      (userId ? `&userId=${userId}` : '')
  );
};

export const purchase = async ({ userId, userFullName, userEmail, collectionItemIds }) => {
  return await apiV2.post('/award-user/collectionItem/purchase', {
    userId,
    userFullName,
    userEmail,
    collectionItemIds
  });
};

import { memo } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardMedia, CardContent, Typography } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';

// utils
import { formatNumber } from '../../../../utils/number';

// components
import VoiceSpeaker from 'src/components/VoiceSpeaker';

// local
import LuckyBoxesCtrl from '../LuckyBoxes.ctrl';

const LuckyBoxCard = ({ boxId }) => {
  // -- ctrl --
  const {
    // selected box id
    setSelectedBoxId,
    // is show modal
    setIsShowModal
  } = LuckyBoxesCtrl.useContainer();

  // -- lucky box --
  const luckyBox = useSelector((state) => state.luckyBoxes.data[boxId]) || {};

  return (
    <Card>
      <div style={{ position: 'relative' }}>
        {/* image */}
        <CardMedia
          sx={{ background: 'white', cursor: 'pointer' }}
          component="img"
          height="300"
          image={luckyBox.imageUrl}
          onClick={() => {
            setSelectedBoxId(boxId);
            setIsShowModal(true);
          }}
        />

        {/* name */}
        <div
          style={{
            position: 'absolute',
            width: '100%',
            textAlign: 'center',
            bottom: 0,
            backgroundColor: 'white',
            color: 'black',
            paddingTop: '5px',
            paddingBottom: '5px'
          }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {luckyBox.nameVoiceUrl && <VoiceSpeaker audioUrl={luckyBox.nameVoiceUrl} />}
            <span style={{ marginLeft: '5px' }}>{luckyBox.name}</span>
          </div>
        </div>
      </div>

      {/* price */}
      <CardContent
        sx={{ background: 'white', borderTop: '1px solid black', padding: '10px !important' }}>
        <Typography sx={{ marginBottom: 0 }} color="text.secondary" align="center" gutterBottom>
          <div
            style={{
              display: 'flex',
              alignItems: 'end',
              justifyContent: 'center'
            }}>
            <StarIcon color="warning" />
            <span>{formatNumber(luckyBox.price)}</span>
          </div>
        </Typography>
      </CardContent>
    </Card>
  );
};

export default memo(LuckyBoxCard);

import { styled } from '@mui/material/styles';
import { FLOATING_BOX_COLOR, BUTTON_COLOR } from 'src/theme/color';

const calculateWidth = (len) => {
  switch (len) {
    case 1:
      return '21.5%';
    case 2:
      return '35%';
    case 3:
      return '50%';
    default:
      return '60%';
  }
};

export const CarouselBox = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: ${(props) => calculateWidth(props.len)};
  height: 150px;
  padding: 10px;

  .courses-skeleton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 15px;
  }
`;

export const BaseCourseBox = styled('div')`
  display: flex;
  flex-direction: row;
  background-color: ${FLOATING_BOX_COLOR};
  border: 2.5px solid #0d4dc9;
  border-radius: 10px;
  padding: 25px 30px;
  margin-top: 10px;
  filter: ${(props) => (props.canLearn ? 'none' : 'contrast(0.8)')};

  .left-part {
    flex: 3.5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 20px;

    .left-title {
      font-size: 24px;
      font-weight: bold;
      text-align: center;
      max-width: 100%;
    }

    img {
      border-radius: 10px;
      margin-top: 6%;
      margin-bottom: 15px;
      width: 200px;
      height: 200px;
      border: 1px solid #0d4dc9;
    }

    .learn-required-text {
      color: red;
      text-align: center;
      margin-top: 0;
      font-size: 18px;
      margin-bottom: 6px;
    }

    .MuiButton-root {
      background-color: ${(props) => (props.canLearn ? BUTTON_COLOR : '#9b9b9b')};
      font-size: 16px;
      color: white;
      text-align: center;
      text-transform: none;
      width: 200px;
    }
  }

  .right-part {
    flex: 6;
    display: flex;
    flex-direction: column;
    margin-left: 30px;
  }

  /* .lock-elm {
        position: absolute;
        color: ${BUTTON_COLOR};
        left: 45%;
        top: 36%;

        svg {
            font-size: 100px;
        }
    } */
`;
//top bottom right left = (top + bottom) (right + left)

// objectFit: 'contain' (image)

// react & mui
import { useEffect, useState } from 'react';

// customed ui
import QuestionBox from '../../../QuizContainer/ToolParts/QuestionBox';
import { getTrueTestAnswers } from './index.logic';
import { MCTestBox } from './index.style';
import RadioTestAnswers from './RadioTestAnswers';
import CheckboxTestAnswers from './CheckboxTestAnswers';

const MCQuizTestContainer = ({ data }) => {
  // in order to render
  const [mcType, setMcType] = useState('multiple');

  // handle logic for one true answer = radio button
  const [radioTrueIndex, setRadioTrueIndex] = useState(-1);
  const [currentChosenIndex, setCurrentChosenIndex] = useState(-1);

  // handle logic for multiple true answers = checkbox button
  const [checkboxTrueIndexes, setCheckboxTrueIndexes] = useState([]);
  const [currentChosenIndexes, setCurrentChosenIndexes] = useState([]);

  useEffect(() => {
    const result = getTrueTestAnswers(data.quizParts);
    if (result.type === 'one') {
      setMcType('one');
      setRadioTrueIndex(result.trueAnswer);
    } else {
      setMcType('multiple');
      setCheckboxTrueIndexes(result.trueAnswer);
    }
  }, []);

  // REAL: audioUrl={data.questionAudioUrl}
  return (
    <MCTestBox image={data.backgroundUrl} opacity={data.backgroundOpacity}>
      <QuestionBox
        imgUrl={data.questionImageUrl}
        audioUrl={data.questionAudioUrl}
        question={data.question}
      />
      {mcType === 'one' ? (
        <RadioTestAnswers
          options={data.quizParts}
          trueAnswer={radioTrueIndex}
          currentChosenIndex={currentChosenIndex}
          setCurrentChosenIndex={setCurrentChosenIndex}
        />
      ) : (
        <CheckboxTestAnswers
          options={data.quizParts}
          trueAnswers={checkboxTrueIndexes}
          currentChosenIndexes={currentChosenIndexes}
          setCurrentChosenIndexes={setCurrentChosenIndexes}
        />
      )}
    </MCTestBox>
  );
};

export default MCQuizTestContainer;

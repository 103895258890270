import { styled } from '@mui/material/styles';

export const VerifyBox = styled('div')`
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  .login-header {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 15px;
  }

  .login-content {
    width: 500px;

    .login-content__email {
      margin-bottom: 20px;
    }

    .login-content__password {
      margin-bottom: 32px;
    }

    .login-content__title {
      margin-bottom: 15px;
      h3 {
        font-size: 20px;
      }
    }

    .login-content__input {
      input {
        width: 100%;
        height: 36px;
        font-size: 18px;
        border-radius: 6px;
        background-color: #dddddd38;
        border: 2px solid #a0a0a0;
        padding: 8px;
      }
    }

    .error-message {
      color: red;
      margin-bottom: 10px;
      text-align: center;
    }

    .success-message {
      color: green;
      margin-bottom: 10px;
      text-align: center;
    }

    .sent-another {
      color: green;
      margin-top: 12px;
      text-align: center;
    }

    .visible-icon {
      position: absolute;
      margin-left: -34px;
      margin-top: 6px;
    }

    button {
      width: 100%;
    }
  }

  .login-footer {
    display: flex;
    gap: 7.5px;
    text-align: center;
  }
`;

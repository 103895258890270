// dnd library
import { useDrop } from 'react-dnd';

// written component
import { DisplayBtmBox } from './index.style';

const BottomBox = ({ boxId, children }) => {
  const [, drop] = useDrop({
    accept: 'phonemeOfRhyme',
    drop: () => ({ boxId })
  });

  return (
    <DisplayBtmBox
      ref={drop}
      sx={{
        '&::-webkit-scrollbar': {
          maxHeight: '8px'
        },
        '&::-webkit-scrollbar-track': {
          background: 'transparent'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#CDC5BD',
          borderRadius: '10px'
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555'
        }
      }}>
      {children}
    </DisplayBtmBox>
  );
};

export default BottomBox;

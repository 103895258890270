export const moveItemToAnotherBox = (currentItem, newBoxId, setItems) => {
  if (newBoxId !== 'bottom-box') {
    setItems((prev) => {
      return prev.map((e) => {
        return {
          ...e,
          belongTo:
            e.belongTo === newBoxId
              ? 'bottom-box'
              : e.answerId === currentItem.answerId
              ? newBoxId
              : e.belongTo
        };
      });
    });
  }
};

export const changeChosen = (oldBelongTo, tboxId, newState, setChosen) => {
  setChosen((prev) => {
    return prev.map((e) => {
      return {
        ...e,
        boxes: e.boxes.map((ele) => {
          if (ele === 'tab' || ele === '\n') return ele;
          else
            return {
              ...ele,
              chosen:
                ele.boxId === oldBelongTo ? 'none' : ele.boxId === tboxId ? newState : ele.chosen
            };
        })
      };
    });
  });
};

export const compare = (string1, string2) => {
  if (string1 == string2) return true;
  else return false;
};

import { styled } from '@mui/material/styles';
import Link from '@mui/material/Link';
import { BUTTON_COLOR, SIDEBAR_COLOR } from '../../../theme/color';
import { Typography } from '@mui/material';
import ABC_bg from '../../../assets/images/modules/abc_bg.jpg';

export const PageLayoutStyle = styled('div')`
  height: 100vh;
  position: relative;
  isolation: isolate;
  background-image: url(${ABC_bg});
  &:after {
    content: '';
    background-color: #fffadc;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 0;
    position: absolute;
    opacity: 0.8;
    inset: 0;
    z-index: -1;
  }

  .MuiPaper-root {
    display: flex;
    flex-direction: row;
    padding: 0 40px;
    align-items: center;
    justify-content: ${(props) => (props.logoDisplay !== 'none' ? 'center' : 'space-between')};
    background-color: #ffc000;
    height: 7%;

    .end {
      display: flex;
      justify-content: center;
      align-items: center;

      .logo {
        width: 32%;
        display: ${(props) => props.logoDisplay};
      }
    }
  }

  .PageContent {
    display: flex;
    height: 93%;
  }
`;

export const StyledBreadcrumb = styled(Link)`
  padding: 6px 10px;
  border-radius: 14px;
  display: ${(props) => props.breadcrumbsdisplay};
  text-decoration: none;
  color: #6c6c6cde;
  &:hover {
    color: inherit;
  }
`;
export const StyledTypography = styled(Typography)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 120px;
`;

export const ProgressStyle = styled('div')`
  display: ${(props) => (props.progressDisplay ? props.progressDisplay : 'flex')};
  align-items: center;

  .StartEndProgress {
    width: 36px;
    height: 36px;
    border: 1px solid ${BUTTON_COLOR};
    border-radius: 50%;
    background-color: white;
    color: ${BUTTON_COLOR};
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ProgressContainer {
    margin: 0 6px;
    height: 36px;
    width: 200px;
    border: 1px solid ${BUTTON_COLOR};
    border-radius: 20px;
    background-color: ${SIDEBAR_COLOR};
    .Completed {
      height: 100%;
      background-color: ${BUTTON_COLOR};
      width: ${(props) => props.completed};
      border-radius: inherit;
      text-align: right;
      img {
        height: 100%;
      }
    }
  }
`;

// mui components
import { Typography, Box, Fab } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ReplayIcon from '@mui/icons-material/Replay';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';

// react main components and other libraries
import { useEffect, useState } from 'react';

// logic function
import { collectSyllableLines, collectWords, collectWordsLen, countCorrect } from './index.logic';

// customed style
import { TOWBox } from './index.style';
import PhonemeLine from './PhonemeLine';
import QuestionBox from '../../ToolParts/QuestionBox';
import ResponsiveDialog from '../../confirm-dialog';

// sounding effect
import useSound from 'use-sound';
import useHint from '../../../../assets/audio/use_hint.mp3';

// redux
import { useDispatch } from 'react-redux';
import { setDisableNext } from 'src/redux/lesson/actions';

const ToneOfWordContainer = ({ data }) => {
  const [wordList, setWordList] = useState([]);
  const [syllables, setSyllables] = useState([]);
  const [isCenter, setIsCenter] = useState(false);
  const [replayNum, setReplayNum] = useState(3);
  const [hintNum, setHintNum] = useState(2);
  const [open, setOpen] = useState(false);
  const [playHintSound] = useSound(useHint);
  const [trueLength, setTrueLength] = useState(0);
  const [isHint, setIsHint] = useState(false);
  const [randHintId, setRandHintId] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    init();
    const len = data.quizParts[0].squareQuizParts.length;
    if (len <= 5) setIsCenter(true);
    else setIsCenter(false);
  }, []);

  useEffect(() => {
    const trueTones = countCorrect(wordList);
    if (trueTones >= trueLength) {
      dispatch(setDisableNext(false));
    }
  }, [wordList]);

  const reset = () => {
    if (replayNum !== 0) {
      setReplayNum(replayNum - 1);
      setHintNum(2);
      init();
    } else {
      // play warning instruction
    }
  };

  const init = () => {
    const len = collectWordsLen(data.quizParts);
    setTrueLength(len);

    const slbs = collectSyllableLines(data.quizParts);
    setSyllables(slbs);

    const clts = collectWords(data.quizParts, data._id);
    setWordList(clts);
  };

  const showInstruction = () => {};

  const showHint = () => {
    if (hintNum !== 0) {
      setHintNum(hintNum - 1);
      const words = wordList.map((e) => e.words)[0]; // only 1 row
      const notSelected = words.filter((w) => w.selectedTone === '');
      if (notSelected.length !== 0) {
        const randomHintWord = notSelected[Math.floor(Math.random() * notSelected.length)];
        setRandHintId(randomHintWord.wordId);
        setIsHint(!isHint);
      }
    } else {
      setOpen(true);
    }
    playHintSound();
  };

  const showFullAnswers = () => {
    dispatch(setDisableNext(false));
    setWordList((prev) => {
      return prev.map((e) => {
        return {
          ...e,
          words: e.words.map((ele) => {
            return {
              ...ele,
              selectedTone: ele.trueTone
            };
          })
        };
      });
    });
    setOpen(false);
  };

  return (
    <TOWBox isCenter={isCenter} image={data.backgroundUrl} opacity={data.backgroundOpacity}>
      <QuestionBox
        imgUrl={data.questionImageUrl}
        audioUrl={data.questionAudioUrl}
        question={data.question}
      />
      {wordList.map((e, index) => {
        return (
          <Box key={index} sx={{ width: '800px' }}>
            <Typography variant="h2" textAlign="left" fontStyle="italic">
              {syllables[index]}
            </Typography>
          </Box>
        );
      })}
      <Box
        className="scrollable"
        sx={{
          '&::-webkit-scrollbar': {
            maxWidth: '8px',
            maxHeight: '8px'
          },
          '&::-webkit-scrollbar-track': {
            background: 'transparent'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#CDC5BD',
            borderRadius: '10px'
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#555'
          }
        }}>
        <Box className="drop-down-sector">
          {wordList.map((e, index) => {
            return (
              <div key={index}>
                <Box className="top-sector">
                  <PhonemeLine
                    lineData={e}
                    setWordList={setWordList}
                    isHint={isHint}
                    randHintId={randHintId}
                  />
                </Box>
              </div>
            );
          })}
        </Box>
      </Box>
      <Fab
        color="warning"
        size="small"
        sx={{ position: 'absolute', right: '10px', bottom: '110px' }}
        onClick={showHint}>
        {hintNum}
        <TipsAndUpdatesIcon />
      </Fab>
      <Fab
        color="warning"
        size="small"
        sx={{ position: 'absolute', right: '10px', bottom: '60px' }}
        onClick={showInstruction}>
        <HelpOutlineIcon />
      </Fab>
      <Fab
        color="warning"
        size="small"
        sx={{ position: 'absolute', right: '10px', bottom: '10px' }}
        onClick={reset}>
        {replayNum}
        <ReplayIcon />
      </Fab>
      <ResponsiveDialog open={open} setOpen={setOpen} showFullAnswers={showFullAnswers} />
    </TOWBox>
  );
};

export default ToneOfWordContainer;

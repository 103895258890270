export const checkpointActionTypes = {
  SET_LIST_CHECKPOINTS_LENGTH: 'SET_LIST_CHECKPOINTS_LENGTH',
  AUDIO_PLAY: 'AUDIO_PLAY'
};

export const setAudioPlay = (data) => {
  return {
    type: checkpointActionTypes.AUDIO_PLAY,
    payload: data
  };
};

export const setListCheckpointsLength = (data) => {
  return {
    type: checkpointActionTypes.SET_LIST_CHECKPOINTS_LENGTH,
    payload: data
  };
};

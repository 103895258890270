import { checkpointActionTypes } from './actions';

export const checkpointInitState = {
  listCheckpointsLength: 0,
  audioPlay: false
};

const checkpointReducer = (state = checkpointInitState, action) => {
  switch (action.type) {
    case checkpointActionTypes.SET_LIST_CHECKPOINTS_LENGTH:
      return {
        ...state,
        listCheckpointsLength: action.payload
      };
    case checkpointActionTypes.AUDIO_PLAY:
      return {
        ...state,
        audioPlay: action.payload
      };
    default:
      return state;
  }
};

export default checkpointReducer;

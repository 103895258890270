import {
  ElementsInWord,
  ElementsOfWord,
  PhonemeInRhyme,
  PhonemeInSyllable,
  PhonemeOfRhyme,
  PhonemeOfSyllable,
  ToneOfWord,
  WordInSentence
} from 'src/components/Square';
import { checkTone } from 'src/utils';
import React from 'react';

const SquareType = ({ square, type }) => {
  const data = {
    text: square.content.text,
    rhymeText: square.structure?.rhyme.content.text,
    tone: square.structure?.tone,
    toneIcon: checkTone(square.structure?.tone),
    consonant: square.structure?.consonant,
    startRhyme: square.structure?.rhyme.structure?.startRhyme,
    mainRhyme: square.structure?.rhyme.structure?.mainRhyme,
    finishRhyme: square.structure?.rhyme.structure?.finishRhyme
  };

  switch (type) {
    case 'word_in_sentence':
      return <WordInSentence data={data} />;
    case 'tone_of_word':
      return <ToneOfWord data={data} />;
    case 'elements_in_word':
      return <ElementsInWord data={data} />;
    case 'elements_of_word':
      return <ElementsOfWord data={data} />;
    case 'phoneme_in_syllable':
      return <PhonemeInSyllable data={data} />;
    case 'phoneme_of_syllable':
      return <PhonemeOfSyllable data={data} />;
    case 'phoneme_in_rhyme':
      return <PhonemeInRhyme data={data} />;
    case 'phoneme_of_rhyme':
      return <PhonemeOfRhyme data={data} />;
    default:
      alert("Can't find this square type");
      break;
  }
};

export default SquareType;

import { combineReducers } from 'redux';
import auth, { initialState as authInitialState } from './auth/reducer';
import noti, { initialState as notiInitialState } from './noti/reducer';
import course, { courseInitState } from './course/reducer';
import chapter, { chapterInitState } from './chapter/reducer';
import lesson, { lessonInitState } from './lesson/reducer';
import checkpoint, { checkpointInitState } from './checkpoint/reducer';
import test, { testInitState } from './test/reducer';
import note, { noteInitState } from './note/reducer';
import path, { pathInitState } from './path/reducer';
import collectionItems from './award-admin/collectionItem/collectionItem';
import userCollectionItems from './award-admin/collectionItem/userCollectionItem';
import luckyBoxes from './award-admin/luckyBox/luckyBox';
import userAwards from './award-admin/userAward/userAward';

export const initialState = {
  auth: authInitialState,
  noti: notiInitialState,
  course: courseInitState,
  chapter: chapterInitState,
  lesson: lessonInitState,
  checkpoint: checkpointInitState,
  test: testInitState,
  note: noteInitState,
  path: pathInitState
};

export default combineReducers({
  auth,
  noti,
  course,
  test,
  chapter,
  lesson,
  checkpoint,
  collectionItems,
  userCollectionItems,
  luckyBoxes,
  userAwards,
  note,
  path
});

// customed components
import { ChapterContentStyle } from './index.style';
import SectionBox from '../SectionBox';

// mui & redux
import { Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { getStorage } from 'src/utils/storage';
import { useEffect } from 'react';
import { useState } from 'react';

const ChapterContent = () => {
  const [listCanLearnSectionIndex, setListCanLearnSectionIndex] = useState([]);
  const currentChapter = useSelector((state) => state.chapter.currentChapter); // use for call api
  const currentStorageResult = getStorage('result');
  const [canLearnChapter, setCanLearnChapter] = useState(false); // use for local storage

  useEffect(() => {
    let listIndex = [];
    currentChapter?.newSection.forEach((section, index) => {
      const foundSection = currentStorageResult?.canLearnSectionIds.find(
        (sectionId) => sectionId == section[`${section.type}Id`]
      );
      if (foundSection) listIndex.push(index);
    });
    setListCanLearnSectionIndex([...listIndex]);

    if (currentStorageResult?.canLearnChapterIds.includes(currentChapter.id)) {
      setCanLearnChapter(true);
    }
  }, [currentChapter]);

  return (
    <ChapterContentStyle>
      <div className="ChapterTitle animate__animated animate__bounceInLeft">
        <Typography variant="h0" fontWeight="bold">
          {currentChapter?.title}
        </Typography>
      </div>
      <Grid container>
        {currentChapter?.newSection?.length > 0 &&
          currentChapter?.newSection.map((section, index) => (
            <Grid key={index} item xl={4} md={4} xs={6}>
              <SectionBox
                index={index}
                section={section}
                // canLearnChapter={currentChapter.canLearn}
                canLearnChapter={canLearnChapter}
                canLearnSection={index <= Math.max(...listCanLearnSectionIndex) ? true : false}
              />
            </Grid>
          ))}
      </Grid>
    </ChapterContentStyle>
  );
};

export default ChapterContent;

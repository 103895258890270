import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StyledBreadcrumb } from 'src/containers/Layout/PageLayout/index.style';
import { StyledTypography } from 'src/containers/Layout/PageLayout/index.style';
import { Typography } from '@mui/material';
import { getTestById } from 'src/apis/test';
import { buildTestContainer } from 'src/containers/TestContainer/index.logic';
import TestOverview from 'src/containers/TestContainer/TestOverview';
import { setCurrentTestSlide } from 'src/redux/test/action';
import { setSlideLen } from 'src/redux/test/action';
import LessonLayout from 'src/containers/Layout/LessonLayout';
import VSTestSlide from 'src/components/VSTestSlide';
import PageLayout from 'src/containers/Layout/PageLayout';
import Spinner from 'src/components/Spinner';

const DoTest = () => {
  const dispatch = useDispatch();
  const currentCourse = useSelector((store) => store.course.currentCourse);
  const currentSection = useSelector((store) => store.course.currentSection);
  const currentChapter = useSelector((store) => store.chapter.currentChapter);
  const currentSlideIndex = useSelector((state) => state.lesson.currentSlideIndex);

  const [combinedData, setCombinedData] = useState([]);

  const breadcrumbs = [
    <StyledBreadcrumb
      key="2"
      underline="none"
      color="inherit"
      href={`/courses/${currentCourse.id}/chapters`}>
      <StyledTypography variant="h2">{currentCourse.title}</StyledTypography>
    </StyledBreadcrumb>,
    <Typography key="3" underline="none" color="inherit" variant="h2">
      {currentChapter?.title}
    </Typography>
  ];

  useEffect(() => {
    const fetchTestData = async (testId) => {
      const fetchedTest = await getTestById('v1', testId);
      const test = fetchedTest.result;

      const containers = buildTestContainer(test.quizIds);
      setCombinedData([
        <TestOverview
          key={100}
          title={test.title}
          testPurpose={test.testPurpose}
          quizNum={test.quizIds.length}
          stars={test.quizIds.length}
        />,
        ...containers
      ]);
      dispatch(setCurrentTestSlide(0));
      dispatch(setSlideLen(containers.length));
    };

    fetchTestData(currentSection.testId);
  }, []);

  return combinedData && combinedData.length !== 0 ? (
    <LessonLayout
      breadcrumbs={breadcrumbs}
      logoDisplay={'none'}
      completed={
        currentSlideIndex === 0 ? '0%' : `${((currentSlideIndex + 1) / combinedData.length) * 100}%`
      }>
      <div style={{ width: '100%', height: '100%' }}>
        <VSTestSlide data={combinedData} />
      </div>
    </LessonLayout>
  ) : (
    <PageLayout breadcrumbs={breadcrumbs} logoDisplay={'none'} progressDisplay={'none'}>
      <Spinner />
    </PageLayout>
  );
};

export default DoTest;

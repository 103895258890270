import { styled } from '@mui/material/styles';

export const BLANK_BOX_WIDTH = '106px';
export const BLANK_BOX_HEIGHT = '72px';

// BQ = blank quiz
// width: 950px;
export const BQBox = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100%;
  width: 100%;

  ${(props) =>
    props.image === undefined || props.opacity === undefined
      ? ''
      : `
        background-image: linear-gradient(rgba(225,225,225,${props.opacity}), rgba(225,225,225,${props.opacity})), url(${props.image});
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
        `};

  .dnd-sector {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 100px;

    .scrollable {
      display: flex;
      flex-direction: row;
      overflow: auto;
      max-height: 235px;
      max-width: 800px;

      .top-sector {
        display: flex;
        flex-direction: row;
        justify-content: center;
      }
    }
  }
`;

// width: 950px;
// height: 500px;
// border: 1px solid black;
// position: relative;

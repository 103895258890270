import { styled } from '@mui/material/styles';
import checkpoint2_bg from 'src/assets/images/modules/checkpoints/checkpoint2_bg.png';

export const CheckpointStyle = styled('div')`
  color: red;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-image: url(${checkpoint2_bg});
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  img {
    width: 10%;
    position: absolute;
    cursor: pointer;
  }

  .Tornado1 {
    right: 25%;
    bottom: 25%;
  }

  .Tornado2 {
    left: 18%;
    top: 20%;
  }
`;

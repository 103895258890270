export const testActionType = {
  SET_DISABLE_TEST_NEXT: 'SET_DISABLE_TEST_NEXT',
  // SET_DISABLE_TEST_PREV: 'SET_DISABLE_TEST_PREV',
  SET_CURRENT_TEST_SLIDE: 'SET_CURRENT_TEST_SLIDE',
  SET_SLIDE_LENGTH: 'SET_SLIDE_LENGTH'
};

export const setDisableTestNext = (data) => {
  return {
    type: testActionType.SET_DISABLE_TEST_NEXT,
    payload: data
  };
};

export const setCurrentTestSlide = (data) => {
  return {
    type: testActionType.SET_CURRENT_TEST_SLIDE,
    payload: data
  };
};

export const setSlideLen = (data) => {
  return {
    type: testActionType.SET_SLIDE_LENGTH,
    payload: data
  };
};

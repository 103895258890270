import { styled } from '@mui/material';
import {
  FLOATING_BOX_COLOR,
  BORDER_COLOR,
  BUTTON_COLOR,
  BACKGROUND_MAIN_COLOR
} from 'src/theme/color';
import ABC_bg from '../../../assets/images/modules/abc_bg.jpg';

export const TheoryTourStyle = styled('div')`
  height: 100%;
  width: 100%;
  background-image: url(${ABC_bg});
  opacity: 0.8;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 4px solid ${BUTTON_COLOR};

  .arrow-btn {
    background-color: ${BUTTON_COLOR};
    color: white;
    border: none;
    border-radius: 50%;
    padding: 6px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Content {
    background-color: ${FLOATING_BOX_COLOR};
    margin-top: 2%;
    height: 90%;
    width: 90%;
    padding: 12px;
    border: 4px solid ${BORDER_COLOR};
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px,
      rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px,
      rgba(0, 0, 0, 0.09) 0px -3px 5px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;

    .Title {
      height: 10%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .TheoryContent {
      height: 90%;
      margin-top: 24px;

      .MuiGrid-container {
        height: 100%;
        width: 100%;
        margin-left: 0;

        .MuiGrid-item {
          height: 45%;
          max-width: 45%;
          border-radius: 8px;
          box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
          padding: 8px;
          margin: 12px;
          display: flex;
          justify-content: center;
          align-items: center;

          .Read {
            .theory__read--list--mode {
              display: flex;
              justify-content: center;
              align-items: center;

              .ReadMode {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-radius: 8px;
                border: 2px solid ${BUTTON_COLOR};
                padding: 4px 12px;
                margin: 4px;
                position: relative;
                width: 132px;

                .mode {
                  color: ${BUTTON_COLOR};
                  position: absolute;
                  top: -16px;
                  display: flex;
                  justify-content: center;
                  align-items: end;
                  font-size: 12px;

                  svg {
                    width: 16px;
                    height: 16px;
                  }
                }
              }
            }
            .Text {
              margin-top: 8px;
              display: flex;
              position: relative;

              .highlight-text {
                background: #cccccc96;
                margin-right: 6px;
                margin-bottom: 10px;
                padding: 4px 8px;
                border-radius: 8px;
              }

              .Text--detail {
                width: 180px;
                height: 120px;
                padding: 4px;
                background-color: ${BACKGROUND_MAIN_COLOR};
                border: 2px solid ${BUTTON_COLOR};
                border-radius: 6px;
                position: absolute;
                right: -74px;
                top: 26px;

                .detail--header {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }

                .detail--line {
                  border-bottom: 2px solid ${BUTTON_COLOR};
                  margin-top: 8px;
                  margin-bottom: 16px;
                }

                .detail--voice {
                  display: flex;
                  justify-content: center;
                  align-items: center;

                  .ReadMode {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-radius: 8px;
                    border: 2px solid ${BUTTON_COLOR};
                    padding: 4px 12px;
                    margin: 4px;
                    position: relative;
                    width: 132px;

                    .mode {
                      color: ${BUTTON_COLOR};
                      position: absolute;
                      top: -16px;
                      display: flex;
                      justify-content: center;
                      align-items: end;
                      font-size: 12px;

                      svg {
                        width: 16px;
                        height: 16px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .Note {
      position: absolute;
      top: 0;
      right: -50px;

      img {
        width: 40px;
        height: 40px;
      }

      .Note__content {
        width: 200px;
        height: 280px;
        background-color: ${BACKGROUND_MAIN_COLOR};
        border: 4px solid ${BORDER_COLOR};
        position: absolute;
        top: 0;
        right: 50px;
        text-align: center;

        .title {
          padding: 8px 0;
          font-weight: bold;
        }

        .lists {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .item {
            border-bottom: 1px solid;
            width: 80%;
            text-align: end;
            padding: 4px 0;
            .item-content {
              position: absolute;
              left: 76px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            svg {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }
  }
`;

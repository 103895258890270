import React from 'react';
import { ProgressStyle } from './index.style';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import LearningImg from '../../../assets/images/modules/lesson/learning.png';

const Progress = ({ progressDisplay, completed }) => {
  return (
    <ProgressStyle progressDisplay={progressDisplay} completed={completed}>
      <div className="StartEndProgress">
        <SportsScoreIcon />
      </div>
      <div className="ProgressContainer">
        <div className="Completed">
          <img src={LearningImg} alt="learning image" />
        </div>
      </div>
      <div className="StartEndProgress">
        <BookmarkAddedIcon />
      </div>
    </ProgressStyle>
  );
};

export default Progress;

export const moveItemToAnotherBox = (currentItem, newBoxId, setItems) => {
  if (newBoxId !== 'bottom-box') {
    setItems((prev) => {
      return prev.map((e) => {
        return {
          ...e,
          belongTo:
            e.belongTo === newBoxId
              ? 'bottom-box'
              : e.answerId === currentItem.answerId
              ? newBoxId
              : e.belongTo
        };
      });
    });
  }
};

export const changeChosen = (oldBelongTo, tboxId, newState, setChosen) => {
  setChosen((prev) => {
    return prev.map((e) => {
      return {
        ...e,
        chosen: e.boxId === oldBelongTo ? 'none' : e.boxId === tboxId ? newState : e.chosen
      };
    });
  });
};

export const compare = (string1, string2) => {
  const dropIds = string1.split('-');
  const dragIds = string2.split('-');

  if (dropIds[0] === dragIds[0] && dropIds[1] === dragIds[1] && dragIds[2] === 'true') {
    return true;
  }
  return false;
};

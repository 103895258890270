import { DrawerHeader } from './index.style';
import StarIcon from '@mui/icons-material/Star';
import { Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

const UserInfoBox = (props) => {
  const { t } = useTranslation();
  return (
    <DrawerHeader>
      <img
        src={props.avatarUrl}
        alt="user avatar"
        style={{
          borderRadius: '100%',
          backgroundColor: 'rgba(252, 235, 123,0.505 )'
        }}
      />

      <Typography fontWeight="bold" variant="h2" textAlign="center">
        {props.fullname}
      </Typography>

      <Typography
        fontWeight="bold"
        variant="h3"
        textAlign="center"
        className="bio"
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: '1',
          WebkitBoxOrient: 'vertical'
        }}>
        {props.biography === 'no_bio' ? t('no_bio') : props.biography}
      </Typography>

      <Box className="star-box" sx={{ marginTop: { md: '12.5px', lg: '17px' } }}>
        <Typography fontWeight="bold" variant="h2" textAlign="center">
          {props.starNum}
        </Typography>
        <StarIcon fontSize="large" sx={{ color: 'orange', fontSize: 40 }} />
      </Box>
    </DrawerHeader>
  );
};

export default UserInfoBox;

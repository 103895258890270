import { styled } from '@mui/material/styles';

// TOW: Tone of Word
export const TOWBox = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100%;
  width: 100%;

  ${(props) =>
    props.image === undefined || props.opacity === undefined
      ? ''
      : `
        background-image: linear-gradient(rgba(225,225,225,${props.opacity}), rgba(225,225,225,${props.opacity})), url(${props.image});
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
        `};

  .scrollable {
    display: flex;
    justify-content: ${(props) => (props.isCenter == true ? 'center' : 'start')};
    align-items: center;
    overflow: auto;
    max-height: 235px;
    max-width: 800px;
    min-width: 700px;

    .syllable-text {
      display: flex;
      flex-direction: row;
      align-items: start;
      margin-left: 15px;
      margin-top: 15px;
      margin-bottom: 15px;
    }

    .drop-down-sector {
      display: flex;
      flex-direction: column;
      gap: 15px;

      .top-sector {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5.5px;
      }
    }
  }
`;

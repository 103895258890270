import { styled } from '@mui/material/styles';
import { BLANK_BOX_HEIGHT, BLANK_BOX_WIDTH } from '../../index.style';

export const DisplayTopBox = styled('div')`
  display: flex;
  flex-direction: row;
  min-width: ${BLANK_BOX_WIDTH};
  min-height: ${BLANK_BOX_HEIGHT};
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.bgc || 'transparent'};
  border-radius: ${(props) => props.radius || '0px'};
  border: ${(props) => (props.isBorder === true ? '1px solid black' : '')};
  margin-bottom: 10px;
  margin-left: 7.5px;
`;

// with text: transparent color
// with blank box drag and drop: props.bgc (background-color)

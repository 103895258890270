import { testActionType } from './action';

export const testInitState = {
  slideLen: 0,
  currentSlideIndex: 0,
  disableNext: false,
  disablePrev: true
};

const testReducer = (state = testInitState, action) => {
  switch (action.type) {
    case testActionType.SET_DISABLE_TEST_NEXT:
      return {
        ...state,
        disableNext: action.payload
      };
    case testActionType.SET_CURRENT_TEST_SLIDE:
      return {
        ...state,
        currentSlideIndex: action.payload
      };
    case testActionType.SET_SLIDE_LENGTH:
      return {
        ...state,
        slideLen: action.payload
      };
    default:
      return state;
  }
};

export default testReducer;

// mui components
import { Box, Typography } from '@mui/material';

// written components
import SpaceBox, { Gap, TabBox } from '../../XSpaceBox';
import { BlankTopBox } from '../DropBox/TopBox';

// written styles
import { LineBox } from './index.style';

// words, tonesAbove, boxes, tonesBelow
const PhonemeLine = ({ lineData, returnItemsForBox }) => {
  return (
    <LineBox>
      <Box className="word-line">
        {lineData.words.map((e, index) => {
          return (
            <Box className="rhyme-text" key={index}>
              <Typography variant="h1">{e}</Typography>
            </Box>
          );
        })}
      </Box>

      <Box className="other-tone-line">
        {lineData.tonesAbove.map((e, index) => {
          switch (e) {
            case 'gap':
              return <Gap key={index} />;
            case 'space':
              return <SpaceBox key={index} />;
            case 'tab':
              return <TabBox key={index} />;
            default:
              return (
                <BlankTopBox key={index} boxId={e.boxId} chosen={e.chosen} boxValue={e.boxValue}>
                  {returnItemsForBox(e.boxId)}
                </BlankTopBox>
              );
          }
        })}
      </Box>

      <Box className="line">
        {lineData.boxes.map((e, index) => {
          if (e === 'tab') return <TabBox key={index} />;
          else if (e === 'gap') return <Gap key={index} />;
          else
            return (
              <BlankTopBox key={index} boxId={e.boxId} chosen={e.chosen} boxValue={e.boxValue}>
                {returnItemsForBox(e.boxId)}
              </BlankTopBox>
            );
        })}
      </Box>

      <Box className="drop-tone-line">
        {lineData.tonesBelow.map((e, index) => {
          switch (e) {
            case 'gap':
              return <Gap key={index} />;
            case 'space':
              return <SpaceBox key={index} />;
            case 'tab':
              return <TabBox key={index} />;
            default:
              return (
                <BlankTopBox key={index} boxId={e.boxId} chosen={e.chosen} boxValue={e.boxValue}>
                  {returnItemsForBox(e.boxId)}
                </BlankTopBox>
              );
          }
        })}
      </Box>
    </LineBox>
  );
};

export default PhonemeLine;

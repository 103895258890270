import React, { useEffect, useRef } from 'react';
import { LessonOverviewStyle } from './index.style';
import Spinner from 'src/components/Spinner';
import { useSelector } from 'react-redux';

const LessonOverview = ({ overview }) => {
  const isOverviewAudioPause = useSelector((state) => state.lesson.isOverviewAudioPause);
  const welcomeAudioRef = useRef();
  const contentAudioRef = useRef();

  useEffect(() => {
    if (isOverviewAudioPause) {
      if (overview?.welcome?.audioUrl) {
        welcomeAudioRef.current.pause();
      }
      if (overview?.content?.audioUrl) {
        contentAudioRef.current.pause();
      }
    }
  }, [isOverviewAudioPause]);

  return overview ? (
    <LessonOverviewStyle background={overview.background}>
      {overview?.welcome?.audioUrl !== '' ? (
        <audio autoPlay ref={welcomeAudioRef}>
          <source src={overview?.welcome?.audioUrl} type="audio/ogg" />
        </audio>
      ) : (
        <></>
      )}
      {overview?.content?.audioUrl ? (
        <audio autoPlay ref={contentAudioRef}>
          <source src={overview?.content?.audioUrl} type="audio/ogg" />
        </audio>
      ) : (
        <></>
      )}
    </LessonOverviewStyle>
  ) : (
    <Spinner />
  );
};

export default LessonOverview;

import React from 'react';

import routes from '../constants/routes';

// auth
import Login from '../pages/auth/login';
import Register from '../pages/auth/register';

// before learning
import Course from '../pages/before_learning/Course';
import Setting from '../pages/before_learning/Setting';
import PersonalInfo from '../pages/before_learning/PersonalInfo';
import Award from '../pages/before_learning/Award';
import StudyingResult from '../pages/before_learning/StudyingResult';
import VietStudyStore from '../pages/before_learning/VietStudyStore';

// while learning
import Chapter from '../pages/while_learning/Chapter';
import Lesson from '../pages/while_learning/Lesson';
import Test from '../pages/while_learning/Test';
import Congratulations from '../pages/while_learning/Congratulations';

// temporary developed, will be deleted in the near future
import PairQuizPage from '../pages/test-quiz/PairQuizPage';
import SquareQuizPage from '../pages/test-quiz/SquareQuizPage';
import SlidePage from '../pages/test-quiz/SlidePage';
import VerifyEmailPage from '../pages/auth/securityQuestion';
import PracticeTestResultPage from '../pages/while_learning/PracticeTestResultPage';
import ForgotPasswordPage from '../pages/auth/forgotPassword';
import InputEmailPage from '../pages/auth/inputEmail';

// refactor
import Home from '../pages/Home/Home';
import LearnCourse from '../pages/LearnCourse/LearnCourse';
import LearnLesson from '../pages/LearnLesson/LearnLesson';
import RoadMap from 'src/pages/RoadMap/RoadMap';
import DoTest from 'src/pages/DoTest/DoTest';

const APP_ROUTES = [
  // Auth, only login & register isPrivate false at production mode
  {
    name: 'login',
    path: routes.LOGIN,
    routeElement: <Login />,
    restricted: false,
    isPrivate: false
  },
  {
    name: 'register',
    path: routes.REGISTER,
    routeElement: <Register />,
    restricted: false,
    isPrivate: false
  },
  {
    name: 'verify-email',
    path: routes.VERIFY_EMAIL,
    routeElement: <VerifyEmailPage />,
    restricted: false,
    isPrivate: false
  },
  {
    name: 'forgot-password',
    path: routes.FORGOT_PASSWORD,
    routeElement: <ForgotPasswordPage />,
    restricted: false,
    isPrivate: false
  },
  {
    name: 'input-email',
    path: routes.INPUT_EMAIL,
    routeElement: <InputEmailPage />,
    restricted: false,
    isPrivate: false
  },
  // Before learning urls
  {
    name: 'courses',
    path: routes.COURSES,
    routeElement: <Course />,
    restricted: true,
    isPrivate: true,
    roles: ['student']
  },
  {
    name: 'settings',
    path: routes.SETTINGS,
    routeElement: <Setting />,
    restricted: false,
    isPrivate: false,
    roles: ['student']
  },
  {
    name: 'personal-info',
    path: routes.PERSONAL,
    routeElement: <PersonalInfo />,
    restricted: true,
    isPrivate: true,
    roles: ['student']
  },
  {
    name: 'study-result',
    path: routes.STUDY_RESULT,
    routeElement: <StudyingResult />,
    restricted: true,
    isPrivate: true,
    roles: ['student']
  },
  {
    name: 'award',
    path: routes.AWARD,
    routeElement: <Award />,
    restricted: true,
    isPrivate: true,
    roles: ['student']
  },
  {
    name: 'vietstudy-store',
    path: routes.VS_STORE,
    routeElement: <VietStudyStore />,
    restricted: true,
    isPrivate: true,
    roles: ['student']
  },

  // while learning
  {
    name: 'chapter',
    path: routes.CHAPTERS,
    routeElement: <Chapter />,
    restricted: true,
    isPrivate: true,
    roles: ['student']
  },
  {
    name: 'lesson',
    path: routes.LESSONS,
    routeElement: <Lesson />,
    restricted: true,
    isPrivate: true,
    roles: ['student']
  },
  {
    name: 'test',
    path: routes.TESTS,
    routeElement: <Test />,
    restricted: true,
    isPrivate: true,
    roles: ['student']
  },
  {
    name: 'practice-test-result',
    path: routes.PRACTICE_TEST_RESULT,
    routeElement: <PracticeTestResultPage />,
    restricted: true,
    isPrivate: true,
    roles: ['student']
  },

  // temporaty, will be deleted in near future
  {
    name: 'pair-quiz',
    path: routes.PAIR_QUIZ,
    routeElement: <PairQuizPage />,
    restricted: false,
    isPrivate: false,
    roles: ['student']
  },
  {
    name: 'square-quiz',
    path: routes.SQUARE_QUIZ,
    routeElement: <SquareQuizPage />,
    restricted: false,
    isPrivate: false,
    roles: ['student']
  },
  {
    name: 'slide-quiz',
    path: routes.SLIDE_QUIZ,
    routeElement: <SlidePage />,
    restricted: false,
    isPrivate: false,
    roles: ['student']
  },

  // refactor
  {
    name: 'home',
    path: routes.HOME,
    routeElement: <Home />,
    restricted: false,
    isPrivate: false
  },
  {
    name: 'roadmaps',
    path: routes.ROAD_MAP,
    routeElement: <RoadMap />,
    restricted: false,
    isPrivate: false
  },
  {
    name: 'learn-course',
    path: routes.LEARN_COURSE,
    routeElement: <LearnCourse />,
    restricted: false,
    isPrivate: false
  },
  {
    name: 'learn-lesson',
    path: routes.LEARN_LESSON,
    routeElement: <LearnLesson />,
    restricted: false,
    isPrivate: false
  },
  {
    name: 'do-test',
    path: routes.DO_TEST,
    routeElement: <DoTest />,
    restricted: false,
    isPrivate: false
  },
  {
    name: 'congratulations',
    path: routes.CONGRATULATIONS,
    routeElement: <Congratulations />,
    restricted: false,
    isPrivate: false
  }
];

export default APP_ROUTES;

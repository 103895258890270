import { BUTTON_COLOR } from 'src/theme/color';
import { ListItemIcon, Typography } from '@mui/material';

import { ItemButtonStyle } from './index.style';

const MenuItem = ({ text, icon, isActive }) => {
  return (
    <ItemButtonStyle w="100%">
      <Typography
        fontWeight="bold"
        color={isActive ? BUTTON_COLOR : 'black'}
        variant="h3"
        textTransform="none">
        {text}
      </Typography>
      <ListItemIcon
        sx={{
          color: isActive ? BUTTON_COLOR : 'black',
          fontSize: '22.5px'
        }}>
        {icon}
      </ListItemIcon>
    </ItemButtonStyle>
  );
};

export default MenuItem;

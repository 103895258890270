import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './Home.scss';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getMe } from 'src/apis/auth';
import { updateUserInfo } from 'src/redux/auth/actions';
import { useEffect } from 'react';
import AppLayout from 'src/containers/Layout/AppLayout/AppLayout';
import { getAllCourses } from 'src/apis/course';
import { setAllCourses, setIsFetchAllCourses } from 'src/redux/course/actions';
import { Button, Pagination, Snackbar } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import useSound from 'use-sound';
import startLearn from 'src/assets/audio/start_learn.wav';
import { setCurrentCourse } from 'src/redux/course/actions';
import LockIcon from '@mui/icons-material/Lock';
import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { getExtractCourse } from 'src/apis/course';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25)
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto'
  }
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch'
      }
    }
  }
}));

const Home = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [playStart] = useSound(startLearn);

  const allCourses = useSelector((store) => store.course.courses);
  const currentUser = useSelector((store) => store.auth.user);
  const isFetchAllCourses = useSelector((store) => store.course.isFetchAllCourses);

  const [availableCourse, setAvailableCourse] = useState([]);
  const [currentCourses, setCurrentCourses] = useState([]);
  const [page, setPage] = useState(1);
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const fetchUser = async () => {
    const user = await getMe('v1');
    dispatch(updateUserInfo(user?.result ? user?.result : null));
  };

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    if (allCourses.length === 0 && !isFetchAllCourses) {
      initCourses();
    }
  }, []);

  useEffect(() => {
    setAvailableCourse(allCourses);
  }, [allCourses]);

  useEffect(() => {
    setCurrentCourses(availableCourse.slice((page - 1) * 6, page * 6));
  }, [page, availableCourse]);

  const initCourses = async () => {
    const data = await getAllCourses();
    dispatch(setAllCourses(data.courses));
    dispatch(setIsFetchAllCourses(true));

    setCurrentCourses(data.courses.slice(0, 6));
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackBar(false);
  };

  const actionSnackBar = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={() => navigate('/login')}>
        Login
      </Button>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const handleClickCourse = async (course) => {
    playStart();
    await getExtractCourse(course._id).then((data) => dispatch(setCurrentCourse(data)));
    navigate(`/learn-course/${course._id}`);
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleSearch = (text) => {
    setAvailableCourse(
      allCourses.filter((course) => {
        return course.title.includes(text);
      })
    );
  };

  const renderCourse = () => {
    return (
      <div className="coursesListContainer">
        <div className="search">
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              onChange={(e) => handleSearch(e.target.value)}
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
            />
          </Search>
        </div>
        <div className={`${currentCourses.length > 0 ? 'courses' : 'noAvailableCourse'}`}>
          {currentCourses.length > 0 ? (
            currentCourses.map((course, index) => {
              let courseLocked = course.isPrivate && currentUser === null;
              return (
                <div
                  key={index}
                  className="courseCard"
                  onClick={() => {
                    if (courseLocked) {
                      setOpenSnackBar(true);
                    } else {
                      handleClickCourse(course);
                    }
                  }}>
                  <img
                    src={course.imageUrl}
                    alt={course.title}
                    style={{ filter: courseLocked ? 'grayscale(100%)' : '' }}
                  />
                  {courseLocked ? (
                    <span className="lockLayer">
                      <LockIcon sx={{ fontSize: 60 }} />
                    </span>
                  ) : (
                    ''
                  )}
                  <div className="courseDetail">{course.title}</div>
                </div>
              );
            })
          ) : (
            <p>No courses available</p>
          )}
        </div>
        <div className="pagination">
          <Pagination
            count={availableCourse.length > 0 ? Math.floor(availableCourse.length / 6) + 1 : 1}
            page={page}
            shape="rounded"
            size="large"
            onChange={handleChangePage}
          />
        </div>

        {currentCourses.length > 0 && <div className="firstbookCase"></div>}
        {currentCourses.length > 3 && <div className="secondbookCase"></div>}
        <Snackbar
          open={openSnackBar}
          autoHideDuration={6000}
          onClose={handleClose}
          message={t('You need to login to learn this course')}
          action={actionSnackBar}
        />
      </div>
    );
  };

  return <AppLayout activeMenu="Courses" renderMain={renderCourse} />;
};

export default Home;

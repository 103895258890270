export const checkValidUser = (user) => {
  if (
    user.firstName === '' ||
    user.lastName === '' ||
    user.username === '' ||
    user.email === '' ||
    user.password === ''
  ) {
    return {
      continued: false,
      errorMessage: 'information_cant_be_empty'
    };
  } else if (!user.email.includes('@')) {
    return {
      continued: false,
      errorMessage: 'email_invalid'
    };
  } else if (user.gender === '') {
    return {
      continued: false,
      errorMessage: 'information_cant_be_empty'
    };
  } else
    return {
      continued: true,
      errorMessage: ''
    };
};

const month30days = [4, 6, 9, 11];

export const checkValidDate = (birthday) => {
  if (month30days.includes(birthday.month) && birthday.date === 31) {
    return {
      continued: false,
      errorMessage: 'chosen_month_doesnt_have_31_days'
    };
  } else if (birthday.month === 2 && birthday.date >= 30) {
    return {
      continued: false,
      errorMessage: 'february_has_28or29_days'
    };
  } else if (birthday.birthday === 2 && birthday.date === 29) {
    const leap = new Date(birthday.year, 1, 29).getDate() === 29;
    if (!leap)
      return {
        continued: false,
        errorMessage: 'not_leap_year_feb_28_days'
      };
  } else
    return {
      continued: true,
      errorMessage: ''
    };
};

export const convertBirthdayToStr = (birthday) => {
  const monthStr = birthday.month < 10 ? `0${birthday.month}` : `${birthday.month}`;
  const dateStr = birthday.date < 10 ? `0${birthday.date}` : `${birthday.date}`;
  return `${birthday.year}-${monthStr}-${dateStr}`;
};

import React from 'react';
import { TheoryTourStyle } from './index.style';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AudioButton from 'src/components/AudioButton';
import { Grid, Typography } from '@mui/material';
import PanToolAltIcon from '@mui/icons-material/PanToolAlt';
import ReactPlayer from 'react-player';
import Square from 'src/components/Square/SquareBox';
import SettingsVoiceIcon from '@mui/icons-material/SettingsVoice';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AddNote from 'src/assets/images/icons/add-note.png';
import NoteIcon from 'src/assets/images/icons/note.png';
import CreateIcon from '@mui/icons-material/Create';
import ClearIcon from '@mui/icons-material/Clear';

const TheoryTour = () => {
  return (
    <TheoryTourStyle>
      <div className="arrow-btn prev-btn">
        <ArrowBackIosIcon />
      </div>
      <div className="Content">
        <div className="Title">
          <span data-tut="theory__title-audio">
            <AudioButton size={'30px'} />
          </span>
          <Typography variant="h0" data-tut="theory__title">
            Bài học hôm nay
          </Typography>
        </div>
        <div className="TheoryContent">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <div className="Read" data-tut="theory__read">
                <div className="theory__read--list--mode">
                  <button className="ReadMode" data-tut="theory__read--mode">
                    <div className="mode">
                      Chế độ đọc
                      <PanToolAltIcon />
                    </div>
                    Tiếng
                    <ArrowDropDownIcon />
                  </button>
                  <button className="ReadMode" data-tut="theory__listen--mode">
                    <div className="mode">
                      Giọng đọc
                      <SettingsVoiceIcon />
                    </div>
                    Nữ miền Bắc
                    <ArrowDropDownIcon />
                  </button>
                  <div>
                    <AudioButton size={'20px'} />
                  </div>
                </div>
                <div className="Text">
                  <Typography variant="h3">
                    <span className="highlight-text">Chế</span>
                    <span className="highlight-text">độ</span>
                    <span className="highlight-text">đọc</span>
                    <span className="highlight-text">dạng</span>
                    <span className="highlight-text">tiếng</span>
                  </Typography>

                  <div className="Text--detail" data-tut="text-detail">
                    <div className="detail--header">
                      tiếng
                      <img
                        src={AddNote}
                        width={32}
                        height={32}
                        alt="add note img"
                        data-tut="add-note"
                      />
                    </div>
                    <div className="detail--line"></div>
                    <div className="detail--voice">
                      <button className="ReadMode" data-tut="theory__read--mode">
                        <div className="mode">
                          Giọng đọc
                          <SettingsVoiceIcon />
                        </div>
                        Nữ miền Bắc
                        <ArrowDropDownIcon />
                      </button>
                      <div data-tut="theory__read-audio-btn">
                        <AudioButton size={'20px'} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <table>
                <tbody data-tut="theory__square">
                  <tr>
                    <td>
                      <h3>Xin</h3>
                    </td>
                    <td>
                      <h3>chào</h3>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Square />
                    </td>
                    <td>
                      <Square />
                    </td>
                  </tr>
                </tbody>
              </table>
            </Grid>
            <Grid item xs={6}>
              <ReactPlayer
                url="https://www.youtube.com/watch?v=NWahEs5eccU"
                width="60%"
                height="90%"
                data-tut="theory__video"
                controls
              />
            </Grid>
            <Grid item xs={6}>
              <img
                src="https://i.ytimg.com/vi/4J6Sm4BIGts/maxresdefault.jpg"
                alt="image"
                style={{ width: '60%', height: '90%' }}
                data-tut="theory__img"
              />
            </Grid>
          </Grid>
        </div>
        <div className="Note">
          <img src={NoteIcon} alt="note icon" data-tut="note-icon" />
          <div className="Note__content" data-tut="note-content">
            <div className="title">Ghi chú</div>
            <div className="lists">
              <div className="item" data-tut="note-item">
                <div className="item-content">
                  Chế{' '}
                  <div data-tut="theory__read-audio-btn">
                    <AudioButton size={'20px'} />
                  </div>
                </div>
                <ClearIcon />
              </div>
              <div className="item">
                <div className="item-content">
                  đọc{' '}
                  <div data-tut="theory__read-audio-btn">
                    <AudioButton size={'20px'} />
                  </div>
                </div>
                <ClearIcon />
              </div>
              <div className="item">
                <CreateIcon />
              </div>
              <div className="item">
                <CreateIcon />
              </div>
              <div className="item">
                <CreateIcon />
              </div>
              <div className="item">
                <CreateIcon />
              </div>
              <div className="item">
                <CreateIcon />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="arrow-btn next-btn" data-tut="theory__btn">
        <ArrowForwardIosIcon />
      </div>
    </TheoryTourStyle>
  );
};

export default TheoryTour;

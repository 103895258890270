export const QUIZ_TYPE = {
  MC_QUIZ: 'mc_quiz',
  PAIR_QUIZ: 'pair_quiz',
  BLANK_QUIZ: 'blank_quiz',
  SQUARE_QUIZ: 'square_quiz',
  WRITING_QUIZ: 'writing_quiz',
  SPEAKING_QUIZ: 'speaking_quiz'
};

export const SQUARE_QUIZ_TYPE = {
  // Tiếng trong câu: mỗi ô vuông sẽ là 1 tiếng trong câu
  WORD_IN_SENTENCE: 'word_in_sentence',

  // Thanh điệu của tiếng: là 1 ô vuông nhưng có hiển thị thêm thanh điệu
  TONE_OF_WORD: 'tone_of_word',

  // Thành phần trong tiếng: mỗi ô vuông sẽ là 1 thành phần trong tiếng (không thanh điệu)
  // ELEMENTS_IN_WORD: 'elements_in_word',

  // Thành phần trong tiếng: mỗi ô vuông sẽ là 1 thành phần trong tiếng (có thanh điệu)
  ELEMENTS_OF_WORD: 'elements_of_word',

  // Âm vị trong tiếng: mỗi ô vuông sẽ là 1 âm vị trong tiếng (không thanh điệu)
  // PHONEME_IN_SYLLABLE: 'phoneme_in_syllable',

  // Âm vị của tiếng: mỗi ô vuông sẽ là 1 âm vị trong tiếng (có thanh điệu)
  PHONEME_OF_SYLLABLE: 'phoneme_of_syllable',

  // Thành phần trong vần: mỗi ô vuông sẽ là 1 thành phần trong vần (không thanh điệu)
  // PHONEME_IN_RHYME: 'phoneme_in_rhyme',

  // Thành phần trong vần: mỗi ô vuông sẽ là 1 thành phần trong vần (có thanh điệu)
  PHONEME_OF_RHYME: 'phoneme_of_rhyme'
};

export const TONE = {
  // thanh bằng / thanh ngang
  LEVEL: 'level',

  // thanh huyền
  FALLING: 'falling',

  // thanh sắc
  RISING: 'rising',

  // thanh hỏi
  CURVE: 'curve',

  // thanh ngã
  BROKEN: 'broken',

  // thanh nặng
  DROP: 'drop'
};

export const VIETNAMESE_ALPHABET = [
  'a',
  'ă',
  'â',
  'b',
  'c',
  'd',
  'đ',
  'e',
  'ê',
  'g',
  'gh',
  'h',
  'i',
  'k',
  'l',
  'm',
  'n',
  'o',
  'ô',
  'ơ',
  'p',
  'ph',
  'q',
  'ch',
  'kh',
  'nh',
  'ng',
  'ngh',
  'r',
  's',
  't',
  'tr',
  'ư',
  'u',
  'th',
  'v',
  'x'
];

export const MEDIA_TYPE = {
  IMAGE: 'image',
  VIDEO: 'video',
  TEXT: 'text',
  AUDIO: 'audio',
  SQUARE: 'square'
};

export const QUIZ_LEVEL = {
  EASY: 'easy',
  MEDIUM: 'medium',
  HARD: 'hard'
};

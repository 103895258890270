import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';

import enTranslation from './locales/en.json';
import viTranslation from './locales/vi.json';
import japTranslation from './locales/jap.json';

/************ when deployed ************/
// storageName: i18nextLng
// const systemLanguage = getStorage('systemLanguage');
// const supportLanguage = getStorage('supportLanguage');
// -> lng: systemLanguage

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enTranslation
      },
      vi: {
        translation: viTranslation
      },
      jp: {
        translation: japTranslation
      }
    },
    lng: 'vi',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    },
    nsSeparator: ':::',
    keySeparator: '::'
  });

export default i18n;

import { styled } from '@mui/material/styles';

export const LoginStyle = styled('div')`
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .login-header {
    text-align: center;
    .login-header__title h1 {
      font-size: 36px;
    }
  }

  .login-content {
    width: 500px;

    .login-content__email {
      margin-bottom: 20px;
    }

    .login-content__password {
      margin-bottom: 32px;
    }

    .login-content__title {
      margin-bottom: 6px;
      h3 {
        font-size: 20px;
      }
    }

    .login-content__input {
      input {
        width: 100%;
        height: 36px;
        font-size: 18px;
        border-radius: 6px;
        background-color: #dddddd38;
        border: 2px solid #a0a0a0;
        padding: 8px;
      }
    }

    .error-message {
      color: red;
      margin-bottom: 12px;
      text-align: center;
    }

    .visible-icon {
      position: absolute;
      margin-left: -34px;
      margin-top: 6px;
    }

    button {
      width: 100%;
    }
  }

  .login-footer {
    text-align: center;
  }
`;

import { Container, styled } from '@mui/material';
import Chapter_bg from '../../../assets/images/modules/chapter_bg.png';
import { BORDER_COLOR } from 'src/theme/color';

export const ChapterContentStyle = styled(Container)`
  margin-top: 2%;
  height: 90%;
  width: 75%;
  border: 4px solid ${BORDER_COLOR};
  border-radius: 10px;
  position: relative;
  isolation: isolate;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px,
    rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px,
    rgba(0, 0, 0, 0.09) 0px -3px 5px;
  &:after {
    content: '';
    background-image: url(${Chapter_bg});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 0;
    border-radius: 6px;
    position: absolute;
    opacity: 1;
    inset: 0;
    z-index: -1;
  }

  .ChapterTitle {
    display: flex;
    justify-content: center;
    padding-top: 10px;
  }

  .MuiGrid-root {
    height: 88%;
    display: flex;
    justify-content: space-around;
  }
`;

import axios from 'axios';
import api from './api';
import { getHeaders, processExpired } from './auth';

export const getSoundUrl = async (version, data) => {
  const accessToken = await processExpired();
  return await api.post(`${version}/tts`, data, {
    headers: getHeaders(accessToken)
  });
};

export const getAcoustParams = async (version, data) => {
  const accessToken = await processExpired();
  return await api.post(`${version}/tts/realised_acoustparams`, data, {
    headers: getHeaders(accessToken)
  });
};

export const checkSound = async (audio_file, transcription) => {
  try {
    const formData = new FormData();
    formData.append('audio_file', audio_file);
    formData.append('transcript', transcription);
    formData.append('num_channels', 2);
    formData.append('sample_rate', 22500);
    formData.append('extension', '.webm');

    const res = await (
      await axios.post('http://9a1b-43-239-223-87.ngrok-free.app/predict', formData)
    ).data;
    return res;
  } catch (error) {
    // Handle errors here, for example:
    console.error('Error in checkSound:', error);
    throw error; // You can choose to handle or rethrow the error
  }
};

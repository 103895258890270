import { createSlice } from '@reduxjs/toolkit';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import _map from 'lodash/map';
import _keyBy from 'lodash/keyBy';

// apis
import { getAllUserItems } from '../../../apis/award-admin/collectionItem';

// initial state
const initialState = {
  metadata: {},
  ids: [],
  data: {},
  isLoading: false
};

// slice
const userCollectionItem = createSlice({
  name: 'userCollectionItem',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setMetadata(state, action) {
      state.metadata = action.payload;
    },
    setCollectionItemIds(state, action) {
      state.ids = action.payload;
    },
    setCollectionItems(state, action) {
      state.data = action.payload;
    }
  }
});

// actions
export const { setLoading, setMetadata, setCollectionItemIds, setCollectionItems } =
  userCollectionItem.actions;

// get all user collection items
export const getAllUserCollectionItems =
  ({ page, limit, keyword, categoryId, rarity, userId } = {}) =>
  async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const res = await getAllUserItems({
        page,
        limit,
        keyword,
        categoryId,
        rarity,
        userId
      });

      if (!res.success) toast.error(`Lỗi: ${res.message}`);
      else {
        dispatch(setMetadata(res.results?.metadata));
        dispatch(setCollectionItemIds(_map(res.results?.collectionItems, '_id')));
        dispatch(setCollectionItems(_keyBy(res.results?.collectionItems, '_id')));
      }
    } catch (error) {
      console.error(error);
      toast.error(`Lỗi: ${error.message}`);
      dispatch(setMetadata(initialState.metadata));
      dispatch(setCollectionItemIds(initialState.ids));
      dispatch(setCollectionItems(initialState.data));
    }

    dispatch(setLoading(false));
  };

export default userCollectionItem.reducer;

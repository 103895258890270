import LayoutForOne from './CustomLayout/LayoutForOne';
import LayoutForTwo from './CustomLayout/LayoutForTwo';
import LayoutForThree from './CustomLayout/LayoutForThree';
import LayoutForFour from './CustomLayout/LayoutForFour';

export default function TheoryContainer({ slideIndex, data }) {
  switch (data?.layout) {
    case 0:
      return <LayoutForOne slideIndex={slideIndex} data={data} />;

    case 1:
    case 2:
      return <LayoutForTwo slideIndex={slideIndex} data={data} />;

    case 3:
    case 4:
      return <LayoutForThree slideIndex={slideIndex} data={data} />;

    case 5:
      return <LayoutForFour slideIndex={slideIndex} data={data} />;

    default:
      return <></>;
  }
}

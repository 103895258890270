import { styled } from '@mui/material/styles';
import { FLOATING_BOX_COLOR } from 'src/theme/color';

// height: 500px;
// width: 950px;
export const InfoBox = styled('div')`
  display: flex;
  justify-content: center;
  background-color: ${FLOATING_BOX_COLOR};
  border: 2.5px solid black;
  border-radius: 10px;
  margin-top: 60px;
  margin-bottom: 10px;
  padding: 30px 30px 30px 5px;

  .left-part {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    margin-right: 20px .title-text {
      font-weight: bold;
      margin-bottom: 10px;
    }

    .avatar-name {
      margin-top: 10px;
      margin-bottom: 8px;
    }
  }

  .right-part {
    flex: 3;
    display: flex;
    flex-direction: column;
    margin-left: 30px;
  }
`;
// padding: top right bottom left

export const collectSyllableLines = (list) => {
  return list.map((e) => e.content.text);
};

export const collectWords = (list, quizId) => {
  let collection = [];
  let words = [];

  for (let i = 0; i < list.length; i++) {
    words = [];
    for (let j = 0; j < list[i].squareQuizParts.length; j++) {
      words.push({
        text: list[i].squareQuizParts[j].content.text,
        selectedTone: '',
        trueTone: list[i].squareQuizParts[j].structure.tone,
        wordId: `${quizId}-${i}-${j}`
      });
    }
    collection.push({ words });
  }
  return collection;
};

export const collectWordsLen = (list) => {
  let counter = 0;
  let total = 0;

  for (let i = 0; i < list.length; i++) {
    counter = 0;
    for (let j = 0; j < list[i].squareQuizParts.length; j++) {
      counter++;
    }
    total = total + counter;
  }
  return total;
};

export const countCorrect = (collection) => {
  let counter = 0;
  for (let i = 0; i < collection.length; i++) {
    const words = collection[i].words;
    for (let j = 0; j < words.length; j++) {
      if (words[j].selectedTone === words[j].trueTone) counter++;
    }
  }
  return counter;
};

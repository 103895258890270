import { Space } from './index.style';

const SpaceBox = () => {
  return <Space></Space>;
};

export const TabBox = () => {
  return <Space w="30px"></Space>;
};

export const Gap = () => {
  // between consonants and rhymes
  return <Space w="10px"></Space>;
};

export default SpaceBox;

import { styled } from '@mui/material/styles';

export const MCTestBox = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100%;
  width: 100%;

  ${(props) =>
    props.image === undefined || props.opacity === undefined
      ? ''
      : `
        background-image: linear-gradient(rgba(225,225,225,${props.opacity}), rgba(225,225,225,${props.opacity})), url(${props.image});
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
        `}
`;

import { useLayoutEffect, useState } from 'react';
import './AppLayout.scss';
import { menuList } from 'src/constants/menuOption';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import VietStudy from '../../../assets/images/logo/iconLogo.svg';
import { getMe } from 'src/apis/auth';
import { updateUserInfo } from 'src/redux/auth/actions';
import UserInfoBox from 'src/containers/MenuSideBar/UserInfoBox';
import { Button, Snackbar, Typography } from '@mui/material';
import { userOption } from 'src/constants/menuOption';
import LogoutIcon from '@mui/icons-material/Logout';
import { getCookie, setCookie } from 'src/utils/cookie';
import { logOut } from 'src/apis/auth';
import { clearStorage } from 'src/utils/storage';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const AppLayout = ({ renderMain, activeMenu }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const currentUser = useSelector((state) => state.auth.user);
  const star = useSelector((state) => state.userAwards.star[currentUser?.result?._id]);

  const [openSnackBar, setOpenSnackBar] = useState(false);

  const fetchUser = async () => {
    await getMe('v1')
      .then((user) => {
        if (user.status === 0) {
          dispatch(updateUserInfo(null));
          return;
        }
        dispatch(updateUserInfo(user.result));
      })
      .catch(() => {
        dispatch(updateUserInfo(null));
      });
  };

  useLayoutEffect(() => {
    fetchUser();
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackBar(false);
  };

  const handleLogout = async () => {
    const refreshToken = getCookie('refreshToken');
    const response = await logOut('v1', refreshToken);
    if (response.result) {
      setCookie('refreshToken', '');
      setCookie('accessToken', '');
      clearStorage();
      navigate('/login');
    }
  };

  const actionSnackBar = (
    <>
      <Button color="secondary" size="small" onClick={() => navigate('/login')}>
        Login
      </Button>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <div className="homeContainer">
      <div className="sideBar">
        <img src={VietStudy} onClick={() => navigate('/')} />
        <div className="menu">
          {menuList.map((menuItem, index) => {
            return (
              <div
                className={`menuItem ${activeMenu === menuItem.text ? 'activeItem' : ''}`}
                key={index}
                onClick={() =>
                  menuItem.needLogin && !currentUser
                    ? setOpenSnackBar(true)
                    : navigate(menuItem.route)
                }>
                <span>{menuItem.icon}</span>
                {t(`${menuItem.text}`)}
              </div>
            );
          })}
        </div>
      </div>
      <div className="content">{renderMain()}</div>

      <div className="tabBar">
        {currentUser !== null ? (
          <div className="user">
            <UserInfoBox
              avatarUrl={currentUser.avatarUrl || currentUser?.result?.avatarUrl}
              biography={currentUser.bio || currentUser?.result?.bio}
              starNum={star}
              fullname={`${currentUser.lastName || currentUser?.result?.lastName} ${
                currentUser.firstName || currentUser?.result?.firstName
              }`}
            />
            <div className="userOptions">
              {userOption.map((option, index) => {
                return (
                  <div className="option" key={index} onClick={() => navigate(option.route)}>
                    <span>{option.icon}</span>
                    {t(`${option.text}`)}
                  </div>
                );
              })}
            </div>
            <div className="logout" onClick={handleLogout}>
              <Typography color="red" fontWeight="bold" variant="h3" textTransform="none">
                {t('logout')}
              </Typography>
              <LogoutIcon sx={{ fontSize: '25px', color: 'red' }} />
            </div>
          </div>
        ) : (
          <div className="loginRequire">
            <Button
              className="btn"
              variant="contained"
              size="large"
              onClick={() => navigate('/login')}>
              {t('login')}
            </Button>
            <Button
              className="btn"
              variant="contained"
              size="large"
              color="success"
              onClick={() => navigate('/register')}>
              {t('register')}
            </Button>
          </div>
        )}
      </div>

      <Snackbar
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={handleClose}
        message={t('You need to login to use this feature')}
        action={actionSnackBar}
      />
    </div>
  );
};

export default AppLayout;

import { TONE } from '../../../../constants/quiz';
import { getTone } from '../index.logic';

export const collectSyllableLines = (list) => {
  return list.map((e) => e.content.text);
};

export const collectBtmList = (list, quizId) => {
  let answers = [];
  let string = '';
  for (let i = 0; i < list.length; i++) {
    for (let j = 0; j < list[i].squareQuizParts.length; j++) {
      string = list[i].squareQuizParts[j].structure.consonant;
      if (string !== '') {
        answers.push({
          answerId: `${quizId}-${i}-${j}-csn`,
          answerValue: string,
          media: {
            text: string
          },
          belongTo: 'bottom-box'
        });
      }

      string = list[i].squareQuizParts[j].structure.rhyme.content.text;
      if (string !== '') {
        answers.push({
          answerId: `${quizId}-${i}-${j}-rhyme`,
          answerValue: string,
          media: {
            text: string
          },
          belongTo: 'bottom-box'
        });
      }

      string = list[i].squareQuizParts[j].structure.tone;
      if (string !== TONE.LEVEL) {
        answers.push(getTone(string, quizId, i, j));
      }
    }
  }
  return answers;
};

export const collectTopList = (list, quizId) => {
  let topList = [];
  let tonesAbove = [],
    boxes = [],
    tonesBelow = [],
    words = [];

  for (let i = 0; i < list.length; i++) {
    tonesAbove = [];
    boxes = [];
    (tonesBelow = []), (words = []);
    for (let j = 0; j < list[i].squareQuizParts.length; j++) {
      words.push(list[i].squareQuizParts[j].content.text);

      if (list[i].squareQuizParts[j].structure.tone === TONE.DROP) {
        tonesAbove.push(
          'space',
          'gap',
          'space',
          {
            boxId: `${quizId}-${i}-${j}-ab`,
            boxValue: `${quizId}-${i}-${j}-ab`,
            chosen: 'none'
          },
          'space',
          'tab'
        );
        tonesBelow.push(
          'space',
          'gap',
          'space',
          {
            boxId: `${quizId}-${i}-${j}-` + TONE.DROP,
            boxValue: TONE.DROP,
            chosen: 'none'
          },
          'space',
          'tab'
        );
      } else {
        tonesAbove.push(
          'space',
          'gap',
          'space',
          {
            boxId: `${quizId}-${i}-${j}-` + list[i].squareQuizParts[j].structure.tone,
            boxValue: list[i].squareQuizParts[j].structure.tone,
            chosen: 'none'
          },
          'space',
          'tab'
        );
        tonesBelow.push(
          'space',
          'gap',
          'space',
          {
            boxId: `${quizId}-${i}-${j}-bl`,
            boxValue: `${quizId}-${i}-${j}-bl`,
            chosen: 'none'
          },
          'space',
          'tab'
        );
      }

      // blank boxes
      boxes.push(
        {
          type: 'normal',
          boxId: `${quizId}-${i}-${j}-csn`,
          boxValue: list[i].squareQuizParts[j].structure.consonant,
          chosen: 'none'
        },
        'gap',
        {
          type: 'rhyme',
          boxId: `${quizId}-${i}-${j}-rhyme`,
          boxValue: list[i].squareQuizParts[j].structure.rhyme.content.text,
          chosen: 'none'
        },
        'tab'
      );
    }
    topList.push({ words, tonesAbove, boxes, tonesBelow });
  }
  return topList;
};

export const showAnswers = (setTopList, setBtmList) => {
  // const ids = btmBoxItems.map(e => e.answerId);
  setTopList((prev) => {
    return prev.map((e) => {
      return {
        ...e,
        boxes: e.boxes.map((ele) => {
          if (ele === 'tab' || ele === 'gap' || ele === 'space') return ele;
          else
            return {
              ...ele,
              chosen: 'correct'
            };
        }),
        tonesAbove: e.tonesAbove.map((e1) => {
          if (e1 === 'tab' || e1 === 'gap' || e1 === 'space') return e1;
          else
            return {
              ...e1,
              chosen: 'correct'
            };
        }),
        tonesBelow: e.tonesBelow.map((e2) => {
          if (e2 === 'tab' || e2 === 'gap' || e2 === 'space') return e2;
          else
            return {
              ...e2,
              chosen: 'correct'
            };
        })
      };
    });
  });
  setBtmList((prev) => {
    return prev.map((e) => {
      return {
        ...e,
        belongTo: e.answerId
      };
    });
  });
};

export const countCorrect = (topList) => {
  let counter = 0;
  for (let i = 0; i < topList.length; i++) {
    let tonesAbove = topList[i].tonesAbove;
    let boxes = topList[i].boxes;
    let tonesBelow = topList[i].tonesBelow;

    for (let i1 = 0; i1 < tonesAbove.length; i1++) {
      if (tonesAbove[i1].chosen === 'correct') {
        counter += 1;
      }
    }

    for (let i2 = 0; i2 < tonesBelow.length; i2++) {
      if (tonesBelow[i2].chosen === 'correct') {
        counter += 1;
      }
    }

    for (let i3 = 0; i3 < boxes.length; i3++) {
      if (boxes[i3].chosen === 'correct') {
        counter += 1;
      }
    }
  }
  return counter;
};

import { styled } from '@mui/material/styles';

export const RegisterStyle = styled('div')`
  /* margin-top: 32px; */
  display: flex;
  flex-direction: column;
  align-items: center;

  .register-header {
    text-align: center;
    .register-header__title h1 {
      font-size: 36px;
    }
  }

  .register-content {
    width: 500px;
    margin: 24px 0;

    .register-content__name,
    .register-content__email,
    .register-content__password,
    .register-content__username {
      margin-bottom: 8px;
    }

    .register-content__name {
      display: flex;
      .register-content__lastname {
        margin-left: 20px;
      }
    }

    .register-content__birthday {
      margin-bottom: 32px;
      .MuiFormControl-root {
        width: 100%;
        .Mui-focused,
        .MuiOutlinedInput-notchedOutline {
          color: black;
        }
        label {
          display: none;
        }
        input {
          padding: 0px 8px !important;
        }
      }
      .MuiFormControl-root:focus {
        border: 2px solid black;
        border-radius: 6px;
      }
    }

    .register-content__title {
      margin-bottom: 4px;
      h3 {
        font-size: 20px;
      }
    }

    .register-content__input {
      input,
      select {
        width: 100%;
        height: 32px;
        font-size: 18px;
        border-radius: 6px;
        background-color: #dddddd38;
        border: 2px solid #a0a0a0;
        padding: 4px 8px;
        overflow-y: scroll !important;
      }

      select::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #f5f5f5;
      }

      select::-webkit-scrollbar {
        width: 8px;
        height: 20px;
        background-color: #f5f5f5;
      }

      select::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #555555;
      }
    }

    .birthday-field {
      display: flex;
      justify-content: space-between;
      .time-field {
        display: flex;
        align-items: center;
        h3 {
          padding-right: 10px;
        }
      }
    }

    .gender-input {
      display: flex;
      justify-content: space-evenly;
    }

    .error-message {
      color: red;
      margin-bottom: 6px;
      text-align: center;
    }

    .visible-icon {
      position: absolute;
      margin-left: -34px;
      margin-top: 6px;
    }

    button {
      width: 100%;
    }
  }

  .register-footer {
    text-align: center;
  }
`;

import { TONE } from '../../../../../constants/quiz';
import { getTone } from '../index.logic';

export const getSyllableLines = (list) => {
  return list.map((e) => e.content.text);
};

export const getBtmList = (list, quizId) => {
  let answers = [];
  let string = '';
  for (let i = 0; i < list.length; i++) {
    for (let j = 0; j < list[i].squareQuizParts.length; j++) {
      // csn = consonant
      string = list[i].squareQuizParts[j].structure.consonant;
      if (string !== '') {
        answers.push({
          answerId: `${quizId}-${i}-${j}-csn`,
          answerValue: string,
          media: {
            text: string
          },
          belongTo: 'bottom-box'
        });
      }

      // str = startRhyme
      string = list[i].squareQuizParts[j].structure.rhyme.structure.startRhyme;
      if (string !== '') {
        answers.push({
          answerId: `${quizId}-${i}-${j}-str`,
          answerValue: string,
          media: {
            text: string
          },
          belongTo: 'bottom-box'
        });
      }

      // mnr = mainRhyme
      string = list[i].squareQuizParts[j].structure.rhyme.structure.mainRhyme;
      if (string !== '') {
        answers.push({
          answerId: `${quizId}-${i}-${j}-mnr`,
          answerValue: string,
          media: {
            text: string
          },
          belongTo: 'bottom-box'
        });
      }

      // fnr = finishRhyme
      string = list[i].squareQuizParts[j].structure.rhyme.structure.finishRhyme;
      if (string !== '') {
        answers.push({
          answerId: `${quizId}-${i}-${j}-fnr`,
          answerValue: string,
          media: {
            text: string
          },
          belongTo: 'bottom-box'
        });
      }

      string = list[i].squareQuizParts[j].structure.tone;
      if (string !== TONE.LEVEL) {
        answers.push(getTone(string, quizId, i, j));
      }
    }
  }
  return answers;
};
// (right-answer-duplication-check required) --> compare 2 values

export const getTopList = (list, quizId) => {
  let topList = [];
  let words = [],
    tonesAbove = [],
    boxes = [],
    tonesBelow = [];

  for (let i = 0; i < list.length; i++) {
    (words = []), (tonesAbove = []);
    boxes = [];
    tonesBelow = [];
    for (let j = 0; j < list[i].squareQuizParts.length; j++) {
      // word
      words.push(list[i].squareQuizParts[j].content.text);

      // tones
      if (list[i].squareQuizParts[j].structure.tone === TONE.DROP) {
        tonesAbove.push(
          'space',
          'gap',
          'space',
          {
            boxId: `${quizId}-${i}-${j}-ab`,
            boxValue: `${quizId}-${i}-${j}-ab`,
            chosen: 'none'
          },
          'space',
          'tab'
        );
        tonesBelow.push(
          'space',
          'gap',
          'space',
          {
            boxId: `${quizId}-${i}-${j}-` + TONE.DROP,
            boxValue: TONE.DROP,
            chosen: 'none'
          },
          'space',
          'tab'
        );
      } else {
        tonesAbove.push(
          'space',
          'gap',
          'space',
          {
            boxId: `${quizId}-${i}-${j}-` + list[i].squareQuizParts[j].structure.tone,
            boxValue: list[i].squareQuizParts[j].structure.tone,
            chosen: 'none'
          },
          'space',
          'tab'
        );
        tonesBelow.push(
          'space',
          'gap',
          'space',
          {
            boxId: `${quizId}-${i}-${j}-bl`,
            boxValue: `${quizId}-${i}-${j}-bl`,
            chosen: 'none'
          },
          'space',
          'tab'
        );
      }

      // blank boxes
      boxes.push(
        {
          boxId: `${quizId}-${i}-${j}-csn`,
          boxValue: list[i].squareQuizParts[j].structure.consonant,
          chosen: 'none'
        },
        'gap',
        {
          boxId: `${quizId}-${i}-${j}-str`,
          boxValue: list[i].squareQuizParts[j].structure.rhyme.structure.startRhyme,
          chosen: 'none'
        },
        {
          boxId: `${quizId}-${i}-${j}-mnr`,
          boxValue: list[i].squareQuizParts[j].structure.rhyme.structure.mainRhyme,
          chosen: 'none'
        },
        {
          boxId: `${quizId}-${i}-${j}-fnr`,
          boxValue: list[i].squareQuizParts[j].structure.rhyme.structure.finishRhyme,
          chosen: 'none'
        },
        'tab'
      );
    }
    topList.push({ words, tonesAbove, boxes, tonesBelow });
  }
  return topList;
};

export const countChosen = (topList) => {
  let counter = 0;
  for (let i = 0; i < topList.length; i++) {
    let tonesAbove = topList[i].tonesAbove;
    let boxes = topList[i].boxes;
    let tonesBelow = topList[i].tonesBelow;

    for (let i1 = 0; i1 < tonesAbove.length; i1++) {
      if (tonesAbove[i1].chosen === 'chosen') {
        counter += 1;
      }
    }

    for (let i2 = 0; i2 < tonesBelow.length; i2++) {
      if (tonesBelow[i2].chosen === 'chosen') {
        counter += 1;
      }
    }

    for (let i3 = 0; i3 < boxes.length; i3++) {
      if (boxes[i3].chosen === 'chosen') {
        counter += 1;
      }
    }
  }
  return counter;
};

// React and MUI
import React from 'react';
import { Box, Typography } from '@mui/material';

// Swiper JS lib
import { SwiperSlide } from 'swiper/react';

// smaller components
import { LanguageListBox } from './index.style';
import { SystemLanguageSmallBox, SupportLanguageSmallBox } from '../LanguageSmallBox';
import VSSwiperSetting from 'src/components/VSSwiperSetting';

// data
import { languages } from '../index.style';

const sysLngData = languages.map((ele, index) => {
  return (
    <SwiperSlide key={index}>
      <SystemLanguageSmallBox
        flag={ele.flag}
        language_name={ele.language_name}
        lang_abbr={ele.lang_abbr}
      />
    </SwiperSlide>
  );
});

export const SystemLanguageBar = (props) => {
  return (
    <LanguageListBox>
      <Box className="text-box">
        <Typography variant="h2" fontWeight="bold" className="big-text">
          {props.bigText}
        </Typography>
        <Typography variant="h3" fontWeight="medium" className="small-text">
          {props.smallText}
        </Typography>
      </Box>

      <Box className="carousel">
        <VSSwiperSetting data={sysLngData} space={props.space} />
      </Box>
    </LanguageListBox>
  );
};

const sptLngData = languages.map((ele, index) => {
  return (
    <SwiperSlide key={index}>
      <SupportLanguageSmallBox
        flag={ele.flag}
        language_name={ele.language_name}
        lang_abbr={ele.lang_abbr}
      />
    </SwiperSlide>
  );
});

export const SupportLanguageBar = (props) => {
  return (
    <LanguageListBox>
      <Box className="text-box">
        <Typography variant="h2" fontWeight="bold" className="big-text">
          {props.bigText}
        </Typography>
        <Typography variant="h3" fontWeight="medium" className="small-text">
          {props.smallText}
        </Typography>
      </Box>

      <Box className="carousel">
        <VSSwiperSetting data={sptLngData} space={props.space} />
      </Box>
    </LanguageListBox>
  );
};

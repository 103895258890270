import { styled } from '@mui/material/styles';
import { BUTTON_COLOR, FLOATING_BOX_COLOR } from 'src/theme/color';

export const CongratulationsStyle = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .CongratulationText {
    padding: 20px 0;
    display: flex;
    align-items: center;
  }
  .Title {
    color: ${BUTTON_COLOR};
    font-weight: bold;
    padding: 20px 0;
  }
  .Main {
    width: 100%;
    height: 40%;
    display: flex;
    justify-content: center;
    align-items: center;

    .pyro > .before,
    .pyro > .after {
      position: absolute;
      width: 10px;
      height: 10px;
      pointer-events: none;
      z-index: 99999999;
      border-radius: 50%;
      box-shadow: -120px -218.66667px blue, 248px -16.66667px #00ff84, 190px 16.33333px #002bff,
        -113px -308.66667px #ff009d, -109px -287.66667px #ffb300, -50px -313.66667px #ff006e,
        226px -31.66667px #ff4000, 180px -351.66667px #ff00d0, -12px -338.66667px #00f6ff,
        220px -388.66667px #99ff00, -69px -27.66667px #ff0400, -111px -339.66667px #6200ff,
        155px -237.66667px #00ddff, -152px -380.66667px #00ffd0, -50px -37.66667px #00ffdd,
        -95px -175.66667px #a6ff00, -88px 10.33333px #0d00ff, 112px -309.66667px #005eff,
        69px -415.66667px #ff00a6, 168px -100.66667px #ff004c, -244px 24.33333px #ff6600,
        97px -325.66667px #ff0066, -211px -182.66667px #00ffa2, 236px -126.66667px #b700ff,
        140px -196.66667px #9000ff, 125px -175.66667px #00bbff, 118px -381.66667px #ff002f,
        144px -111.66667px #ffae00, 36px -78.66667px #f600ff, -63px -196.66667px #c800ff,
        -218px -227.66667px #d4ff00, -134px -377.66667px #ea00ff, -36px -412.66667px #ff00d4,
        209px -106.66667px #00fff2, 91px -278.66667px #000dff, -22px -191.66667px #9dff00,
        139px -392.66667px #a6ff00, 56px -2.66667px #0099ff, -156px -276.66667px #ea00ff,
        -163px -233.66667px #00fffb, -238px -346.66667px #00ff73, 62px -363.66667px #0088ff,
        244px -170.66667px #0062ff, 224px -142.66667px #b300ff, 141px -208.66667px #9000ff,
        211px -285.66667px #ff6600, 181px -128.66667px #1e00ff, 90px -123.66667px #c800ff,
        189px 70.33333px #00ffc8, -18px -383.66667px #00ff33, 100px -6.66667px #ff008c;
      -moz-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards,
        5s position linear infinite backwards;
      -webkit-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards,
        5s position linear infinite backwards;
      -o-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards,
        5s position linear infinite backwards;
      -ms-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards,
        5s position linear infinite backwards;
      animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards,
        5s position linear infinite backwards;
    }

    .pyro > .after {
      -moz-animation-delay: 1.25s, 1.25s, 1.25s;
      -webkit-animation-delay: 1.25s, 1.25s, 1.25s;
      -o-animation-delay: 1.25s, 1.25s, 1.25s;
      -ms-animation-delay: 1.25s, 1.25s, 1.25s;
      animation-delay: 1.25s, 1.25s, 1.25s;
      -moz-animation-duration: 1.25s, 1.25s, 6.25s;
      -webkit-animation-duration: 1.25s, 1.25s, 6.25s;
      -o-animation-duration: 1.25s, 1.25s, 6.25s;
      -ms-animation-duration: 1.25s, 1.25s, 6.25s;
      animation-duration: 1.25s, 1.25s, 6.25s;
    }

    @-webkit-keyframes bang {
      from {
        box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
          0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
          0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
          0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
          0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
          0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
          0 0 white, 0 0 white, 0 0 white, 0 0 white;
      }
    }
    @-moz-keyframes bang {
      from {
        box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
          0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
          0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
          0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
          0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
          0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
          0 0 white, 0 0 white, 0 0 white, 0 0 white;
      }
    }
    @-o-keyframes bang {
      from {
        box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
          0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
          0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
          0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
          0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
          0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
          0 0 white, 0 0 white, 0 0 white, 0 0 white;
      }
    }
    @-ms-keyframes bang {
      from {
        box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
          0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
          0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
          0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
          0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
          0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
          0 0 white, 0 0 white, 0 0 white, 0 0 white;
      }
    }
    @keyframes bang {
      from {
        box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
          0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
          0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
          0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
          0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
          0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
          0 0 white, 0 0 white, 0 0 white, 0 0 white;
      }
    }
    @-webkit-keyframes gravity {
      to {
        transform: translateY(200px);
        -moz-transform: translateY(200px);
        -webkit-transform: translateY(200px);
        -o-transform: translateY(200px);
        -ms-transform: translateY(200px);
        opacity: 0;
      }
    }
    @-moz-keyframes gravity {
      to {
        transform: translateY(200px);
        -moz-transform: translateY(200px);
        -webkit-transform: translateY(200px);
        -o-transform: translateY(200px);
        -ms-transform: translateY(200px);
        opacity: 0;
      }
    }
    @-o-keyframes gravity {
      to {
        transform: translateY(200px);
        -moz-transform: translateY(200px);
        -webkit-transform: translateY(200px);
        -o-transform: translateY(200px);
        -ms-transform: translateY(200px);
        opacity: 0;
      }
    }
    @-ms-keyframes gravity {
      to {
        transform: translateY(200px);
        -moz-transform: translateY(200px);
        -webkit-transform: translateY(200px);
        -o-transform: translateY(200px);
        -ms-transform: translateY(200px);
        opacity: 0;
      }
    }
    @keyframes gravity {
      to {
        transform: translateY(200px);
        -moz-transform: translateY(200px);
        -webkit-transform: translateY(200px);
        -o-transform: translateY(200px);
        -ms-transform: translateY(200px);
        opacity: 0;
      }
    }
    @-webkit-keyframes position {
      0%,
      19.9% {
        margin-top: -15%;
        margin-left: 30%;
      }

      20%,
      39.9% {
        margin-top: -10%;
        margin-left: 60%;
      }

      40%,
      59.9% {
        margin-top: 0%;
        margin-left: 10%;
      }

      60%,
      79.9% {
        margin-top: 5%;
        margin-left: 80%;
      }

      80%,
      99.9% {
        margin-top: 5%;
        margin-left: 50%;
      }
    }
    @-moz-keyframes position {
      0%,
      19.9% {
        margin-top: -15%;
        margin-left: 30%;
      }

      20%,
      39.9% {
        margin-top: -10%;
        margin-left: 60%;
      }

      40%,
      59.9% {
        margin-top: 0%;
        margin-left: 10%;
      }

      60%,
      79.9% {
        margin-top: 5%;
        margin-left: 80%;
      }

      80%,
      99.9% {
        margin-top: 5%;
        margin-left: 50%;
      }
    }
    @-o-keyframes position {
      0%,
      19.9% {
        margin-top: -15%;
        margin-left: 30%;
      }

      20%,
      39.9% {
        margin-top: -10%;
        margin-left: 60%;
      }

      40%,
      59.9% {
        margin-top: 0%;
        margin-left: 10%;
      }

      60%,
      79.9% {
        margin-top: 5%;
        margin-left: 80%;
      }

      80%,
      99.9% {
        margin-top: 5%;
        margin-left: 50%;
      }
    }
    @-ms-keyframes position {
      0%,
      19.9% {
        margin-top: -15%;
        margin-left: 30%;
      }

      20%,
      39.9% {
        margin-top: -10%;
        margin-left: 60%;
      }

      40%,
      59.9% {
        margin-top: 0%;
        margin-left: 10%;
      }

      60%,
      79.9% {
        margin-top: 5%;
        margin-left: 80%;
      }

      80%,
      99.9% {
        margin-top: 5%;
        margin-left: 50%;
      }
    }
    @keyframes position {
      0%,
      19.9% {
        margin-top: -15%;
        margin-left: 30%;
      }

      20%,
      39.9% {
        margin-top: -10%;
        margin-left: 60%;
      }

      40%,
      59.9% {
        margin-top: 0%;
        margin-left: 10%;
      }

      60%,
      79.9% {
        margin-top: 5%;
        margin-left: 80%;
      }

      80%,
      99.9% {
        margin-top: 5%;
        margin-left: 50%;
      }
    }
    img {
      margin: 0 40px;
      width: 16%;
      height: 100%;
    }
    .ResultBox {
      width: 36%;
      height: 80%;
      border: 2px solid ${BUTTON_COLOR};
      border-radius: 6px;
      box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
      background-color: ${FLOATING_BOX_COLOR};
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      .Result {
        display: flex;
        align-items: center;
        .Progress {
          margin: 0 12px;
          padding: 4px;
          width: 60px;
        }
      }
    }
  }
  .Footer {
    display: flex;
    button {
      box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
      margin: 40px;
      width: 140px;
      height: 50px;
      border: 1px solid ${BUTTON_COLOR};
      border-radius: 6px;
      color: white;
      cursor: pointer;
    }
    button:hover {
      transform: scale(1.03);
      transition: 0.3s ease-in-out;
    }
    .ButtonBack {
      background-color: red;
    }
    .ButtonNext {
      background-color: green;
    }
    .ButtonLogin {
      background-color: blue;
    }
  }
`;

import { applyMiddleware, createStore, compose } from 'redux';
import rootReducer, { initialState } from './reducer';
import middlewares from '../middlewares';

const middleWare = applyMiddleware(...middlewares);
const composeEnhancers =
  process.env.NODE_ENV !== 'production'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose;

function configureStore(preloadedState = initialState) {
  return createStore(rootReducer, preloadedState, composeEnhancers(middleWare));
}

// const persisConfig = {
//     key: 'root',
//     storage: sessionStorage,
//     whitelist: ['course', 'chapter'],
//     stateReconciler: autoMergeLevel2
// }

// const pReducer = persistReducer(persisConfig, rootReducer);
// const pStore = createStore(pReducer);
// export const persistor = persistStore(pStore);

export default configureStore;

import AuthLayout from 'src/containers/Layout/AuthLayout';
import { VerifyBox } from '../../VerifyEmail/index.style';
import { requestToSendEmail } from 'src/apis/auth';

import { Typography, CircularProgress, Button } from '@mui/material';
import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

const InputEmailContainer = () => {
  const [inputEmail, setInputEmail] = useState('');
  const codeRef = useRef();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [errMessage, setErrMessage] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [disable, setDisable] = useState(false);

  const sendEmail = async () => {
    if (inputEmail === '') {
      setErrMessage('information_cant_be_empty');
    } else {
      setIsLoading(true);
      const res = await requestToSendEmail('v1', inputEmail.trim());
      if (res.result.emailSent === true) {
        setDisable(true);
        setErrMessage('');
        setSuccessMsg('sent_success');
      } else {
        setSuccessMsg('');
        setErrMessage(res.result.errorMsg);
      }
      setIsLoading(false);
    }
  };

  return (
    <AuthLayout>
      <VerifyBox>
        <div className="login-header">
          <div>
            <Typography variant="h2">{t('input_your_email')}</Typography>
          </div>
        </div>
        <div className="login-content">
          <div className="login-content__password">
            <div className="login-content__title">
              <Typography variant="h3">{t('your_email')}</Typography>
            </div>
            <div className="login-content__input">
              <input
                type="text"
                ref={codeRef}
                placeholder={`${t('for_example')}: lananh@gmail.com`}
                onChange={(e) => setInputEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="error-message">{t(errMessage)}</div>
          <div className="success-message">{t(successMsg)}</div>
          <Button disabled={disable} variant="contained" type="submit" onClick={() => sendEmail()}>
            {!isLoading ? t('send') : <CircularProgress sx={{ color: 'white' }} size="1.25rem" />}
          </Button>
        </div>
      </VerifyBox>
    </AuthLayout>
  );
};

export default InputEmailContainer;

export const API_URL = process.env.REACT_APP_API_URL;
export const API_VIA_GATEWAY_URL = process.env.REACT_APP_API_VIA_GATEWAY_URL;

export const KL_REALM = process.env.REACT_APP_KEYCLOAK_REALM_NAME;
export const KL_URL = process.env.REACT_APP_KEYCLOAK_URL;
export const KL_CLIENT_ID = process.env.REACT_APP_KEYCLOAK_CLIENT_ID;
export const KL_CLIENT_SECRET = process.env.REACT_APP_KEYCLOAK_CLIENT_SECRET;
export const KL_ADMIN_USERNAME = process.env.REACT_APP_KEYCLOAK_ADMIN_USERNAME;
export const KL_ADMIN_PASSWORD = process.env.REACT_APP_KEYCLOAK_ADMIN_PASSWORD;
// export const CLIENT_UUID = process.env.REACT_APP_CLIENT_UUID;

import React, { useState, useEffect } from 'react';
import { AudioHasChangeVoiceStyle, AudioStyle } from './index.style';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { getSoundUrl } from 'src/apis/tts';
import { CircularProgress } from '@mui/material';
import { TTS_TYPE } from 'src/constants/theory';

const AudioButton = ({
  playing,
  setPlaying,
  size,
  audioUrl,
  audioText,
  audioRate,
  handleRead,
  needChangeVoice = false
}) => {
  const [url, setUrl] = useState(audioUrl);
  const [fetchingNewUrl, setFetchingNewUrl] = useState(false);
  const [isChangeVoice, setIsChangeVoice] = useState(false);

  // call api change voice: speaker, rate
  const changeVoice = async (newVoice) => {
    const data = {
      speaker: newVoice,
      input_text: audioText,
      return_type: TTS_TYPE.AUDIO,
      speed_rate: audioRate
    };
    setFetchingNewUrl(true);
    const res = await getSoundUrl('v1', data);
    setUrl(res?.data?.fileAccessLink);
    setFetchingNewUrl(false);
  };

  const setAudioPlaying = async () => {
    setPlaying((prev) => !prev);
  };

  useEffect(() => {
    const audio = document.getElementById(`audio-${url ? url : audioUrl}`);
    if (playing) {
      if (isChangeVoice) {
        audio.load();
        setIsChangeVoice(false);
      }
      audio.play();
    } else {
      audio.pause();
    }
    audio.onended = () => {
      setPlaying(false);
    };
  }, [playing]);

  return needChangeVoice ? (
    <AudioHasChangeVoiceStyle size={size}>
      <div className="VoiceType">
        {/* <FormControl sx={{ minWidth: 136 }}>
          <InputLabel id={`${url}-speaker-label`}>
            {t('speaker')} <SettingsVoiceIcon />
          </InputLabel>
          <Select
            labelId={`${url}speaker-label`}
            className="speaker-box"
            value={speaker}
            label={t('speaker')}
            onChange={handleChangeVoice}>
            <MenuItem value={SPEAKER.NGOCHUYEN}>Nữ miền Bắc</MenuItem>
            <MenuItem value={SPEAKER.HUONGGIANG}>Nữ miền Trung</MenuItem>
          </Select>
        </FormControl> */}
      </div>
      <div className="VoiceAudio" onClick={handleRead}>
        <audio id={`audio-${url ? url : audioUrl}`}>
          <source src={url ? url : audioUrl} />
        </audio>
        {fetchingNewUrl ? (
          <CircularProgress style={{ width: size, height: size }} />
        ) : (
          <div className="controls">
            {playing ? (
              <PauseIcon onClick={() => setAudioPlaying()} />
            ) : (
              <PlayArrowIcon className="playBtn" onClick={() => setAudioPlaying()} />
            )}
          </div>
        )}
      </div>
    </AudioHasChangeVoiceStyle>
  ) : (
    <AudioStyle size={size}>
      <div className="VoiceAudio" onClick={handleRead}>
        <audio id={`audio-${url ? url : audioUrl}`}>
          <source src={url ? url : audioUrl} />
        </audio>
        {fetchingNewUrl ? (
          <CircularProgress style={{ width: size, height: size }} />
        ) : (
          <div className="controls">
            {playing ? (
              <PauseIcon onClick={() => setAudioPlaying()} />
            ) : (
              <PlayArrowIcon onClick={() => setAudioPlaying()} />
            )}
          </div>
        )}
      </div>
    </AudioStyle>
  );
};

export default AudioButton;

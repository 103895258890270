export const getTrueTestAnswers = (arr) => {
  let answers = [];
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].mcQuizParts.correctness === true) {
      answers.push(i);
    }
  }
  if (answers.length === 1) {
    return { type: 'one', trueAnswer: answers[0] };
  }
  return { type: 'multiple', trueAnswer: answers };
};

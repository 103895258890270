import axios from 'axios';
import { API_VIA_GATEWAY_URL } from 'src/configs';

const axiosClient = axios.create({
  baseURL: API_VIA_GATEWAY_URL,
  responseType: 'json',
  timeout: 15 * 1000
  // transformResponse: [(data) => camelCase(data, { deep: true })]
});

axiosClient.interceptors.request.use(async (config) => {
  // let accessToken = getCookie('accessToken');
  // if (jwtDecode(accessToken).exp < Date.now() / 1000) {
  //   // token expired -> using refreshToken to generate new tokens
  //   const refreshToken = getCookie('refreshToken');
  //   const data = await regenerateTokensKL(refreshToken);
  //   accessToken = data.access_token;
  //   setCookie('accessToken', accessToken);
  //   setCookie('refreshToken', data.refresh_token);
  // }
  // config.headers.Authorization = `Bearer ${accessToken}`;
  return config;
});

axiosClient.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    // Handle error
    console.error('error', error.response ? error.response.data : error.response);
  }
);

export default axiosClient;

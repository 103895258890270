export const ChapterTourConfig = [
  {
    selector: '[data-tut="chapter__breadcrumb"]',
    content: 'Đầu tiên, đây là đường dẫn đến đến khóa học hiện tại của chúng ta.'
  },
  {
    selector: '[data-tut="chapter__sidebar"]',
    content: 'Ở bên trái là danh sách các chương có trong khóa học này. '
  },
  {
    selector: '[data-tut="chapter__chapterbox__open"]',
    content:
      'Đầu tiên, đối với chương có màu sáng hơn là chương học đang mở khóa, có thể học. Click vào ô vuông này để hiển thị các bài học, bài test có trong chương.'
  },
  {
    selector: '[data-tut="chapter__chapterbox__close"]',
    content:
      'Chương học màu tối hơn là chương học đang khóa, phải học hết các bài học chương trước mới có thể học chương bị khóa.'
  },
  {
    selector: '[data-tut="chapter__content"]',
    content: 'Đây là phần nội dung của chương học đã chọn.'
  },
  {
    selector: '[data-tut="chapter__title"]',
    content: 'Đây là tiêu đề của chương.'
  },
  {
    selector: '[data-tut="chapter__lesson"]',
    content:
      'Đây là một bài học của chương. Nếu màu sáng là bài học đã mở khóa và có thể học. Click vào để bắt đầu học.'
  },
  {
    selector: '[data-tut="chapter__test"]',
    content:
      'Đây là một bài test của chương. Nếu màu tối hơn là bài test đang bị khóa và chưa thể học. Hoàn thành bài học trước để mở khóa bài test.'
  }
];

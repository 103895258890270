import { createSlice } from '@reduxjs/toolkit';

// apis
import { getStar } from '../../../apis/award-admin/userAward';

// initial state
const initialState = {
  star: 0,
  isLoading: false
};

// slice
const userAward = createSlice({
  name: 'userAward',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setStar(state, action) {
      state.star = action.payload;
    }
  }
});

// actions
export const { setLoading, setStar } = userAward.actions;

// get user star
export const getUserStar =
  ({ userId } = {}) =>
  async (dispatch) => {
    let result = {
      success: false
    };

    dispatch(setLoading(true));

    try {
      const res = await getStar({
        userId
      });

      if (res.success) {
        result.success = true;
        dispatch(
          setStar({
            [userId]: res.results?.star || 0
          })
        );
      }
    } catch (error) {
      console.error(error);
    }

    dispatch(setLoading(false));

    return result;
  };

export default userAward.reducer;

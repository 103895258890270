// react & mui
import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';

// customed ui
import { StyledBreadcrumb, StyledTypography } from '../Layout/PageLayout/index.style';
import LessonLayout from '../Layout/LessonLayout';
import PageLayout from '../Layout/PageLayout';
import VSTestSlide from 'src/components/VSTestSlide';
import Spinner from 'src/components/Spinner';

// logic function & call apis
import { getStorage } from 'src/utils/storage';
import { buildTestContainer } from './index.logic';
import { getTestById } from 'src/apis/test';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentTestSlide, setSlideLen } from 'src/redux/test/action';
import TestOverview from './TestOverview';

const TestContainer = () => {
  const dispatcher = useDispatch();
  const currentCourse = getStorage('currentCourse');
  const chapterTitle = getStorage('currentChapter').title;
  const currentSection = getStorage('currentSection');
  const currentSlideIndex = useSelector((state) => state.lesson.currentSlideIndex);
  const [combinedData, setCombinedData] = useState([]);

  const breadcrumbs = [
    <StyledBreadcrumb
      key="2"
      underline="none"
      color="inherit"
      href={`/courses/${currentCourse.id}/chapters`}>
      <StyledTypography variant="h2">{currentCourse.title}</StyledTypography>
    </StyledBreadcrumb>,
    <Typography key="3" underline="none" color="inherit" variant="h2">
      {chapterTitle}
    </Typography>
  ];

  useEffect(() => {
    const fetchTestData = async (testId) => {
      const fetchedTest = await getTestById('v1', testId);
      const test = fetchedTest.result;

      const containers = buildTestContainer(test.quizIds);
      setCombinedData([
        <TestOverview
          key={100}
          title={test.title}
          testPurpose={test.testPurpose}
          quizNum={test.quizIds.length}
          stars={test.quizIds.length}
        />,
        ...containers
      ]);
      dispatcher(setCurrentTestSlide(0));
      dispatcher(setSlideLen(containers.length));
    };

    fetchTestData(currentSection.testId);
  }, []);

  return combinedData && combinedData.length !== 0 ? (
    <LessonLayout
      breadcrumbs={breadcrumbs}
      logoDisplay={'none'}
      completed={
        currentSlideIndex === 0 ? '0%' : `${((currentSlideIndex + 1) / combinedData.length) * 100}%`
      }>
      <div style={{ width: '100%', height: '100%' }}>
        <VSTestSlide data={combinedData} />
      </div>
    </LessonLayout>
  ) : (
    <PageLayout breadcrumbs={breadcrumbs} logoDisplay={'none'} progressDisplay={'none'}>
      <Spinner />
    </PageLayout>
  );
};

export default TestContainer;

import MenuBookTwoToneIcon from '@mui/icons-material/MenuBookTwoTone';
import QuizTwoToneIcon from '@mui/icons-material/QuizTwoTone';

// custom
import TheoryTour from './LessonTour/TheoryTour';
import QuizContainer from '../QuizContainer';
import TheoryContainer from '../TheoryContainer';
import Checkpoint from '../Checkpoint';

export const lessonTour = [
  { id: 1, tour: <TheoryTour /> },
  { id: 2, tour: <></> }
];

export const actions = [
  { icon: <MenuBookTwoToneIcon />, name: 'Theory' },
  { icon: <QuizTwoToneIcon />, name: 'Quiz' }
];

export const buildContainer = (learningPoints) => {
  const containers = learningPoints.map((e, index) => {
    switch (e.type) {
      case 'quiz':
        return <QuizContainer key={index} slideIndex={index} data={e.quizId} />;

      case 'theory':
        return <TheoryContainer key={index} slideIndex={index} data={e.theoryId} />;

      case 'checkpoint':
        return <Checkpoint key={index} slideIndex={index} data={e?.checkpointId} />;

      default:
        return <></>;
    }
  });

  const slides = learningPoints.map((e) => e.type);
  return { containers, slides };
};

import { pathActionType } from './actions';

export const pathInitState = {
  paths: [],
  isFetchAllPaths: false,
  currentPath: null
};

const pathReducer = (state = pathInitState, action) => {
  switch (action.type) {
    case pathActionType.SET_PATHS:
      return {
        ...state,
        paths: action.payload,
        isFetchAllPaths: true
      };
    case pathActionType.SET_CURRENT_PATH:
      return {
        ...state,
        currentPath: action.payload
      };

    default:
      return state;
  }
};

export default pathReducer;

import { useDispatch } from 'react-redux';
import { setDisableNext } from 'src/redux/lesson/actions';
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import { useEffect, useRef, useState } from 'react';
import { CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import { checkSound } from 'src/apis/tts';
import AudioButton from 'src/components/AudioButton';
import styles from './SpeakingQuiz.module.scss';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import HearingIcon from '@mui/icons-material/Hearing';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

const SpeakingQuizContainer = ({ data }) => {
  const dispatch = useDispatch();
  const recorderControls = useAudioRecorder(
    {
      noiseSuppression: true,
      echoCancellation: true
    },
    (err) => console.table(err)
  );
  const [playingQuestion, setPlayingQuestion] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [isReplay, setIsReplay] = useState(false);
  const [result, setResult] = useState(null);
  const [blobResult, setBlobResult] = useState(null);
  const [checkedResult, setCheckedResult] = useState(null);
  const [isChecking, setIsChecking] = useState(false);

  const speakingQuizRef = useRef();
  const speakingMicRef = useRef();

  useEffect(() => {
    const audio = document.getElementById('audio-result');
    if (isReplay) {
      setIsRecording(false);
      audio.play();
    } else {
      audio.pause();
    }
    audio.onended = () => {
      setIsReplay(false);
    };
  }, [isReplay]);

  const handleRecord = () => {
    if (speakingMicRef && speakingMicRef.current) {
      let $micro = speakingMicRef.current.querySelector('.audio-recorder-mic');
      $micro.click();
      setIsRecording(true);
    }
  };

  const handleStopRecord = () => {
    if (speakingMicRef && speakingMicRef.current) {
      let $stopBtn = speakingMicRef.current.querySelector(
        '.audio-recorder-options[data-testid="ar_cancel"]'
      );
      $stopBtn.click();
      setIsRecording(false);
    }
  };

  const addAudioElement = (blob) => {
    if (speakingQuizRef.current) {
      const url = URL.createObjectURL(blob);
      speakingQuizRef.current.src = url;
      setResult(url);
      setBlobResult(blob);
    }
  };

  function blobToFile(blob) {
    const file = new File([blob], 'audiofile.webm', { type: 'audio/webm;codecs=opus' });
    return file;
  }

  const handleSubmit = async () => {
    if (!blobResult) return;
    setIsChecking(true);
    await checkSound(blobToFile(blobResult), data.quizParts[0].content.text)
      .then((res) => {
        setCheckedResult(res);
        setIsChecking(false);
      })
      .catch((err) => console.log('error: ', err));

    dispatch(setDisableNext(false));
  };

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.question}`}>
        <AudioButton
          playing={playingQuestion}
          setPlaying={setPlayingQuestion}
          size={'30px'}
          audioUrl={data.questionAudioUrl}
        />
        <h1>{data.question}</h1>
      </div>
      <div className={`${styles.content}`}>
        <AudioButton
          playing={playing}
          setPlaying={setPlaying}
          size={'30px'}
          audioUrl={data.quizParts[0].content.audioUrl}
        />
        {checkedResult ? (
          <h2>
            {data.quizParts[0].content.text.split(' ').map((word, index) => (
              <span
                key={index}
                className={`${
                  checkedResult.substitution_idx.includes(index) ||
                  checkedResult.insertion_idx.includes(index) ||
                  checkedResult.deletion_idx.includes(index)
                    ? styles.wrongWord
                    : styles.correctWord
                }`}>
                {word}{' '}
              </span>
            ))}
          </h2>
        ) : (
          <h2 style={{ color: 'black' }}>{data.quizParts[0].content.text}</h2>
        )}
      </div>
      {checkedResult && (
        <div className={`${styles.prediction}`}>
          <h4>Bạn đã nói : </h4>
          <h2>
            {checkedResult.prediction.split(' ').map((word, index) => (
              <span
                key={index}
                className={`${
                  checkedResult?.substitution_idx?.includes(index) ||
                  checkedResult?.insertion_idx?.includes(index) ||
                  checkedResult?.deletion_idx?.includes(index)
                    ? styles.wrongWord
                    : styles.correctWord
                }`}>
                {word}{' '}
              </span>
            ))}
          </h2>
          <p>
            Điểm của bạn :{' '}
            {Math.ceil(
              ((checkedResult.transcription.split(' ').length -
                (checkedResult.substitution_idx.length +
                  checkedResult.insertion_idx.length +
                  checkedResult.deletion_idx.length)) *
                100) /
                checkedResult.transcription.split(' ').length
            )}{' '}
            / 100
          </p>
        </div>
      )}
      <div style={{ display: 'none' }} ref={speakingMicRef}>
        <AudioRecorder
          onRecordingComplete={(blob) => addAudioElement(blob)}
          recorderControls={recorderControls}
          showVisualizer={false}
          downloadOnSavePress={false}
        />
      </div>
      {checkedResult ? (
        <div
          className={`${styles.retryBtn}`}
          onClick={() => {
            // setBlobResult(null);
            setCheckedResult(null);
            // setResult(null);
          }}>
          Thử lại
        </div>
      ) : (
        <div className={`${styles.microIcon}`}>
          {isRecording ? (
            <HearingIcon color="inherit" fontSize="large" onClick={handleStopRecord} />
          ) : (
            <KeyboardVoiceIcon color="inherit" fontSize="large" onClick={handleRecord} />
          )}
        </div>
      )}

      <div className={`${styles.result}`}>
        <audio
          id="audio-result"
          ref={speakingQuizRef}
          src={null}
          controls={true}
          style={{ display: 'none' }}
        />
        {checkedResult === null && result ? (
          <>
            Kết quả của bạn :{' '}
            {isReplay ? (
              <PauseIcon onClick={() => setIsReplay(false)} />
            ) : (
              <PlayArrowIcon
                className={`${styles.playResultBtn}`}
                onClick={() => setIsReplay(true)}
              />
            )}
            {isChecking ? (
              <CircularProgress />
            ) : (
              <Button
                variant="contained"
                style={{ backgroundColor: '#273c97' }}
                onClick={handleSubmit}>
                Nộp
              </Button>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default SpeakingQuizContainer;

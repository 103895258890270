import { useDispatch, useSelector } from 'react-redux';

// custom components
import { ChapterBoxStyle } from './index.style';
import { setCurrentChapter } from '../../../redux/chapter/actions';

// mui
import { Box, Typography } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import LockIcon from '@mui/icons-material/Lock';

import itemSound from 'src/assets/audio/item.wav';
import useSound from 'use-sound';
import { getStorage } from 'src/utils/storage';

const ChapterBox = ({ index, id, number, title }) => {
  // state
  const dispatch = useDispatch();
  const chapters = useSelector((state) => state.chapter.chapters);
  const [playChooseChapter] = useSound(itemSound);

  // choose chapter
  const handleClick = () => {
    playChooseChapter();
    dispatch(setCurrentChapter(chapters.find((chapter) => chapter.id === id)));
  };

  const currentStorageResult = getStorage('result');

  return (
    <ChapterBoxStyle
      onClick={() => handleClick()}
      canLearn={currentStorageResult?.canLearnChapterIds.includes(id)}>
      <Box>
        <div className="ChapterBoxStar">
          <StarIcon />
        </div>
        <div className="ChapterBoxNumber">{number}</div>
        <img
          src={process.env.PUBLIC_URL + `/icons/chapter/ic_chapter_${index}.png`}
          alt=""
          style={{ maxWidth: '3rem' }}
        />
        <div className="ChapterBoxTitle">
          <Typography variant="h2">{title}</Typography>
        </div>
        {!currentStorageResult.canLearnChapterIds.includes(id) ? (
          <div className="lock-elm">
            <LockIcon />
          </div>
        ) : (
          <></>
        )}
      </Box>
    </ChapterBoxStyle>
  );
};

export default ChapterBox;

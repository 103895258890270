// react & core libraries
import React, { useEffect, useState } from 'react';
import Xarrow from 'react-xarrows';
import Scrollbars from 'react-custom-scrollbars-2';

// customed ui
import { PQTestBox } from './index.style';
import QuestionBox from '../../../QuizContainer/ToolParts/QuestionBox';
import Item from './Item';
import { CHOSEN_TEST_QUIZ_COLOR } from 'src/theme/color';

// customed logic functions
import { createPairs } from './index.logic';
import { filterArrBySet } from '../../../../utils/array';

// mui
import { Box } from '@mui/material';

// react dnd
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';

// sounding effect & redux
import { useDispatch } from 'react-redux';
import { setDisableTestNext } from 'src/redux/test/action';

const PairQuizTestContainer = ({ data }) => {
  const isMobile = window.innerWidth < 600;
  const [arrows, setArrows] = useState([]);
  const [leftPairs, setLeftPairs] = useState([]);
  const [rightPairs, setRightPairs] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const pairs = createPairs(data.quizParts, data._id);
    setLeftPairs(pairs.leftPairs);
    setRightPairs(pairs.rightPairs);
    setArrows([]);
  }, []);

  useEffect(() => {
    const filteredArrows = filterArrBySet(arrows);
    if (filteredArrows.length !== arrows.length) {
      setArrows([...filteredArrows]);
    }
    console.log(arrows);
    const counterTrue = filteredArrows.map((e) => e.arrowColor === CHOSEN_TEST_QUIZ_COLOR);
    if (counterTrue.length >= leftPairs.length) {
      dispatch(setDisableTestNext(false));
    }
  }, [arrows]);

  return (
    <PQTestBox image={data.backgroundUrl} opacity={data.backgroundOpacity}>
      <QuestionBox
        imgUrl={data.questionImageUrl}
        audioUrl={data.questionAudioUrl}
        question={data.question}
      />

      <Scrollbars autoHeight autoHeightMax={400}>
        <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
          {leftPairs.map((ele, index) => {
            return (
              <Box className="line" key={index}>
                {[
                  { data: ele, dot: 'left' },
                  { data: rightPairs[index], dot: 'right' }
                ].map((p) => {
                  return (
                    <Item
                      key={p.dot}
                      data={p.data}
                      dot={p.dot}
                      arrows={arrows}
                      setArrows={setArrows}
                      leftPairs={leftPairs}
                      setLeftPairs={setLeftPairs}
                      rightPairs={rightPairs}
                      setRightPairs={setRightPairs}
                    />
                  );
                })}
              </Box>
            );
          })}

          {arrows.map((ar, index) => (
            <Xarrow
              key={index}
              start={ar.start}
              end={ar.end}
              showHead={false}
              color={CHOSEN_TEST_QUIZ_COLOR}
              strokeWidth={2}
            />
          ))}
        </DndProvider>
      </Scrollbars>
    </PQTestBox>
  );
};

export default PairQuizTestContainer;

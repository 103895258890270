import { styled } from '@mui/material/styles';

export const ItemBox = styled('div')`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 300px;
  height: 70px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: ${(props) =>
    props.chosen === 'incorrect'
      ? '4px solid red'
      : props.chosen === 'correct'
      ? '4px solid green'
      : '1px solid black'};

  .media-display {
    display: flex;
    align-items: center;
  }
`;

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setNote } from 'src/redux/note/actions';
import Popover from '@mui/material/Popover';
import { BUTTON_COLOR } from 'src/theme/color';
import { Typography } from '@mui/material';
import AudioButton from 'src/components/AudioButton';
import AddNote from 'src/assets/images/icons/add-note.png';

import { getSoundUrl } from 'src/apis/tts';
import { TTS_TYPE } from 'src/constants/theory';

export default function WordDetail({ index, text, mediaObject, textParams }) {
  const [audioUrl, setAudioUrl] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const noteLists = useSelector((state) => state.note.noteLists);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    handleDetailAudio();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  // handle add note
  const handleAddNote = () => {
    const existNoteIndex = noteLists.findIndex(
      (element) => text.toLowerCase().localeCompare(element.text.toLowerCase()) === 0
    );
    if (existNoteIndex === -1) {
      dispatch(setNote({ text, textParams, audioUrl, speaker: mediaObject.voice }));
    }
  };
  // call detail audio
  const handleDetailAudio = async () => {
    const data = {
      speaker: mediaObject.voice,
      input_text: text,
      return_type: TTS_TYPE.AUDIO,
      speed_rate: 1
    };
    const res = await getSoundUrl('v1', data);
    setAudioUrl(res?.data?.fileAccessLink);
  };

  return (
    <div>
      <span aria-describedby={`${id}-${index}`} onClick={handleClick} style={{ cursor: 'pointer' }}>
        {text}
      </span>
      <Popover
        id={`${id}-${index}`}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}>
        <div style={{ padding: 8, width: 210 }}>
          <div
            style={{
              width: '100%',
              height: '100%',
              padding: 10,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              border: `2px solid ${BUTTON_COLOR}`,
              borderRadius: 6
            }}>
            <Typography
              variant="h2"
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {text}
              <div style={{ cursor: 'pointer', marginLeft: '8px' }} onClick={handleAddNote}>
                <img src={AddNote} width={32} height={32} alt="add note img" />
              </div>
            </Typography>
            <span style={{ border: `1px solid ${BUTTON_COLOR}`, margin: '12px 0 20px' }}></span>
            <AudioButton
              size={'24px'}
              audioText={text}
              audioUrl={audioUrl}
              audioRate={1}
              audioSpeaker={mediaObject.voice}
              needChangeVoice={true}
            />
          </div>
        </div>
      </Popover>
    </div>
  );
}

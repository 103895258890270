import { styled } from '@mui/material/styles';

// EOW: Element of Word
export const EOWBox = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;

  ${(props) =>
    props.image === undefined || props.opacity === undefined
      ? ''
      : `
        background-image: linear-gradient(rgba(225,225,225,${props.opacity}), rgba(225,225,225,${props.opacity})), url(${props.image});
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
        `};

  .syllable-text {
    display: flex;
    flex-direction: row;
    align-items: start;
  }

  .dnd-sector {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .scrollable {
      display: flex;
      justify-content: ${(props) => (props.isCenter == true ? 'center' : 'start')};
      align-items: center;
      overflow: auto;
      max-height: 260px;
      min-width: 700px;
      max-width: 800px;

      .syllables {
        margin-top: 10px;
        margin-bottom: 2.5px;
        display: flex;
        flex-direction: row;
        align-items: start;
      }

      .top-sector {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5.5px;
      }
    }

    .bottom-dnd {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`;

export const moveItemToAnotherBox = (currentItem, newBoxId, setItems) => {
  if (newBoxId !== 'bottom-box') {
    setItems((prev) => {
      return prev.map((e) => {
        return {
          ...e,
          belongTo:
            e.belongTo === newBoxId
              ? 'bottom-box'
              : e.answerId === currentItem.answerId
              ? newBoxId
              : e.belongTo
        };
      });
    });
  }
};

export const compare = (string1, string2) => {
  if (string1 == string2) return true;
  else return false;
};

export const changeChosen = (oldBelongTo, tboxId, newState, setChosen) => {
  setChosen((prev) => {
    return prev.map((e) => {
      return {
        ...e,
        boxes: e.boxes.map((ele) => {
          if (ele === 'tab' || ele === 'gap') return ele;
          else
            return {
              ...ele,
              chosen:
                ele.boxId === oldBelongTo ? 'none' : ele.boxId === tboxId ? newState : ele.chosen
            };
        }),
        tonesAbove: e.tonesAbove.map((eleA) => {
          if (eleA === 'tab' || eleA === 'gap' || eleA === 'space') return eleA;
          else
            return {
              ...eleA,
              chosen:
                eleA.boxId === oldBelongTo ? 'none' : eleA.boxId === tboxId ? newState : eleA.chosen
            };
        }),
        tonesBelow: e.tonesBelow.map((eleB) => {
          if (eleB === 'tab' || eleB === 'gap' || eleB === 'space') return eleB;
          else
            return {
              ...eleB,
              chosen:
                eleB.boxId === oldBelongTo ? 'none' : eleB.boxId === tboxId ? newState : eleB.chosen
            };
        })
      };
    });
  });
};

// basic UI MUI
import React from 'react';
import { Box, Typography } from '@mui/material';
import LocalFloristIcon from '@mui/icons-material/LocalFlorist';

// react-circular lib
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

// CSS style
import { ChapterOverviewBox } from './index.style';

export const CompletedProgressbar = (props) => {
  return (
    <CircularProgressbar
      value={props.completePercent}
      text={`${props.completePercent}%`}
      strokeWidth={12.5}
      styles={buildStyles({
        textSize: '25px',
        textStyle: 'bold',
        textColor: 'black',
        pathColor: '#87D147'
      })}
    />
  );
};

const ChapterOverview = (props) => {
  return (
    <ChapterOverviewBox>
      {/* 1. Icon */}
      <LocalFloristIcon className="flower-icon" />

      {/* 2. Box of 2 text */}
      <Box className="text-box">
        <Typography fontWeight="bold" variant="body1">
          {props.title}
        </Typography>

        <Typography fontStyle="italic" variant="body1" sx={{ textAlign: 'left' }}>
          {props.numberOfSection} bài
        </Typography>
      </Box>

      {/* 3. Circular Progress */}
      <Box sx={{ flex: { lg: 1.5, xl: 1 } }}>
        <CompletedProgressbar completePercent={props.completePercent} />
      </Box>
    </ChapterOverviewBox>
  );
};

export default ChapterOverview;

// react & mui
import { useEffect } from 'react';
import { Container, Typography, Box } from '@mui/material';

// custom UI
import BeforeLearningLayout from '../Layout/BeforeLearningLayout';

// redux
import { changeMenu } from 'src/redux/course/actions';
import { useDispatch } from 'react-redux';

const StudyingResultContainer = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeMenu(false));
  }, []);

  return (
    <BeforeLearningLayout>
      <Container maxWidth={false} sx={{ maxWidth: '86%', marginTop: { xl: '50px' } }}>
        <Box sx={{ marginTop: '150px' }}>
          <Typography variant="h2" textTransform={'none'} textAlign={'center'}>
            Tính năng đang được phát triển
          </Typography>
        </Box>
      </Container>
    </BeforeLearningLayout>
  );
};

export default StudyingResultContainer;

// React lib
import React from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Tooltip, Typography } from '@mui/material';

// Smaller components
import { LanguageBox } from './index.style';
import { setStorage } from 'src/utils/storage';
import { useDispatch, useSelector } from 'react-redux';
import { changeSupportLanguage } from 'src/redux/course/actions';

export const SystemLanguageSmallBox = (props) => {
  const { t, i18n } = useTranslation();
  const chooseLanguage = () => {
    i18n.changeLanguage(props.lang_abbr);
  };

  return (
    <Tooltip title={<h3>{t(props.language_name)}</h3>}>
      <LanguageBox onClick={chooseLanguage} isChosen={i18n.language === props.lang_abbr}>
        <img src={props.flag} />
        <Typography variant="body1" fontWeight="bold" textAlign="center">
          {t(props.language_name)}
        </Typography>
      </LanguageBox>
    </Tooltip>
  );
};

export const SupportLanguageSmallBox = (props) => {
  const { t } = useTranslation();
  const dispatcher = useDispatch();
  const currentSptLang = useSelector((state) => state.course.supportLanguage);

  const chooseLanguage = () => {
    setStorage({ key: 'supportLanguage', data: props.lang_abbr });
    dispatcher(changeSupportLanguage(props.lang_abbr));
  };

  return (
    <Tooltip title={<h3>{t(props.language_name)}</h3>}>
      <LanguageBox onClick={chooseLanguage} isChosen={currentSptLang === props.lang_abbr}>
        <img src={props.flag} />
        <Typography variant="body1" fontWeight="bold" textAlign="center">
          {t(props.language_name)}
        </Typography>
      </LanguageBox>
    </Tooltip>
  );
};

import { memo } from 'react';
import { Grid } from '@mui/material';

// local
import LuckyBoxesCtrl from './LuckyBoxes.ctrl';
import LuckyBoxCard from './LuckyBoxCard/LuckyBoxCard';
import LuckyBoxModal from './LuckyBoxModal/LuckyBoxModal';

// eslint-disable-next-line react/display-name
const LuckyBoxes = memo(() => {
  // -- ctrl --
  const { luckyBoxIds } = LuckyBoxesCtrl.useContainer();

  return (
    <>
      {/* list lucky boxes */}
      <Grid container spacing={2}>
        {luckyBoxIds.map((id) => (
          <Grid key={id} item xs={12} md={3}>
            <LuckyBoxCard boxId={id} />
          </Grid>
        ))}
      </Grid>

      {/* modal */}
      <LuckyBoxModal />
    </>
  );
});

// eslint-disable-next-line react/display-name
export default () => (
  <LuckyBoxesCtrl.Provider>
    <LuckyBoxes />
  </LuckyBoxesCtrl.Provider>
);

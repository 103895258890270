// React core + redux
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAllChapters, setCurrentChapter } from 'src/redux/chapter/actions';
import { updateUserInfo } from 'src/redux/auth/actions';

// apis
import { getStorage, setStorage } from 'src/utils/storage';
import { getChaptersByCourseId } from 'src/apis/course';
import { getMe } from 'src/apis/auth';

// custom components
import ChapterContent from './ChapterContent';
import SideBar from './SideBar';
import PageLayout from '../Layout/PageLayout';
import ChapterTour from './ChapterTour';
import Spinner from 'src/components/Spinner';
import { ChapterTourConfig } from './ChapterTour/tour-config';

// const & mui
import { Typography } from '@mui/material';
import { TOUR_TYPE } from 'src/constants';
import { processLearnedChapters } from './index.logic';

const ChapterContainer = () => {
  const dispatch = useDispatch();
  const currentCourse = getStorage('currentCourse');
  const chapters = useSelector((state) => state.chapter.chapters);

  useEffect(() => {
    // const fetchChaptersByCourseId = async (courseId) => {
    //   const data = await getChaptersByCourseId(courseId);
    //   dispatch(setAllChapters(data.result));
    //   dispatch(setCurrentChapter(data.result[0]));
    // }

    // fetchChaptersByCourseId(currentCourse.id);
    initChapters(currentCourse.id);
  }, []);

  const initChapters = async (courseId) => {
    const data = await getChaptersByCourseId(courseId);
    const user = await getMe('v1');
    dispatch(updateUserInfo(user));

    const learningResult = user.result.resultId;
    // get list done chapter -> process to find which chapter can be learned
    const chapterDoneIds =
      learningResult.progress === undefined || learningResult.progress === null
        ? []
        : learningResult.progress.chaptersDone;
    const processData = processLearnedChapters(data.result, chapterDoneIds);
    dispatch(setAllChapters(processData));

    // find out which chapter can be learned by user
    let currentChapter;
    if (learningResult.progress === null || learningResult.progress === undefined) {
      currentChapter = processData[0];
    } else {
      const currentChapterId = learningResult.currentdIds.currentChapterId;
      currentChapter = learningResult.filter((e) => e._id === currentChapterId)[0];
    }
    dispatch(setCurrentChapter(currentChapter));

    // save can learn sections to localstorage
    const currentStorageResult = getStorage('result');
    if (
      !currentStorageResult?.canLearnSectionIds ||
      !currentStorageResult?.canLearnSectionIds.length
    ) {
      setStorage({
        key: 'result',
        data: {
          ...currentStorageResult,
          canLearnSectionIds: [
            currentChapter.newSection[0][`${currentChapter.newSection[0].type}Id`]
          ]
        }
      });
    }
  };

  const breadcrumbs = currentCourse
    ? [
        <Typography key="2" color="inharit" variant="h2">
          {currentCourse.title}
        </Typography>
      ]
    : [];

  return chapters && chapters.length !== 0 ? (
    <PageLayout
      breadcrumbs={breadcrumbs}
      logoDisplay={'none'}
      progressDisplay={'none'}
      tourConfig={ChapterTourConfig}
      tourUI={<ChapterTour />}
      tourType={TOUR_TYPE.SINGLE}>
      <SideBar listChapters={chapters} />
      <ChapterContent />
    </PageLayout>
  ) : (
    <PageLayout breadcrumbs={breadcrumbs} logoDisplay={'none'} progressDisplay={'none'}>
      <Spinner />
    </PageLayout>
  );
};

export default ChapterContainer;

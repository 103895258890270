import { keyframes, styled } from '@mui/material';
import { FLOATING_BOX_COLOR } from 'src/theme/color';

const LoadingAnimation = keyframes`
  from{
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    transition-timing-function: ease-in-out;
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
    transition-timing-function: ease-in-out;
  }
`;
export const LoadingStyle = styled('div')`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${FLOATING_BOX_COLOR};

  img {
    animation: ${LoadingAnimation} 16s infinite;
  }
`;

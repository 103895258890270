import { checkUpdatablePassword, handleUpdatePassword } from 'src/apis/auth';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import AuthLayout from 'src/containers/Layout/AuthLayout';
import { VerifyBox } from '../../VerifyEmail/index.style';

import { Typography, CircularProgress, Button } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { useTranslation } from 'react-i18next';

const InputNewPWContainer = () => {
  const { userId, verifyCode } = useParams();
  const [updatable, setUpdatable] = useState(false);
  const [errMessage, setErrMessage] = useState('');
  const [userUUID, setUserUUID] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [isLoadingInit, setIsLoadingInit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [disable, setDisable] = useState(false);
  const codeRef = useRef();
  const { t } = useTranslation();
  const navigator = useNavigate();

  useEffect(() => {
    const checkUpdatable = async () => {
      setIsLoadingInit(true);
      const res = await checkUpdatablePassword('v1', userId, verifyCode);
      if (res.result.errorMsg === '') {
        setUserUUID(res.result.UUID);
        setUpdatable(true);
      } else {
        setErrMessage(res.result.errorMsg);
        setUpdatable(false);
      }
      setIsLoadingInit(false);
    };

    checkUpdatable();
  }, []);

  const updatePassword = async () => {
    if (newPassword === '') {
      setErrMessage('information_cant_be_empty');
    } else {
      setIsLoading(true);
      setDisable(true);
      const res1 = await handleUpdatePassword('v1', newPassword.trim(), userUUID);
      if (res1.result === true) {
        toast.success(t('update_pw_success'));
        setErrMessage('');
        setTimeout(() => navigator('/'), 3000);
      } else {
        toast.error(t('update_pw_failed'));
        setErrMessage(''); //retry later;
      }
      setIsLoading(false);
    }
  };

  return (
    <AuthLayout sx={{ position: 'relative' }}>
      {isLoadingInit ? (
        <CircularProgress sx={{ position: 'absolute', left: '50%', top: '50%' }} />
      ) : updatable ? (
        <VerifyBox>
          <div className="login-header">
            <div>
              <Typography variant="h2">{t('update_pw')}</Typography>
            </div>
          </div>
          <div className="login-content">
            <div className="login-content__password">
              <div className="login-content__title">
                <Typography variant="h3">{t('your_new_pw')}</Typography>
              </div>
              <div className="login-content__input">
                <input
                  type={isVisible ? 'text' : 'password'}
                  ref={codeRef}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                <span className="visible-icon" onClick={() => setIsVisible(!isVisible)}>
                  {isVisible ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                </span>
              </div>
            </div>
            <div className="error-message">{t(`${errMessage}`)}</div>
            <Button
              disabled={disable}
              variant="contained"
              type="submit"
              onClick={() => updatePassword()}>
              {!isLoading ? t('send') : <CircularProgress sx={{ color: 'white' }} size="1.25rem" />}
            </Button>
          </div>
        </VerifyBox>
      ) : (
        <div className="login-header">
          <div style={{ marginTop: '25px' }}>
            <Typography variant="h2">{t(`${errMessage}`)}</Typography>
          </div>
        </div>
      )}
    </AuthLayout>
  );
};

export default InputNewPWContainer;

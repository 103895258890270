import { styled } from '@mui/material/styles';

export const PORBox = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;

  ${(props) =>
    props.image === undefined || props.opacity === undefined
      ? ''
      : `
        background-image: linear-gradient(rgba(225,225,225,${props.opacity}), rgba(225,225,225,${props.opacity})), url(${props.image});
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
        `};

  .syllable-text {
    display: flex;
    flex-direction: row;
    align-items: start;
  }

  .dnd-sector {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 5px;

    .scrollable {
      display: flex;
      justify-content: ${(props) => (props.isCenter == true ? 'center' : 'start')};
      align-items: center;
      overflow: auto;
      max-height: 235px;
      min-width: 700px;
      max-width: 800px;

      .syllables {
        margin-top: 8px;
        display: flex;
        flex-direction: row;
        align-items: start;
      }

      .top-sector {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    .btm-dnd {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`;

import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

export const LogoutButton = styled(Button)({
  width: '60%',
  position: 'absolute',
  bottom: '8px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center'
});

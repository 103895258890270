import { styled } from '@mui/material/styles';
import { BUTTON_COLOR } from 'src/theme/color';

export const SingleTourStyle = styled('div')`
  display: flex;
  width: 55px;

  .MuiSvgIcon-root {
    font-size: 40px;
    color: ${BUTTON_COLOR};
    cursor: pointer;
  }
`;

export const MultiTourStyle = styled('div')`
  display: flex;
  width: 55px;

  .TopIcon > .MuiButtonBase-root {
    width: 0;
  }

  .SubIcon {
    background-color: ${BUTTON_COLOR};
    color: white;
  }

  .HelpIcon {
    font-size: 40px;
    color: ${BUTTON_COLOR};
    cursor: pointer;
  }
`;

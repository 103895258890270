import { styled } from '@mui/material/styles';

export const StyledSquare = styled('div')`
  padding: 4px;
  min-height: 40px;
  min-width: 40px;
  background-color: ${(props) => props.display} white;
  border: 3px solid ${(props) => props.display} ${(props) => props.color};
  color: ${(props) => props.color};
  font-weight: bold;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
`;

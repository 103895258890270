import { FLOATING_BOX_COLOR } from 'src/theme/color';

import moonImg from '../../assets/images/modules/moon.jpg';
import starImg from '../../assets/images/modules/star.jpeg';
import mountainImg from '../../assets/images/modules/mountain.jpg';

import audio2 from '../../assets/audio/voice2.mp3';

import { TONE, MEDIA_TYPE, SQUARE_QUIZ_TYPE } from 'src/constants/quiz';

import school2 from '../../assets/images/modules/quizBackground/school2.jpeg';
import school from '../../assets/images/modules/quizBackground/school1.png';
import minhhoa1 from '../../assets/images/modules/minhhoa1.jpg';

const OPACITY = {
  LOW: 0.3,
  MEDIUM: 0.6,
  HIGH: 0.6
};

export const quizzes = [
  {
    id: 1,
    inTestBank: false,
    title: 'Câu hỏi cho trẻ em lớp 1',
    backgroundUrl: school,
    backgroundOpacity: OPACITY.MEDIUM,
    textBackgroundColor: FLOATING_BOX_COLOR,
    question: 'Kéo thả tiếng thích hợp vào ô trống ?',
    voice: 'Hương Giang',
    rateVoice: 1.5,
    score: 25,
    type: 'blank_quiz',
    quizParts: [
      {
        content: {
          text: 'Trời'
        },
        blankQuizParts: {
          wrongAnswer: {
            text: 'Đất'
          },
          isHidden: true
        }
      },
      {
        content: {
          text: 'thêm'
        },
        blankQuizParts: {
          wrongAnswer: {
            text: 'thêm'
          },
          isHidden: false
        }
      },
      {
        content: {
          text: 'ngày'
        },
        blankQuizParts: {
          wrongAnswer: {
            text: 'đêm'
          },
          isHidden: true
        }
      },
      {
        content: {
          text: 'tháng'
        },
        blankQuizParts: {
          wrongAnswer: {
            text: 'tháng'
          },
          isHidden: false
        }
      },
      {
        content: {
          text: 'người'
        },
        blankQuizParts: {
          wrongAnswer: {
            text: 'người'
          },
          isHidden: false
        }
      },
      {
        content: {
          text: 'thêm'
        },
        blankQuizParts: {
          wrongAnswer: {
            text: 'bớt'
          },
          isHidden: true
        }
      },
      {
        content: {
          text: 'tuổi'
        },
        blankQuizParts: {
          wrongAnswer: {
            text: 'tuổi'
          },
          isHidden: false
        }
      }
    ]
  },
  {
    id: 0,
    inTestBank: false,
    title: 'Câu hỏi cho trẻ em lớp 1',
    backgroundUrl: school2,
    backgroundOpacity: OPACITY.MEDIUM,
    textBackgroundColor: FLOATING_BOX_COLOR,
    question: 'Nối các từ với hình ảnh của chúng sao cho đúng',
    voice: 'Hương Giang',
    rateVoice: 1.5,
    score: 35,
    type: 'pair_quiz',
    quizParts: [
      {
        content: {
          text: 'trăng'
        },
        pairQuizParts: {
          pairContent: {
            imageUrl: moonImg
          }
        }
      },
      {
        content: {
          text: 'sao'
        },
        pairQuizParts: {
          pairContent: {
            imageUrl: starImg
          }
        }
      },
      {
        content: {
          text: 'núi'
        },
        pairQuizParts: {
          pairContent: {
            imageUrl: mountainImg
          }
        }
      },
      {
        content: {
          text: 'bánh quy'
        },
        pairQuizParts: {
          pairContent: {
            audioUrl: audio2
          }
        }
      }
    ]
  },
  {
    id: 2,
    inTestBank: false,
    title: 'Câu hỏi cho trẻ em lớp 1',
    textBackgroundColor: FLOATING_BOX_COLOR,
    question: 'Phụ âm đầu của tiếng "Mẹ" là gì ?',
    questionImageUrl: minhhoa1,
    voice: 'Hương Giang',
    rateVoice: 1.5,
    score: 25,
    type: 'mc_quiz',
    quizParts: [
      {
        content: {
          text: 'e',
          rateVoice: 1,
          voice: 'Hương Giang'
        },
        mcQuizParts: {
          correctness: false
        }
      },
      {
        content: {
          text: 'm',
          rateVoice: 1,
          voice: 'Hương Giang'
        },
        mcQuizParts: {
          correctness: true
        }
      },
      {
        content: {
          text: 'me',
          rateVoice: 1,
          voice: 'Hương Giang'
        },
        mcQuizParts: {
          correctness: false
        }
      },
      {
        content: {
          text: 'mẹ',
          rateVoice: 1,
          voice: 'Hương Giang'
        },
        mcQuizParts: {
          correctness: false
        }
      }
    ]
  },
  {
    id: 3,
    inTestBank: false,
    title: 'Câu hỏi cho trẻ em lớp 1',
    backgroundUrl: school,
    backgroundOpacity: OPACITY.MEDIUM,
    textBackgroundColor: FLOATING_BOX_COLOR,
    question: 'Kéo thả thành phần của tiếng sao cho thích hợp vào ô trống ?',
    voice: 'Hương Giang',
    rateVoice: 1.5,
    score: 25,
    type: 'square_quiz',
    squareQuizType: SQUARE_QUIZ_TYPE.PHONEME_OF_SYLLABLE,
    quizParts: [
      {
        content: {
          text: 'triều đại',
          type: MEDIA_TYPE.TEXT
        },
        squareQuizParts: [
          {
            content: {
              text: 'triều',
              audioUrl: ''
            },
            structure: {
              consonant: 'tr',
              rhyme: {
                content: {
                  text: 'iêu',
                  audioUrl: ''
                },
                structure: {
                  startRhyme: '',
                  mainRhyme: 'iê',
                  finishRhyme: 'u'
                }
              },
              tone: TONE.FALLING
            }
          },
          {
            content: {
              text: 'đại',
              audioUrl: ''
            },
            structure: {
              consonant: 'đ',
              rhyme: {
                content: {
                  text: 'ai',
                  audioUrl: ''
                },
                structure: {
                  startRhyme: '',
                  mainRhyme: 'a',
                  finishRhyme: 'i'
                }
              },
              tone: TONE.DROP
            }
          }
        ]
      }
    ]
  }
];

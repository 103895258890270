import { styled } from '@mui/material/styles';
import { BORDER_COLOR, FLOATING_BOX_COLOR } from '../../../theme/color';

export const SideBarStyle = styled('div')`
  background-color: ${FLOATING_BOX_COLOR};
  height: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 18%;
  border-right: 3px solid ${BORDER_COLOR};
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`;

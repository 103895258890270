import Slider from 'react-slick';
import { styled } from '@mui/material/styles';
import { BUTTON_COLOR } from 'src/theme/color';

export const SlideStyle = styled(Slider)`
  height: 100%;
  .slick-list {
    height: 100%;
    .slick-track {
      height: 100%;
      .slick-slide > div {
        height: 100%;
        position: relative;
      }
    }
  }
`;

export const ArrowStyle = styled('div')`
  height: 0;

  .arrow-btn {
    background-color: ${BUTTON_COLOR};
    color: white;
    border: none;
    border-radius: 50%;
    padding: 6px;
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .prev-btn {
    position: absolute;
    left: 2.5%;
    top: 50%;
    opacity: ${(props) => (props.disablePrev ? 0.5 : 1)};
  }

  .next-btn {
    position: absolute;
    right: 2.5%;
    top: 50%;
    opacity: ${(props) => (props.disableNext ? 0.5 : 1)};
  }
`;

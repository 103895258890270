const BACKGROUND_MAIN_COLOR = '#EED185';

const FLOATING_BOX_COLOR = '#FFFB98';

const BUTTON_COLOR = '#0D4DC9';

const SIDEBAR_COLOR = '#FFDF00'; // progress bg color

const LESSON_BOX_COLOR = '#df4d4d'; // star bg color

const CONNECT_POINT_COLOR = '#FF9933'; // orange

const BORDER_COLOR = '#F1CC24';

const CHOSEN_TEST_QUIZ_COLOR = '#2196f3';

export {
  BACKGROUND_MAIN_COLOR,
  FLOATING_BOX_COLOR,
  BUTTON_COLOR,
  SIDEBAR_COLOR,
  LESSON_BOX_COLOR,
  CONNECT_POINT_COLOR,
  BORDER_COLOR,
  CHOSEN_TEST_QUIZ_COLOR
};

import { chapterActionTypes } from './actions';

export const chapterInitState = {
  chapters: [],
  currentChapter: {
    id: undefined,
    title: undefined,
    imageUrl: undefined,
    newSection: []
  },

  isOpenChapterContent: false
};

const chapterReducer = (state = chapterInitState, action) => {
  switch (action.type) {
    case chapterActionTypes.SET_ALL_CHAPTERS:
      return {
        ...state,
        chapters: action.payload
      };

    case chapterActionTypes.SET_CURRENT_CHAPTER:
      return {
        ...state,
        currentChapter: action.payload
      };

    case chapterActionTypes.CHANGE_CHAPTER_CONTENT:
      return {
        ...state,
        isOpenChapterContent: action.payload
      };

    default:
      return state;
  }
};

export default chapterReducer;

import { styled } from '@mui/material/styles';
import { CHOSEN_TEST_QUIZ_COLOR } from 'src/theme/color';

export const RadioTestAnswersBox = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  margin-top: 22.5px;

  .line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
  }

  .only-one-line {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    gap: 10px;
  }
`;

export const RadioTestOptBox = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  gap: 10px;
  padding: 10px;
  border: ${(props) =>
    props.chosen === 'unchosen' || props.compare
      ? '1px solid black'
      : props.chosen === 'chosen'
      ? `3px solid ${CHOSEN_TEST_QUIZ_COLOR}`
      : '1px solid black'};
  border-radius: 10px;
  box-sizing: border-box;
  width: ${(props) => props.width || '60%'};
`;

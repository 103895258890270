import { useDrop } from 'react-dnd';
import { FLOATING_BOX_COLOR } from '../../../../../theme/color';
import { DisplayTopBox } from './index.style';
import MediaDisplayer from 'src/containers/QuizContainer/ToolParts/MediaDisplayer';

export const TextTopBox = ({ media }) => {
  return (
    <DisplayTopBox border={false}>
      <MediaDisplayer media={media} />
    </DisplayTopBox>
  );
};

export const BlankTopBox = ({ boxId, children, chosen }) => {
  const [, drop] = useDrop({
    accept: 'dnd-box',
    drop: () => ({ boxId })
  });

  return (
    <DisplayTopBox
      bgc={FLOATING_BOX_COLOR}
      radius={'10px'}
      isBorder={true}
      ref={drop}
      id={`${boxId}-top`}
      sx={{
        border:
          chosen === 'correct' ? '2px solid green' : chosen === 'incorrect' ? '2px solid red' : ''
      }}>
      {children}
    </DisplayTopBox>
  );
};

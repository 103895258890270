import React from 'react';
// MUI
import { Box } from '@mui/material';

// written component
import Header from '../Header';
import MenuSideBar from 'src/containers/MenuSideBar';
import { Main } from './index.style';
import { useSelector } from 'react-redux';

const BeforeLearningLayout = ({ children }) => {
  const isOpen = useSelector((state) => state.course.menuOpen);

  return (
    <Box
      sx={{
        display: 'flex',
        marginTop: '10vh',
        height: '90vh'
        // position: 'relative',
        // isolation: 'isolate',
        // backgroundImage: `url(${ABC_bg})`
      }}>
      <Header />
      <Main sx={{ filter: isOpen === false ? 'brightness(100%)' : 'brightness(92%)' }}>
        {children}
      </Main>
      <MenuSideBar />
    </Box>
  );
};

export default BeforeLearningLayout;

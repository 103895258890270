import React from 'react';
import Square from '../../SquareBox';
import { SQUARE_COLOR } from 'src/styles/color';

const ElementsInWord = ({ data }) => {
  const { text, rhymeText, consonant } = data;

  return (
    <table>
      <tbody>
        <tr>
          <td colSpan={2}>
            <h3> {text}</h3>
          </td>
        </tr>
        <tr>
          <td>
            <Square color={SQUARE_COLOR.turquoise}>{consonant}</Square>
          </td>
          <td>
            <Square color={SQUARE_COLOR.violet}>{rhymeText}</Square>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default ElementsInWord;

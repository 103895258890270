import React, { useState } from 'react';
import HelpIcon from '@mui/icons-material/Help';
import Tour from 'reactour';
import { BUTTON_COLOR } from 'src/theme/color';
import { Box, Modal, SpeedDial, SpeedDialAction } from '@mui/material';
import { MultiTourStyle } from './index.style';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  height: '90%',
  bgcolor: 'background.paper'
};

const CustomMultiTour = ({ tourUI, tourConfig, actions }) => {
  const [isOpenTour, setIsOpenTour] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentTour, setCurrentTour] = useState(0);

  // show more icon
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };
  const handleChooseTour = (i) => {
    setOpen(false);
    setCurrentTour(i);
    handleOpenModal();
    handleOpenTour();
  };

  // handle modal
  const handleOpenModal = () => {
    setIsOpenModal(true);
  };
  const handleCloseModal = () => setIsOpenModal(false);

  // handle tour
  const handleCloseTour = () => {
    setIsOpenTour(false);
  };

  const handleOpenTour = () => {
    setIsOpenTour(true);
  };

  return (
    <MultiTourStyle>
      {tourConfig ? (
        <>
          <SpeedDial
            ariaLabel="SpeedDial controlled open example"
            sx={{ position: 'absolute', top: -2, right: 40 }}
            className="TopIcon"
            icon={<HelpIcon className="HelpIcon" />}
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
            direction="down">
            {actions.map((action, index) => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                onClick={() => handleChooseTour(index)}
                className="SubIcon"
              />
            ))}
          </SpeedDial>
          <Tour
            onRequestClose={() => handleCloseTour()}
            steps={tourConfig[currentTour]}
            isOpen={isOpenTour}
            className="helper"
            rounded={5}
            accentColor={BUTTON_COLOR}
          />
          <Modal
            open={isOpenModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={style}>{tourUI[currentTour].tour}</Box>
          </Modal>
        </>
      ) : (
        <></>
      )}
    </MultiTourStyle>
  );
};

export default CustomMultiTour;

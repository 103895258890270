// run only once inside useEffect !!!
export const extractTopList = (list, dataId) => {
  return list.map((e, index) => {
    return e.blankQuizParts.isHidden === true
      ? {
          media: 'blank',
          boxId: `${dataId}-${index}-true`,
          chosen: 'none'
        }
      : {
          media: e.content
        };
  });
};

// run only once inside useEffect !!!
export const extractBtmList = (list, dataId) => {
  let bottomList = [];
  for (let i = 0; i < list.length; i++) {
    if (list[i].blankQuizParts.isHidden === true) {
      bottomList.push(
        {
          media: list[i].blankQuizParts.wrongAnswer,
          answerId: `${dataId}-${i}-false`,
          isTrue: false,
          belongTo: 'bottom-box'
        },
        {
          media: list[i].content,
          answerId: `${dataId}-${i}-true`,
          isTrue: true,
          belongTo: 'bottom-box'
        }
      );
    }
  }
  return bottomList;
};

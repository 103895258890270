import { StyledBreadcrumb, StyledTypography } from 'src/containers/Layout/PageLayout/index.style';
import { setConfirmURL, setOpenConfirm } from 'src/redux/lesson/actions';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { getLessonById } from 'src/apis/lesson';
import { setListCheckpointsLength } from 'src/redux/checkpoint/actions';
import LessonOverview from 'src/containers/LessonContainer/LessonOverview';
import { setCurrentSlide, setSlide } from 'src/redux/lesson/actions';
import { setAllowConfirm } from 'src/redux/lesson/actions';
import { setNewNotes } from 'src/redux/note/actions';
import LessonLayout from 'src/containers/Layout/LessonLayout';
import ConfirmExitLesson from 'src/components/ConfirmExitLesson';
import VSSlide from 'src/components/VSSlide';
import PageLayout from 'src/containers/Layout/PageLayout';
import Spinner from 'src/components/Spinner';
import { TOUR_TYPE } from 'src/constants';
import { LessonTourConfig } from 'src/containers/LessonContainer/LessonTour/tour-config';
import { lessonTour, actions, buildContainer } from 'src/containers/LessonContainer/index.logic';

const LearnLesson = () => {
  const dispatch = useDispatch();

  const currentLesson = useSelector((store) => store.lesson.currentLesson);
  const currentCourse = useSelector((store) => store.course.currentCourse);
  const currentSlideIndex = useSelector((state) => state.lesson.currentSlideIndex);

  const [combinedData, setCombinedData] = useState([]);
  const [currentLessonData, setCurrentLessonData] = useState();

  const handleOpenConfirm = () => {
    dispatch(setOpenConfirm(true));
    dispatch(setConfirmURL(`/learn-course/${currentCourse.id}`));
  };

  const breadcrumbs = [
    <StyledBreadcrumb key="2" underline="none" color="inherit" onClick={handleOpenConfirm}>
      <StyledTypography variant="h2">{currentCourse.title}</StyledTypography>
    </StyledBreadcrumb>,
    <Typography key="3" underline="none" color="inherit" variant="h2">
      {currentLesson.title}
    </Typography>
  ];

  useEffect(() => {
    const fetchLessonData = async (lessonId) => {
      const fetchedLesson = (await getLessonById('v1', lessonId)).Lesson;
      setCurrentLessonData(fetchedLesson);

      let checkpointsLength = 0;
      fetchedLesson.learningPoints.forEach(
        (elm) => elm.type === 'checkpoint' ?? checkpointsLength++
      );
      dispatch(setListCheckpointsLength(checkpointsLength));

      const { containers, slides } = buildContainer(fetchedLesson.learningPoints);
      // const overview = await getLessonOverviewById('v1', fetchedLesson.overviewId._id);
      // setCombinedData(containers);
      // dispatcher(setSlide(slides));

      setCombinedData([
        <LessonOverview key={999} overview={fetchedLesson.overview} />,
        ...containers
      ]);
      dispatch(setSlide(['overview', ...slides]));
      dispatch(setCurrentSlide(0));
      dispatch(setAllowConfirm(true));
      if (fetchedLesson?.notes) {
        dispatch(setNewNotes(fetchedLesson?.notes));
      }
    };

    fetchLessonData(currentLesson.id);
  }, []);

  return combinedData && combinedData.length !== 0 ? (
    <LessonLayout
      breadcrumbs={breadcrumbs}
      logoDisplay={'none'}
      completed={
        currentSlideIndex === 0 ? '0%' : `${((currentSlideIndex + 1) / combinedData.length) * 100}%`
      }
      tourConfig={LessonTourConfig}
      tourUI={lessonTour}
      actions={actions}
      tourType={TOUR_TYPE.MULTI}>
      <div style={{ width: '100%', height: '100%' }}>
        <VSSlide data={combinedData} currentLessonData={currentLessonData} />
      </div>
      <ConfirmExitLesson />
    </LessonLayout>
  ) : (
    <PageLayout breadcrumbs={breadcrumbs} logoDisplay={'none'} progressDisplay={'none'}>
      <Spinner />
    </PageLayout>
  );
};

export default LearnLesson;

// mui components
import { Typography, Box } from '@mui/material';

// react main components and other libraries
import { useEffect, useState } from 'react';

// logic function
import {
  collectSyllableLines,
  collectWords,
  collectWordsLen,
  countSelectedTone
} from './index.logic';

// customed style
import { TOWBox } from './index.style';
import PhonemeLine from './PhonemeLine';
import QuestionBox from '../../../../QuizContainer/ToolParts/QuestionBox';

// redux
import { useDispatch } from 'react-redux';
import { setDisableTestNext } from 'src/redux/test/action';

const ToneOfWordTestContainer = ({ data }) => {
  const [wordList, setWordList] = useState([]);
  const [syllables, setSyllables] = useState([]);
  const [isCenter, setIsCenter] = useState(false);

  const [trueNum, setTrueNum] = useState(0);
  const [trueLength, setTrueLength] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    setTrueNum(0);
    const length = collectWordsLen(data.quizParts);
    setTrueLength(length);
    const slbs = collectSyllableLines(data.quizParts);
    setSyllables(slbs);

    const clts = collectWords(data.quizParts, data._id);
    setWordList(clts);
    const len = data.quizParts[0].squareQuizParts.length;
    if (len <= 5) setIsCenter(true);
    else setIsCenter(false);
  }, []);

  useEffect(() => {
    const countSelected = countSelectedTone(wordList);
    if (countSelected >= trueLength) {
      dispatch(setDisableTestNext(false));
    }
  }, [wordList]);

  return (
    <TOWBox isCenter={isCenter} image={data.backgroundUrl} opacity={data.backgroundOpacity}>
      <QuestionBox
        imgUrl={data.questionImageUrl}
        audioUrl={data.questionAudioUrl}
        question={data.question}
      />
      {wordList.map((e, index) => {
        return (
          <Box key={index} sx={{ width: '800px' }}>
            <Typography variant="h2" textAlign="left" fontStyle="italic">
              {syllables[index]}
            </Typography>
          </Box>
        );
      })}
      <Box
        className="scrollable"
        sx={{
          '&::-webkit-scrollbar': {
            maxWidth: '8px',
            maxHeight: '8px'
          },
          '&::-webkit-scrollbar-track': {
            background: 'transparent'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#CDC5BD',
            borderRadius: '10px'
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#555'
          }
        }}>
        <Box className="drop-down-sector">
          {wordList.map((e, index) => {
            return (
              <div key={index}>
                <Box className="top-sector">
                  <PhonemeLine
                    lineData={e}
                    setWordList={setWordList}
                    setTrueNum={setTrueNum}
                    trueNum={trueNum}
                  />
                </Box>
              </div>
            );
          })}
        </Box>
      </Box>
    </TOWBox>
  );
};

export default ToneOfWordTestContainer;

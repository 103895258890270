// react + mui
import { FormControl, MenuItem, Select, Box } from '@mui/material';

// tone image
import RISING from '../../../../../../assets/images/tone/rising.png';
import BROKEN from '../../../../../../assets/images/tone/broken.png';
import CURVE from '../../../../../../assets/images/tone/curve.png';
import FALLING from '../../../../../../assets/images/tone/falling.png';
import DROP from '../../../../../../assets/images/tone/drop.png';
import { TONE } from '../../../../../../constants/quiz';
import { CHOSEN_TEST_QUIZ_COLOR } from 'src/theme/color';

const ToneImg = ({ src }) => {
  return <img height="20px" width="45px" src={src} />;
};

const DropdownBox = ({ wordId, trueTone, selectedTone, setWordList }) => {
  const optionSx = { display: 'flex', justifyContent: 'center' };

  const onToneChange = (event) => {
    if (event.target.value === trueTone) {
      // playTrue();
    }
    setWordList((prev) => {
      return prev.map((e) => {
        return {
          ...e,
          words: e.words.map((ele) => {
            return {
              ...ele,
              isSelected: ele.wordId === wordId ? true : ele.isSelected,
              selectedTone: ele.wordId === wordId ? event.target.value : ele.selectedTone
            };
          })
        };
      });
    });
  };

  return (
    <FormControl sx={{ minWidth: 120, marginBottom: '8px' }}>
      <Select
        value={selectedTone}
        onChange={onToneChange}
        sx={{
          '& .MuiOutlinedInput-notchedOutline': {
            borderWidth: selectedTone === '' ? '1px' : '2.5px',
            borderColor:
              selectedTone === '' ? 'black !important' : `${CHOSEN_TEST_QUIZ_COLOR} !important`
          }
        }}>
        <MenuItem value={TONE.LEVEL} sx={optionSx}>
          <Box sx={{ height: '20px', width: '45px' }}></Box>
        </MenuItem>

        <MenuItem value={TONE.FALLING} sx={optionSx}>
          <ToneImg src={FALLING} />
        </MenuItem>

        <MenuItem value={TONE.RISING} sx={optionSx}>
          <ToneImg src={RISING} />
        </MenuItem>

        <MenuItem value={TONE.CURVE} sx={optionSx}>
          <ToneImg src={CURVE} />
        </MenuItem>

        <MenuItem value={TONE.BROKEN} sx={optionSx}>
          <ToneImg src={BROKEN} />
        </MenuItem>

        <MenuItem value={TONE.DROP} sx={optionSx}>
          <ToneImg src={DROP} />
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default DropdownBox;

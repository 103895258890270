import { memo } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardMedia, CardContent, Typography } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';

// utils
import { formatNumber } from '../../../../utils/number';

// components
import VoiceSpeaker from 'src/components/VoiceSpeaker';

// local
import CollectionItemsCtrl from '../CollectionItems.ctrl';

const CollectionItemCard = ({ itemId, readOnly = false, shouldShowOnlyOwnedItems = false }) => {
  // -- ctrl --
  const {
    // selected item id
    setSelectedItemId,
    // is show modal
    setIsShowModal
  } = CollectionItemsCtrl.useContainer();

  // -- collection item --
  const collectionItem =
    useSelector((state) =>
      shouldShowOnlyOwnedItems
        ? state.userCollectionItems.data[itemId]
        : state.collectionItems.data[itemId]
    ) || {};

  return (
    <Card
      style={{
        ...(collectionItem.isOwned && { filter: 'brightness(50%)' })
      }}>
      <div style={{ position: 'relative' }}>
        {/* image */}
        <CardMedia
          sx={{ background: 'white', cursor: 'pointer' }}
          component="img"
          height="300"
          image={collectionItem.imageUrl}
          onClick={() => {
            setSelectedItemId(itemId);
            setIsShowModal(true);
          }}
        />

        {/* name */}
        <div
          style={{
            position: 'absolute',
            width: '100%',
            textAlign: 'center',
            bottom: 0,
            backgroundColor: 'white',
            color: 'black',
            paddingTop: '5px',
            paddingBottom: '5px'
          }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {collectionItem.nameVoiceUrl && <VoiceSpeaker audioUrl={collectionItem.nameVoiceUrl} />}
            <span style={{ marginLeft: '5px' }}>{collectionItem.name}</span>
            {collectionItem.isOwned && <span>&nbsp;(Đã sở hữu)</span>}
          </div>
        </div>
      </div>

      {/* price */}
      {!readOnly && (
        <CardContent
          sx={{ background: 'white', borderTop: '1px solid black', padding: '10px !important' }}>
          <Typography sx={{ marginBottom: 0 }} color="text.secondary" align="center" gutterBottom>
            <div
              style={{
                display: 'flex',
                alignItems: 'end',
                justifyContent: 'center'
              }}>
              <StarIcon color="warning" />
              <span style={{ marginLeft: '2px' }}>{formatNumber(collectionItem.price)}</span>
            </div>
          </Typography>
        </CardContent>
      )}
    </Card>
  );
};

export default memo(CollectionItemCard);

export const processLearnedCourses = (courses, coursesDone) => {
  if (coursesDone.length === 0) {
    return courses.map((e, index) => {
      if (index === 0) {
        return {
          ...e,
          canLearn: true // co the hoc hay khong
        };
      } else {
        return {
          ...e,
          canLearn: false // co the hoc hay khong
        };
      }
    });
  } else {
    let learnedMaxIndex = 0;
    let flag = false;
    return courses.map((e, index) => {
      // nhung khoa hoc ma co ids thuoc coursesDone se "canLearn": true
      if (coursesDone.includes(e._id)) {
        learnedMaxIndex = index;
        return {
          ...e,
          canLearn: true
        };
      } else {
        // khoa hoc dau tien co ids khong thuoc courseDone se "canLearn": true
        // cac khoa hoc con lai: "canLearn": false
        if (!flag && index === learnedMaxIndex + 1) {
          flag = true; // chi hoat dong 1 lan -> tim duoc la gan true ngay
          return {
            ...e,
            canLearn: true
          };
        } else {
          return {
            ...e,
            canLearn: false
          };
        }
      }
    });
  }
};

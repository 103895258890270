import { styled } from '@mui/material/styles';
import { BUTTON_COLOR } from 'src/theme/color';

export const AudioHasChangeVoiceStyle = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 4px;

  .VoiceType {
    margin-right: 8px;
    .MuiFormControl-root > .MuiInputLabel-root {
      color: #1976d2;
      left: 0px;
      top: -10px;
      font-size: 16px;

      svg {
        font-size: 20px;
      }
    }
    .MuiFormControl-root > .MuiInputLabel-root.Mui-focused {
      left: 0px;
      top: -10px;
    }

    .speaker-box > div {
      padding: 4px 32px 4px 8px;
      font-size: 16px;
      line-height: 28px;
    }
    .speaker-box {
      fieldset {
        border-color: #1976d2;
        border-width: 2px;
      }
    }
  }

  .VoiceAudio {
    width: ${(props) => props.size};
    height: ${(props) => props.size};
    .controls {
      width: ${(props) => props.size};
      height: ${(props) => props.size};
      border: 2px solid ${BUTTON_COLOR};
      border-radius: 50%;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: ${(props) => props.size};
      color: ${BUTTON_COLOR};
      cursor: pointer;
      &:hover {
        background-color: antiquewhite;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      }
    }
  }

  .MuiPopover-paper {
    padding: 4px 12px;
    background: transparent;
  }
`;

export const AudioStyle = styled('div')`
  padding: 4px;
  .VoiceAudio {
    width: ${(props) => props.size};
    height: ${(props) => props.size};
    .controls {
      width: ${(props) => props.size};
      height: ${(props) => props.size};
      border: 2px solid ${BUTTON_COLOR};
      border-radius: 50%;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: ${(props) => props.size};
      color: ${BUTTON_COLOR};
      cursor: pointer;
      &:hover {
        background-color: antiquewhite;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      }
    }
  }
`;

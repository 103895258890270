// mui & react
import { Radio, Box } from '@mui/material';
import Scrollbars from 'react-custom-scrollbars-2';

// redux
import { useDispatch } from 'react-redux';
import { setDisableTestNext } from 'src/redux/test/action';

// customed ui
import { RadioTestAnswersBox, RadioTestOptBox } from './index.style';
import MediaDisplayer from '../../../../QuizContainer/ToolParts/MediaDisplayer';
import { BlinkArrow } from '../../../../QuizContainer/ToolParts/BlinkArrow';
import { useState } from 'react';

const RadioTestOption = ({
  media,
  index,
  width,
  trueAnswer,
  currentChosenIndex,
  setCurrentChosenIndex
}) => {
  const [chosen, setChosen] = useState('unchosen');
  const dispatch = useDispatch();

  const handleChangeOption = (event) => {
    const value = parseInt(event.target.value);
    setCurrentChosenIndex(value);
    dispatch(setDisableTestNext(false));

    setChosen('chosen');
    if (value === trueAnswer) {
      // playTrue();
    } else {
      console.log('wrong');
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center' }}>
      <BlinkArrow color={'transparent'} />
      <RadioTestOptBox chosen={chosen} compare={currentChosenIndex !== index} width={width}>
        <Radio checked={currentChosenIndex === index} value={index} onChange={handleChangeOption} />
        <MediaDisplayer media={media} index={index} />
      </RadioTestOptBox>
    </Box>
  );
};

const RadioTestAnswers = ({ options, trueAnswer, currentChosenIndex, setCurrentChosenIndex }) => {
  return (
    <RadioTestAnswersBox>
      <Scrollbars autoHeight autoHeightMax={265}>
        {options.length % 2 === 0
          ? options.map((e, index) => {
              if (index % 2 === 0) {
                return (
                  <Box className="line" key={index}>
                    {[index, index + 1].map((ele) => {
                      return (
                        <RadioTestOption
                          media={options[ele].content}
                          trueAnswer={trueAnswer}
                          currentChosenIndex={currentChosenIndex}
                          setCurrentChosenIndex={setCurrentChosenIndex}
                          index={ele}
                          key={ele}
                        />
                      );
                    })}
                  </Box>
                );
              }
            })
          : options.map((e, index) => {
              return (
                <Box className="only-one-line" key={index}>
                  <RadioTestOption
                    media={e.content}
                    trueAnswer={trueAnswer}
                    currentChosenIndex={currentChosenIndex}
                    setCurrentChosenIndex={setCurrentChosenIndex}
                    index={index}
                    width={'50%'}
                  />
                </Box>
              );
            })}
      </Scrollbars>
    </RadioTestAnswersBox>
  );
};

export default RadioTestAnswers;

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import NotificationsIcon from '@mui/icons-material/Notifications';
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton } from '@mui/material';

import { IconContainer } from './index.style';
import VietStudy from './../../../assets/images/logo/viet_study_logo.png';
import { AppBar } from './index.style';

import { changeMenu } from '../../../redux/course/actions';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const isOpen = useSelector((state) => state.course.menuOpen);
  const dispatcher = useDispatch();
  const navigate = useNavigate();

  const changeMenuOpen = (currentOpen) => {
    dispatcher(changeMenu(!currentOpen));
  };

  const navigateToHome = () => navigate('/');

  return (
    <AppBar open={isOpen}>
      <img
        style={{ width: '10%', height: '10%', margin: '8px', cursor: 'pointer' }}
        src={VietStudy}
        onClick={navigateToHome}
      />

      <IconContainer>
        <IconButton style={{ color: 'black' }}>
          <NotificationsIcon fontSize="large" />
        </IconButton>
        <IconButton style={{ color: 'black' }} onClick={() => changeMenuOpen(isOpen)}>
          <MenuIcon fontSize="large" />
        </IconButton>
      </IconContainer>
    </AppBar>
  );
};
export default Header;

import api from './api';
import { getHeaders, processExpired } from './auth';

export const getLessonById = async (version = 'v1', id) => {
  return await (
    await api.get(`${version}/lessons/${id}`)
  ).result;
};

export const updateLessonById = async (version = 'v1', id, newLesson) => {
  const accessToken = await processExpired();

  return await api({
    method: 'PUT',
    url: `${version}/lessons/${id}`,
    data: newLesson,
    headers: getHeaders(accessToken)
  });
};

export const getLessonOverviewById = async (version = 'v1', id) => {
  const accessToken = await processExpired();
  return await api.get(`${version}/lesson-overview/${id}`, {
    headers: getHeaders(accessToken)
  });
};

// mui
import { Button, CircularProgress, Typography } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

// react * i18 multi languages
import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

// custom layout
import AuthLayout from 'src/containers/Layout/AuthLayout';
import { VerifyBox } from './index.style';

// navigation & api
import { NavLink, useNavigate } from 'react-router-dom';
import { setCookie, getCookie } from 'src/utils/cookie';
import { verifyEmail, resendVerifyEmail, getMe } from 'src/apis/auth';
import { getStar } from 'src/apis/award-admin/userAward';
import { setStorage } from 'src/utils/storage';

const VerifyEmailContainer = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [annouce, setAnnouce] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [verifyCode, setVerifyCode] = useState('');
  const codeRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const { email, firstName, lastName } = location.state.user;
  const password = location.state.password;

  const { t } = useTranslation();

  useEffect(() => {
    const accessToken = getCookie('accessToken');
    const refreshToken = getCookie('refreshToken');
    if (accessToken !== '' && refreshToken !== '') {
      navigate('/courses');
    }
  }, []);

  const handleVerifyEmail = async () => {
    if (verifyCode === '') {
      setErrorMessage(t('verify_code_empty'));
    } else {
      setIsLoading(true);
      const response = await verifyEmail('v1', email, password, verifyCode.trim());
      if (response.result.accessToken === '') {
        setIsLoading(false);
        setErrorMessage(t(`${response.result.errorMessage}`));
      } else {
        setIsLoading(false);
        setCookie('accessToken', response.result.accessToken);
        setCookie('refreshToken', response.result.refreshToken);
        setStorage({ key: 'userRole', data: ['student'] });
        const user = await getMe('v1');
        await getStar({ userId: user?.result?._id });
        navigate('/courses');
      }
    }
  };

  const sendAnotherEmail = async () => {
    setIsLoading(true);
    const response = await resendVerifyEmail('v1', email, firstName, lastName);
    setIsLoading(false);
    if (response.result) {
      setAnnouce(t('sent_another'));
    } else {
      setErrorMessage(t('sent_another_failed'));
    }
  };

  return (
    <AuthLayout>
      <VerifyBox>
        <div className="login-header">
          <div>
            <Typography variant="h2">{`${t('sent_a_verify_code_to')}: ${email}`}</Typography>
          </div>
          <div>{t('misclassified_and_moved')}</div>
        </div>
        <div className="login-content">
          <div className="login-content__password">
            <div className="login-content__title">
              <Typography variant="h3">{t('verify_code')}</Typography>
            </div>
            <div className="login-content__input">
              <input
                type={isVisible ? 'text' : 'password'}
                ref={codeRef}
                onChange={(e) => setVerifyCode(e.target.value)}
              />
              <span className="visible-icon" onClick={() => setIsVisible(!isVisible)}>
                {isVisible ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
              </span>
            </div>
          </div>
          <div className="error-message">{errorMessage}</div>
          <Button variant="contained" type="submit" onClick={() => handleVerifyEmail()}>
            {!isLoading ? t('verify') : <CircularProgress sx={{ color: 'white' }} size="1.25rem" />}
          </Button>
          <div className="sent-another">{annouce}</div>
        </div>
        <div className="login-footer">
          {t('havent_received_email')}
          <NavLink onClick={sendAnotherEmail}>{t('send_me_another')}</NavLink>
        </div>
      </VerifyBox>
    </AuthLayout>
  );
};

export default VerifyEmailContainer;

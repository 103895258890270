export const chapterActionTypes = {
  SET_ALL_CHAPTERS: 'SET_ALL_CHAPTERS',
  SET_CURRENT_CHAPTER: 'SET_CURRENT_CHAPTER',

  CHANGE_CHAPTER_CONTENT: 'CHANGE_CHAPTER_CONTENT'
};

export const setAllChapters = (data) => {
  return {
    type: chapterActionTypes.SET_ALL_CHAPTERS,
    payload: data
  };
};

export const setCurrentChapter = (data) => {
  return {
    type: chapterActionTypes.SET_CURRENT_CHAPTER,
    payload: data
  };
};

export const changeChapterContent = (data) => {
  return {
    type: chapterActionTypes.CHANGE_CHAPTER_CONTENT,
    payload: data
  };
};

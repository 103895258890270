// npm lib & react
import { Box, Typography, Fab } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ReplayIcon from '@mui/icons-material/Replay';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';

// styled components
import BottomBox from './DropBox/BottomBox';
import MediaBox from './MediaBox';
import { PORBox } from './index.style';
import PhonemeLine from './PhonemeLine';
import QuestionBox from '../../ToolParts/QuestionBox';
import ResponsiveDialog from '../../confirm-dialog';
import { BlinkXArrow } from '../../ToolParts/BlinkArrow';

// logic functions
import {
  findBtmList,
  findTopList,
  findSyllableLines,
  showAnswers,
  countCorrect
} from './index.logic';
import { shuffleArray } from '../../../../utils/shuffle';

// sounding effect & redux
import useSound from 'use-sound';
import useHint from '../../../../assets/audio/use_hint.mp3';
import { useDispatch } from 'react-redux';
import { setDisableNext } from 'src/redux/lesson/actions';

const PhonemeOfRhymeContainer = ({ data }) => {
  const isMobile = window.innerWidth < 600;
  const [topList, setTopList] = useState([]);
  const [btmList, setBtmList] = useState([]);
  const [syllables, setSyllables] = useState([]);
  const [replayNum, setReplayNum] = useState(4);
  const [hintNum, setHintNum] = useState(2);
  const [open, setOpen] = useState(false);
  const [isCenter, setIsCenter] = useState(false);
  const [trueAnswers, setTrueAnswers] = useState(0);
  const [showHintArrow, setShowHintArrow] = useState(false);
  const [arrow, setArrow] = useState({});
  const dispatch = useDispatch();
  const [playHintSound] = useSound(useHint);

  const showFullAnswers = () => {
    dispatch(setDisableNext(false));
    showAnswers(setTopList, setBtmList);
    setOpen(false);
    playHintSound();
  };

  const reset = () => {
    if (replayNum !== 0) {
      setReplayNum(replayNum - 1);
      setHintNum(2);
      const tList = findTopList(data.quizParts, data._id);
      setTopList(tList);

      const bList = findBtmList(data.quizParts, data._id);
      setTrueAnswers(bList.length);
      setBtmList(shuffleArray(bList));

      const slbs = findSyllableLines(data.quizParts);
      setSyllables(slbs);
    }
    // playInstruction sound with TTS
  };

  const showHint = () => {
    const itemsInBtmBox = btmList.filter((e) => e.belongTo == 'bottom-box');
    if (itemsInBtmBox.length !== 0 && hintNum !== 0) {
      setHintNum(hintNum - 1);
      const selectedHint = itemsInBtmBox[Math.floor(Math.random() * itemsInBtmBox.length)];
      const id = selectedHint.answerId;
      setArrow({ start: `${id}-btm`, end: `${id}-top` });
      setShowHintArrow(true);
      playHintSound();
      setTimeout(() => {
        setArrow({});
        setShowHintArrow(false);
      }, 2000);
    }
    if (itemsInBtmBox.length == 0) {
      setHintNum(hintNum - 1);
      playHintSound();
    }
    if (hintNum == 0) {
      setHintNum(0);
      setOpen(true);
      playHintSound();
    }
  };

  const showInstruction = () => {};

  useEffect(() => {
    reset();
    const len = data.quizParts[0].squareQuizParts.length;
    if (len <= 4) setIsCenter(true);
    else setIsCenter(false);
  }, []);

  useEffect(() => {
    const correctNum = countCorrect(topList);
    if (correctNum >= trueAnswers) {
      dispatch(setDisableNext(false));
    }
  }, [topList]);

  const returnItemsForBox = (boxId) => {
    return btmList
      .filter((item) => item.belongTo === boxId)
      .map((e, index) => (
        <MediaBox
          key={index}
          answerId={e.answerId}
          answerValue={e.answerValue}
          media={e.media}
          belongTo={e.belongTo}
          setItems={setBtmList}
          setChosen={setTopList} // aware
        />
      ));
  };

  return (
    <PORBox isCenter={isCenter} image={data.backgroundUrl} opacity={data.backgroundOpacity}>
      <QuestionBox
        imgUrl={data.questionImageUrl}
        audioUrl={data.questionAudioUrl}
        question={data.question}
      />

      <Box className="dnd-sector">
        <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
          <Box
            className="scrollable"
            sx={{
              '&::-webkit-scrollbar': {
                maxWidth: '8px',
                maxHeight: '8px'
              },
              '&::-webkit-scrollbar-track': {
                background: 'transparent'
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#CDC5BD',
                borderRadius: '10px'
              },
              '&::-webkit-scrollbar-thumb:hover': {
                background: '#555'
              }
            }}>
            {topList.map((e, index) => {
              return (
                <div key={index}>
                  <Box className="syllables" key={index}>
                    <Typography variant="h2" textAlign="left" fontStyle="italic">
                      {syllables[index]}
                    </Typography>
                  </Box>
                  <Box className="top-sector">
                    <PhonemeLine returnItemsForBox={returnItemsForBox} lineData={e} />
                  </Box>
                </div>
              );
            })}
          </Box>

          <Box className="btm-dnd">
            <BottomBox boxId="bottom-box">{returnItemsForBox('bottom-box')}</BottomBox>
          </Box>

          {showHintArrow ? (
            <BlinkXArrow color="#87CEFA" strokeWidth={4} start={arrow.start} end={arrow.end} />
          ) : (
            <></>
          )}
        </DndProvider>
      </Box>

      <Fab
        color="warning"
        size="small"
        sx={{ position: 'absolute', right: '10px', bottom: '110px' }}
        onClick={showHint}>
        {hintNum}
        <TipsAndUpdatesIcon />
      </Fab>
      <Fab
        color="warning"
        size="small"
        sx={{ position: 'absolute', right: '10px', bottom: '60px' }}
        onClick={showInstruction}>
        <HelpOutlineIcon />
      </Fab>
      <Fab
        color="warning"
        size="small"
        sx={{ position: 'absolute', right: '10px', bottom: '10px' }}
        onClick={reset}>
        {replayNum}
        <ReplayIcon />
      </Fab>
      <ResponsiveDialog open={open} setOpen={setOpen} showFullAnswers={showFullAnswers} />
    </PORBox>
  );
};
export default PhonemeOfRhymeContainer;

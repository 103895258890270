import { useState, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createContainer } from 'unstated-next';

// reducers
import { getAllCollectionItems } from '../../../redux/award-admin/collectionItem/collectionItem';
import { getAllUserCollectionItems } from '../../../redux/award-admin/collectionItem/userCollectionItem';

const useCollectionItemsCtrl = ({ shouldShowOnlyOwnedItems = false }) => {
  // -- ready to render --
  const [readyToRender, setReadyToRender] = useState(false);

  // -- collection item --
  const { isLoading, ids: collectionItemIds = [] } = useSelector((state) =>
    shouldShowOnlyOwnedItems ? state.userCollectionItems : state.collectionItems
  );

  // -- user id --
  const userId = useSelector((state) => state.auth.user.result?._id);

  // -- dispatch --
  const dispatch = useDispatch();

  // -- selected item id --
  const [selectedItemId, setSelectedItemId] = useState();

  // -- is show modal --
  const [isShowModal, setIsShowModal] = useState(false);

  useLayoutEffect(() => {
    if (userId && !readyToRender) {
      if (shouldShowOnlyOwnedItems) {
        dispatch(getAllUserCollectionItems({ page: 1, limit: 100, userId }));
      } else {
        dispatch(getAllCollectionItems({ page: 1, limit: 100, userId, excludeOwnedItem: false }));
      }
      setReadyToRender(true);
    }
  }, [userId]);

  return {
    // ready to render
    readyToRender,
    // collection items
    isLoading,
    collectionItemIds,
    // selected item id
    selectedItemId,
    setSelectedItemId,
    // is show modal
    isShowModal,
    setIsShowModal
  };
};

export default createContainer(useCollectionItemsCtrl);

// MUI
import { Tooltip } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';

// CSS style
import { CourseImageBox } from './index.style';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentCourse } from '../../../redux/course/actions';

import itemSound from 'src/assets/audio/item.wav';
import useSound from 'use-sound';

const SmallCourseBox = (props) => {
  const dispatcher = useDispatch();
  const courses = useSelector((state) => state.course.courses);
  const [playChooseCourse] = useSound(itemSound);

  const chooseAnotherCourse = () => {
    playChooseCourse();
    const newCourse = courses.filter((ele) => ele.id == props.courseId)[0]; //a list !!!!!
    dispatcher(
      setCurrentCourse({
        id: props.courseId,
        title: newCourse.title,
        description: newCourse.description,
        imageUrl: newCourse.imageUrl,
        chapterOverviews: newCourse.chapterOverviews,
        canLearn: newCourse.canLearn
      })
    );
  };

  return (
    <Tooltip title={<h3>{props.title}</h3>}>
      <CourseImageBox
        isCompleted={false}
        canLearn={props.canLearn}
        onClick={chooseAnotherCourse}
        sx={{
          width: { lg: '110px', xl: '130px' },
          height: { lg: '110px', xl: '130px' }
        }}>
        <img src={props.imgSrc} />
        {!props.canLearn ? (
          <div className="lock-elm">
            <LockIcon />
          </div>
        ) : (
          <></>
        )}
      </CourseImageBox>
    </Tooltip>
  );
};
export default SmallCourseBox;

import { styled } from '@mui/material/styles';
import AuthBG from 'src/assets/images/auth_bg.png';

export const AuthLayoutStyle = styled('div')`
  height: 100vh;
  background-color: white;
  background-image: url(${AuthBG});
  background-repeat: no-repeat;
  background-size: 90% 70%;
  background-position: center bottom;

  .logo {
    width: 100%;
    height: 12%;
    display: flex;
    justify-content: center;
    align-items: end;
    margin-bottom: 1%;

    img {
      height: 65%;
    }
  }

  .content {
    width: 100%;
    height: 83%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5%;
    padding: 0 6%;
    display: flex;
    justify-content: space-between;

    .privacy {
      padding-right: 20px;
    }
  }
`;

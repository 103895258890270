import { useDrag } from 'react-dnd';
import { DisplayMediaBox } from './index.style';
import MediaDisplayer from '../../../../QuizContainer/ToolParts/MediaDisplayer';
import { moveItemToAnotherBox, compare, changeChosen } from './index.logic';

const MediaBox = ({ answerId, media, belongTo, setItems, setChosen }) => {
  const [, drag] = useDrag({
    item: { answerId, belongTo },
    type: 'dnd-box',
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();

      if (dropResult && dropResult.boxId != item.belongTo) {
        // move to different container box
        moveItemToAnotherBox(item, dropResult.boxId, setItems);

        // check if answer is right or wrong
        const comparedResult = compare(dropResult.boxId, item.answerId);

        // test quiz -> chosen ! (incorrect & correct is not mentioned)
        changeChosen(item.belongTo, dropResult.boxId, 'chosen', setChosen);
        if (comparedResult) {
          console.log('true');
        } else {
          console.log('wrong');
        }
      }
    }
  });

  return (
    <DisplayMediaBox ref={drag} isBorder={belongTo === 'bottom-box'} id={`${answerId}-btm`}>
      <MediaDisplayer media={media} index={answerId} />
    </DisplayMediaBox>
  );
};

export default MediaBox;

import { styled } from '@mui/material/styles';
import { FLOATING_BOX_COLOR } from 'src/theme/color';

export const LanguageBox = styled('button')`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: ${FLOATING_BOX_COLOR};
  border: solid ${(props) => (props.isChosen ? '3.5px #0D4DC9' : '2px #9b9b9b')};
  width: 125px;
  height: 130px;

  img {
    border-radius: 10px;
    width: 115px;
    height: 95px;
    object-fit: cover;
  }
`;

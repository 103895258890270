import { TONE } from '../../../../constants/quiz';
import RISING from '../../../../assets/images/tone/rising.png';
import BROKEN from '../../../../assets/images/tone/broken.png';
import CURVE from '../../../../assets/images/tone/curve.png';
import FALLING from '../../../../assets/images/tone/falling.png';
import DROP from '../../../../assets/images/tone/drop.png';

export const getTone = (tone, quizId, i, j) => {
  let container = {
    belongTo: 'bottom-box',
    answerId: `${quizId}-${i}-${j}-`
  };
  switch (tone) {
    case TONE.RISING:
      container.answerId = container.answerId + TONE.RISING;
      container.answerValue = TONE.RISING;
      container.media = { imageUrl: RISING };
      break;
    case TONE.BROKEN:
      container.answerId = container.answerId + TONE.BROKEN;
      container.answerValue = TONE.BROKEN;
      container.media = { imageUrl: BROKEN };
      break;
    case TONE.FALLING:
      container.answerId = container.answerId + TONE.FALLING;
      container.answerValue = TONE.FALLING;
      container.media = { imageUrl: FALLING };
      break;
    case TONE.CURVE:
      container.answerId = container.answerId + TONE.CURVE;
      container.answerValue = TONE.CURVE;
      container.media = { imageUrl: CURVE };
      break;
    case TONE.DROP:
      container.answerId = container.answerId + TONE.DROP;
      container.answerValue = TONE.DROP;
      container.media = { imageUrl: DROP };
      break;
    default:
      container.media = { text: '' };
      break;
  }
  return container;
};

import { SideBarStyle } from './index.style';
import { Scrollbars } from 'react-custom-scrollbars-2';
import ChapterBox from '../ChapterBox';

const SideBar = ({ listChapters }) => {
  return (
    <SideBarStyle>
      <Scrollbars style={{ width: '100%', heigh: '100%' }}>
        {listChapters &&
          listChapters.map((chapter, index) => (
            <ChapterBox
              key={chapter.id}
              index={index}
              id={chapter.id}
              number={index + 1}
              title={chapter.title}
              canLearn={chapter.canLearn}
            />
          ))}
      </Scrollbars>
    </SideBarStyle>
  );
};

export default SideBar;

import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import APP_ROUTES from './appRoutes';
import Protected from './Protected';

const KLRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        {APP_ROUTES.map((route) => {
          if (!route.isPrivate && !route.restricted) {
            return <Route key={route.path} path={route.path} element={route.routeElement} />;
          } else {
            return (
              <Route
                key={route.path}
                path={route.path}
                element={<Protected requiredRole={route.roles}>{route.routeElement}</Protected>}
              />
            );
          }
        })}
      </Routes>
    </BrowserRouter>
  );
};

export default KLRoutes;

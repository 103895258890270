const convertArrObjToStr = (arr) => {
  return arr.map((e) => JSON.stringify(e));
};

const convertArrStrToObj = (arr) => {
  return arr.map((e) => JSON.parse(e));
};

export const filterArrBySet = (arr) => {
  const strArr = convertArrObjToStr(arr);
  const strSet = new Set(strArr);
  return convertArrStrToObj([...strSet]);
};

export const compare2List = (arr1, arr2) => {
  let identical = true;
  if (arr1.length !== arr2.length) return false;
  for (let i = 0; i < arr2.length; i++) {
    if (!arr1.includes(arr2[i])) {
      identical = false;
    }
  }
  return identical;
};

// const newArr = []
// const result = compare2List([0, 1], [...newArr, 1]);
// console.log(result);

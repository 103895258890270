import React from 'react';
import { Grid, Typography } from '@mui/material';
import TheoryContent from '../../TheoryContent';
import { TheoryContentStyle } from '../../index.style';
import AudioButton from 'src/components/AudioButton';
import Scrollbars from 'react-custom-scrollbars-2';

const LayoutForFour = ({ data }) => {
  return (
    <TheoryContentStyle>
      <div className="title">
        <AudioButton
          size={'24px'}
          audioUrl={data.titleAudioUrl}
          audioText={data.title}
          audioRate={data.rateVoice}
          audioSpeaker={data.titleVoice}
        />
        <Typography variant="h0">{data.title}</Typography>
      </div>
      <div className="title-line"></div>
      <Scrollbars className="scroll-content">
        <Grid container height="96%" margin="0">
          <Grid item xs={12} md={6} style={{ minHeight: '45%' }}>
            <TheoryContent
              mediaObject={data.customLayout?.children[0]?.children[0]?.mediaObject}
              text={data.customLayout?.children[0]?.children[0]?.mediaObject?.text}
              squareObject={data.customLayout?.children[0]?.children[0]?.mediaObject?.squareObject}
            />
          </Grid>
          <Grid item xs={12} md={6} style={{ minHeight: '45%' }}>
            <TheoryContent
              mediaObject={data.customLayout?.children[0]?.children[1]?.mediaObject}
              text={data.customLayout?.children[0]?.children[1]?.mediaObject?.text}
              squareObject={data.customLayout?.children[0]?.children[1]?.mediaObject?.squareObject}
            />
          </Grid>
          <Grid item xs={12} md={6} style={{ minHeight: '45%' }}>
            <TheoryContent
              mediaObject={data.customLayout?.children[1]?.children[0]?.mediaObject}
              text={data.customLayout?.children[1]?.children[0]?.mediaObject?.text}
              squareObject={data.customLayout?.children[1]?.children[0]?.mediaObject?.squareObject}
            />
          </Grid>
          <Grid item xs={12} md={6} style={{ minHeight: '45%' }}>
            <TheoryContent
              mediaObject={data.customLayout?.children[1]?.children[1]?.mediaObject}
              text={data.customLayout?.children[1]?.children[1]?.mediaObject?.text}
              squareObject={data.customLayout?.children[1]?.children[1]?.mediaObject?.squareObject}
            />
          </Grid>
        </Grid>
      </Scrollbars>
    </TheoryContentStyle>
  );
};

export default LayoutForFour;

import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import _map from 'lodash/map';
import _keyBy from 'lodash/keyBy';

// apis
import { getAll, purchase } from '../../../apis/award-admin/luckyBox';

// initial state
const initialState = {
  metadata: {},
  ids: [],
  data: {},
  isLoading: false
};

// slice
const luckyBox = createSlice({
  name: 'luckyBox',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setMetadata(state, action) {
      state.metadata = action.payload;
    },
    setLuckyBoxIds(state, action) {
      state.ids = action.payload;
    },
    setLuckyBoxes(state, action) {
      state.data = action.payload;
    }
  }
});

// actions
export const { setLoading, setMetadata, setLuckyBoxIds, setLuckyBoxes } = luckyBox.actions;

// get all lucky boxes
export const getAllLuckyBoxes =
  ({ page, limit, keyword, categoryId, rarity } = {}) =>
  async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const res = await getAll({
        page,
        limit,
        keyword,
        categoryId,
        rarity
      });

      if (!res.success) toast.error(`Lỗi: ${res.message}`);
      else {
        dispatch(setMetadata(res.results?.metadata));
        dispatch(setLuckyBoxIds(_map(res.results?.luckyBoxes, '_id')));
        dispatch(setLuckyBoxes(_keyBy(res.results?.luckyBoxes, '_id')));
      }
    } catch (error) {
      console.error(error);
      toast.error(`Lỗi: ${error.message}`);
      dispatch(setMetadata(initialState.metadata));
      dispatch(setLuckyBoxIds(initialState.ids));
      dispatch(setLuckyBoxes(initialState.data));
    }

    dispatch(setLoading(false));
  };

// purchase lucky boxes
export const purchaseLuckyBoxes =
  ({ userId, luckyBoxIds } = {}) =>
  async (dispatch, getState) => {
    const { user = {} } = getState().auth;

    let result = {
      success: false
    };

    dispatch(setLoading(true));

    try {
      const res = await purchase({
        userId,
        userFullName: [user.result?.firstName || '', user.result?.lastName || ''].join(' '),
        userEmail: user.result?.email,
        luckyBoxIds
      });
      console.log(res);
      if (!res.success) {
        toast.error(`Lỗi: ${res.message}`);
      } else {
        result.success = true;
        result.openedLuckyBoxes = res.results.luckyBoxes;
        toast.success('Mua thành công');
      }
    } catch (error) {
      console.error(error);
      toast.error(`Lỗi: ${error.message}`);
    }

    dispatch(setLoading(false));

    return result;
  };

export default luckyBox.reducer;

// npm lib & react
import { Box, Fab } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ReplayIcon from '@mui/icons-material/Replay';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';

// styled components
import BottomBox from './DropBox/BottomBox';
import MediaBox from './MediaBox';
import { BlankTopBox, TextTopBox } from './DropBox/TopBox';
import { BQBox } from './index.style';
import { BlinkXArrow } from '../ToolParts/BlinkArrow';

// logic functions
import { extractTopList, extractBtmList } from './index.logic';
import { shuffleArray } from '../../../utils/shuffle';

import QuestionBox from '../ToolParts/QuestionBox';
import useSound from 'use-sound';
import useHint from '../../../assets/audio/use_hint.mp3';
import ResponsiveDialog from '../confirm-dialog';

// redux
import { useDispatch } from 'react-redux';
import { setDisableNext } from 'src/redux/lesson/actions';

const BlankQuizContainer = ({ data }) => {
  const isMobile = window.innerWidth < 600;
  const [topList, setTopList] = useState([]);
  const [btmList, setBtmList] = useState([]);
  const [replayNum, setReplayNum] = useState(4);
  const [hintNum, setHintNum] = useState(2);
  const [open, setOpen] = useState(false);
  const [showHintArrow, setShowHintArrow] = useState(false);
  const [arrow, setArrow] = useState({});
  const [playHintSound] = useSound(useHint);
  const dispatch = useDispatch();

  useEffect(() => {
    reset();
  }, []);

  useEffect(() => {
    const blankBox = topList.filter((e) => e.chosen !== undefined);
    const trueBlankBox = topList.filter((e) => e.chosen === 'correct');

    if (trueBlankBox.length >= blankBox.length) {
      dispatch(setDisableNext(false));
    }
  }, [topList]);

  const returnItemsForBox = (boxId) => {
    return btmList
      .filter((item) => item.belongTo === boxId)
      .map((e, index) => (
        <MediaBox
          key={index}
          answerId={e.answerId}
          media={e.media}
          belongTo={e.belongTo}
          setItems={setBtmList}
          setChosen={setTopList}
          topList={topList}
        />
      ));
  };

  const showHint = () => {
    if (hintNum !== 0) {
      setHintNum(hintNum - 1);
      const btmBoxAnswers = btmList.filter((e) => e.belongTo === 'bottom-box' && e.isTrue === true);
      if (btmBoxAnswers.length !== 0) {
        const randHint = btmBoxAnswers[Math.floor(Math.random() * btmBoxAnswers.length)];
        const arrowId = randHint.answerId;
        setArrow({ start: `${arrowId}-btm`, end: `${arrowId}-top` });
        setShowHintArrow(true);
        setTimeout(() => {
          setArrow({});
          setShowHintArrow(false);
        }, 1750);
      }
    } else {
      // say some warning sound
      setOpen(true);
    }
    playHintSound();
  };

  const showFullAnswers = () => {
    dispatch(setDisableNext(false));
    setBtmList((prev) => {
      return prev.map((e) => {
        return {
          ...e,
          belongTo: e.isTrue === true ? e.answerId : e.belongTo
        };
      });
    });
    setTopList((prev) => {
      return prev.map((e) => {
        if (e.media !== 'blank') return e;
        else
          return {
            ...e,
            chosen: 'correct'
          };
      });
    });
    setOpen(false);
  };

  const showInstruction = () => {};

  const reset = () => {
    if (replayNum != 0) {
      setReplayNum(replayNum - 1);
      setHintNum(2);
      const tList = extractTopList(data.quizParts, data._id);
      setTopList(tList);

      const bList = extractBtmList(data.quizParts, data._id);
      const shuffledBList = shuffleArray(bList);
      setBtmList(shuffledBList);
    } else {
      // playing warning sound
    }
  };

  return (
    <BQBox image={data.backgroundUrl} opacity={data.backgroundOpacity}>
      <QuestionBox
        imgUrl={data.questionImageUrl}
        audioUrl={data.questionAudioUrl}
        question={data.question}
      />

      <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
        <Box className="dnd-sector">
          <Box
            className="scrollable"
            sx={{
              '&::-webkit-scrollbar': {
                maxWidth: '8px',
                maxHeight: '8px'
              },
              '&::-webkit-scrollbar-track': {
                background: 'transparent'
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#CDC5BD',
                borderRadius: '10px'
              },
              '&::-webkit-scrollbar-thumb:hover': {
                background: '#555'
              }
            }}>
            <Box className="top-sector">
              {topList.map((ele, index) => {
                if (ele.media !== 'blank') return <TextTopBox key={index} media={ele.media} />;
                else
                  return (
                    <BlankTopBox key={index} boxId={ele.boxId} chosen={ele.chosen}>
                      {returnItemsForBox(ele.boxId)}
                    </BlankTopBox>
                  );
              })}
            </Box>
          </Box>

          <BottomBox boxId="bottom-box">{returnItemsForBox('bottom-box')}</BottomBox>
        </Box>

        {showHintArrow ? (
          <BlinkXArrow color="#87CEFA" strokeWidth={4} start={arrow.start} end={arrow.end} />
        ) : (
          <></>
        )}
      </DndProvider>
      <Fab
        color="warning"
        size="small"
        sx={{ position: 'absolute', right: '10px', bottom: '110px' }}
        onClick={showHint}>
        {hintNum}
        <TipsAndUpdatesIcon />
      </Fab>
      <Fab
        color="warning"
        size="small"
        sx={{ position: 'absolute', right: '10px', bottom: '60px' }}
        onClick={showInstruction}>
        <HelpOutlineIcon />
      </Fab>
      <Fab
        color="warning"
        size="small"
        sx={{ position: 'absolute', right: '10px', bottom: '10px' }}
        onClick={reset}>
        {replayNum}
        <ReplayIcon />
      </Fab>
      <ResponsiveDialog open={open} showFullAnswers={showFullAnswers} />
    </BQBox>
  );
};

export default BlankQuizContainer;

import React from 'react';
import { Grid, Typography } from '@mui/material';
import AudioButton from 'src/components/AudioButton';
import { TheoryContentStyle } from '../../index.style';
import TheoryContent from '../../TheoryContent';
import Scrollbars from 'react-custom-scrollbars-2';

const LayoutForOne = ({ data }) => {
  return (
    <TheoryContentStyle>
      <div className="title">
        <AudioButton
          size={'24px'}
          audioUrl={data.titleAudioUrl}
          audioText={data.title}
          audioRate={data.rateVoice}
          audioSpeaker={data.titleVoice}
          onClick={() => console.log('audio start')}
        />
        <Typography variant="h0">{data.title}</Typography>
      </div>
      <div className="title-line"></div>
      <Scrollbars className="scroll-content">
        <Grid container height="96%" margin="0">
          <Grid item xs={12} md={12} style={{ minHeight: '100%' }}>
            <TheoryContent
              mediaObject={data.customLayout?.mediaObject}
              text={data.customLayout?.mediaObject?.text}
              squareObject={data.customLayout?.mediaObject?.squareObject}
            />
          </Grid>
        </Grid>
      </Scrollbars>
    </TheoryContentStyle>
  );
};

export default LayoutForOne;

import BeforeLearningLayout from 'src/containers/Layout/BeforeLearningLayout';
import { squareQuizList } from '../../containers/QuizContainer/SquareQuizContainer/mock.data';
import SquareQuizContainer from 'src/containers/QuizContainer/SquareQuizContainer';
import { Container, Box } from '@mui/material';

const SquareQuizPage = () => {
  return (
    <BeforeLearningLayout>
      <Container maxWidth="md">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: '15px'
          }}>
          <SquareQuizContainer data={squareQuizList[7]} />
        </Box>
      </Container>
    </BeforeLearningLayout>
  );
};

export default SquareQuizPage;

// 6: Thanh dieu cua tieng
// 7: Tieng trong cau
// 5: Thanh phan trong tieng (van + dau thanh)
// 4: Thanh phan cua tieng (tach van)
// 0 + 1: Cau tao cua van (phoneme of rhyme)

// -------
// 0: simple, no scroll
// 2: long, with scroll

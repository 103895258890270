import React from 'react';
import PageLayout from '../PageLayout';
import { StyledLessonContent } from './index.style';

const LessonLayout = (props) => {
  const {
    children,
    breadcrumbs,
    completed,
    logoDisplay,
    progressDisplay,
    tourConfig,
    tourUI,
    actions,
    tourType
  } = props;

  return (
    <PageLayout
      breadcrumbs={breadcrumbs}
      logoDisplay={logoDisplay}
      completed={completed}
      progressDisplay={progressDisplay} //only use when hide progrless
      tourConfig={tourConfig}
      tourUI={tourUI}
      actions={actions}
      tourType={tourType}>
      <StyledLessonContent>{children}</StyledLessonContent>
    </PageLayout>
  );
};

export default LessonLayout;

// react & mui
import { useState, useCallback, memo } from 'react';
import { Container, Box, Typography, Tabs, Tab } from '@mui/material';
import { useTranslation } from 'react-i18next';

// custom UI
import BeforeLearningLayout from '../Layout/BeforeLearningLayout';

// store
import CollectionItems from '../VietStudyStoreContainer/CollectionItems/CollectionItems';

// local
import UserCollections from './UserCollections/UserCollections';

const tabs = [
  // {
  //   label: 'THÀNH TỰU',
  //   i18nText: 'award_achievements',
  //   component: 'THÀNH TỰU'
  // },
  {
    label: 'VẬT PHẨM',
    i18nText: 'award_collection_items',
    component: <CollectionItems readOnly={true} shouldShowOnlyOwnedItems={true} />
  },
  {
    label: 'BỘ SƯU TẬP',
    i18nText: 'award_collections',
    component: <UserCollections />
  }
];

const TabPanel = ({ children, value, index, ...otherProps }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`vertical-tabpanel-${index}`}
    aria-labelledby={`vertical-tab-${index}`}
    {...otherProps}>
    {value === index && (
      <Box sx={{ p: 3 }}>
        <Typography>{children}</Typography>
      </Box>
    )}
  </div>
);

const a11yProps = (index) => ({
  id: `vertical-tab-${index}`,
  'aria-controls': `vertical-tabpanel-${index}`
});

const AwardContainer = () => {
  // -- i18n --
  const { t } = useTranslation();

  // -- selected tab idx --
  const [selectedTabIdx, setSelectedTabIdx] = useState(0);

  // -- handle change tab --
  const handleChangeTab = useCallback((_, newSelectedTabIndex) => {
    setSelectedTabIdx(newSelectedTabIndex);
  }, []);

  return (
    <BeforeLearningLayout>
      <Container maxWidth={false} sx={{ maxWidth: '86%', marginTop: { xl: '50px' } }}>
        <Typography sx={{ fontSize: '1.75rem !important', textAlign: 'center' }} component="h1">
          {t('award')}
        </Typography>

        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={selectedTabIdx}
            onChange={handleChangeTab}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile>
            {tabs.map((tab, idx) => (
              <Tab
                key={idx}
                label={<b style={{ textTransform: 'uppercase' }}>{t(tab.i18nText)}</b>}
                {...a11yProps(idx)}
              />
            ))}
          </Tabs>
        </Box>
        {tabs.map((tab, idx) => (
          <TabPanel
            key={idx}
            value={selectedTabIdx}
            index={idx}
            style={{ height: '80vh', overflowY: 'auto' }}>
            {tab.component}
          </TabPanel>
        ))}
      </Container>
    </BeforeLearningLayout>
  );
};

export default memo(AwardContainer);

import React from 'react';
import ReactPlayer from 'react-player';
import { TheoryContentStyle } from './index.style';
import AudioButton from '../../../components/AudioButton';
import { Typography } from '@mui/material';
import SquareType from './SquareType';
import { THEORY_CONTENT_TYPE } from 'src/constants/theory';
import TextType from './TextType';
import Teacher from 'src/assets/images/teacher.png';

const TheoryContent = ({ slideIndex, mediaObject, text, squareObject, playAudio }) => {
  const renderTheory = (type) => {
    switch (type) {
      case THEORY_CONTENT_TYPE.TEXT:
        return (
          <TextType
            slideIndex={slideIndex}
            text={text}
            mediaObject={mediaObject}
            playAudio={playAudio}
          />
        );
      case THEORY_CONTENT_TYPE.SQUARE:
        return (
          <>
            <div className="Text">
              {text ? (
                <AudioButton
                  size={'24px'}
                  audioUrl={mediaObject.audioUrl}
                  audioText={text}
                  audioRate={mediaObject.rateVoice}
                  audioSpeaker={mediaObject.voice}
                  needChangeVoice={true}
                />
              ) : (
                ''
              )}
              <Typography variant="h1">{text}</Typography>
            </div>
            <div className="ListSquare">
              {squareObject.squares
                ? squareObject.squares.map((square, index) => (
                    <div className="SquareContent" key={index}>
                      <SquareType square={square} type={squareObject.type} />
                    </div>
                  ))
                : ''}
            </div>
          </>
        );
      case THEORY_CONTENT_TYPE.VIDEO:
        return (
          <div className="videoType">
            <ReactPlayer url={mediaObject.videoUrl} width="80%" height="90%" controls />
            <img className="teacherImg" src={Teacher} alt="" />
          </div>
        );
      case THEORY_CONTENT_TYPE.IMAGE:
        return (
          <img
            className="imgType"
            src={mediaObject.imageUrl}
            alt="image"
            style={{ width: '80%', minHeight: '65%', maxHeight: '90%' }}
          />
        );
    }
  };

  return <TheoryContentStyle>{renderTheory(mediaObject.type)}</TheoryContentStyle>;
};

export default React.memo(TheoryContent);

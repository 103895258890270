import React, { useEffect, useState, useLayoutEffect } from 'react';
import { CircularProgress, Typography } from '@mui/material';

import AudioButton from 'src/components/AudioButton';
import { READING_MODE } from 'src/constants/theory';
import WordDetail from '../WordDetail';
import { useSelector } from 'react-redux';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';

function findPreviousTime(arr, target) {
  let start = 0;
  let end = arr.length - 1;
  let result = -1;

  while (start <= end) {
    let mid = Math.floor((start + end) / 2);

    if (arr[mid] < target) {
      result = arr[mid];
      start = mid + 1;
    } else {
      end = mid - 1;
    }
  }

  return result;
}

function findNextTime(arr, target) {
  let start = 0;
  let end = arr.length - 1;
  let result = -1;

  while (start <= end) {
    let mid = Math.floor((start + end) / 2);

    if (arr[mid] > target) {
      result = arr[mid];
      end = mid - 1;
    } else {
      start = mid + 1;
    }
  }

  return result;
}

const TextType = ({ slideIndex, text, mediaObject, playAudio }) => {
  const currentSlideIndex = useSelector((state) => state.lesson.currentSlideIndex);

  const [textParams, setTextParams] = useState();
  const [listStartTime, setListStartTime] = useState([]);

  const [currentTime, setCurrentTime] = useState(0);
  const [playing, setPlaying] = useState(false);
  let timer = 0;

  useLayoutEffect(() => {
    setListStartTime([0]);
    if (mediaObject.readMode !== READING_MODE.SENTENCE) return;
    if (slideIndex !== currentSlideIndex - 1) return;
    setListStartTime(
      mediaObject.sentencePositions.map((sentenceObj) => Number(sentenceObj.startTime))
    );
  }, [mediaObject.readMode, currentSlideIndex]);

  useEffect(() => {
    setPlaying(playAudio);
  }, [playAudio]);

  useEffect(() => {
    if (slideIndex === currentSlideIndex - 1 && playing) {
      if (mediaObject.readMode === READING_MODE.WORD) {
        timer = setInterval(() => {
          setCurrentTime((prev) => prev + 100);
        }, 100);

        if (currentTime > mediaObject.wordPositions[mediaObject.wordPositions.length - 1].endTime) {
          setCurrentTime(0);
        }

        return () => {
          clearInterval(timer);
        };
      } else if (mediaObject.readMode === READING_MODE.SENTENCE) {
        let timer = setInterval(() => {
          setCurrentTime((prev) => prev + 100);
        }, 100);

        if (
          currentTime >
          mediaObject.sentencePositions[mediaObject.sentencePositions.length - 1].endTime
        ) {
          setCurrentTime(0);
        }

        return () => {
          clearInterval(timer);
        };
      }
    }
  }, [mediaObject.readMode, playing, slideIndex, currentSlideIndex]);

  const handlePrevious = () => {
    if (mediaObject.readMode !== READING_MODE.SENTENCE) return;
    if (listStartTime.length === 0) return;
    setPlaying(false);
    clearInterval(timer);

    const audio = document.getElementById(`audio-${mediaObject.audioUrl}`);
    let previousTime = findPreviousTime(listStartTime, currentTime);
    if (previousTime === -1) {
      audio.currentTime = 0;
      setCurrentTime(0);
    } else {
      setCurrentTime(previousTime);
      audio.currentTime = Number.parseFloat(previousTime / 1000);
    }
  };
  console.log(currentTime);
  const handleNext = () => {
    if (mediaObject.readMode !== READING_MODE.SENTENCE) return;
    if (listStartTime.length === 0) return;
    setPlaying(false);
    clearInterval(timer);

    const audio = document.getElementById(`audio-${mediaObject.audioUrl}`);
    let nextTime = findNextTime(listStartTime, currentTime);
    if (nextTime === -1) {
      audio.currentTime = 0;
      setCurrentTime(0);
    } else {
      setCurrentTime(nextTime);
      audio.currentTime = Number.parseFloat(nextTime / 1000);
    }
  };
  // handle read mode ui
  const handleTextReadMode = (text, mode) => {
    switch (mode) {
      case READING_MODE.WORD:
        return (
          <>
            {mediaObject.wordPositions.map((word, i) => (
              <div
                key={`${word.id}`}
                className={`TextDisplay SplitSentence ${
                  word.startTime < currentTime && word.endTime > currentTime
                    ? 'TextReadingWord'
                    : ''
                }`}>
                <WordDetail
                  index={i}
                  text={word.text}
                  mediaObject={mediaObject}
                  textParams={textParams?.children?.children}>
                  {word}
                </WordDetail>
              </div>
            ))}
          </>
        );
      case READING_MODE.SOUND:
        return textParams ? (
          <>
            {textParams?.children?.children?.map((word, i) => (
              <div key={`${word}-${i}`} className={'TextDisplay SplitSentence TextReadingWord'}>
                <WordDetail
                  index={i}
                  text={word.text}
                  mediaObject={mediaObject}
                  textParams={textParams?.children?.children}>
                  {word.text}
                </WordDetail>
              </div>
            ))}
          </>
        ) : (
          <CircularProgress />
        );
      case READING_MODE.SENTENCE:
        return (
          <div className="type-sentence">
            {mediaObject.sentencePositions.map((sentence, i) => (
              <div key={`${sentence}-${i}`} className="type-sentence--elm">
                {sentence !== '' ? (
                  <div
                    className={`type-sentence--elm--text FullSentence FullSentenceWord ${
                      sentence.startTime < currentTime && sentence.endTime > currentTime
                        ? 'TextReadingSentence'
                        : ''
                    }`}
                    key={i}>
                    {sentence.text.split(' ').map((word, idx) => {
                      return (
                        <span
                          className={`${
                            sentence.words && sentence.words[idx]
                              ? sentence.words[idx].startTime < currentTime &&
                                sentence.words[idx].endTime > currentTime
                                ? 'TextReadingSentenceWord'
                                : ''
                              : ''
                          }`}
                          key={idx}>
                          {word}{' '}
                        </span>
                      );
                    })}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            ))}
          </div>
        );
      default:
        return <div className="NomalText">{text}</div>;
    }
  };

  return (
    <>
      <div className="TheoryTitle">
        <div className="ReadMode"></div>
        {mediaObject ? (
          <div className="TheoryAudio" style={{ display: 'flex', alignItems: 'center' }}>
            {mediaObject.readMode === READING_MODE.SENTENCE && (
              <SkipPreviousIcon
                sx={{
                  color: '#0D4DC9',
                  border: '2px solid #0D4DC9',
                  borderRadius: '50%',
                  fontSize: '24px',
                  cursor: 'pointer'
                }}
                onClick={handlePrevious}
              />
            )}
            <AudioButton
              playing={playing}
              setPlaying={setPlaying}
              size={'24px'}
              audioUrl={mediaObject.audioUrl}
              audioText={text}
              audioRate={mediaObject.rateVoice}
              audioSpeaker={mediaObject.voice}
              // handleRead={handleRead}
              needChangeVoice={true}
            />
            {mediaObject.readMode === READING_MODE.SENTENCE && (
              <SkipNextIcon
                sx={{
                  color: '#0D4DC9',
                  border: '2px solid #0D4DC9',
                  borderRadius: '50%',
                  fontSize: '24px',
                  cursor: 'pointer',
                  marginLeft: '0.5rem'
                }}
                onClick={handleNext}
              />
            )}
          </div>
        ) : (
          ''
        )}
      </div>
      <div className="Text TypeTextContent">
        <Typography variant="h1">{handleTextReadMode(text, mediaObject.readMode)}</Typography>
      </div>
    </>
  );
};

export default TextType;

import { styled } from '@mui/material/styles';
import { FLOATING_BOX_COLOR } from 'src/theme/color';
import { SQR_HEIGHT, SQR_MG_RIGHT, SQR_WIDTH } from '../../../XSpaceBox/index.style';

export const DisplayTopBox = styled('div')`
  display: flex;
  flex-direction: row;
  width: ${(props) => props.w || SQR_WIDTH};
  margin-right: ${SQR_MG_RIGHT};
  height: ${SQR_HEIGHT};
  align-items: center;
  justify-content: center;
  border: ${(props) => (props.isBorder === true ? '2px solid black' : '')};
  background-color: ${FLOATING_BOX_COLOR};
  border-radius: ${(props) => props.radius || '0px'};
`;

// with text: transparent color
// with blank box drag and drop: props.bgc (background-color)

export const THEORY_CONTENT_TYPE = {
  TEXT: 'text',
  SQUARE: 'square',
  VIDEO: 'video',
  IMAGE: 'image'
};

export const READING_MODE = {
  NORMAL: 0,
  SOUND: 1,
  WORD: 2,
  SENTENCE: 3
};

export const TTS_TYPE = {
  AUDIO: 'audio',
  ACOUST: 'acoust'
};

export const SPECIAL_CHARACTERS = ['', '.', '!', '@', '#', '$', '%', '*', '&', '(', ')', '-'];

export const PARAMS_TONE = {
  _1: 'level',
  _2: 'falling',
  _3: 'curve',
  _4: 'broken',
  _5a: 'rising',
  _6a: 'drop'
};

import { styled } from '@mui/material';
import { BOX_SHADOW } from 'src/styles/config';
import ABC_bg from '../../../assets/images/modules/abc_bg.jpg';
import { LESSON_BOX_COLOR, SIDEBAR_COLOR, FLOATING_BOX_COLOR, BUTTON_COLOR } from 'src/theme/color';
import Chapter_bg from '../../../assets/images/modules/chapter_bg.png';
import { BORDER_COLOR } from 'src/theme/color';

export const ChapterTourStyle = styled('div')`
  height: 100%;
  width: 100%;
  background-image: url(${ABC_bg});
  opacity: 0.8;
  border: 4px solid ${BUTTON_COLOR};

  .Tour-TopBar {
    width: 100%;
    height: 8%;
    background-color: #ffc000;
    display: flex;
    justify-content: start;
    align-items: center;

    .Tour-TopBar__Breadcrumb {
      font-weight: bold;

      span {
        padding: 0 4px;
      }
    }
  }

  .Tour-Main {
    width: 100%;
    height: 92%;
    display: flex;

    .Tour-SideBar {
      width: 20%;
      height: 100%;
      background-color: ${FLOATING_BOX_COLOR};
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .chapterbox-close {
        filter: contrast(0.6);
        position: relative;

        .lock-elm {
          position: absolute;
          svg {
            width: 32px;
            height: 32px;
          }
        }
      }

      .MuiBox-root {
        width: 80%;
        max-width: 280px;
        height: 240px;
        padding: 6px 12px;
        max-height: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        position: relative;
        margin: 40px 0;
        padding: 12px;
        background-color: white;
        border: 2px solid ${BUTTON_COLOR};
        border-radius: 10px;
        box-sizing: border-box;
        box-shadow: ${BOX_SHADOW};
        cursor: pointer;

        .ChapterBoxStar {
          position: absolute;
          height: 28px;
          width: 28px;
          padding: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: ${LESSON_BOX_COLOR};
          color: ${SIDEBAR_COLOR};
          border-radius: 50%;
          border: 2px solid ${SIDEBAR_COLOR};
          right: -10px;
          top: -10px;
        }

        .ChapterBoxNumber {
          width: 32px;
          height: 32px;
          padding: 6px;
          font-size: 24px;
          font-weight: bold;
          background-color: ${BUTTON_COLOR};
          color: white;
          border: none;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .ChapterBoxTitle {
          font-weight: bold;
          text-align: center;
        }
      }
    }

    .Tour-Content {
      margin-top: 2%;
      margin: auto;
      height: 90%;
      width: 70%;
      border: 4px solid ${BORDER_COLOR};
      border-radius: 10px;
      position: relative;
      isolation: isolate;
      text-align: center;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px,
        rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px,
        rgba(0, 0, 0, 0.09) 0px -3px 5px;
      &:after {
        content: '';
        background-image: url(${Chapter_bg});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 0;
        border-radius: 6px;
        position: absolute;
        opacity: 1;
        inset: 0;
        z-index: -1;
      }

      .Tour-Content__Title {
        height: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .Tour-Content__List {
        height: 90%;
        display: flex;
        align-items: center;
        justify-content: space-around;

        .chapter__test_close {
          filter: contrast(0.6);
        }

        .Content {
          border: 2px solid ${BUTTON_COLOR};
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: ${FLOATING_BOX_COLOR};
          padding: 8px;
          position: relative;

          .ChapterBoxStar {
            position: absolute;
            height: 16px;
            width: 16px;
            padding: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: ${FLOATING_BOX_COLOR};
            color: ${BORDER_COLOR};
            border-radius: 50%;
            border: 2px solid ${BORDER_COLOR};
            right: -8px;
            top: -16px;

            svg {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }
  }
`;

// React redux & router dom
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// MUI
import { Drawer, List, Divider, Box, Typography } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';

// written components
import UserInfoBox from './UserInfoBox';
import MenuItem from './MenuItem';
import { LogoutButton } from './index.style';

// constants
import { DRAWER_WIDTH } from 'src/theme/size';
import { FLOATING_BOX_COLOR } from 'src/theme/color';
import { menuList } from 'src/constants/menuOption';

import Scrollbars from 'react-custom-scrollbars-2';
import { useEffect } from 'react';
import { getMe, logOut } from '../../apis/auth';
import { updateUserInfo } from 'src/redux/auth/actions';
import { getCookie, setCookie } from 'src/utils/cookie';
import { clearStorage } from 'src/utils/storage';
import { getUserStar } from 'src/redux/award-admin/userAward/userAward';

const MenuSideBar = () => {
  const isOpen = useSelector((state) => state.course.menuOpen);
  const user = useSelector((state) => state.auth.user);
  const star = useSelector((state) => state.userAwards.star[user?.result?._id]);

  const dispatcher = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const handleLogout = async () => {
    const refreshToken = getCookie('refreshToken');
    const response = await logOut('v1', refreshToken);
    if (response.result) {
      setCookie('refreshToken', '');
      setCookie('accessToken', '');
      clearStorage();
      navigate('/');
    }
  };

  useEffect(() => {
    const fetchUser = async () => {
      const user = await getMe('v1');
      dispatcher(updateUserInfo(user));
      dispatcher(getUserStar({ userId: user?.result?._id }));
    };
    if (Object.keys(user).length === 0) {
      fetchUser();
    }
  }, []);

  return (
    <Drawer
      sx={{
        width: DRAWER_WIDTH,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          backgroundColor: FLOATING_BOX_COLOR,
          width: DRAWER_WIDTH,
          boxSizing: 'border-box',
          marginTop: '4rem',
          border: 'none',
          borderRadius: '1rem 0 0 1rem',
          height: 'calc(100vh - 4rem)'
        }
      }}
      variant="persistent"
      anchor="right"
      open={isOpen}>
      <UserInfoBox
        avatarUrl={user.avatarUrl || user?.result?.avatarUrl}
        biography={user.bio || user?.result?.bio}
        starNum={star}
        fullname={`${user.lastName || user?.result?.lastName} ${
          user.firstName || user?.result?.firstName
        }`}
      />
      <Divider sx={{ backgroundSize: '15px', backgroundColor: 'black' }} />
      <Box sx={{ display: 'flex', flex: '1 1 0%', flexDirection: 'column', alignItems: 'center' }}>
        <Scrollbars>
          <List>
            {menuList.map((element, index) => {
              return (
                <NavLink to={element.route} key={index} style={{ textDecoration: 'none' }}>
                  <MenuItem
                    text={t(element.text)}
                    icon={element.icon}
                    isActive={element.route === pathname}
                  />
                </NavLink>
              );
            })}
          </List>
        </Scrollbars>

        <LogoutButton sx={{ position: 'relative' }} className="logout" onClick={handleLogout}>
          <Typography color="red" fontWeight="bold" variant="h3" textTransform="none">
            {t('logout')}
          </Typography>
          <LogoutIcon sx={{ fontSize: '25px', color: 'red' }} />
        </LogoutButton>
      </Box>
    </Drawer>
  );
};

export default MenuSideBar;

import PageLayout from 'src/containers/Layout/PageLayout';
import { CongratulationsStyle } from './index.style';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import FireworkLeft from 'src/assets/images/modules/congratulation/firework-left.png';
import FireworkRight from 'src/assets/images/modules/congratulation/firework-right.png';
import { CompletedProgressbar } from 'src/containers/CourseContainer/ChapterOverview';

// DELETE !!!
import Congrate from 'src/assets/audio/congratulation/congratulation.wav';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAllowConfirm, setOpenConfirm } from 'src/redux/lesson/actions';
import { getStorage, setStorage } from 'src/utils/storage';

const CongratulationContainer = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const congrateAudioRef = useRef();

  const currentSection = useSelector((store) => store.course.currentSection);
  const currentCourse = useSelector((store) => store.course.currentCourse);
  const currentUser = useSelector((store) => store.auth.user);

  const result = getStorage('result');

  useEffect(() => {
    if (!result) {
      let newResult = {
        done: [],
        learning: {
          [currentCourse._id]: [currentSection.id]
        }
      };
      setStorage({
        key: 'result',
        data: newResult
      });

      return;
    }
    if (currentCourse.sections[currentCourse.sections.length - 1]._id === currentSection.id) {
      let newResult = {
        done: [...result.done, currentCourse._id],
        learning: result.learning
      };
      delete newResult.learning[currentCourse._id];
      setStorage({
        key: 'result',
        data: newResult
      });
    } else {
      let newResult = {
        done: result.done,
        learning: result.learning
      };
      if (newResult.learning[currentCourse._id].includes(currentSection.id)) {
        return;
      } else
        newResult.learning[currentCourse._id] = [
          ...newResult.learning[currentCourse._id],
          currentSection.id
        ];
      setStorage({
        key: 'result',
        data: newResult
      });
    }
  }, []);

  // useEffect(async () => {
  //   // if (!currentUser) return;

  //   // let result = {
  //   //   userId: currentUser._id,
  //   //   courseId: currentCourse._id,
  //   //   sectionId: currentSection.id
  //   // };

  //   // await dispatch(updateResult(result));

  //   return () => {
  //     dispatch(setCurrentSection(null));
  //   };
  // }, []);

  const handleNavigate = (url) => {
    dispatch(setOpenConfirm(false));
    dispatch(setAllowConfirm(false));
    navigate(url);
  };

  return (
    <PageLayout breadcrumbsdisplay={'none'} progressDisplay={'none'}>
      <CongratulationsStyle>
        <div className="CongratulationText">
          <audio autoPlay ref={congrateAudioRef}>
            <source src={Congrate} type="audio/ogg" />
          </audio>
          <Typography
            style={{ cursor: 'pointer' }}
            variant="h1"
            onClick={() => congrateAudioRef.current.play()}>
            {t('congratulations')}
          </Typography>
        </div>
        <Typography variant="h0" className="Title">
          {currentSection.title}
        </Typography>
        <div className="Main">
          <div className="pyro">
            <div className="before"></div>
            <div className="after"></div>
          </div>
          <img src={FireworkLeft} className="animate__animated animate__tada animate__infinite" />
          <div className="ResultBox">
            <div className="Result">
              <Typography variant="h2">{t('theory_result')}</Typography>
              <div className="Progress">
                <CompletedProgressbar completePercent={'100'} />
              </div>
            </div>
            <div className="Result">
              <Typography variant="h2">{t('quiz_result')}</Typography>
              <div className="Progress">
                <CompletedProgressbar completePercent={'100'} />
              </div>
            </div>
          </div>
          <img src={FireworkRight} className="animate__animated animate__tada animate__infinite" />
        </div>
        {currentUser === null && (
          <h2>Hãy đăng nhập vào VietStudy để có thể lưu lại kết quả học nha !</h2>
        )}
        <div className="Footer">
          <button className="ButtonBack" onClick={() => handleNavigate('/')}>
            <Typography variant="h2">{t('goHome')}</Typography>
          </button>
          <button
            className="ButtonNext"
            onClick={() => handleNavigate(`/learn-course/${currentCourse._id}`)}>
            <Typography variant="h2">{t('next')}</Typography>
          </button>
          {currentUser === null && (
            <button className="ButtonLogin" onClick={() => handleNavigate('/login')}>
              <Typography variant="h2">{t('login')}</Typography>
            </button>
          )}
        </div>
      </CongratulationsStyle>
    </PageLayout>
  );
};

export default CongratulationContainer;

// react & mui
import { useState } from 'react';
import { Typography } from '@mui/material';

// styled component
import AudioButton from 'src/components/AudioButton';
import { TWSBox } from './index.style';

// sound & const
import useSound from 'use-sound';

export function MediaDisplayer({ media, index, isTone }) {
  const [clicked, setClicked] = useState(false);
  const [playAudio] = useSound(media.audioUrl);

  const onHandleClick = () => {
    setClicked(true);
    playAudio();
    setTimeout(() => {
      setClicked(false);
    }, 500);
  };

  switch (media.type) {
    case 'text':
      if (media.text !== '' && media.audioUrl !== '') {
        return (
          <TWSBox clicked={clicked} onClick={onHandleClick}>
            <Typography variant="h2"> {media.text} </Typography>
          </TWSBox>
        );
      }
      break;

    case 'image':
      if (media.imageUrl !== '') {
        return isTone === true ? (
          <img
            src={media.imageUrl}
            alt={`option ${index}`}
            style={{ width: '40px', height: '24.5px' }}
          />
        ) : (
          <img
            src={media.imageUrl}
            alt={`option ${index}`}
            style={{ width: '100px', height: '60px' }}
          />
        );
      }
      break;

    case 'video':
      if (media.videoUrl !== '') {
        return (
          <video controls style={{ width: '150px', height: '75px' }} controlsList="nodownload">
            <source src={media.videoUrl} />
          </video>
        );
      }
      break;

    case 'audio':
      if (media.audioUrl !== '') {
        <AudioButton size={'30px'} audioUrl={media.audioUrl} />;
      }
      break;

    default:
      if (media.text !== '' && media.text) {
        return <Typography variant="h2">{media.text}</Typography>;
      } else if (media.imageUrl && media.imageUrl !== '') {
        return isTone === true ? (
          <img
            src={media.imageUrl}
            alt={`option ${index}`}
            style={{ width: '40px', height: '24.5px' }}
          />
        ) : (
          <img
            src={media.imageUrl}
            alt={`option ${index}`}
            style={{ width: '100px', height: '60px' }}
          />
        );
      }
  }
}

export default MediaDisplayer;

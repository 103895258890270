import { styled } from '@mui/material/styles';

export const ConnectDotBox = styled('div')`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #ff8b13;
  position: absolute;
  opacity: ${(props) => props.opacity || 0.5};
  left: ${(props) => (props.dotPosition === 'right' ? '10%' : '')};
  right: ${(props) => (props.dotPosition === 'left' ? '10%' : '')};
  border: 1px solid black;
`;

import { styled } from '@mui/material/styles';

export const LineBox = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;

  .col {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 136px;
    margin-top: 15px;
  }
`;

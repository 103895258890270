import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SectionBoxStyle } from './index.styled';
import { Typography } from '@mui/material';
import waterDropSound from '../../../assets/audio/water_drop.mp3';
import useSound from 'use-sound';
import { useTranslation } from 'react-i18next';
import StarIcon from '@mui/icons-material/Star';
import { useDispatch } from 'react-redux';
import { setCurrentLesson } from 'src/redux/lesson/actions';
import { setCurrentSection } from 'src/redux/course/actions';

const SectionBox = ({ index, section, canLearn }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [playWaterDrop] = useSound(waterDropSound);

  const [bounce, setBounce] = useState(false);

  // hande animation
  const addBounce = () => {
    setBounce(!bounce);
  };

  const clickLesson = () => {
    addBounce();
    dispatch(setCurrentLesson({ id: section.lessonId._id, title: section.lessonId.title }));
    dispatch(setCurrentSection({ id: section._id, title: section.lessonId.title, type: 'lesson' }));
    playWaterDrop();
    setTimeout(() => navigate(`/learn-lesson/${section.lessonId._id}`), 300);
  };

  const clickTest = () => {
    addBounce();
    playWaterDrop();
    navigate(`/do-test/${section.testId}`);
  };
  return (
    <SectionBoxStyle canLearn={canLearn}>
      <div className="animate__animated animate__flipInY">
        {section.type === 'lesson' ? (
          <div onClick={clickLesson} className="sectionContent">
            <div className="Title">
              <Typography variant="h1">{t('lesson')}</Typography>
            </div>
            <img
              className={bounce ? 'animate__animated animate__bounce animate__infinite' : ''}
              src={process.env.PUBLIC_URL + `/icons/lesson/ic_lesson_${(index % 4) + 1}.png`}
              alt=""
              style={{ maxWidth: '12rem' }}
            />
            <div className="Content">
              <Typography variant="h1">
                {section.lessonId ? section.lessonId.title : section.lessonTitle}
              </Typography>
              <div className="ChapterBoxStar">
                <StarIcon />
              </div>
            </div>
          </div>
        ) : (
          <div onClick={clickTest} className="sectionContent">
            <div className="Title">
              <Typography variant="h1">{t('test')}</Typography>
            </div>
            <img
              className={bounce ? 'animate__animated animate__bounce animate__infinite' : ''}
              src={process.env.PUBLIC_URL + '/icons/ic_test.png'}
              alt=""
              style={{ maxWidth: '12rem' }}
            />
            <div className="Content">
              <Typography variant="h1">{section.testTitle}</Typography>
              <div className="ChapterBoxStar">
                <StarIcon />
              </div>
            </div>
          </div>
        )}
      </div>
    </SectionBoxStyle>
  );
};

export default React.memo(SectionBox);

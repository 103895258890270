// basic function
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// mui component
import { Divider, IconButton, Typography, Box, Tooltip, Container } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

// created components
import AvatarBox from './AvatarBox';
import { InfoBox } from './index.style';

import BeforeLearningLayout from '../Layout/BeforeLearningLayout';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeMenu } from 'src/redux/course/actions';
import { getMe } from '../../apis/auth';
import { updateUserInfo } from 'src/redux/auth/actions';
import { UpdateSecureDialog } from './UpdateSecureDialog';
import UpdateGeneralDialog from './UpdateGeneralDialog';

const PersonalInfoContainer = () => {
  const dispatcher = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const { t } = useTranslation();
  const [openGeneral, setOpenGeneral] = useState(false);
  const [openSecure, setOpenSecure] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  // const [openPw, setOpenPw] = useState(false);

  useEffect(() => {
    dispatcher(changeMenu(false));
    const fetchUser = async () => {
      const fetchedUser = await getMe('v1');
      setPhoneNumber(fetchedUser.result.phoneNumber);
      dispatcher(updateUserInfo(fetchedUser.result));
    };

    if (Object.keys(user).length === 0) {
      fetchUser();
    } else {
      setPhoneNumber(user.phoneNumber);
    }
  }, []);

  return (
    <BeforeLearningLayout>
      <Container maxWidth={false} sx={{ maxWidth: '86%', marginTop: { xl: '60px' } }}>
        <InfoBox>
          {/* left part (avatar) */}
          <Box className="left-part">
            <AvatarBox avatar={user.avatarUrl} />
            <Typography variant="h3" className="avatar-name">
              {`${user.lastName} ${user.firstName}`}
            </Typography>
            <Typography
              variant="h3"
              className="avatar-gender"
              fontWeight="medium"
              fontStyle="italic">
              {t(`${user.gender}`)}
            </Typography>
          </Box>

          {/* Divider to seperate 2 parts */}
          <Divider
            orientation="vertical"
            flexItem
            sx={{ borderRightWidth: 2, backgroundColor: 'black' }}
          />

          {/* right part (with typography) */}
          <Box className="right-part">
            {/* 1. General Information */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h2" className="title-text">
                {t('general_info')}
              </Typography>

              <Tooltip title={<h3>{'Cập nhật thông tin cơ bản'}</h3>}>
                <IconButton onClick={() => setOpenGeneral(true)}>
                  <EditIcon sx={{ fontSize: { xs: '0.75rem', md: '1.25rem', lg: '1.5rem' } }} />
                </IconButton>
              </Tooltip>
            </Box>

            <Box sx={{ marginTop: '15px', marginBottom: '10px' }}>
              <Typography variant="h3">{t('dob')}</Typography>
              <Typography variant="h3" fontWeight="medium">
                {user.birthday}
              </Typography>
            </Box>

            <Box sx={{ marginTop: '10px', marginBottom: '25px' }}>
              <Typography variant="h3">{t('bio')}</Typography>
              <Typography variant="h3" fontWeight="medium">
                {user.bio === 'no_bio' ? t('no_bio') : user.bio}
              </Typography>
            </Box>

            {/* 2. Contact Information */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h2" className="title-text">
                {t('contact_secure')}
              </Typography>
              <Tooltip title={<h3>{'Cập nhật thông tin liên lạc'}</h3>}>
                <IconButton onClick={() => setOpenSecure(true)}>
                  <EditIcon sx={{ fontSize: { xs: '0.75rem', md: '1.25rem', lg: '1.5rem' } }} />
                </IconButton>
              </Tooltip>
            </Box>
            <Box sx={{ marginTop: '15px', marginBottom: '10px' }}>
              <Typography variant="h3">{t('phone')}</Typography>
              <Typography variant="h3" fontWeight="medium">
                {user.phoneNumber === 'no_phone_number'
                  ? t(`${user.phoneNumber}`)
                  : phoneNumber.slice(0, 3) + ' *** ****'}
              </Typography>
            </Box>
            <Box sx={{ marginTop: '15px', marginBottom: '25px' }}>
              <Typography variant="h3">{t('email')}</Typography>
              <Typography variant="h3" fontWeight="medium">
                {user.email}
              </Typography>
            </Box>
          </Box>
          <UpdateGeneralDialog open={openGeneral} setOpen={setOpenGeneral} />
          <UpdateSecureDialog
            updateSecureInfo={() => {}}
            open={openSecure}
            setOpen={setOpenSecure}
          />
        </InfoBox>
      </Container>
    </BeforeLearningLayout>
  );
};

export default PersonalInfoContainer;

import { getCookie, setCookie } from '../utils/cookie';
import api from './api';
import jwtDecode from 'jwt-decode';

export const login = async (version = 'v1', username, password) => {
  return await api.post(`${version}/user/login`, { username, password });
};

export const register = async (version = 'v1', keycloakUser, password, gender, birthday) => {
  return await api.post(`${version}/user/register`, {
    keycloakUser,
    role: ['student'],
    password,
    gender,
    birthday
  });
};

export const verifyEmail = async (version = 'v1', email, password, code) => {
  return await api.post(`${version}/user/verify-email`, { email, password, code });
};

export const resendVerifyEmail = async (version = 'v1', email, firstName, lastName) => {
  return await api.post(`${version}/user/resend-email`, { email, firstName, lastName });
};

export const regenerateTokens = async (version = 'v1', refreshToken) => {
  return await api.post(`${version}/user/regenerate-tokens`, { refreshToken });
};

export const updateFullname = async (version = 'v1', userId, firstName, lastName) => {
  const accessToken = await processExpired();
  return await api({
    method: 'PUT',
    url: `${version}/user/update-with-keycloak`,
    data: JSON.stringify({ userId, firstName, lastName }),
    headers: getHeaders(accessToken)
  });
};

export const updateWithoutKeycloak = async (version = 'v1', newUser) => {
  const accessToken = await processExpired();

  return await api({
    method: 'PUT',
    url: `${version}/user/update-no-keycloak`,
    data: JSON.stringify(newUser),
    headers: getHeaders(accessToken)
  });
};

export const requestToSendEmail = async (version = 'v1', email) => {
  return await api.post(`${version}/user/send-email-forgot-pw`, { email: email });
};

export const checkUpdatablePassword = async (version = 'v1', userId, verifyCode) => {
  return await api.post(`${version}/user/check-updatable-pw`, {
    userId,
    verifyCode
  });
};

export const handleUpdatePassword = async (version = 'v1', newPassword, keycloakUUID) => {
  return await api({
    method: 'PUT',
    url: `${version}/user/update-pw`,
    data: { newPassword: newPassword, UUID: keycloakUUID }
  });
};

/*********** NO NEED AUTHENTICATION APIS - END ***********/

export const logOut = async (version = 'v1', refreshToken) => {
  const accessToken = await processExpired();
  return await api.post(
    `${version}/user/logout`,
    { refreshToken },
    {
      headers: getHeaders(accessToken)
    }
  );
};

export const getMe = async (version = 'v1') => {
  const accessToken = await processExpired();
  return await api.get(`${version}/user/profile`, {
    headers: getHeaders(accessToken)
  });
};

export const processExpired = async () => {
  let accessToken = getCookie('accessToken');
  const refreshToken = getCookie('refreshToken');
  if (accessToken !== '' && refreshToken !== '') {
    const decodeResult = jwtDecode(accessToken);
    const compareValue = new Date().getTime() > decodeResult.exp * 1000;
    if (compareValue) {
      const response = await regenerateTokens('v1', refreshToken);
      if (response?.result?.accessToken !== '') {
        accessToken = response?.result?.accessToken;
        setCookie('accessToken', response?.result?.accessToken);
        setCookie('refreshToken', response?.result?.refreshToken);
      }
    }
  }
  return accessToken;
};

export const getHeaders = (accessToken) => {
  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`
  };
};

import { SQUARE_QUIZ_TYPE } from '../../../constants/quiz';
import ElementOfWordContainer from './ElementsOfWordCTN';
import PhonemeOfRhymeContainer from './PhonemeOfRhymeCTN';
import PhonemeOfSyllableContainer from './PhonemeOfSyllableCTN';
import ToneOfWordContainer from './ToneOfWordCTN';
import WordInSentenceContainer from './WordInSentenceCTN';

const SquareQuizContainer = ({ data }) => {
  switch (data.squareQuizType) {
    case SQUARE_QUIZ_TYPE.ELEMENTS_OF_WORD:
      return <ElementOfWordContainer data={data} />;

    case SQUARE_QUIZ_TYPE.PHONEME_OF_RHYME:
      return <PhonemeOfRhymeContainer data={data} />;

    case SQUARE_QUIZ_TYPE.PHONEME_OF_SYLLABLE:
      return <PhonemeOfSyllableContainer data={data} />;

    case SQUARE_QUIZ_TYPE.TONE_OF_WORD:
      return <ToneOfWordContainer data={data} />;

    case SQUARE_QUIZ_TYPE.WORD_IN_SENTENCE:
      return <WordInSentenceContainer data={data} />;

    default:
      return <></>;
  }
};

export default SquareQuizContainer;

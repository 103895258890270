export const gatherSyllableLines = (list) => {
  return list.map((e) => e.content.text);
};

export const gatherWords = (list) => {
  let collection = [],
    words = [];

  for (let i = 0; i < list.length; i++) {
    words = [];
    for (let j = 0; j < list[i].squareQuizParts.length; j++) {
      words.push(list[i].squareQuizParts[j].content.text);
    }
    collection.push({ words });
  }
  return collection;
};

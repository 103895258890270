import { Box, Typography } from '@mui/material';
import TestOVBox from './index.style';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import { useState, useEffect } from 'react';
import StarIcon from '@mui/icons-material/Star';

const TestOverview = ({ title, testPurpose, quizNum, stars }) => {
  // Chưa làm, Đã làm
  const buildTestType = (testPurpose) => {
    if (testPurpose === 'evaluation') {
      return {
        bigTitle: 'Bài kiểm tra đánh giá',
        attemptTimes: 'Bạn chỉ có thể làm 1 lần. (Chưa làm)',
        note: 'Trả lời đúng mỗi câu hỏi, bạn sẽ nhận được 1 sao, trả lời sai sẽ không nhận được ngôi sao nào.',
        note2:
          'Lần làm bài này, nếu bạn chỉ sai không quá 2 câu, bạn sẽ nhận được tổng 3 ngôi sao.',
        note3: 'Nếu sai hơn 2 câu, bạn sẽ không nhận được ngôi sao nào cả.'
      };
    } else {
      return {
        bigTitle: 'Bài kiểm tra luyện tập',
        attemptTimes: 'Bạn có thể làm nhiều lần. (Đã làm 1 lần)',
        note: 'Trả lời đúng mỗi câu hỏi, bạn sẽ nhận được 1 sao, trả lời sai sẽ không nhận được ngôi sao nào.',
        note2:
          'Lần làm bài này, nếu bạn chỉ sai không quá 2 câu, bạn sẽ nhận được tổng 3 ngôi sao.',
        note3: 'Nếu sai hơn 2 câu, bạn sẽ không nhận được ngôi sao nào cả.'
      };
    }
  };

  const [testInfo, setTestInfo] = useState({});

  useEffect(() => {
    setTestInfo(buildTestType(testPurpose));
  }, []);

  return (
    <TestOVBox>
      <Box className="test-purpose">
        <Typography variant="h1">{testInfo.bigTitle}</Typography>
        <Typography variant="h3">{testInfo.attemptTimes}</Typography>
      </Box>

      <Box className="test-title">
        <Typography variant="h1">{`${title} (${quizNum} câu)`}</Typography>
      </Box>
      <Box className="star">
        <Typography variant="h2">{stars}</Typography>
        <StarIcon fontSize="large" sx={{ color: 'orange', fontSize: 32.5 }} />
      </Box>

      <Box>
        <Box className="test-note">
          <NoteAltIcon />
          <Typography variant="h3">{testInfo.note}</Typography>
        </Box>
        {testPurpose === 'evaluation' ? (
          <Box className="test-note">
            <NoteAltIcon />
            <Typography variant="h3">{testInfo.note2}</Typography>
          </Box>
        ) : (
          <></>
        )}
        {testPurpose === 'evaluation' ? (
          <Box className="test-note">
            <NoteAltIcon />
            <Typography variant="h3">{testInfo.note3}</Typography>
          </Box>
        ) : (
          <></>
        )}
      </Box>
    </TestOVBox>
  );
};

export default TestOverview;

import { useDrag } from 'react-dnd';
import { DisplayMediaBox } from './index.style';
import MediaDisplayer from '../../ToolParts/MediaDisplayer';
import { moveItemToAnotherBox, changeChosen, compare } from './index.logic';

import correct from '../../../../assets/audio/correct.mp3';
import useSound from 'use-sound';

const MediaBox = ({ answerId, media, belongTo, setItems, setChosen }) => {
  const [playTrue] = useSound(correct);
  const [, drag] = useDrag({
    item: { answerId, belongTo },
    type: 'dnd-box',
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();

      if (dropResult && dropResult.boxId != item.belongTo) {
        // move to different container box
        moveItemToAnotherBox(item, dropResult.boxId, setItems);

        // check if answer is right or wrong
        const comparedResult = compare(dropResult.boxId, item.answerId);
        if (comparedResult) {
          changeChosen(item.belongTo, dropResult.boxId, 'correct', setChosen);
          playTrue();
        } else changeChosen(item.belongTo, dropResult.boxId, 'incorrect', setChosen);
      }
    }
  });

  return (
    <DisplayMediaBox ref={drag} isBorder={belongTo === 'bottom-box'} id={`${answerId}-btm`}>
      <MediaDisplayer media={media} index={answerId} />
    </DisplayMediaBox>
  );
};

export default MediaBox;

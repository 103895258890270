import { shuffleArray } from '../../../../utils/shuffle';
import { CHOSEN_TEST_QUIZ_COLOR } from 'src/theme/color';

export const createPairs = (arr, id) => {
  const beginList = arr.map((ele, index) => {
    return {
      left: {
        media: ele.content,
        index,
        boxId: `${id}-${index}-left`,
        chosen: 'unchosen'
      },
      right: {
        media: ele.pairQuizParts.pairContent,
        index,
        boxId: `${id}-${index}-right`,
        chosen: 'unchosen'
      }
    };
  });

  const beginLeftArr = beginList.map((ele) => ele.left);
  const beginRightArr = beginList.map((ele) => ele.right);

  return {
    leftPairs: shuffleArray(beginLeftArr),
    rightPairs: shuffleArray(beginRightArr)
  };
};

export const isOnePair = (boxId1, boxId2) => {
  const arr1 = boxId1.split('-');
  const arr2 = boxId2.split('-');

  // objectid-2-left === objId-2-right: matching right pair --> true
  if (arr1[0] === arr2[0] && arr1[1] === arr2[1]) return true;
  else return false;
};

export const isOneSide = (boxId1, boxId2) => {
  const arr1 = boxId1.split('-');
  const arr2 = boxId2.split('-');

  // objId-2-left === objId-1-left: matching pair in the same side
  if (arr1[2] === arr2[2]) return true;
  else return false;
};

export const isArrowExisted = (newStart, newEnd, arrows) => {
  for (let i = 0; i < arrows.length; i++) {
    if (arrows[i].start === newStart && arrows[i].end === newEnd) return true;
    if (arrows[i].start === newEnd && arrows[i].end === newStart) return true;
  }
  return false;
};

export const hasAPointInUse = (newStart, newEnd, arrows) => {
  for (let i = 0; i < arrows.length; i++) {
    if (arrows[i].start === newStart || arrows[i].start === newEnd) return true;
    if (arrows[i].end === newEnd || arrows[i].end === newStart) return true;
  }
  return false;
};

export const handleArrows = ({ newStart, newEnd, arrows, setArrows }) => {
  // check if a point (a vertice) is in used
  const isInUse = hasAPointInUse(newStart, newEnd, arrows);
  if (!isInUse) {
    setArrows([...arrows, { start: newStart, end: newEnd, arrowColor: CHOSEN_TEST_QUIZ_COLOR }]);
  } else
    setArrows((prev) => {
      return prev
        .map((e) => {
          return {
            ...e,
            end: e.start === newStart ? newEnd : e.start === newEnd ? newStart : e.end,
            start: e.end === newEnd ? newStart : e.end === newStart ? newEnd : e.start
          };
        })
        .map((ele) => {
          return {
            ...ele,
            arrowColor:
              (ele.start === newStart && ele.end === newEnd) ||
              (ele.end === newStart && ele.start === newEnd)
                ? CHOSEN_TEST_QUIZ_COLOR
                : ele.arrowColor
          };
        });
    });
};

export const changeChosen = ({ setLeftPairs, setRightPairs, start, end, check, arrows }) => {
  const startLeftOver = findPairBoxId(start, arrows);
  const endLeftOver = findPairBoxId(end, arrows);

  setLeftPairs((prev) => {
    return prev.map((e) => {
      return {
        ...e,
        chosen:
          (e.boxId === start || e.boxId === end) && check
            ? 'chosen'
            : (e.boxId === start || e.boxId === end) && !check
            ? 'chosen'
            : e.boxId === startLeftOver || e.boxId === endLeftOver
            ? 'unchosen'
            : e.chosen
      };
    });
  });
  setRightPairs((prev) => {
    return prev.map((e) => {
      return {
        ...e,
        chosen:
          (e.boxId === start || e.boxId === end) && check
            ? 'chosen'
            : (e.boxId === start || e.boxId === end) && !check
            ? 'chosen'
            : e.boxId === startLeftOver || e.boxId === endLeftOver
            ? 'unchosen'
            : e.chosen
      };
    });
  });
};

const findPairBoxId = (boxId, arrows) => {
  for (let i = 0; i < arrows.length; i++) {
    if (arrows[i].start === boxId) return arrows[i].end;
    if (arrows[i].end === boxId) return arrows[i].start;
  }
  return '';
};

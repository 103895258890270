import React from 'react';
import { StyledSquare } from './index.style';

const Square = ({ children, display, color }) => {
  return (
    <StyledSquare display={display} color={color}>
      {children}
    </StyledSquare>
  );
};

export default Square;

export const menuList = [
  {
    text: 'Courses',
    icon: (
      <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAuElEQVR4nO2YsRHCMAxF1WUCr6GCEZglbJHM4GE4erxU2k9DkQuNMCGJ4b27X/u+9WWfZAYAcEh8PCmq2jPS5a6oMOBU4JVEhFZuYpVOEW1SgeF6VlS1BvrbpKj+z4C3HiGvMZBNEWEgwvKW55n/VoT6WeZXjxAGSosVyM/GbTZCGQOiAk6EjCZOvEIjz6j4yHYZaN78idPuA83PGShsJcRWwhnqraGtRDlQEzsGOiqQPokQAIBtwQMY/hdrKS5YsAAAAABJRU5ErkJggg==" />
    ),
    route: '/',
    needLogin: false
  },
  {
    text: 'Road map',
    icon: (
      <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEUElEQVR4nO2XT2zbVBzHw0AwEBwmwYkDsAutkDiBQELa4dc26zLouthuS0MJpetK/9hpmqb2czI8VdvEyBZ7rH8EdteJMkAD0bFpQ0ibuCGtu/DngEAcQEJcQIAAIYFofsix02aJ4zit7bSiT/qe/GR/Pi+/93svgcDW2BobezAcEWiW/EqxQjCw2QbFkUcpTvyH5kSkWfJj96Cwo95MAV6ef1BPtXkMw9xKc+RGHt4MxYpaoJ5DyM7tFBTtt3yyczvt5tIcSRTDFxKOibsD9RiSJN3GK+qngqKhEfWGJJ2/3WpueCj1AMWRP6wEaI5815ZM3uO7AK9ox1bhzcjqUau5NCd+bA1fKCVy2l94WdslyOq/pQK8oi0TRWsqnktxQtQO3hRYDsdSu3yBF2Zmdgiy9n3Z6q9IqD+MnXj9Xn0uw5L7aE78uZqA2ZW+YeLxO607F/lCb7/uCMjahUrwKxKydjmAeAvFiuccwa/uh0zxt/Q2S3HkW/N5Lhwj7euDV7ShavCF9B/KTNUGb5bSCHlK/5YkSdtoVrx803NO/IUZST20JviJ7BuPCIr6lxP4iayKXWMv52oVoI18FY1K22mWTFqXmrjUyrJ31AQvzc9vF2T1M6er33fo+FrAi1f6I/3XsCm1UzUJ8LI67RQ+fnwa6VhqXQK0gzAsoR3BC4oaEmQ15wSeVzSMjB/2HJ42Sul3JpZ+2BZePHn2fkHRfnK6+gNSxh94bkXic6vWa9S9JG3jZfWqU/hEZhaZUe9Lhy6TILPWpSOrxCm8IKvYw0/6D88V9oPQcxN8Mqs9xivq3wVAXGpwnOXrjThzKuKrAMWKf3bG0o1G6UxN3c0r2tfFK1yLgJ7c9QY8O9vh93748umD0l36VWGhtERqFShkcb7N71/iTCB9CbA0axW49snjZe8qTfJtwJdOAEZFwI4hwH3PAba2AQZDgADl0Z/tfwEwkgA8cBSQnQXkz6++zxWB3FIDXrn2REXoxALg8wRwL2UNCWaag4D7o4bc8GlA4X37xXBFYHmpERevPmn58vEFwM5hQGiqDB0MAXaxgEMKoPhBdWBHAlbp7e3Nx+n8gVcBW1orgz/TBXjwFUBxsXZozwWiqcrgLXsABzKA6YvrA097JaBvULsaj8+5A572SmAvXVmAGXAXPu22QOpD+y6zL7LBBdKXAPe020v08OvfuGkvBQZP2gvkN3IrYMcgYN8RQHYGcOKdtW9qT7oQ3V9dojRNLYChduMgezZmyI3N10lg4l2j49QqARYJhQH7j/ksoEe/w7ghAGY6R3wUGMy6Cw9m4mc8FkhdMG6MbpUPlOTAkQoCev8W3luNfuUdf8tI4k3AUQ0wEonkMzINOPya0W302nzxMGD3qLH59GuCF+BgRr87lQl4+UFX0wSYPLeJBbrjFTZxvcHAQfRzRd9fm06gOQjYN2l/Sm9Igebdxn/g/BWj2km8NbbG/3z8B7nIzkHFzyzIAAAAAElFTkSuQmCC" />
    ),
    route: '/roadmaps',
    needLogin: true
  },
  {
    text: 'Store',
    icon: (
      <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACoUlEQVR4nO2TT2gTQRTG55JZaKWHYiJK/5AIBXNoe/HiTTxI0UNRNHqwoK7oqRQFD94sgi0eCm2pKEKDSJBQbAh6MTTdVAotCBH/JQcv2hZNoVBMNtBo++RtUZqdmWbLks1G5sEHIfPNfO+3M48QWbJkyXJlwYXAKQj5lyEUgJrqvH8JQoGevQNsb6xt86F/EN/2DlDrpkPlkgBQTzcAs55uSClQpiRlZ6KvfZ3xoUSN8Lx97euMD7PMvllPt3UATRlhDphqfMME9bdkbQP0t2QZH2aZfZoyYq35t8QDGl1lDniwnwUY9C3YBhj0LTA+zGIA6Cr2VhlgjvZyg661sU9ovFmzDTDerDE+zOJ552ivleczben9oyJN9gEiTSyAaA40ZbrC19/nBU0pmTduTjXOc0PiDYu2AeINizyvkckClLBHMUCKDvBCNu56+TeQUNZsAySUNZ7XyOT5U3RADDBDv/M2la60/uZfs7JlGyCpbPG8vzCT55+hOTFAmKxAmMBOlSIHIHPmCGRPeMuUORuEUsQHTvthkiyLASbJkHnDj8QN+Dh8kwn4MHwLcq+vg9N+CJP7YoAooQiB5Cg84F0mA+lPGSMEvxQKf+N/uIYep/yAHzhKKKlU6ewXcKOI1fqvAZZ+blZVaScA/BN5Szp4+72h3TyBiTwcDRfg3Au9vgAOP8zDsacFuBjTQX1VNFQXAB2P8nD8mQ6X4ttNqzvkaoDg4wKcfK7D5Zds46qbAQ7d+QynozpcFTStuhHg72C23vsKbUMrFRtX3QJgHkxsvi4AOgSD6XqAoGkwO0dz0DWWEwKY19VaAXQ+yXMHE5vrGhUDmNfVWgFYfRKufUKqBChW9wZiyXkQqdo3ENslWwLE5A0UnXlCsmTJkkWcqj8n882anbQiawAAAABJRU5ErkJggg==" />
    ),
    route: '/vietstudy-store',
    needLogin: true
  },
  {
    text: 'award',
    icon: (
      <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAE7ElEQVR4nO1YXWgcVRQ+mzTJJpgiKTW7mtmUuG3z6M+DqVh8sC+C1frcSjEtFUSpBnbjk2shya7V2K60D5F2ZxLB1rzJJs0mQn5MJT8Uyqy0xAhKmh1U8KfRpKZW/OTszGRnd2fTzGY2biAHDiw7d+79vjvnfPecS7RlW7Zlthgi1AyRvoVICxBpEBE6iF4qTbpEL0KiIYj0JySagUSvUjEZRDoGiWDiyxDprumzCDVTMRgi9AREWsxBILfz1+imxzcecC9VIkL7IdJhSBSGREuWwad8KTkHz8Vz9lJlYcF30z6INJ8T0KVyILoDGBGA8QZgco/q/Jv/42efla9G6BZEairczucCf9kJjHiA6ca1OZPhd6ScJJz2E+BPnLlYtwOI1aaATe0GxtzAlw8AA+VAX4nq/HuoWn3GY/TxsVpAcmST6KZn7Ccg0itpi/SUAqPark/tBUZ2qmCjtLrzGB7L70xrX+PT0kwSR+wnINHHaTuvh8yEF4g57w880wecwMSj6hy8ETxnSqHO2i+VRrXRw2bSC/Rvsw5e9/5t6hwr4WRQJ7skNnlIGXWek08PG97FfMHrHuP59gJTjcDnzvRzYr2HHR/5WQmmhw7H8XrBRzUf3ZnKh+yEProeAjNZOq+rzVoSds2hVJpSp+xz4kb+BNTiKzVZ3w51kTGXfeCjmrPE8tx9NZkEfl/PFxhKDx9BXYR13m4CQ9Xq3MMZYSRSLH8CXBIbJ7vaoC7CB5PdBAa08OSyIz0HXsifgFrLL69MxnUNL9JvY/xH9Two0aR5j5HAXwhQyeYg0JeDQC+V2h9CVzZLCEk5kpgTrlBJPJKVxIP2yWhUl1G3/QS+0mQ0miWjtzfHQTaZ4yAT6abNpYSweUqJVYu56QIUc5czijmRjlFRl9NXytR+Ink4PmQEvwiRHrMF/AoJ9QZCW8SRCqV8G5qYoaEZETJbyzO2gtcIHEmLTW4DdRIcApwTnIxrSdhRQ0s5vFEtpVlTz7vG4TSV0dSzpvPBxCd2v6GpZ6nU1WZKDxvHBjX1q12rXKoAhuusXasYuy8pTTbnCnKtkiQhUlPy3sZsYXbWcK7nhzMutq7qF1s1q19siTSHHnqqIOANJJzjnzS9/FY4fO/i+eO4I1bmBnQfvyM6ceGjw3ijvQOHTkn3DgZ6vLQRJgSVDzwhBezPdw5iSayyDH4xUoUD7/bC7Y8b/XTBwXsDv2wXQsptnQC7L/yhKci7UnnSzZ61dLyXCR5uv/xH/cnrDxaUgBBS3jGC170lfBbfXdiNBXE7xrqeRfMZCbtC80k/2n4eo+eexsLFasx2NeBkR5sJ+LjqvnhrwcB7w7MVnqCimBHI5XVtc7/lBOs3IyD/VB/4oTAq5Akqx62ATxI49X2bJQJ+9m/sqYHSDHAIocQNK+CFUGKOTqDM7ZNvWiHg8sdnKID8e2Ezq3tfOWR19z3BhI/fdfnk1y1/hVb5JVsJCEHla2sEEkt1nbdq+F134FqVyy//apHEJG2EPdl1rcwTSoxnh49yzjjO7ZM7TRRnwvvmbAX931Z/+meXJ6QkDKHzb3270mgcU9t6fZfbH//HoPk/PuKX66hYrL4jsU8IKsvJ3Q8qUbMxLn/8C43A326fvJ+KzYTQ/GvaFzhg9vxhn/ycFjonqFhNCCVaWHLNn8Lh8slv27ngf3bkpLURUvg6AAAAAElFTkSuQmCC"></img>
    ),
    route: '/award',
    needLogin: true
  },
  {
    text: 'Settings',
    icon: (
      <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAFRklEQVR4nO1ZzW/cRBTfAgIOVVsqwQEVcUEFiQstgmuEYm8SkUADsbIebzPOzmRRVkojDmkSOo6Rmk2TmfQQTg3i64xEL6lE2qoBbtz4E1qk9kARjaDiQ0TB6I3XW8c7XtubbNRAnjSSNZ55835v3ryPmVxun/bp4aOiK57BDr9lO8KDBt/Ql9srZDOOAuGDNjyzaOYeFrLdhZO2I67aTKwR9+LRhv+OmI0CgL7oOOJePGo7/BvM+OpptnhiV4THTAjMxOYD8xDXXdd9bAsAJi43AGDicngMzMGOuF7nw8Qm8G6r8K7rPoKZ2FBo9yPQJp4Rp22HL9uM/9oIAPr4MoyRmmd8KToGeOdy3oG2grAZv6IAIBdX9WcZix2x0lbhJYAZbqQVNGsbZmKg7QDGxpaesBlf33EAjK8D77YDsJmYjBOiOFn1BsamvT5yxuuxK17eKssG39AH/4pTc/Gm5fD5nfY2G2DzsLU1zbtxgvfRcU9HNFUDMNbZ2ZjzwR1YC9aEtf3zwhezadldOBl2lcEWKw6jd2p0oi6Yhugvmkk/7UT07ajQebN0Skf0Mw2Re0Ff/+iE5KEyp8g6myBTegAQpBJsdujcvNSkLwz5Q0d0ttuyDgU8ogCCfs0oH9ZMOleb4/XScW/o3EIKz8VX0wNgYq05Qy4Xrgl3p9MaeS3KIw5A6P8rukl+DEAod2JrIFxLDQBNXXgqHCWjrW42JrndbdFjKh5JAIC6LXoMePjmdLaZwr4bnq4+nctCEOohwkaZweELzCaP6Ktx89MAAILd0036J4xRHmzGl6KpSmqS6UEkaj6we9qQnLUCAEhHZF56JzreELFVyWJqGmJ8KOou697GKB+Om6fyQrpJ+uLGd2B8JPBOxalqJDrzYssAbCY+DjN7d2zaB2CST5K0qW6kGjdPM8nnMAbW2BrcxKVslRQTBcx4FTv8q2hWGZgPaDi15qPxANFeJXCL9kuPRLaaEcgAskiZmCg0rezCZaCq9eBRX4gieUEpBCLfJgHQTKp0h11W6UX434MrzeOBw2/FAkgKJl3FshSiw6gcjAFwPwWA31RzO4zKQfgPayTJ0TIASMziAHQO0uM6IhvJuRDZ6CrQl6Pzuy3rkL+777UOINGE7Ip/Bgbp8brmMD4CB1dD5K+0yZxukr81ky6FPVneLL8kTchubkI2Eze3fYghMTMM49E8omUN0bupBW8wJ/KThugIlKoaIu9s+xA37oi4FGYG+byvwZEVDZHvWxVcAeQHHZFV+B4Y+6B1N9oAQBbpoUA2NddcEERu6hYdAFcJ3sY/1OS+hsgNrVB6E/7JMU14FCMFD8jQMgBVKhHKQsMa/F03yYcdGD+ZxNMwjMc1c2QcvJEeLXR2MpWIT+bO18yI/qMj2b58A5Wez8pfK5Se1RBd1kyyWePlWZPndyaZS0qnoZKqaf5uXDqdhvKD9nMaIj8npdOY8Wsg044WNKEa+E7eJK9nFb6zUDqRpaDBDr+RmjmUb0nBBMrA+nnw8/kLEBOSeMvM06QLQdzoJe0oKTMU9bD1IS90T2aVFu2HwAQRG5oMUhbt10z6hW7S9WB8f2VC7maaoj7z5a/qWgU7YkalHWty1nur/H7GaxXFgXVkY4prFZ5J+JYvtqaqMp8Hs4CUABIzaPANfQNndvFi6797tTizxy93sSNW2nW9bjN+pc3ieweUizO+lOaBAxIzKNL9p6XGKI+Z2IDMtK0QwCNseWJi/NqeeWIKCHyy7YivITpu+5GPyah/NdPlbe7//sya9aEbysDMd5v7tE+5XaF/ASpy3TwgsWoUAAAAAElFTkSuQmCC"></img>
    ),
    route: '/settings',
    needLogin: false
  }
];

export const userOption = [
  {
    text: 'Personal Information',
    icon: (
      <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAFKUlEQVR4nO2YXUxTZxyHz1oWXeKVccnSG5cth2SJaY0mOl1M+74gIpwCzo8LTeb0RrzYSJYsWTZjZBemIgwxOpA6MQEp4Ji6YYsDtQ63iW5SSlCKKBR62tKBED8ynKW/5RxGgdIvSmm7xH/yC2/ez+c5Pee0gWFe1atKrEpPT19EKd1GCKkhhHQSQkYppZhMSkqKiknUUqlUmZTSnunAfmJkErEIIV+EAPeGENJNKS1QKpVvMYlQlNK8cOF98lS43eIKr1QqVxJCxiIUACHErVKpNsVNgBBiiBSeTkn0r169+vWYwyuVyhWUUs98BeiExI6YCxBCDkQDnk4IVMRDoClaApTSOzEXoJT2RlGgLx4CT6N4Cz2PuYDwCpwL5PasVOTvT8NPmnTYazgYj23Gh1yKdzwuAnt3pEKXnx4WMAzqWRmp53Aod2PsBVDNbtmds8F99sAmEUT4u3NLKjSfpOFK4WY4ajL9AgfK7pwNbuiSs2MnoGPtrQVy9FbODTRQWgvkgI7lYynQB11yVOAhRNirmu2NnUANmwod2w195vh0kLI9LMr2sgFB/Y5fzhhHNWtBdTKNmYBXRK++Px2mdA8rJpCA/3GuM+bg0wTKonALnYyfQGMWmbeAnlPGTUCU0HNNkcOrG+MKLwoYst+GQe2K4MoPoSHzHSYRCgZu3RwlXNCr32cSqcRP4oe1oeEvrAUaMpYziVJJp2zrJd/cb5cUmN2/n5CPoWIpULcC+FEJNKRNRGgLfRVLIcyRHDG7JcX3TEmnbeviB17Ob5BobbelWh6S4i5INO1YU1jlRhmDYFlXVPlSmCsptkBcW863Jmn5D2JHXtT/hrScPystt3kEABGi1CoKCMk/lhsQXhibnCcp7RPXihH2KufPMBW9ixcW/rRVNnnVfSMp6fbCZRSdxJUT6+EoXSZGaAt9XvjjD2asPVirxXffa5BXU9WTp9O+tzDwp/hlUi1v8QfvzfEeJGlMU6A+STrSBqkPvFTLw96wy/uQ/315i2fg0q4vowsPvCbR8k1B4SdT1o/lJUawR/V488gNMUJb6BPG/K1hz5hwuPZbjOh3TH9jHYoav0Rr2x0W/DyytaoBbkO27w89zfzp6yCVam2PFhKeq2zEC0NOgO8O7vN58Uu1/MaFhP+4uh7js678jIxBn74ocoFyW8nkYUvO8GCr7Vh1firvnpvoDxdYmCusWXXejo/qf8Uz/fYQ397cCK4rk4JCwpCVBYPaAT03Cj13aezGp1uHXa6Mxy7nVxc6nUO3HznR7xjEyF+B4oR94AGq2p3Yd9UhSk4CJ+vs2H/VgXNmJ0zWqTVPrK3w/Dzx0P5z6yDcxn2z4F8acjxuY+7ekFcZBrXNn/3Lls+CQAfO0KADzXVVaKqtDDjnxR+FE/CtX2PEZceoowduY673bM+VbXjW04zHLud4OAJ+P74XxjxYLZ142GFCd/tdb7ru3kZ32x2x32q5h8EB6wy44SACwtw+yz30mVrg+O0sLHdvefd92HYToy0aPLt5CINdLeLcYac99P+MfMHv1JeIAHOJ8eJ5tP1yHQM9XbME+h90oa3lGm5crJvzvs21lehQKGBWKDxhC8z5EJ/8eb3Je7i3HWlqIxB4mL1GXBRJCmQycIsXz0iRTBbxfh3/JZTAQLBX2fPStLAPapPLcVQmw84lS8QIbZNcvsACl7nNISRaQj5IDMN0KBSHg0DkM/+H6pDL1Wa5/JpZoXhqViiedCgUzeaVKzPizfWqmAWofwHysgqbOMPO4gAAAABJRU5ErkJggg==" />
    ),
    route: '/personal-info'
  },
  {
    text: 'Result',
    icon: (
      <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACF0lEQVR4nO2ZX0vbUBjGA36Mtdf9IMtRoa7ibkbjhGLO9WCiCN7LBtUlXgpKC4l0rebUC7HgnfgN/APuD2w31RYW0aoFvTnjHJrxTtvaJY1m8v7gpQTSvM9zzhNy4FWUEFgolOOG7WyYttMQZdiMGdZmQvkfWCiU46bFXNNm/K+ymLtkOTEl6hhy5aXorcV86YUQbVrOdstESYk6powN43C15a7YjBu2c/EkogbfZOJE0zfUNG0QjXKv7sUE1Nyn5aT3/xljOdXtXgKeKXqoGi0PjU8m+iZe1agLm/RiIJv7fCNMCPHZXOmmVwPEM6LpZ6J3YANi5ds1eMjAvxTp8Hyi0eDvDIzN0ZfvHNIvA5CD428wTsHfGbgid1lhlcDiV1nl3nNhz1AN/KjWAplYZRX+s1p7OgNhQdAAAHfABwQjBMAIdeH6tsk/7mW523SjH6HTyxqf3/0gRXvi321P8Zf5IT67M/f4BgaKVw8W5H1lWooVot3m2Z/r9PoEP2mcRN9A/arO3zoZKfrV2lhH8ZGNkBejpJ2S4kfsUXndjsgaEOzXD3lq7bX87USkDfQCQQMA3AEfEIwQACPUhWs8C/UAnoVa4FnIL3gW8gHBDxkAP2Q+IBih5xQhFQw4xPAhbA6OvwID+nlwAxotdxkBhVx6MbABMS0UA7dHF5+mv4bHaayPY1ZaEjOrsIWrsode7Jt4BHnm/AaINYYdA+v/VgAAAABJRU5ErkJggg==" />
    ),
    route: '/study-result'
  }
];

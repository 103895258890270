import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

export const ItemButtonStyle = styled(Button)`
  margin-top: 2px;
  margin-bottom: 2px;
  width: ${(props) => props.w || '60%'};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`;

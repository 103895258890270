import React, { useState } from 'react';
import HelpIcon from '@mui/icons-material/Help';
import Tour from 'reactour';
import { BUTTON_COLOR } from 'src/theme/color';
import { Box, Modal } from '@mui/material';
import { SingleTourStyle } from './index.style';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  height: '90%',
  bgcolor: 'background.paper'
};

const CustomSingleTour = ({ tourUI, tourConfig }) => {
  const [isOpenTour, setIsOpenTour] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  // handle modal
  const handleOpenModal = () => {
    setIsOpenModal(true);
  };
  const handleCloseModal = () => setIsOpenModal(false);

  // handle tour
  const handleCloseTour = () => {
    setIsOpenTour(false);
  };

  const handleOpenTour = () => {
    setIsOpenTour(true);
  };

  // hanlde click
  const hanldeClick = () => {
    handleOpenModal();
    handleOpenTour();
  };

  return (
    <SingleTourStyle>
      {tourConfig ? <HelpIcon onClick={() => hanldeClick()} /> : <></>}
      <Tour
        onRequestClose={() => handleCloseTour()}
        steps={tourConfig}
        isOpen={isOpenTour}
        className="helper"
        rounded={5}
        accentColor={BUTTON_COLOR}
      />
      <Modal
        open={isOpenModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>{tourUI}</Box>
      </Modal>
    </SingleTourStyle>
  );
};

export default CustomSingleTour;

import React from 'react';
import BeforeLearningLayout from 'src/containers/Layout/BeforeLearningLayout';
import { Container, Box } from '@mui/material';
import { pairQuizList } from '../../containers/QuizContainer/PairQuizContainer/mock.data';
import PairQuizContainer from 'src/containers/QuizContainer/PairQuizContainer';

const PairQuizPage = () => {
  return (
    <BeforeLearningLayout>
      <Container maxWidth="md">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: '15px'
          }}>
          <PairQuizContainer data={pairQuizList[1]} />
        </Box>
      </Container>
    </BeforeLearningLayout>
  );
};

export default PairQuizPage;

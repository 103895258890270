export const lessonActionTypes = {
  SET_CURRENT_SLIDE: 'SET_CURRENT_SLIDE',
  SET_DISABLE_NEXT: 'SET_DISABLE_NEXT',
  SET_DISABLE_PREV: 'SET_DISABLE_PREV',
  SET_SLIDE: 'SET_SLIDE',
  OVERVIEW_AUDIO_PAUSE: 'OVERVIEW_AUDIO_PAUSE',

  SET_OPEN_CONFIRM: 'SET_OPEN_CONFIRM',
  IS_ALLOW_CONFIM: 'IS_ALLOW_CONFIM',
  SET_CONFIRM_URL: 'SET_CONFIRM_URL',

  SET_CURRENT_LESSON: 'SET_CURRENT_LESSON'
};

export const setCurrentSlide = (data) => {
  return {
    type: lessonActionTypes.SET_CURRENT_SLIDE,
    payload: data
  };
};

export const setDisableNext = (data) => {
  return {
    type: lessonActionTypes.SET_DISABLE_NEXT,
    payload: data
  };
};

export const setDisablePrev = (data) => {
  return {
    type: lessonActionTypes.SET_DISABLE_PREV,
    payload: data
  };
};

export const setSlide = (data) => {
  return {
    type: lessonActionTypes.SET_SLIDE,
    payload: data
  };
};

export const setOverviewAudioPause = (data) => {
  return {
    type: lessonActionTypes.OVERVIEW_AUDIO_PAUSE,
    payload: data
  };
};

export const setOpenConfirm = (data) => {
  return {
    type: lessonActionTypes.SET_OPEN_CONFIRM,
    payload: data
  };
};

export const setAllowConfirm = (data) => {
  return {
    type: lessonActionTypes.IS_ALLOW_CONFIM,
    payload: data
  };
};

export const setConfirmURL = (data) => {
  return {
    type: lessonActionTypes.SET_CONFIRM_URL,
    payload: data
  };
};

export const setCurrentLesson = (data) => {
  return {
    type: lessonActionTypes.SET_CURRENT_LESSON,
    payload: data
  };
};

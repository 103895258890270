// mui library
import { Box, Typography } from '@mui/material';

// written components
import SpaceBox, { TabBox } from '../../XSpaceBox';
import { BlankTopBox } from '../DropBox/TopBox';

// written style
import { LineBox, ToneImg } from './index.style';

// images and constants
import RISING from '../../../../../assets/images/tone/rising.png';
import BROKEN from '../../../../../assets/images/tone/broken.png';
import CURVE from '../../../../../assets/images/tone/curve.png';
import FALLING from '../../../../../assets/images/tone/falling.png';
import DROP from '../../../../../assets/images/tone/drop.png';
import { TONE } from '../../../../../constants/quiz';

const PhonemeLine = ({ lineData, returnItemsForBox }) => {
  return (
    <LineBox>
      <Box className="line">
        {lineData.rhymes.map((e, index) => {
          if (e === 'space') return <SpaceBox key={index} />;
          else if (e === 'tab') return <TabBox key={index} />;
          else
            return (
              <Box className="rhyme-text" key={index}>
                <Typography variant="h1">{e}</Typography>
              </Box>
            );
        })}
      </Box>

      <Box className="line">
        {lineData.tonesAbove.map((e, index) => {
          switch (e) {
            case TONE.BROKEN:
              return <ToneImg src={BROKEN} key={index} />;
            case TONE.RISING:
              return <ToneImg src={RISING} key={index} />;
            case TONE.FALLING:
              return <ToneImg src={FALLING} key={index} />;
            case TONE.CURVE:
              return <ToneImg src={CURVE} key={index} />;
            case 'tab':
              return <TabBox key={index} />;
            default:
              return <SpaceBox key={index} />;
          }
        })}
      </Box>

      <Box className="line">
        {lineData.boxes.map((e, index) => {
          if (e === 'tab') return <TabBox key={index} />;
          else
            return (
              <BlankTopBox key={index} boxId={e.boxId} chosen={e.chosen} boxValue={e.boxValue}>
                {returnItemsForBox(e.boxId)}
              </BlankTopBox>
            );
        })}
      </Box>

      <Box className="drop-tone-line">
        {lineData.tonesBelow.map((e, index) => {
          switch (e) {
            case TONE.DROP:
              return <ToneImg src={DROP} key={index} />;
            case 'tab':
              return <TabBox key={index} />;
            default:
              return <SpaceBox key={index} />;
          }
        })}
      </Box>
    </LineBox>
  );
};

export default PhonemeLine;

export const LessonTourConfig = [
  // theory config
  [
    {
      selector: '[data-tut="theory__title"]',
      content: 'Đầu tiên, đây là tiêu đề của bài học.'
    },
    {
      selector: '[data-tut="theory__title-audio"]',
      content: 'Bạn có thể click vào các icon audio này để phát đoạn text.'
    },
    {
      selector: '[data-tut="theory__read"]',
      content:
        'Lý thuyết có 4 dạng chính. Đầu tiên là dạng đọc, bạn có thể thay đổi các chế độ đọc đoạn text.'
    },
    {
      selector: '[data-tut="theory__read--mode"]',
      content: 'Bạn có thể thay đổi chế độ đọc tại đây.'
    },
    {
      selector: '[data-tut="theory__listen--mode"]',
      content: 'Bạn cũng có thể thay đổi chế độ nghe tại đây.'
    },
    {
      selector: '[data-tut="text-detail"]',
      content:
        'Đối với chế độ đọc tiếng và từ, khi click vào tiếng/từ sẽ hiển thị cho bạn thông tin chi tiết về tiếng/từ đó.'
    },
    {
      selector: '[data-tut="add-note"]',
      content: 'Bạn có thể ghi chú lại những từ quan trọng khi click vào đây.'
    },
    {
      selector: '[data-tut="note-icon"]',
      content: 'Click vào đây để hiển thị toàn bộ các tiếng/từ đã ghi chú.'
    },
    {
      selector: '[data-tut="note-content"]',
      content: 'Đây là danh sách các tiếng/từ được lưu lại.'
    },
    {
      selector: '[data-tut="note-item"]',
      content:
        'Khi di chuột vào tiếng/từ cũng sẽ hiển thị thông tin chi tiết về tiếng/từ đó, hoặc bạn cũng có thể xóa nó khỏi danh sách.'
    },
    {
      selector: '[data-tut="theory__square"]',
      content:
        'Tiếp theo là dạng ô vuông. Với dạng này sẽ có nhiều dạng khác nhau hơn, chúng ta sẽ tiếp tục tìm hiểu trong các bài học sau.'
    },
    {
      selector: '[data-tut="theory__video"]',
      content: 'Tiếp theo là dạng video, chúng ta có thể xem bài học tại đây.'
    },
    {
      selector: '[data-tut="theory__img"]',
      content: 'Cuối cùng là dạng ảnh, bài học sẽ hiển thị dưới dạng ảnh.'
    },
    {
      selector: '[data-tut="theory__btn"]',
      content:
        'Cuối cùng là 2 button chuyển tiếp ở 2 bên giúp chúng ta có thể quay trở lại bài học trước hoặc chuyển sang bài học tiếp theo sau khi học xong bài học hiện tại.'
    }
  ],
  // quiz config
  [
    {
      selector: '[data-tut="lesson__title"]',
      content: 'Đầu tiên, đây là đường dẫn đến đến khóa học hiện tại của chúng ta.'
    }
  ]
];

// mui
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

// react * i18 multi languages
import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// custom layout
import AuthLayout from 'src/containers/Layout/AuthLayout';
import { LoginStyle } from './index.style';

// navigation & api
import { useNavigate, useLocation } from 'react-router-dom';
import { login } from 'src/apis/auth';
import { setCookie, getCookie } from 'src/utils/cookie';
import { setStorage } from 'src/utils/storage';
import jwtDecode from 'jwt-decode';

//sound
import useSound from 'use-sound';
import LoginAudio from 'src/assets/audio/login/login.wav';
import MailOrUsernameAudio from 'src/assets/audio/login/mail_or_username.wav';
import PasswordAudio from 'src/assets/audio/login/password.wav';
import WelcomeBack from 'src/assets/audio/login/welcome_back.wav';

const LoginContainer = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const [password, setPassword] = useState('');
  const [emailOrUsername, setEmailOrUsername] = useState('');
  const emailRef = useRef();
  const passRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const [playLoginAudio] = useSound(LoginAudio);
  const [playMailOrUsernameAudio] = useSound(MailOrUsernameAudio);
  const [playPasswordAudio] = useSound(PasswordAudio);
  const [playWelcomeBackAudio] = useSound(WelcomeBack);

  const { t } = useTranslation();

  useEffect(() => {
    if (location.state !== null && location.state.messsage !== undefined) {
      setErrorMessage(t(`${location.state.messsage}`));
      return;
    }
    const accessToken = getCookie('accessToken');
    const refreshToken = getCookie('refreshToken');
    if (accessToken !== '' && refreshToken !== '') {
      const decodeResult = jwtDecode(refreshToken);
      const compareValue = new Date().getTime() > decodeResult.exp * 1000;
      if (compareValue) {
        setCookie('accessToken', '');
        setCookie('refreshToken', '');
      } else {
        navigate('/');
      }
    }
  }, []);

  const handleLogin = async () => {
    setErrorMessage('');
    if (emailOrUsername === '' || password === '') {
      setErrorMessage(t('two_field_cant_empty'));
    } else {
      setIsLoading(true);
      const response = await login('v1', emailOrUsername.trim(), password.trim());
      if (response.result.accessToken === '') {
        setIsLoading(false);
        setErrorMessage(t(`${response.result.errorMessage}`));
      } else {
        setIsLoading(false);
        setCookie('accessToken', response.result.accessToken);
        setCookie('refreshToken', response.result.refreshToken);
        setStorage({ key: 'userRole', data: ['student'] });
        navigate('/');
      }
    }
  };

  const forgotPassword = () => navigate('/input-email');

  return (
    <AuthLayout>
      <LoginStyle>
        <div className="login-header">
          <div className="login-header__title">
            <Typography variant="h1" onClick={playLoginAudio} sx={{ cursor: 'pointer' }}>
              {t('login')}
            </Typography>
          </div>
          <div
            className="header__content"
            onClick={playWelcomeBackAudio}
            style={{ cursor: 'pointer' }}>
            {t('welcome_back')}
          </div>
        </div>
        <div className="login-content">
          <div className="login-content__email">
            <div className="login-content__title">
              <Typography variant="h3" onClick={playMailOrUsernameAudio} sx={{ cursor: 'pointer' }}>
                {t('email_or_username')}
              </Typography>
            </div>
            <div className="login-content__input">
              <input
                type="email"
                ref={emailRef}
                placeholder={`${t('for_example')}: lananh@gmail.com`}
                onChange={(e) => setEmailOrUsername(e.target.value)}
                value={emailOrUsername}
              />
            </div>
          </div>
          <div className="login-content__password">
            <div className="login-content__title">
              <Typography variant="h3" onClick={playPasswordAudio} sx={{ cursor: 'pointer' }}>
                {t('password')}
              </Typography>
            </div>
            <div className="login-content__input">
              <input
                type={isVisible ? 'text' : 'password'}
                ref={passRef}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <span className="visible-icon" onClick={() => setIsVisible(!isVisible)}>
                {isVisible ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
              </span>
            </div>
          </div>
          <div className="error-message">{errorMessage}</div>
          <Button variant="contained" type="submit" onClick={() => handleLogin()}>
            {!isLoading ? t('login') : <CircularProgress sx={{ color: 'white' }} size="1.25rem" />}
          </Button>
        </div>
        <Box sx={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
          <Button variant="text" sx={{ textTransform: 'none' }} onClick={forgotPassword}>
            <Typography variant="h3">{t('forgot_pw')}</Typography>
          </Button>
        </Box>
        <div className="login-footer">
          <a href="/register">{t('register_account')}</a>
        </div>
      </LoginStyle>
    </AuthLayout>
  );
};

export default LoginContainer;

import { lessonActionTypes } from './actions';

export const lessonInitState = {
  currentSlideIndex: 0,
  slide: [],
  disableNext: false,
  disablePrev: true,
  isOverviewAudioPause: false,
  currentLesson: null,

  // confirm
  isOpenConfirm: false,
  allowConfirm: false,
  confirmUrl: ''
};

const lessonReducer = (state = lessonInitState, action) => {
  switch (action.type) {
    case lessonActionTypes.SET_SLIDE:
      return {
        ...state,
        slide: action.payload
      };

    case lessonActionTypes.SET_DISABLE_NEXT:
      return {
        ...state,
        disableNext: action.payload
      };

    case lessonActionTypes.SET_DISABLE_PREV:
      return {
        ...state,
        disablePrev: action.payload
      };

    case lessonActionTypes.SET_CURRENT_SLIDE:
      return {
        ...state,
        currentSlideIndex: action.payload
      };

    case lessonActionTypes.OVERVIEW_AUDIO_PAUSE:
      return {
        ...state,
        isOverviewAudioPause: action.payload
      };

    case lessonActionTypes.SET_OPEN_CONFIRM:
      return {
        ...state,
        isOpenConfirm: action.payload
      };

    case lessonActionTypes.IS_ALLOW_CONFIM:
      return {
        ...state,
        allowConfirm: action.payload
      };

    case lessonActionTypes.SET_CONFIRM_URL:
      return {
        ...state,
        confirmUrl: action.payload
      };

    case lessonActionTypes.SET_CURRENT_LESSON:
      return {
        ...state,
        currentLesson: action.payload
      };

    default:
      return state;
  }
};

export default lessonReducer;

// react & core libraries
import React, { useEffect, useState } from 'react';
import Xarrow from 'react-xarrows';
import Scrollbars from 'react-custom-scrollbars-2';

// customed ui
import { PQBox } from './index.style';
import QuestionBox from '../ToolParts/QuestionBox';
import Item from './Item';
import { BlinkXArrow } from '../ToolParts/BlinkArrow';

// customed logic functions
import { createPairs, getAbleHints } from './index.logic';
import { filterArrBySet } from '../../../utils/array';

// mui
import { Box, Fab } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ReplayIcon from '@mui/icons-material/Replay';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import ResponsiveDialog from '../confirm-dialog';

// react dnd
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';

// sounding effect & redux
import useSound from 'use-sound';
import useHint from '../../../assets/audio/use_hint.mp3';
import { useDispatch } from 'react-redux';
import { setDisableNext } from 'src/redux/lesson/actions';

const PairQuizContainer = ({ data }) => {
  const isMobile = window.innerWidth < 600;
  const [arrows, setArrows] = useState([]);
  const [replayNum, setReplayNum] = useState(4);
  const [hintNum, setHintNum] = useState(2);
  const [open, setOpen] = useState(false);
  const [showHintArrow, setShowHintArrow] = useState(false);
  const [hintArrow, setHintArrow] = useState({});
  const [playHintSound] = useSound(useHint);
  const [leftPairs, setLeftPairs] = useState([]);
  const [rightPairs, setRightPairs] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    reset();
  }, []);

  useEffect(() => {
    const filteredArrows = filterArrBySet(arrows);
    if (filteredArrows.length !== arrows.length) {
      setArrows([...filteredArrows]);
    }
    const counterTrue = filteredArrows.filter((e) => e.arrowColor === 'green');
    if (counterTrue.length >= leftPairs.length) {
      dispatch(setDisableNext(false));
    }
  }, [arrows]);

  const showFullAnswers = () => {
    setOpen(false);
    const ableHint = getAbleHints(arrows, leftPairs);
    const addedArrows = ableHint.map((e) => {
      return { start: `${e}-left`, end: `${e}-right`, arrowColor: 'green' };
    });
    setArrows([...arrows, ...addedArrows]);

    // border of left-right pairs !!
    setLeftPairs((prev) => {
      return prev.map((e) => {
        return { ...e, chosen: 'correct' };
      });
    });

    setRightPairs((prev) => {
      return prev.map((e) => {
        return { ...e, chosen: 'correct' };
      });
    });
  };

  const reset = () => {
    if (replayNum !== 0) {
      setReplayNum(replayNum - 1);
      setHintNum(2);
      const pairs = createPairs(data.quizParts, data._id);
      setLeftPairs(pairs.leftPairs);
      setRightPairs(pairs.rightPairs);
      setArrows([]);
    } else {
      // play wrong warning
    }
  };
  const showHint = () => {
    if (hintNum !== 0) {
      setHintNum(hintNum - 1);
      const ableHint = getAbleHints(arrows, leftPairs);
      const randHint = ableHint[Math.floor(Math.random() * ableHint.length)];

      setHintArrow({ start: `${randHint}-left`, end: `${randHint}-right` });
      setShowHintArrow(true);
      setTimeout(() => {
        setHintArrow({});
        setShowHintArrow(false);
      }, 2000);
    } else {
      // play warning sound
      setOpen(true);
    }
    playHintSound();
  };

  const showInstruction = () => {};

  return (
    <PQBox image={data.backgroundUrl} opacity={data.backgroundOpacity}>
      <QuestionBox
        imgUrl={data.questionImageUrl}
        audioUrl={data.questionAudioUrl}
        question={data.question}
      />

      <Scrollbars autoHeight autoHeightMax={400}>
        <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
          {leftPairs.map((ele, index) => {
            return (
              <Box className="line" key={index}>
                {[
                  { data: ele, dot: 'left' },
                  { data: rightPairs[index], dot: 'right' }
                ].map((p) => {
                  return (
                    <Item
                      key={p.dot}
                      data={p.data}
                      dot={p.dot}
                      arrows={arrows}
                      setArrows={setArrows}
                      leftPairs={leftPairs}
                      setLeftPairs={setLeftPairs}
                      rightPairs={rightPairs}
                      setRightPairs={setRightPairs}
                    />
                  );
                })}
              </Box>
            );
          })}

          {arrows.map((ar, index) => (
            <Xarrow
              key={index}
              start={ar.start}
              end={ar.end}
              showHead={false}
              color={ar.arrowColor}
              strokeWidth={2}
            />
          ))}

          {showHintArrow ? (
            <BlinkXArrow
              color="#87CEFA"
              strokeWidth={4}
              start={hintArrow.start}
              end={hintArrow.end}
            />
          ) : (
            <></>
          )}
        </DndProvider>
      </Scrollbars>
      <Fab
        color="warning"
        size="small"
        sx={{ position: 'absolute', right: '10px', bottom: '110px' }}
        onClick={showHint}>
        {hintNum}
        <TipsAndUpdatesIcon />
      </Fab>
      <Fab
        color="warning"
        size="small"
        sx={{ position: 'absolute', right: '10px', bottom: '60px' }}
        onClick={showInstruction}>
        <HelpOutlineIcon />
      </Fab>
      <Fab
        color="warning"
        size="small"
        sx={{ position: 'absolute', right: '10px', bottom: '10px' }}
        onClick={reset}>
        {replayNum}
        <ReplayIcon />
      </Fab>
      <ResponsiveDialog open={open} showFullAnswers={showFullAnswers} />
    </PQBox>
  );
};

export default PairQuizContainer;

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NoteStyle } from './index.style';
import NoteIcon from 'src/assets/images/icons/note.png';
import { Badge, styled, Tooltip, tooltipClasses, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CreateIcon from '@mui/icons-material/Create';
import ClearIcon from '@mui/icons-material/Clear';
import { setNewNotes } from 'src/redux/note/actions';
import AudioButton from '../AudioButton';
import { checkTone } from 'src/utils';
import { PARAMS_TONE } from 'src/constants/theory';

const Note = () => {
  const [isShowNote, setIsShowNote] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const noteLists = useSelector((state) => state.note.noteLists);

  // render list line default
  let listItemsDefault = [];
  for (let i = 0; i < 8 - noteLists?.length; i++) {
    listItemsDefault.push(
      <div className="note__content__item note__content__item--default">
        <div className="pen-icon">
          <CreateIcon />
        </div>
      </div>
    );
  }

  // show note
  const handleShowNote = () => {
    setIsShowNote(!isShowNote);
  };

  // delete note
  const handleDeleteNote = (currentText) => {
    const existNoteIndex = noteLists.findIndex(
      (element) => currentText.toLowerCase().localeCompare(element.text.toLowerCase()) === 0
    );
    if (existNoteIndex > -1) {
      noteLists.splice(existNoteIndex, 1);
      dispatch(setNewNotes(noteLists));
    }
  };

  //
  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(16),
      border: '1px solid #dadde9'
    }
  }));

  const handleCheckTone = (text) => {
    const wordDetail = noteLists[0].textParams.find((note) => note.text === text);
    console.log('wordDetail', wordDetail);
    if (Array.isArray(wordDetail?.children)) {
      let tonesElm = [];
      for (let i = 0; i < wordDetail?.children.length; i++) {
        if (i === 1) {
          tonesElm.push(checkTone(PARAMS_TONE[wordDetail?.children[i].text[3]]) || 'ー');
        } else {
          tonesElm.push(checkTone(PARAMS_TONE[wordDetail?.children[i].text[3 + i * 5]]) || 'ー');
        }
      }
      return <>{tonesElm}</>;
    } else {
      return (
        checkTone(PARAMS_TONE[wordDetail?.children?.text[wordDetail?.children?.text.length - 1]]) ||
        'ー'
      );
    }
  };

  return (
    <NoteStyle isShowNote={isShowNote} noteListsLenght={noteLists.length}>
      <Badge
        onClick={handleShowNote}
        badgeContent={noteLists.length}
        color="primary"
        className={
          noteLists.length > 0 && !isShowNote
            ? 'animate__animated animate__swing animate__infinite animate__slow'
            : ''
        }>
        <img src={NoteIcon} alt="note icon" />
      </Badge>
      <div className={`note ${isShowNote ? 'show' : ''}`}>
        <div className="note__title">
          <Typography variant="h2">{t('note')}</Typography>
        </div>
        <div className="note__content">
          {noteLists.map((item, index) => (
            <div className="note__content__item" key={`${item.text}-${index}`}>
              <HtmlTooltip
                placement="left"
                title={<React.Fragment>Thanh điệu: {handleCheckTone(item.text)}</React.Fragment>}>
                <span className="item--text">{item.text}</span>
              </HtmlTooltip>
              <AudioButton
                size={'24px'}
                audioText={item.text}
                audioUrl={item.audioUrl}
                audioRate={1}
                audioSpeaker={item.speaker}
                needChangeVoice={false}
              />
              <div className="clear-icon" onClick={() => handleDeleteNote(item.text)}>
                <ClearIcon />
              </div>
            </div>
          ))}
          {listItemsDefault}
        </div>
      </div>
    </NoteStyle>
  );
};

export default Note;

import { QUIZ_TYPE } from '../../../constants/quiz';
import BlankQuizTestContainer from './BlankQuizTestContainer';
import MCQuizTestContainer from './MCQuizTestContainer';
import PairQuizTestContainer from './PairQuizTestContainer';
import SquareQuizTestContainer from './SquareQuizTestContainer';

const TestQuizContainer = ({ data }) => {
  switch (data.type) {
    case QUIZ_TYPE.MC_QUIZ:
      return <MCQuizTestContainer data={data} />;
    case QUIZ_TYPE.BLANK_QUIZ:
      return <BlankQuizTestContainer data={data} />;
    case QUIZ_TYPE.PAIR_QUIZ:
      return <PairQuizTestContainer data={data} />;
    case QUIZ_TYPE.SQUARE_QUIZ:
      return <SquareQuizTestContainer data={data} />;
    default:
      return <></>;
  }
};

export default TestQuizContainer;

import React from 'react';
import Square from '../../SquareBox';
import { SQUARE_COLOR } from 'src/styles/color';

const PhonemeInRhyme = ({ data }) => {
  const { text, rhymeText, startRhyme, mainRhyme, finishRhyme } = data;

  return (
    <table>
      <tbody>
        <tr>
          <td colSpan={3}>
            <h3>
              {' '}
              {text} =&gt; {rhymeText}
            </h3>
          </td>
        </tr>
        <tr>
          <td>
            <Square color={SQUARE_COLOR.greenery}>{startRhyme}</Square>
          </td>
          <td>
            <Square color={SQUARE_COLOR.violet}>{mainRhyme}</Square>
          </td>
          <td>
            <Square color={SQUARE_COLOR.pink}>{finishRhyme}</Square>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default PhonemeInRhyme;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import useSound from 'use-sound';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setOpenConfirm, setAllowConfirm } from 'src/redux/lesson/actions';
import { setShowNote } from 'src/redux/note/actions';

import ConfirmExitLessonAudio from 'src/assets/audio/ConfirmExitLesson.wav';

const ConfirmExitLesson = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isOpenConfirm = useSelector((state) => state.lesson.isOpenConfirm);
  const allowConfirm = useSelector((state) => state.lesson.allowConfirm);
  const confirmUrl = useSelector((state) => state.lesson.confirmUrl);
  const [ConfirmAudio] = useSound(ConfirmExitLessonAudio);

  const handleCancel = () => {
    dispatch(setOpenConfirm(false));
  };

  const handleAgree = () => {
    dispatch(setOpenConfirm(false));
    dispatch(setAllowConfirm(false));
    dispatch(setShowNote(false));
    navigate(confirmUrl);
  };

  return (
    <Dialog
      open={isOpenConfirm && allowConfirm}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title" onClick={ConfirmAudio}>
        {'Bạn có chắc muốn thoát khỏi bài học chứ ?'}
      </DialogTitle>
      <DialogActions>
        <Button onClick={handleCancel}>Không</Button>
        <Button onClick={handleAgree} autoFocus>
          Đồng ý
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmExitLesson;

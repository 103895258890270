// react & mui
import { useEffect, useState } from 'react';
import { Fab } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ReplayIcon from '@mui/icons-material/Replay';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';

// customed ui
import QuestionBox from '../ToolParts/QuestionBox';
import { getTrueAnswers } from './index.logic';
import { MCBox } from './index.style';
import RadioAnswers from './RadioAnswers';
import CheckboxAnswers from './CheckboxAnswers';
import ResponsiveDialog from '../confirm-dialog';

// voice & sound
import useSound from 'use-sound';
import useHint from '../../../assets/audio/use_hint.mp3';

// redux
import { useDispatch } from 'react-redux';
import { setDisableNext } from 'src/redux/lesson/actions';

const MCQuizContainer = ({ data }) => {
  // in order to render
  const [mcType, setMcType] = useState('multiple');
  const [replayNum, setReplayNum] = useState(3);
  const [hintNum, setHintNum] = useState(2);
  const [open, setOpen] = useState(false);
  const [isHint, setIsHint] = useState(false);
  const [isHintCheckbox, setIsHintCheckbox] = useState(false);
  const [randIdxCheckbox, setRandIdxCheckbox] = useState(-1);
  const [playHintSound] = useSound(useHint);
  const dispatch = useDispatch();

  // handle logic for one true answer = radio button
  const [radioTrueIndex, setRadioTrueIndex] = useState(-1);
  const [currentChosenIndex, setCurrentChosenIndex] = useState(-1);

  // handle logic for multiple true answers = checkbox button
  const [checkboxTrueIndexes, setCheckboxTrueIndexes] = useState([]);
  const [currentChosenIndexes, setCurrentChosenIndexes] = useState([]);

  useEffect(() => {
    const result = getTrueAnswers(data.quizParts);
    if (result.type === 'one') {
      setMcType('one');
      setRadioTrueIndex(result.trueAnswer);
    } else {
      setMcType('multiple');
      setCheckboxTrueIndexes(result.trueAnswer);
    }
  }, []);

  const showHint = () => {
    if (hintNum !== 0) {
      setHintNum(hintNum - 1);
      if (mcType === 'one') {
        setIsHint(!isHint);
      } else {
        const absentIdxs = checkboxTrueIndexes.filter((e) => !currentChosenIndexes.includes(e));
        if (absentIdxs.length !== 0) {
          if (absentIdxs.length === 1) {
            setIsHintCheckbox(!isHintCheckbox);
            setRandIdxCheckbox(absentIdxs[0]);
          } else {
            const randValue = absentIdxs[Math.floor(Math.random() * absentIdxs.length)];
            setIsHintCheckbox(!isHintCheckbox);
            setRandIdxCheckbox(randValue);
          }
        }
      }
      playHintSound();
    } else {
      setOpen(true);
      // play warning sound();
    }
  };

  const showInstruction = () => {};

  const reset = () => {
    if (replayNum !== 0) {
      setHintNum(2);
      setReplayNum(replayNum - 1);
      if (mcType === 'one') {
        setCurrentChosenIndex(-1);
      } else setCurrentChosenIndexes([]);
    } else {
      // play warning sound();
    }
  };

  const showFullAnswers = () => {
    if (mcType === 'one') {
      setCurrentChosenIndex(radioTrueIndex);
      dispatch(setDisableNext(false));
    } else {
      setCurrentChosenIndexes(checkboxTrueIndexes);
      dispatch(setDisableNext(false));
    }
    playHintSound();
    setOpen(false);
  };

  // REAL: audioUrl={data.questionAudioUrl}
  return (
    <MCBox image={data.backgroundUrl} opacity={data.backgroundOpacity}>
      <QuestionBox
        imgUrl={data.questionImageUrl}
        audioUrl={data.questionAudioUrl}
        question={data.question}
      />
      {mcType === 'one' ? (
        <RadioAnswers
          options={data.quizParts}
          trueAnswer={radioTrueIndex}
          currentChosenIndex={currentChosenIndex}
          setCurrentChosenIndex={setCurrentChosenIndex}
          isHint={isHint}
        />
      ) : (
        <CheckboxAnswers
          options={data.quizParts}
          trueAnswers={checkboxTrueIndexes}
          currentChosenIndexes={currentChosenIndexes}
          setCurrentChosenIndexes={setCurrentChosenIndexes}
          isHintCheckbox={isHintCheckbox}
          randIndex={randIdxCheckbox}
        />
      )}
      <Fab
        color="warning"
        size="small"
        sx={{ position: 'absolute', right: '10px', bottom: '110px' }}
        onClick={showHint}>
        {hintNum}
        <TipsAndUpdatesIcon />
      </Fab>
      <Fab
        color="warning"
        size="small"
        sx={{ position: 'absolute', right: '10px', bottom: '60px' }}
        onClick={showInstruction}>
        <HelpOutlineIcon />
      </Fab>
      <Fab
        color="warning"
        size="small"
        sx={{ position: 'absolute', right: '10px', bottom: '10px' }}
        onClick={reset}>
        {replayNum}
        <ReplayIcon />
      </Fab>
      <ResponsiveDialog open={open} showFullAnswers={showFullAnswers} />
    </MCBox>
  );
};

export default MCQuizContainer;

import { Box, styled } from '@mui/material';
import { BUTTON_COLOR, FLOATING_BOX_COLOR, BORDER_COLOR } from 'src/theme/color';

export const SectionBoxStyle = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .sectionContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: ${(props) => (props.canLearn ? 'pointer' : 'default')};
    filter: ${(props) => (props.canLearn ? 'none' : 'contrast(0.65)')};
  }

  .Title {
    max-width: 200px;
    overflow: true;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    &.title_class {
      display: none;
    }
  }

  .Content {
    border: 2px solid ${BUTTON_COLOR};
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${FLOATING_BOX_COLOR};
    padding: 8px;
    position: relative;

    .ChapterBoxStar {
      position: absolute;
      height: 16px;
      width: 16px;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${FLOATING_BOX_COLOR};
      color: ${BORDER_COLOR};
      border-radius: 50%;
      border: 2px solid ${BORDER_COLOR};
      right: -8px;
      top: -16px;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
`;

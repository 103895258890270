// React
import { useTranslation } from 'react-i18next';

// MUI
import { Container } from '@mui/material';

// Smaller components
import { SupportLanguageBar, SystemLanguageBar } from './LanguageBar';

// data
import { explainations } from './index.style';
import AppLayout from '../Layout/AppLayout/AppLayout';

const SettingContainer = () => {
  const { t } = useTranslation();

  const renderSettings = () => {
    return (
      <Container maxWidth={false} sx={{ maxWidth: '86%', marginTop: { xl: '50px' } }}>
        <SystemLanguageBar
          bigText={t(explainations[0].bigText)}
          smallText={t(explainations[0].smallText)}
          space={0}
        />
        <SupportLanguageBar
          bigText={t(explainations[1].bigText)}
          smallText={t(explainations[1].smallText)}
          space={0}
        />
      </Container>
    );
  };

  return <AppLayout activeMenu="Settings" renderMain={renderSettings} />;
};

export default SettingContainer;

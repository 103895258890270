import api from './api';
import { getHeaders, processExpired } from './auth';

export const getCourseGeneralInfo = async (version = 'v1') => {
  const accessToken = await processExpired();
  return await api.get(`${version}/courses`, {
    headers: getHeaders(accessToken)
  });
};

export const getChaptersByCourseId = async (courseId) => {
  const accessToken = await processExpired();
  return await api.get(`v2/courses/${courseId}`, {
    headers: getHeaders(accessToken)
  });
};

export const getAllCourses = async () => {
  return await (
    await api.get('v1/chapters')
  ).result;
};

export const getExtractCourse = async (courseId) => {
  return await (
    await api.get(`v1/chapters/${courseId}`)
  ).result.chapter;
};

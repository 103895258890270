import React, { useEffect, useState, useMemo } from 'react';
import { Grid, Typography } from '@mui/material';
import TheoryContent from '../../TheoryContent';
import { TheoryContentStyle } from '../../index.style';
import AudioButton from 'src/components/AudioButton';
import Scrollbars from 'react-custom-scrollbars-2';
import { useSelector } from 'react-redux';

const LayoutForTwo = ({ slideIndex, data }) => {
  const currentSlideIndex = useSelector((state) => state.lesson.currentSlideIndex);
  let audio;

  const audioUrls = useMemo(
    () => data.customLayout?.children.map((el) => el.mediaObject.audioUrl),
    [data.customLayout?.children]
  );

  const [currentAudio, setCurrentAduio] = useState(0);

  const handleAudioEnded = () => {
    setCurrentAduio((prev) => prev + 1);
  };

  useEffect(() => {
    if (slideIndex === currentSlideIndex - 1) {
      if (currentAudio >= audioUrls.length) return;

      audio = document.getElementById(`audio-${audioUrls[currentAudio]}`);
      if (!audio) {
        handleAudioEnded();
      } else {
        audio.addEventListener('ended', handleAudioEnded);
        audio.play();

        return () => {
          audio.removeEventListener('ended', handleAudioEnded);
        };
      }
    }
  }, [currentSlideIndex, data, currentAudio]);

  return (
    <TheoryContentStyle>
      <div className="title">
        <AudioButton
          size={'24px'}
          audioUrl={data.titleAudioUrl}
          audioText={data.title}
          audioRate={data.rateVoice}
          audioSpeaker={data.titleVoice}
        />
        <Typography variant="h0">{data.title}</Typography>
      </div>
      <div className="title-line"></div>
      {data.customLayout.layout === 'vertical' ? (
        <Scrollbars className="scroll-content">
          <Grid container height="90%" margin="0">
            <Grid item xs={12} md={12} style={{ minHeight: '45%' }}>
              <TheoryContent
                slideIndex={slideIndex}
                mediaObject={data.customLayout?.children[0]?.mediaObject}
                text={data.customLayout?.children[0]?.mediaObject?.text}
                squareObject={data.customLayout?.children[0]?.mediaObject?.squareObject}
                playAudio={currentAudio === 0 && slideIndex === currentSlideIndex - 1}
              />
            </Grid>
            <Grid item xs={12} md={12} style={{ minHeight: '45%' }}>
              <TheoryContent
                slideIndex={slideIndex}
                mediaObject={data.customLayout?.children[1]?.mediaObject}
                text={data.customLayout?.children[1]?.mediaObject?.text}
                squareObject={data.customLayout?.children[1]?.mediaObject?.squareObject}
                playAudio={currentAudio === 1 && slideIndex === currentSlideIndex - 1}
              />
            </Grid>
          </Grid>
        </Scrollbars>
      ) : (
        <Scrollbars className="scroll-content">
          <Grid container height="90%" margin="0">
            <Grid item xs={12} md={6} style={{ minHeight: '90%' }}>
              <TheoryContent
                slideIndex={slideIndex}
                mediaObject={data.customLayout?.children[0]?.mediaObject}
                text={data.customLayout?.children[0]?.mediaObject?.text}
                squareObject={data.customLayout?.children[0]?.mediaObject?.squareObject}
                playAudio={currentAudio === 0 && slideIndex === currentSlideIndex - 1}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ minHeight: '90%' }}>
              <TheoryContent
                slideIndex={slideIndex}
                mediaObject={data.customLayout?.children[1]?.mediaObject}
                text={data.customLayout?.children[1]?.mediaObject?.text}
                squareObject={data.customLayout?.children[1]?.mediaObject?.squareObject}
                playAudio={currentAudio === 1 && slideIndex === currentSlideIndex - 1}
              />
            </Grid>
          </Grid>
        </Scrollbars>
      )}
    </TheoryContentStyle>
  );
};

export default LayoutForTwo;

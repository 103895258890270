// npm lib & react
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';

// styled components
import BottomBox from './DropBox/BottomBox';
import MediaBox from './MediaBox';
import { BlankTopBox, TextTopBox } from './DropBox/TopBox';
import { BQTestBox } from './index.style';

// logic functions
import { extractTopList, extractBtmList, extractTrueAnswerNumber } from './index.logic';
import { shuffleArray } from '../../../../utils/shuffle';

import QuestionBox from '../../../QuizContainer/ToolParts/QuestionBox';

// redux
import { useDispatch } from 'react-redux';
import { setDisableTestNext } from 'src/redux/test/action';

const BlankQuizTestContainer = ({ data }) => {
  const isMobile = window.innerWidth < 600;
  const [topList, setTopList] = useState([]);
  const [btmList, setBtmList] = useState([]);
  const [trueAnswers, setTrueAnswers] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    const tList = extractTopList(data.quizParts, data._id);
    setTopList(tList);

    const bList = extractBtmList(data.quizParts, data._id);
    const shuffledBList = shuffleArray(bList);
    setBtmList(shuffledBList);

    const number = extractTrueAnswerNumber(data.quizParts);
    setTrueAnswers(number);
  }, []);

  useEffect(() => {
    const blankBox = topList.filter((e) => e.chosen === 'none' && e.chosen !== undefined);

    if (blankBox.length === 0) {
      dispatch(setDisableTestNext(false));
    }
  }, [topList]);

  const returnItemsForBox = (boxId) => {
    return btmList
      .filter((item) => item.belongTo === boxId)
      .map((e, index) => (
        <MediaBox
          key={index}
          answerId={e.answerId}
          media={e.media}
          belongTo={e.belongTo}
          setItems={setBtmList}
          topList={topList}
          setChosen={setTopList}
        />
      ));
  };

  return (
    <BQTestBox image={data.backgroundUrl} opacity={data.backgroundOpacity}>
      <QuestionBox
        imgUrl={data.questionImageUrl}
        audioUrl={data.questionAudioUrl}
        question={data.question}
      />

      <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
        <Box className="dnd-sector">
          <Box
            className="scrollable"
            sx={{
              '&::-webkit-scrollbar': {
                maxWidth: '8px',
                maxHeight: '8px'
              },
              '&::-webkit-scrollbar-track': {
                background: 'transparent'
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#CDC5BD',
                borderRadius: '10px'
              },
              '&::-webkit-scrollbar-thumb:hover': {
                background: '#555'
              }
            }}>
            <Box className="top-sector">
              {topList.map((ele, index) => {
                if (ele.media !== 'blank') return <TextTopBox key={index} media={ele.media} />;
                else
                  return (
                    <BlankTopBox key={index} boxId={ele.boxId} chosen={ele.chosen}>
                      {returnItemsForBox(ele.boxId)}
                    </BlankTopBox>
                  );
              })}
            </Box>
          </Box>

          <BottomBox boxId="bottom-box">{returnItemsForBox('bottom-box')}</BottomBox>
        </Box>
      </DndProvider>
    </BQTestBox>
  );
};

export default BlankQuizTestContainer;

import { styled } from '@mui/material/styles';
import { FLOATING_BOX_COLOR } from 'src/theme/color';

export const DisplayMediaBox = styled('div')`
  display: flex;
  flex-direction: row;
  min-width: 46px;
  min-height: 38.5px;
  align-items: center;
  justify-content: center;
  background-color: ${FLOATING_BOX_COLOR};
  border: ${(props) => (props.isBorder === true ? '1.5px solid black' : '')};
  border-radius: 15%;
  margin-bottom: ${(props) => (props.isBorder === true ? '8px' : '')};
`;

//${(props) => props.isShadow === true ? "1px 1px 6px rgba(0,0,0,0.5)" : ""};

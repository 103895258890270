import React from 'react';
import { PageLayoutStyle } from './index.style';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Stack from '@mui/material/Stack';
import Progress from './Progress';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { AppBar } from '@mui/material';
import VietStudy from 'src/assets/images/logo/viet_study_logo.png';
import { TOUR_TYPE } from 'src/constants';
import CustomSingleTour from 'src/components/CustomTour/CustomSingleTour';
import CustomMultiTour from 'src/components/CustomTour/CustomMultiTour';

import { useSelector } from 'react-redux';
import ConfirmExitLesson from 'src/components/ConfirmExitLesson';
import Note from 'src/components/Note';

const PageLayout = (props) => {
  const {
    children,
    breadcrumbs,
    progressDisplay,
    completed,
    logoDisplay,
    tourConfig,
    tourUI,
    tourType,
    actions
  } = props;
  const isShowNote = useSelector((state) => state.note.isShowNote);

  return (
    <PageLayoutStyle logoDisplay={logoDisplay}>
      <AppBar position="static">
        <Stack>
          <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="large" />}>
            {breadcrumbs}
          </Breadcrumbs>
        </Stack>
        <div className="end">
          <img className="logo" src={VietStudy} />
          <Progress progressDisplay={progressDisplay} completed={completed} />

          {tourType === TOUR_TYPE.SINGLE ? (
            <CustomSingleTour tourConfig={tourConfig} tourUI={tourUI} />
          ) : (
            <CustomMultiTour tourConfig={tourConfig} tourUI={tourUI} actions={actions} />
          )}
        </div>
      </AppBar>
      <div className="PageContent">
        {isShowNote ? <Note /> : ''}
        {children}
      </div>
      <ConfirmExitLesson url={'/courses'} />
    </PageLayoutStyle>
  );
};

export default PageLayout;

import axios from 'axios';
// import camelCase from 'camelcase-keys';
// import { toast } from 'react-toastify';
import { API_URL } from 'src/configs';

const axiosClient = axios.create({
  baseURL: `${API_URL}/api/`,
  responseType: 'json',
  timeout: 15 * 1000
  // transformResponse: [(data) => camelCase(data, { deep: true })],
});

axiosClient.interceptors.request.use(async (config) => {
  return config;
});

axiosClient.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    console.error('error', error.response ? error.response.data : error.response);
    return error.response ? error.response.data : error.response;
  }
);

export default axiosClient;

import { styled } from '@mui/material/styles';
import { Container } from '@mui/material';
import { BOX_SHADOW } from '../../../styles/config';
import { BUTTON_COLOR, LESSON_BOX_COLOR, SIDEBAR_COLOR } from '../../../theme/color';

export const ChapterBoxStyle = styled(Container)`
  display: flex;
  justify-content: center;

  .MuiBox-root {
    width: 86%;
    max-width: 280px;
    height: 230px;
    padding: 6px 12px;
    max-height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    position: relative;
    margin: 40px 0;
    padding: 12px;
    background-color: white;
    border: 2px solid ${BUTTON_COLOR};
    border-radius: 10px;
    box-sizing: border-box;
    box-shadow: ${BOX_SHADOW};
    cursor: pointer;
    filter: ${(props) => (props.canLearn ? 'none' : 'contrast(0.8)')};

    .ChapterBoxStar {
      position: absolute;
      height: 35px;
      width: 35px;
      padding: 12.5px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${LESSON_BOX_COLOR};
      color: ${SIDEBAR_COLOR};
      border-radius: 50%;
      border: 2px solid ${SIDEBAR_COLOR};
      right: -12px;
      top: -16px;
    }

    .ChapterBoxNumber {
      width: 40px;
      height: 40px;
      padding: 6px;
      font-size: 30px;
      font-weight: bold;
      background-color: ${BUTTON_COLOR};
      color: white;
      border: none;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .ChapterBoxTitle {
      font-weight: bold;
      text-align: center;
      max-width: 110px;
      overflow: true;
    }

    .lock-elm {
      position: absolute;
      color: ${BUTTON_COLOR};

      svg {
        font-size: 40px;
      }
    }
  }
`;

import { TONE } from '../../../../constants/quiz';

export const findSyllableLines = (list) => {
  return list.map((e) => e.content.text);
};

export const findBtmList = (list, quizId) => {
  let answers = [];
  let string = '';
  for (let i = 0; i < list.length; i++) {
    for (let j = 0; j < list[i].squareQuizParts.length; j++) {
      string = list[i].squareQuizParts[j].structure.rhyme.structure.startRhyme;
      if (string !== '') {
        answers.push({
          answerId: `${quizId}-${i}-${j}-start`,
          answerValue: string,
          media: {
            text: string
          },
          belongTo: 'bottom-box'
        });
      }

      string = list[i].squareQuizParts[j].structure.rhyme.structure.mainRhyme;
      if (string !== '') {
        answers.push({
          answerId: `${quizId}-${i}-${j}-main`,
          answerValue: string,
          media: {
            text: string
          },
          belongTo: 'bottom-box'
        });
      }

      string = list[i].squareQuizParts[j].structure.rhyme.structure.finishRhyme;
      if (string !== '') {
        answers.push({
          answerId: `${quizId}-${i}-${j}-finish`,
          answerValue: string,
          media: {
            text: string
          },
          belongTo: 'bottom-box'
        });
      }
    }
  }
  return answers;
};
// (right-answer-duplication-check required)

export const findTopList = (list, quizId) => {
  let topList = [];
  let rhymes = [],
    tonesAbove = [],
    boxes = [],
    tonesBelow = [];

  for (let i = 0; i < list.length; i++) {
    rhymes = [];
    tonesAbove = [];
    boxes = [];
    tonesBelow = [];
    for (let j = 0; j < list[i].squareQuizParts.length; j++) {
      // ["space", "iec", "space", "tab", "space", "uyen", "space", "\n"]
      rhymes.push('space', list[i].squareQuizParts[j].structure.rhyme.content.text, 'space', 'tab');

      // tones
      if (list[i].squareQuizParts[j].structure.tone === TONE.DROP) {
        tonesAbove.push('space', 'space', 'space', 'tab');
        tonesBelow.push('space', TONE.DROP, 'space', 'tab');
      } else {
        tonesAbove.push('space', list[i].squareQuizParts[j].structure.tone, 'space', 'tab');
        tonesBelow.push('space', 'space', 'space', 'tab');
      }

      // blank boxes
      boxes.push(
        {
          boxId: `${quizId}-${i}-${j}-start`,
          boxValue: list[i].squareQuizParts[j].structure.rhyme.structure.startRhyme,
          chosen: 'none'
        },
        {
          boxId: `${quizId}-${i}-${j}-main`,
          boxValue: list[i].squareQuizParts[j].structure.rhyme.structure.mainRhyme,
          chosen: 'none'
        },
        {
          boxId: `${quizId}-${i}-${j}-finish`,
          boxValue: list[i].squareQuizParts[j].structure.rhyme.structure.finishRhyme,
          chosen: 'none'
        },
        'tab'
      );
    }
    topList.push({ rhymes, tonesAbove, boxes, tonesBelow });
  }
  return topList;
};

export const showAnswers = (setTopList, setBtmList) => {
  // const ids = btmBoxItems.map(e => e.answerId);
  setTopList((prev) => {
    return prev.map((e) => {
      return {
        ...e,
        boxes: e.boxes.map((ele) => {
          if (ele === 'tab' || ele === '\n') return ele;
          else
            return {
              ...ele,
              chosen: 'correct'
            };
        })
      };
    });
  });
  setBtmList((prev) => {
    return prev.map((e) => {
      return {
        ...e,
        belongTo: e.answerId
      };
    });
  });
};

export const countCorrect = (topList) => {
  let counter = 0;
  for (let i = 0; i < topList.length; i++) {
    const boxes = topList[i].boxes;

    for (let i1 = 0; i1 < boxes.length; i1++) {
      if (boxes[i1].chosen === 'correct') {
        counter += 1;
      }
    }
  }
  return counter;
};

import BeforeLearningLayout from 'src/containers/Layout/BeforeLearningLayout';
import { Container, Box } from '@mui/material';
import { quizzes } from './SlideMockData';

import Slider from 'react-slick';
import QuizContainer from 'src/containers/QuizContainer';
import { useRef } from 'react';

const SlidePage = () => {
  const slider = useRef();

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    touchMove: false
  };

  return (
    <BeforeLearningLayout>
      <Container maxWidth={false} sx={{ width: '82.5%', marginTop: '30px' }}>
        <Slider {...settings} ref={slider}>
          {quizzes.map((e) => {
            return (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
                key={e.id}>
                <QuizContainer data={e} />
              </Box>
            );
          })}
        </Slider>
      </Container>
    </BeforeLearningLayout>
  );
};

export default SlidePage;

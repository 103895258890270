// react + redux
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDisableNext, setDisablePrev, setCurrentSlide } from 'src/redux/lesson/actions';

// slick
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// custom components
import { SlideStyle, ArrowStyle } from './index.style';

// mui
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';

import congrat from 'src/assets/audio/congratulation.mp3';
import useSound from 'use-sound';
import { setAudioPlay } from 'src/redux/checkpoint/actions';
import { setOverviewAudioPause } from 'src/redux/lesson/actions';
import { setShowNote } from 'src/redux/note/actions';

const VSSlide = ({ data }) => {
  const dispatch = useDispatch();
  const slideRef = useRef();

  const currentSlideIndex = useSelector((state) => state.lesson.currentSlideIndex);
  const slide = useSelector((state) => state.lesson.slide);
  const disableNext = useSelector((state) => state.lesson.disableNext);
  const disablePrev = useSelector((state) => state.lesson.disablePrev);
  const [listLearnedIndex, setListLearnedIndex] = useState([]);

  const navigate = useNavigate();
  const [playCongrat] = useSound(congrat);

  const settings = {
    arrows: false,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    touchMove: false
  };

  useEffect(() => {
    if (currentSlideIndex === 0) {
      dispatch(setDisablePrev(true));
    }
  });

  const onClickPrev = () => {
    dispatch(setDisablePrev(false));
    dispatch(setCurrentSlide(currentSlideIndex - 1));
    if (listLearnedIndex.includes(currentSlideIndex - 1)) {
      dispatch(setDisableNext(false));
    }
    // pause audio of checkpoint if prev
    if (slide[currentSlideIndex] === 'checkpoint') {
      dispatch(setAudioPlay(false));
    }
    // set show note
    if (slide[currentSlideIndex - 1] === 'theory') {
      dispatch(setShowNote(true));
    } else {
      dispatch(setShowNote(false));
    }

    slideRef.current.slickPrev();
  };

  const onClickNext = async () => {
    dispatch(setDisablePrev(false));
    const nextIndex = currentSlideIndex + 1;

    if (nextIndex >= slide.length) {
      playCongrat();
      dispatch(setOverviewAudioPause(false));
      dispatch(setShowNote(false));
      // updateNotes();
      // handle update result
      navigate('/congratulations');
      return;
    }

    if (currentSlideIndex === 0) {
      dispatch(setOverviewAudioPause(true));
    }

    if (listLearnedIndex.includes(currentSlideIndex)) {
      dispatch(setDisableNext(false));
    } else {
      setListLearnedIndex([...listLearnedIndex, currentSlideIndex]);
      if (slide[nextIndex] === 'quiz') {
        dispatch(setDisableNext(true));
      } else if (slide[nextIndex] === 'theory') {
        dispatch(setDisableNext(true));
        setTimeout(() => {
          dispatch(setDisableNext(false));
        }, 5000);
      }
    }
    // always nextable and play checkpoint audio
    if (slide[nextIndex] === 'checkpoint') {
      dispatch(setAudioPlay(true));
      dispatch(setDisableNext(false));
    }
    // pause audio of checkpoint if next
    if (slide[currentSlideIndex] === 'checkpoint') {
      dispatch(setAudioPlay(false));
    }
    dispatch(setCurrentSlide(nextIndex));

    // set show note
    if (slide[nextIndex] === 'theory') {
      dispatch(setShowNote(true));
    } else {
      dispatch(setShowNote(false));
    }

    slideRef.current.slickNext();
  };

  // const updateNotes = async () => {
  //   currentLessonData.notes = noteLists;
  //   await updateLessonById('v1', currentSection.lessonId, currentLessonData);
  // };

  const arrows = () => {
    return (
      <ArrowStyle className="slider-arrow" disableNext={disableNext} disablePrev={disablePrev}>
        <button className="arrow-btn prev-btn" onClick={onClickPrev} disabled={disablePrev}>
          <ArrowBackIosIcon />
        </button>

        <button className="arrow-btn next-btn" onClick={onClickNext} disabled={disableNext}>
          <ArrowForwardIosIcon />
        </button>
      </ArrowStyle>
    );
  };

  return (
    <>
      {arrows()}
      <SlideStyle ref={slideRef} {...settings}>
        {data}
      </SlideStyle>
    </>
  );
};

export default VSSlide;

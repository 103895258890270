// basic libs
import React from 'react';

// Redux and persistor
import { Provider } from 'react-redux';
import store from './redux/store';

// UI, multiple routes
import KLRoutes from './router';
import 'animate.css';
import learning_theme from './theme/learning-theme';
import { ThemeProvider } from '@mui/material';
import { CssBaseline } from '@mui/material';
import { ToastContainer } from 'react-toastify';

const App = () => {
  return (
    <ThemeProvider theme={learning_theme}>
      <CssBaseline />
      <Provider store={store()}>
        {/* <PersistGate loading={null} persistor={persistor}> */}
        <KLRoutes />
        {/* </PersistGate> */}
      </Provider>
      <ToastContainer />
    </ThemeProvider>
  );
};

export default App;

import { styled } from '@mui/material/styles';

// TWS = Text With Sound
export const TWSBox = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  background: ${(props) => (props.clicked === true ? '#87CEFA' : '')};
  cursor: pointer;
`;

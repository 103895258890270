import { createSlice } from '@reduxjs/toolkit';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import _map from 'lodash/map';
import _keyBy from 'lodash/keyBy';

// apis
import { getAll, purchase } from '../../../apis/award-admin/collectionItem';

// initial state
const initialState = {
  metadata: {},
  ids: [],
  data: {},
  isLoading: false
};

// slice
const collectionItem = createSlice({
  name: 'collectionItem',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setMetadata(state, action) {
      state.metadata = action.payload;
    },
    setCollectionItemIds(state, action) {
      state.ids = action.payload;
    },
    setCollectionItems(state, action) {
      state.data = action.payload;
    },
    // collection item
    setCollectionItem(state, action) {
      state.data[action.payload.id] = { ...state.data[action.payload.id], ...action.payload };
    }
  }
});

// actions
export const {
  setLoading,
  setMetadata,
  setCollectionItemIds,
  setCollectionItems,
  setCollectionItem
} = collectionItem.actions;

// get all collection items
export const getAllCollectionItems =
  ({ page, limit, keyword, categoryId, rarity, userId, excludeOwnedItem } = {}) =>
  async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const res = await getAll({
        page,
        limit,
        keyword,
        categoryId,
        rarity,
        userId,
        excludeOwnedItem
      });

      if (!res.success) toast.error(`Lỗi: ${res.message}`);
      else {
        dispatch(setMetadata(res.results?.metadata));
        dispatch(setCollectionItemIds(_map(res.results?.collectionItems, '_id')));
        dispatch(setCollectionItems(_keyBy(res.results?.collectionItems, '_id')));
      }
    } catch (error) {
      console.error(error);
      toast.error(`Lỗi: ${error.message}`);
      dispatch(setMetadata(initialState.metadata));
      dispatch(setCollectionItemIds(initialState.ids));
      dispatch(setCollectionItems(initialState.data));
    }

    dispatch(setLoading(false));
  };

// purchase collection items
export const purchaseCollectionItems =
  ({ userId, collectionItemIds } = {}) =>
  async (dispatch, getState) => {
    const { user = {} } = getState().auth;

    let result = false;

    dispatch(setLoading(true));

    try {
      const res = await purchase({
        userId,
        userFullName: [user.result?.firstName || '', user.result?.lastName || ''].join(' '),
        userEmail: user.result?.email,
        collectionItemIds
      });

      if (!res.success) {
        toast.error(`Lỗi: ${res.message}`);
      } else {
        result = true;
        // mark collection as owned
        collectionItemIds.map((collectionItemId) => {
          dispatch(
            setCollectionItem({
              id: collectionItemId,
              isOwned: true
            })
          );
        });
        toast.success('Mua thành công');
      }
    } catch (error) {
      console.error(error);
      toast.error(`Lỗi: ${error.message}`);
    }

    dispatch(setLoading(false));

    return result;
  };

export default collectionItem.reducer;

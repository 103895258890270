// react
import { useEffect, useState } from 'react';

// mui
import { Box, Button, Typography } from '@mui/material';

// customed ui
import { WordBox } from './index.style';

// sound
import useSound from 'use-sound';
import ting from '../../../../../../assets/audio/ting.mp3';

const WordColumn = ({ data, isReset, setChosenNum, chosenNum }) => {
  const [display, setDisplay] = useState(false);
  const [playSound] = useSound(ting);

  const handleClick = () => {
    setDisplay(!display);
    setChosenNum(chosenNum + 1);
    playSound();
  };

  useEffect(() => {
    setDisplay(false);
  }, [isReset]);

  return (
    <WordBox>
      <Box className="word-text">
        {/* <Tooltip title={<h3>Nhấn vào tiếng</h3>}> */}
        <Button onClick={handleClick} sx={{ textTransform: 'none', color: 'black' }}>
          <Typography variant="h1">{data}</Typography>
        </Button>
        {/* </Tooltip> */}
      </Box>

      {display === false ? <Box className="blank"></Box> : <Box className="square"></Box>}
    </WordBox>
  );
};

export default WordColumn;

import { styled } from '@mui/material/styles';
import { FLOATING_BOX_COLOR } from 'src/theme/color';
import { BLANK_BOX_HEIGHT, BLANK_BOX_WIDTH } from '../index.style';

const calculateHeight = (height) => {
  const heightString = height.substring(0, 2);
  return parseInt(heightString) - 4 + 'px';
};

const calculateWidth = (width) => {
  const widthString = width.substring(0, 3);
  return parseInt(widthString) - 4 + 'px';
};

export const DisplayMediaBox = styled('div')`
  display: flex;
  flex-direction: row;
  min-width: ${calculateWidth(BLANK_BOX_WIDTH)};
  min-height: ${calculateHeight(BLANK_BOX_HEIGHT)};
  align-items: center;
  justify-content: center;
  background-color: ${FLOATING_BOX_COLOR};
  border: ${(props) => (props.isBorder ? '1px solid black' : '')};
  border-radius: 10px;
  margin-bottom: ${(props) => (props.isBorder ? '8px' : '')};
`;

export const pathActionType = {
  SET_PATHS: 'SET_PATHS',
  SET_CURRENT_PATH: 'SET_CURRENT_PATH'
};

export const setAllPaths = (data) => {
  return {
    type: pathActionType.SET_PATHS,
    payload: data
  };
};

export const setCurrentPath = (data) => {
  return {
    type: pathActionType.SET_CURRENT_PATH,
    payload: data
  };
};

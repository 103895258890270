// react & mui
import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';

// customed ui
import { StyledBreadcrumb, StyledTypography } from '../Layout/PageLayout/index.style';
import LessonLayout from '../Layout/LessonLayout';
import PageLayout from '../Layout/PageLayout';
import VSSlide from 'src/components/VSSlide';
import { LessonTourConfig } from './LessonTour/tour-config';
import Spinner from 'src/components/Spinner';
import LessonOverview from './LessonOverview';

// logic function & call apis
import { getStorage } from 'src/utils/storage';
import { getLessonById } from 'src/apis/lesson';
import { lessonTour, actions, buildContainer } from './index.logic';

// redux
import { useDispatch, useSelector } from 'react-redux';
import {
  setSlide,
  setCurrentSlide,
  setOpenConfirm,
  setAllowConfirm,
  setConfirmURL
} from 'src/redux/lesson/actions';
import { setNewNotes } from 'src/redux/note/actions';
import { TOUR_TYPE } from 'src/constants';
import { setListCheckpointsLength } from 'src/redux/checkpoint/actions';
import ConfirmExitLesson from 'src/components/ConfirmExitLesson';

const LessonContainer = () => {
  const dispatcher = useDispatch();
  const currentCourse = getStorage('currentCourse');
  const chapterTitle = getStorage('currentChapter').title;
  const currentSection = getStorage('currentSection');
  const currentSlideIndex = useSelector((state) => state.lesson.currentSlideIndex);
  const [combinedData, setCombinedData] = useState([]);

  const [currentLessonData, setCurrentLessonData] = useState();

  const handleOpenConfirm = () => {
    dispatcher(setOpenConfirm(true));
    dispatcher(setConfirmURL(`/courses/${currentCourse.id}/chapters`));
  };

  const breadcrumbs = [
    <StyledBreadcrumb key="2" underline="none" color="inherit" onClick={handleOpenConfirm}>
      <StyledTypography variant="h2">{currentCourse.title}</StyledTypography>
    </StyledBreadcrumb>,
    <Typography key="3" underline="none" color="inherit" variant="h2">
      {chapterTitle}
    </Typography>
  ];

  useEffect(() => {
    const fetchLessonData = async (lessonId) => {
      const fetchedData = await getLessonById('v1', lessonId);
      const fetchedLesson = fetchedData.result.Lesson;
      setCurrentLessonData(fetchedLesson);
      let checkpointsLength = 0;
      fetchedLesson.learningPoints.forEach(
        (elm) => elm.type === 'checkpoint' ?? checkpointsLength++
      );
      dispatcher(setListCheckpointsLength(checkpointsLength));

      const { containers, slides } = buildContainer(fetchedLesson.learningPoints);
      // const overview = await getLessonOverviewById('v1', fetchedLesson.overviewId._id);
      // setCombinedData(containers);
      // dispatcher(setSlide(slides));

      setCombinedData([
        <LessonOverview key={999} overview={fetchedLesson.overview} />,
        ...containers
      ]);
      dispatcher(setSlide(['overview', ...slides]));
      dispatcher(setCurrentSlide(0));
      dispatcher(setAllowConfirm(true));
      if (fetchedLesson?.notes) {
        dispatcher(setNewNotes(fetchedLesson?.notes));
      }
    };

    fetchLessonData(currentSection.lessonId);
  }, []);

  return combinedData && combinedData.length !== 0 ? (
    <LessonLayout
      breadcrumbs={breadcrumbs}
      logoDisplay={'none'}
      completed={
        currentSlideIndex === 0 ? '0%' : `${((currentSlideIndex + 1) / combinedData.length) * 100}%`
      }
      tourConfig={LessonTourConfig}
      tourUI={lessonTour}
      actions={actions}
      tourType={TOUR_TYPE.MULTI}>
      <div style={{ width: '100%', height: '100%' }}>
        <VSSlide data={combinedData} currentLessonData={currentLessonData} />
      </div>
      <ConfirmExitLesson />
    </LessonLayout>
  ) : (
    <PageLayout breadcrumbs={breadcrumbs} logoDisplay={'none'} progressDisplay={'none'}>
      <Spinner />
    </PageLayout>
  );
};

export default LessonContainer;

// react & core libraries
import { useState } from 'react';
import { useDrag } from 'react-dnd';

// customed ui & logic
import { isOneSide, isOnePair, isArrowExisted, handleArrows, changeChosen } from '../index.logic';
import { ConnectDotBox } from './index.style';

// sound
import correct from '../../../../assets/audio/correct.mp3';
import useSound from 'use-sound';

const ConnectDot = ({ dotPosition, boxId, arrows, setArrows, setLeftPairs, setRightPairs }) => {
  const [playTrue] = useSound(correct);
  const [dotId, setDotId] = useState(`${boxId}-dot`);
  const [position, setPosition] = useState({});

  const [{ isDragging, offsetWhileDragging }, drag] = useDrag({
    item: { boxId: boxId },
    type: 'connect-dot',
    collect: (monitor) => {
      return {
        isDragging: monitor.isDragging()
        // offsetWhileDragging: monitor.getClientOffset(),
      };
    },
    end: (item, monitor) => {
      setPosition({});
      const dropResult = monitor.getDropResult();
      if (dropResult) {
        // compare to see 2 items belong to 1 side (left-left, right-right)
        const compareResult = isOneSide(item.boxId, dropResult.boxId);

        // compare to check arrow made by 2 boxId is existed or not
        const checkResult = isArrowExisted(item.boxId, dropResult.boxId, arrows);

        if (!compareResult && !checkResult) {
          const check = isOnePair(item.boxId, dropResult.boxId);
          handleArrows({
            newStart: item.boxId,
            newEnd: dropResult.boxId,
            arrows,
            setArrows,
            check: check
          });
          if (check) {
            changeChosen({
              setLeftPairs,
              setRightPairs,
              start: item.boxId,
              end: dropResult.boxId,
              check: true,
              arrows
            });
            playTrue();
          } else {
            changeChosen({
              setLeftPairs,
              setRightPairs,
              start: item.boxId,
              end: dropResult.boxId,
              check: false,
              arrows
            });
          }
        }
      }
    }
  });

  const opacity = isDragging ? 1 : 0.5;

  return (
    <>
      <ConnectDotBox
        id={dotId}
        ref={drag}
        dotPosition={dotPosition}
        opacity={opacity}
        sx={{ ...position }}
        onDrag={(e) => {
          setPosition({
            position: 'fixed',
            left: e.clientX,
            top: e.clientY,
            opacity: 0,
            backgroundColor: '#FF8B13'
          });
        }}></ConnectDotBox>
      {/* {
                isDragging ?
                    <Xarrow start={boxId} end={dotId}
                        showHead={false} color="black"
                        strokeWidth={2}
                    /> :
                    <></>
            } */}
    </>
  );
};

export default ConnectDot;

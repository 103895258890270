// dnd & mui libraries
import { useDrop } from 'react-dnd';
import { Typography } from '@mui/material';

// written components
import { DisplayTopBox } from './index.style';
import { CHOSEN_TEST_QUIZ_COLOR } from '../../../../../../../theme/color';

export const TextTopBox = ({ text, w }) => {
  return (
    <DisplayTopBox shadow={false} w={w}>
      <Typography variant="h2">{text}</Typography>
    </DisplayTopBox>
  );
};

export const BlankTopBox = ({ boxId, boxValue, children, chosen }) => {
  const [, drop] = useDrop({
    accept: 'phonemeOfSyllable',
    drop: () => ({ boxId, boxValue })
  });

  return (
    <DisplayTopBox
      radius={'10px'}
      isBorder={true}
      ref={drop}
      id={`${boxId}-top`}
      sx={{ border: chosen === 'chosen' ? `2px solid ${CHOSEN_TEST_QUIZ_COLOR}` : '' }}>
      {children}
    </DisplayTopBox>
  );
};

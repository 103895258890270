import school from '../../../assets/images/modules/quizBackground/school1.png';
import { TONE, MEDIA_TYPE, SQUARE_QUIZ_TYPE } from '../../../constants/quiz';
import { FLOATING_BOX_COLOR } from '../../../theme/color';

const OPACITY = {
  LOW: 0.3,
  MEDIUM: 0.6,
  HIGH: 0.6
};

export const squareQuizList = [
  {
    id: 0,
    inTestBank: false,
    title: 'Câu hỏi cho trẻ em lớp 1',
    backgroundUrl: school,
    backgroundOpacity: OPACITY.MEDIUM,
    textBackgroundColor: FLOATING_BOX_COLOR,
    question: 'Hãy kéo thả các thành phần của vần vào ô trống cho thích hợp.',
    voice: 'Hương Giang',
    rateVoice: 1.5,
    score: 25,
    type: 'square_quiz',
    squareQuizType: SQUARE_QUIZ_TYPE.PHONEME_OF_RHYME,
    quizParts: [
      {
        content: {
          text: 'triều đại',
          type: MEDIA_TYPE.TEXT
        },
        squareQuizParts: [
          {
            content: {
              text: 'triều',
              audioUrl: ''
            },
            structure: {
              consonant: 'tr',
              rhyme: {
                content: {
                  text: 'iêu',
                  audioUrl: ''
                },
                structure: {
                  startRhyme: '',
                  mainRhyme: 'iê',
                  finishRhyme: 'u'
                }
              },
              tone: TONE.FALLING
            }
          },
          {
            content: {
              text: 'đại',
              audioUrl: ''
            },
            structure: {
              consonant: 'đ',
              rhyme: {
                content: {
                  text: 'ai',
                  audioUrl: ''
                },
                structure: {
                  startRhyme: '',
                  mainRhyme: 'a',
                  finishRhyme: 'i'
                }
              },
              tone: TONE.DROP
            }
          }
        ]
      }
    ]
  },
  {
    id: 1,
    inTestBank: false,
    title: 'Câu hỏi cho trẻ em lớp 1',
    textBackgroundColor: FLOATING_BOX_COLOR,
    question: 'Hãy kéo thả các thành phần của vần vào ô trống cho thích hợp.',
    voice: 'Hương Giang',
    rateVoice: 1.5,
    score: 25,
    type: 'square_quiz',
    squareQuizType: SQUARE_QUIZ_TYPE.PHONEME_OF_RHYME,
    quizParts: [
      {
        content: {
          text: 'chiếc thuyền gỗ',
          type: MEDIA_TYPE.TEXT
        },
        squareQuizParts: [
          {
            content: {
              text: 'chiếc',
              audioUrl: ''
            },
            structure: {
              consonant: 'ch',
              rhyme: {
                content: {
                  text: 'iêc',
                  audioUrl: ''
                },
                structure: {
                  startRhyme: '',
                  mainRhyme: 'iê',
                  finishRhyme: 'c'
                }
              },
              tone: TONE.RISING
            }
          },
          {
            content: {
              text: 'thuyền',
              audioUrl: ''
            },
            structure: {
              consonant: 'th',
              rhyme: {
                content: {
                  text: 'uyên',
                  audioUrl: ''
                },
                structure: {
                  startRhyme: 'u',
                  mainRhyme: 'yê',
                  finishRhyme: 'n'
                }
              },
              tone: TONE.FALLING
            }
          },
          {
            content: {
              text: 'gỗ',
              audioUrl: ''
            },
            structure: {
              consonant: 'g',
              rhyme: {
                content: {
                  text: 'ô',
                  audioUrl: ''
                },
                structure: {
                  startRhyme: '',
                  mainRhyme: 'ô',
                  finishRhyme: ''
                }
              },
              tone: TONE.BROKEN
            }
          }
        ]
      }
    ]
  },
  {
    id: 2,
    inTestBank: false,
    title: 'Câu hỏi cho trẻ em lớp 1',
    textBackgroundColor: FLOATING_BOX_COLOR,
    question: 'Hãy kéo thả các thành phần của vần vào ô trống cho thích hợp.',
    voice: 'Hương Giang',
    rateVoice: 1.5,
    score: 25,
    type: 'square_quiz',
    squareQuizType: SQUARE_QUIZ_TYPE.PHONEME_OF_RHYME,
    quizParts: [
      {
        content: {
          text: 'Nghĩa mẹ như nước trong nguồn chảy ra',
          type: MEDIA_TYPE.TEXT
        },
        squareQuizParts: [
          {
            content: {
              text: 'nghĩa',
              audioUrl: ''
            },
            structure: {
              consonant: 'ngh',
              rhyme: {
                content: {
                  text: 'ĩa',
                  audioUrl: ''
                },
                structure: {
                  startRhyme: '',
                  mainRhyme: 'i',
                  finishRhyme: 'a'
                }
              },
              tone: TONE.BROKEN
            }
          },
          {
            content: {
              text: 'mẹ',
              audioUrl: ''
            },
            structure: {
              consonant: 'm',
              rhyme: {
                content: {
                  text: 'ẹ',
                  audioUrl: ''
                },
                structure: {
                  startRhyme: '',
                  mainRhyme: 'e',
                  finishRhyme: ''
                }
              },
              tone: TONE.DROP
            }
          },
          {
            content: {
              text: 'như',
              audioUrl: ''
            },
            structure: {
              consonant: 'nh',
              rhyme: {
                content: {
                  text: 'ư',
                  audioUrl: ''
                },
                structure: {
                  startRhyme: '',
                  mainRhyme: 'ư',
                  finishRhyme: ''
                }
              },
              tone: TONE.LEVEL
            }
          },
          {
            content: {
              text: 'nước',
              audioUrl: ''
            },
            structure: {
              consonant: 'n',
              rhyme: {
                content: {
                  text: 'ước',
                  audioUrl: ''
                },
                structure: {
                  startRhyme: 'ư',
                  mainRhyme: 'ơ',
                  finishRhyme: 'c'
                }
              },
              tone: TONE.RISING
            }
          },
          {
            content: {
              text: 'trong',
              audioUrl: ''
            },
            structure: {
              consonant: 'tr',
              rhyme: {
                content: {
                  text: 'ong',
                  audioUrl: ''
                },
                structure: {
                  startRhyme: '',
                  mainRhyme: 'o',
                  finishRhyme: 'ng'
                }
              },
              tone: TONE.LEVEL
            }
          },
          {
            content: {
              text: 'nguồn',
              audioUrl: ''
            },
            structure: {
              consonant: 'ng',
              rhyme: {
                content: {
                  text: 'uồn',
                  audioUrl: ''
                },
                structure: {
                  startRhyme: 'u',
                  mainRhyme: 'ô',
                  finishRhyme: 'n'
                }
              },
              tone: TONE.FALLING
            }
          },
          {
            content: {
              text: 'chảy',
              audioUrl: ''
            },
            structure: {
              consonant: 'ch',
              rhyme: {
                content: {
                  text: 'ảy',
                  audioUrl: ''
                },
                structure: {
                  startRhyme: '',
                  mainRhyme: 'a',
                  finishRhyme: 'y'
                }
              },
              tone: TONE.BROKEN
            }
          },
          {
            content: {
              text: 'ra',
              audioUrl: ''
            },
            structure: {
              consonant: 'r',
              rhyme: {
                content: {
                  text: 'a',
                  audioUrl: ''
                },
                structure: {
                  startRhyme: '',
                  mainRhyme: 'a',
                  finishRhyme: ''
                }
              },
              tone: TONE.LEVEL
            }
          }
        ]
      }
    ]
  },
  {
    id: 3,
    inTestBank: false,
    title: 'Câu hỏi cho trẻ em lớp 1',
    backgroundUrl: school,
    backgroundOpacity: OPACITY.MEDIUM,
    textBackgroundColor: FLOATING_BOX_COLOR,
    question: 'Kéo thả thành phần của tiếng sao cho thích hợp vào ô trống ?',
    voice: 'Hương Giang',
    rateVoice: 1.5,
    score: 25,
    type: 'square_quiz',
    squareQuizType: SQUARE_QUIZ_TYPE.PHONEME_OF_SYLLABLE,
    quizParts: [
      {
        content: {
          text: 'triều đại',
          type: MEDIA_TYPE.TEXT
        },
        squareQuizParts: [
          {
            content: {
              text: 'triều',
              audioUrl: ''
            },
            structure: {
              consonant: 'tr',
              rhyme: {
                content: {
                  text: 'iêu',
                  audioUrl: ''
                },
                structure: {
                  startRhyme: '',
                  mainRhyme: 'iê',
                  finishRhyme: 'u'
                }
              },
              tone: TONE.FALLING
            }
          },
          {
            content: {
              text: 'đại',
              audioUrl: ''
            },
            structure: {
              consonant: 'đ',
              rhyme: {
                content: {
                  text: 'ai',
                  audioUrl: ''
                },
                structure: {
                  startRhyme: '',
                  mainRhyme: 'a',
                  finishRhyme: 'i'
                }
              },
              tone: TONE.DROP
            }
          }
        ]
      }
    ]
  },
  {
    id: 4,
    inTestBank: false,
    title: 'Câu hỏi cho trẻ em lớp 1',
    backgroundUrl: school,
    backgroundOpacity: OPACITY.MEDIUM,
    textBackgroundColor: FLOATING_BOX_COLOR,
    question: 'Kéo thả thành phần của tiếng sao cho thích hợp vào ô trống ?',
    voice: 'Hương Giang',
    rateVoice: 1.5,
    score: 25,
    type: 'square_quiz',
    squareQuizType: SQUARE_QUIZ_TYPE.PHONEME_OF_SYLLABLE,
    quizParts: [
      {
        content: {
          text: 'chiếc thuyền ngoài xa',
          type: MEDIA_TYPE.TEXT
        },
        squareQuizParts: [
          {
            content: {
              text: 'chiếc',
              audioUrl: ''
            },
            structure: {
              consonant: 'ch',
              rhyme: {
                content: {
                  text: 'iêc',
                  audioUrl: ''
                },
                structure: {
                  startRhyme: '',
                  mainRhyme: 'iê',
                  finishRhyme: 'c'
                }
              },
              tone: TONE.RISING
            }
          },
          {
            content: {
              text: 'thuyền',
              audioUrl: ''
            },
            structure: {
              consonant: 'th',
              rhyme: {
                content: {
                  text: 'uyên',
                  audioUrl: ''
                },
                structure: {
                  startRhyme: 'u',
                  mainRhyme: 'yê',
                  finishRhyme: 'n'
                }
              },
              tone: TONE.FALLING
            }
          },
          {
            content: {
              text: 'ngoài',
              audioUrl: ''
            },
            structure: {
              consonant: 'ng',
              rhyme: {
                content: {
                  text: 'oai',
                  audioUrl: ''
                },
                structure: {
                  startRhyme: 'o',
                  mainRhyme: 'a',
                  finishRhyme: 'i'
                }
              },
              tone: TONE.FALLING
            }
          },
          {
            content: {
              text: 'xa',
              audioUrl: ''
            },
            structure: {
              consonant: 'x',
              rhyme: {
                content: {
                  text: 'a',
                  audioUrl: ''
                },
                structure: {
                  startRhyme: '',
                  mainRhyme: 'a',
                  finishRhyme: ''
                }
              },
              tone: TONE.LEVEL
            }
          }
        ]
      }
    ]
  },
  {
    id: 5,
    inTestBank: false,
    title: 'Câu hỏi cho trẻ em lớp 1',
    backgroundUrl: school,
    backgroundOpacity: OPACITY.MEDIUM,
    textBackgroundColor: FLOATING_BOX_COLOR,
    question: 'Hãy kéo thả các thành phần của tiếng và dấu thanh vào ô thích hợp.',
    voice: 'Hương Giang',
    rateVoice: 1.5,
    score: 25,
    type: 'square_quiz',
    squareQuizType: SQUARE_QUIZ_TYPE.ELEMENTS_OF_WORD,
    quizParts: [
      {
        content: {
          text: 'chiếc thuyền ngoài xa',
          type: MEDIA_TYPE.TEXT
        },
        squareQuizParts: [
          {
            content: {
              text: 'chiếc',
              audioUrl: ''
            },
            structure: {
              consonant: 'ch',
              rhyme: {
                content: {
                  text: 'iêc',
                  audioUrl: ''
                },
                structure: {
                  startRhyme: '',
                  mainRhyme: 'iê',
                  finishRhyme: 'c'
                }
              },
              tone: TONE.RISING
            }
          },
          {
            content: {
              text: 'thuyền',
              audioUrl: ''
            },
            structure: {
              consonant: 'th',
              rhyme: {
                content: {
                  text: 'uyên',
                  audioUrl: ''
                },
                structure: {
                  startRhyme: 'u',
                  mainRhyme: 'yê',
                  finishRhyme: 'n'
                }
              },
              tone: TONE.FALLING
            }
          },
          {
            content: {
              text: 'ngoài',
              audioUrl: ''
            },
            structure: {
              consonant: 'ng',
              rhyme: {
                content: {
                  text: 'oai',
                  audioUrl: ''
                },
                structure: {
                  startRhyme: 'o',
                  mainRhyme: 'a',
                  finishRhyme: 'i'
                }
              },
              tone: TONE.FALLING
            }
          },
          {
            content: {
              text: 'xa',
              audioUrl: ''
            },
            structure: {
              consonant: 'x',
              rhyme: {
                content: {
                  text: 'a',
                  audioUrl: ''
                },
                structure: {
                  startRhyme: '',
                  mainRhyme: 'a',
                  finishRhyme: ''
                }
              },
              tone: TONE.LEVEL
            }
          }
        ]
      }
    ]
  },
  {
    id: 6,
    inTestBank: false,
    title: 'Câu hỏi cho trẻ em lớp 1',
    backgroundUrl: school,
    backgroundOpacity: OPACITY.MEDIUM,
    textBackgroundColor: FLOATING_BOX_COLOR,
    question: 'Hãy lựa chọn dấu thanh cho đúng với dấu thanh của tiếng.',
    voice: 'Hương Giang',
    rateVoice: 1.5,
    score: 25,
    type: 'square_quiz',
    squareQuizType: SQUARE_QUIZ_TYPE.TONE_OF_WORD,
    quizParts: [
      {
        content: {
          text: 'chiếc thuyền ngoài xa kia',
          type: MEDIA_TYPE.TEXT
        },
        squareQuizParts: [
          {
            content: {
              text: 'chiếc',
              audioUrl: ''
            },
            structure: {
              consonant: 'ch',
              rhyme: {
                content: {
                  text: 'iêc',
                  audioUrl: ''
                },
                structure: {
                  startRhyme: '',
                  mainRhyme: 'iê',
                  finishRhyme: 'c'
                }
              },
              tone: TONE.RISING
            }
          },
          {
            content: {
              text: 'thuyền',
              audioUrl: ''
            },
            structure: {
              consonant: 'th',
              rhyme: {
                content: {
                  text: 'uyên',
                  audioUrl: ''
                },
                structure: {
                  startRhyme: 'u',
                  mainRhyme: 'yê',
                  finishRhyme: 'n'
                }
              },
              tone: TONE.FALLING
            }
          },
          {
            content: {
              text: 'ngoài',
              audioUrl: ''
            },
            structure: {
              consonant: 'ng',
              rhyme: {
                content: {
                  text: 'oai',
                  audioUrl: ''
                },
                structure: {
                  startRhyme: 'o',
                  mainRhyme: 'a',
                  finishRhyme: 'i'
                }
              },
              tone: TONE.FALLING
            }
          },
          {
            content: {
              text: 'xa',
              audioUrl: ''
            },
            structure: {
              consonant: 'x',
              rhyme: {
                content: {
                  text: 'a',
                  audioUrl: ''
                },
                structure: {
                  startRhyme: '',
                  mainRhyme: 'a',
                  finishRhyme: ''
                }
              },
              tone: TONE.LEVEL
            }
          },
          {
            content: {
              text: 'kia',
              audioUrl: ''
            },
            structure: {
              consonant: 'k',
              rhyme: {
                content: {
                  text: 'ia',
                  audioUrl: ''
                },
                structure: {
                  startRhyme: '',
                  mainRhyme: 'i',
                  finishRhyme: 'a'
                }
              },
              tone: TONE.LEVEL
            }
          }
        ]
      }
    ]
  },
  {
    id: 7,
    inTestBank: false,
    title: 'Câu hỏi cho trẻ em lớp 2',
    backgroundUrl: school,
    backgroundOpacity: OPACITY.MEDIUM,
    textBackgroundColor: FLOATING_BOX_COLOR,
    question: 'Hãy click chuột vào các tiếng trong câu.',
    voice: 'Hương Giang',
    rateVoice: 1.5,
    score: 25,
    type: 'square_quiz',
    squareQuizType: SQUARE_QUIZ_TYPE.WORD_IN_SENTENCE,
    quizParts: [
      {
        content: {
          text: 'Thuyền ai đậu bến Cô Tô',
          type: MEDIA_TYPE.TEXT
        },
        squareQuizParts: [
          {
            content: {
              text: 'Thuyền',
              audioUrl: ''
            }
          },
          {
            content: {
              text: 'ai',
              audioUrl: ''
            }
          },
          {
            content: {
              text: 'đậu',
              audioUrl: ''
            }
          },
          {
            content: {
              text: 'bến',
              audioUrl: ''
            }
          },
          {
            content: {
              text: 'Cô',
              audioUrl: ''
            }
          },
          {
            content: {
              text: 'Tô',
              audioUrl: ''
            }
          }
        ]
      },
      {
        content: {
          text: 'Nửa đêm nghe tiếng chuông chùa Hàn Sơn',
          type: MEDIA_TYPE.TEXT
        },
        squareQuizParts: [
          {
            content: {
              text: 'Nửa',
              audioUrl: ''
            }
          },
          {
            content: {
              text: 'đêm',
              audioUrl: ''
            }
          },
          {
            content: {
              text: 'nghe',
              audioUrl: ''
            }
          },
          {
            content: {
              text: 'tiếng',
              audioUrl: ''
            }
          },
          {
            content: {
              text: 'chuông',
              audioUrl: ''
            }
          },
          {
            content: {
              text: 'chùa',
              audioUrl: ''
            }
          },
          {
            content: {
              text: 'Hàn',
              audioUrl: ''
            }
          },
          {
            content: {
              text: 'Sơn',
              audioUrl: ''
            }
          }
        ]
      }
    ]
  }
];

// react, redux & translation lib
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeMenu, setAllCourses, setCurrentCourse } from '../../redux/course/actions';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// MUI
import { Box, Container, Typography, Divider, Button, Skeleton } from '@mui/material';
import MenuBookIcon from '@mui/icons-material/MenuBook';
// import LockIcon from '@mui/icons-material/Lock';

// Scrollbar
import { Scrollbars } from 'react-custom-scrollbars-2';
// Swiper JS lib
import { SwiperSlide } from 'swiper/react';

// smaller component & CSS style
import ChapterOverview from './ChapterOverview';
import SmallCourseBox from './SmallCourseBox';
import BeforeLearningLayout from '../Layout/BeforeLearningLayout';
import { BaseCourseBox, CarouselBox } from './index.style';

import { getStorage, setStorage } from 'src/utils/storage';
import { getCourseGeneralInfo } from '../../apis/course';
import VSSwiperCourse from 'src/components/VSCourseSwiper';

import startLearn from 'src/assets/audio/start_learn.wav';
import useSound from 'use-sound';
import { getMe } from 'src/apis/auth';
import { updateUserInfo } from 'src/redux/auth/actions';
import { processLearnedCourses } from './index.logic';

const ScrollChapterOverview = ({ chapterOverviews }) => {
  return (
    <Scrollbars autoHeight autoHeightMax={210}>
      {chapterOverviews.map((chapterOverview, index) => {
        return (
          <div key={index}>
            {index !== 0 ? (
              <Divider sx={{ borderRightWidth: 0.5, backgroundColor: 'black' }} />
            ) : (
              <></>
            )}
            <ChapterOverview
              title={chapterOverview.title}
              numberOfSection={chapterOverview.numberOfSection}
              completePercent={chapterOverview.completePercent}
            />
          </div>
        );
      })}
    </Scrollbars>
  );
};

const CourseContainer = () => {
  const { t } = useTranslation();
  const courses = useSelector((state) => state.course.courses);
  const currentCourse = useSelector((state) => state.course.currentCourse);
  const dispatcher = useDispatch();
  const navigate = useNavigate();
  const [playStart] = useSound(startLearn);

  const currentStorageResult = getStorage('result');

  useEffect(() => {
    dispatcher(changeMenu(false));
    // const fetchCourseData = async () => {
    //   const data = await getCourseGeneralInfo('v2');
    //   dispatcher(setAllCourses(data.result));
    //   dispatcher(setCurrentCourse(data.result[0]));
    // };

    // const fetchUser = async () => {
    //   const user = await getMe('v1');
    //   dispatcher(updateUserInfo(user.result));
    // }
    // fetchCourseData();
    // fetchUser();
    initCourses();
  }, []);

  const initCourses = async () => {
    const data = await getCourseGeneralInfo('v2');
    const user = await getMe('v1');
    dispatcher(updateUserInfo(user.result));

    const learningResult = user.result.resultId;
    // get list done course -> process to find which course can be learned
    const courseDoneIds = !learningResult.progress ? [] : learningResult.progress.coursesDone;
    const processData = processLearnedCourses(data.result, courseDoneIds);
    dispatcher(setAllCourses(processData));

    // find out which course is being learned by user
    let currentCourse;
    if (learningResult.progress === null || learningResult.progress === undefined) {
      currentCourse = processData[0];
    } else {
      const currentCourseId = learningResult.currentdIds.currentCourseId;
      currentCourse = learningResult.filter((e) => e._id === currentCourseId)[0];
    }
    dispatcher(setCurrentCourse(currentCourse));

    // save can learn courses to localstorage
    const result = {
      canLearnCourseIds: [currentCourse.id],
      canLearnChapterIds: [currentCourse.chapterOverviews[0].id],
      canLearnSectionIds: []
    };
    if (
      !currentStorageResult ||
      !currentStorageResult?.canLearnCourseIds ||
      !currentStorageResult?.canLearnCourseIds.length
    ) {
      setStorage({
        key: 'result',
        data: result
      });
    }
  };

  const data = courses.map((ele) => {
    const canLearnIndex = currentStorageResult?.canLearnCourseIds.findIndex(
      (canLearnId) => canLearnId == ele.id
    );
    return (
      <SwiperSlide key={ele.id}>
        <SmallCourseBox
          imgSrc={ele.imageUrl}
          title={ele.title}
          courseId={ele.id}
          // canLearn={ele.canLearn}
          canLearn={canLearnIndex > -1 ? true : ele?.canLearn}
        />
      </SwiperSlide>
    );
  });

  const handleStartCourseClick = () => {
    playStart();
    setStorage({
      key: 'currentCourse',
      data: {
        id: currentCourse.id,
        title: currentCourse.title
      }
    });
    navigate(`/courses/${currentCourse.id}/chapters`);
  };

  return (
    <BeforeLearningLayout>
      {/* 1. carousel of all courses images */}
      <CarouselBox sx={{ mt: { md: 2, lg: 4, xl: 6 } }} len={data.length}>
        {courses.length !== 0 ? (
          <VSSwiperCourse data={data} space={15} type="course" />
        ) : (
          <Box className="courses-skeleton">
            {[1, 2, 3, 4].map((e, idx) => (
              <Skeleton key={idx} variant="rounded" width={125} height={90} />
            ))}
          </Box>
        )}
      </CarouselBox>

      {/* 2. base box, showing info of current course */}
      <Container
        maxWidth={false}
        sx={{
          maxWidth: '86%',
          marginTop: { xl: '60px' }
        }}>
        {currentCourse.imageUrl !== undefined ? (
          <BaseCourseBox
            sx={{ height: { lg: '380px', xl: '420px' } }}
            canLearn={
              currentStorageResult?.canLearnCourseIds.findIndex(
                (canLearnId) => canLearnId == currentCourse.id
              ) > -1
                ? true
                : currentCourse.canLearn
            }>
            {/* 2a. left part, contains course title, image, button */}
            <Box className="left-part">
              <Typography variant="h2" fontWeight="bold" textAlign="center">
                {currentCourse.title}
              </Typography>
              <img src={currentCourse.imageUrl} />

              {(
                currentStorageResult?.canLearnCourseIds.findIndex(
                  (canLearnId) => canLearnId == currentCourse.id
                ) > -1
                  ? true
                  : currentCourse.canLearn
              ) ? (
                <Button startIcon={<MenuBookIcon />} onClick={handleStartCourseClick}>
                  <Typography variant="h3">{t('letslearn')}</Typography>
                </Button>
              ) : (
                <>
                  <span className="learn-required-text">{t('learn_required')}</span>
                  <Button disabled>
                    <Typography variant="h3">{t('course_not_open')}</Typography>
                  </Button>
                </>
              )}
            </Box>

            <Divider
              orientation="vertical"
              flexItem
              sx={{ borderRightWidth: 2, backgroundColor: 'black' }}
            />

            {/* 2b. right part, contains course description & chapters overviews */}
            <Box className="right-part">
              <Typography variant="h2" fontWeight="bold" textAlign="left">
                {t('courseInfo')}
              </Typography>

              <Typography variant="body1" fontStyle="italic" textAlign="left" sx={{ mb: 4, mt: 1 }}>
                {currentCourse.description}
              </Typography>

              <Typography variant="h2" fontWeight="bold" textAlign="left" sx={{ mb: 1.5 }}>
                {t('courseTopics')}
              </Typography>

              <ScrollChapterOverview chapterOverviews={currentCourse.chapterOverviews} />
            </Box>

            {/* {!currentCourse.canLearn ? (
              <div className="lock-elm">
                <LockIcon />
              </div>
            ) : (
              <></>
            )} */}
          </BaseCourseBox>
        ) : (
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Skeleton variant="rounded" width={850} height={350} />
          </Box>
        )}
      </Container>
    </BeforeLearningLayout>
  );
};

export default CourseContainer;

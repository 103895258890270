import { styled } from '@mui/material/styles';

export const ChapterOverviewBox = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 10px 5px 0;

  .text-box {
    display: flex;
    flex-direction: column;
    padding: 4%;
    flex: 8;
  }

  .flower-icon {
    flex: 1;
  }
`;

// margin: top right bottom left

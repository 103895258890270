import React from 'react';
import Square from '../../SquareBox';
import { SQUARE_COLOR } from 'src/styles/color';

const ToneOfWord = ({ data }) => {
  const { text, tone, toneIcon } = data;
  return (
    <table>
      <tbody>
        <tr>
          <td>
            <h3> {text}</h3>
          </td>
        </tr>
        <tr>
          <td>
            {tone !== 'drop' ? (
              <Square display={'none'}>{toneIcon}</Square>
            ) : (
              <Square display={'none'}></Square>
            )}
          </td>
        </tr>
        <tr>
          <td>
            <Square color={SQUARE_COLOR.turquoise}></Square>
          </td>
        </tr>
        <tr>
          <td>
            {tone === 'drop' ? (
              <Square display={'none'}>{toneIcon}</Square>
            ) : (
              <Square display={'none'}></Square>
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default ToneOfWord;

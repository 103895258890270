// React
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { IconButton, Tooltip, Box } from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';

import { uploadOneFile } from 'src/apis/fileStorage/storage';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { updateWithoutKeycloak } from 'src/apis/auth';
import { updateUserInfo } from 'src/redux/auth/actions';

const AvatarBox = ({ avatar }) => {
  const { t } = useTranslation();
  const dispatcher = useDispatch();
  const imgRef = useRef();
  const user = useSelector((state) => state.auth.user);

  const handleChange = async (event) => {
    let newImageUrl;
    const fileObject = event.target.files[0];
    // upload image
    if (fileObject) {
      const formData = new FormData();
      formData.append('file', fileObject);
      const uploadImageRes = await uploadOneFile(formData);
      if (!uploadImageRes.result) toast.error(`Lỗi: ${JSON.stringify(uploadImageRes.message)}`);
      else newImageUrl = uploadImageRes.data.fileAccessLink;
    }

    user.avatarUrl = newImageUrl;
    const updateRes = await updateWithoutKeycloak('v1', { ...user, userId: user._id });
    if (updateRes.result === true) {
      dispatcher(updateUserInfo(user));
    }
  };

  return (
    <Box
      sx={{
        background: `url(${avatar})`,
        backgroundSize: '180px 150px',
        backgroundRepeat: 'no-repeat',
        height: 150,
        width: 180,
        borderRadius: '10px',
        border: '1.5px solid black',
        position: 'relative'
      }}>
      <Tooltip title={<h3>{t('change_avatar')}</h3>}>
        <Box
          onClick={() => imgRef.current.click()}
          sx={{
            position: 'absolute',
            bottom: 0,
            cursor: 'pointer',
            width: 178,
            height: 37.5,
            borderRadius: '0 0 10px 10px',
            backgroundColor: 'rgba(150,150,150,0.5)',
            '&:hover': {
              backgroundColor: 'rgba(80,80,80,0.5) !important'
            }
          }}>
          <IconButton component="label" sx={{ left: 70 }}>
            <input
              style={{ display: 'none' }}
              accept="image/*"
              type="file"
              ref={imgRef}
              onChange={handleChange}
            />
            <CameraAltIcon sx={{ fontSize: { xs: '0.75rem', md: '1.25rem', lg: '1.5rem' } }} />
          </IconButton>
        </Box>
      </Tooltip>
    </Box>
  );
};

export default AvatarBox;

/* lack of component="label" --> ERROR */

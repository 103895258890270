import React from 'react';
import Square from '../../SquareBox';
import { SQUARE_COLOR } from 'src/styles/color';

const PhonemeOfSyllable = ({ data }) => {
  const { text, tone, toneIcon, consonant, startRhyme, mainRhyme, finishRhyme } = data;

  return (
    <table>
      <tbody>
        <tr>
          <td colSpan={4}>
            <h3> {text}</h3>
          </td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td>
            {tone !== 'drop' ? (
              <Square display={'none'}>{toneIcon}</Square>
            ) : (
              <Square display={'none'}></Square>
            )}
          </td>
          <td></td>
        </tr>
        <tr>
          <td>
            <Square color={SQUARE_COLOR.turquoise}>{consonant}</Square>
          </td>
          <td>
            <Square color={SQUARE_COLOR.greenery}>{startRhyme}</Square>
          </td>
          <td>
            <Square color={SQUARE_COLOR.violet}>{mainRhyme}</Square>
          </td>
          <td>
            <Square color={SQUARE_COLOR.pink}>{finishRhyme}</Square>
          </td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td>
            {tone === 'drop' ? (
              <Square display={'none'}>{toneIcon}</Square>
            ) : (
              <Square display={'none'}></Square>
            )}
          </td>
          <td></td>
        </tr>
      </tbody>
    </table>
  );
};

export default PhonemeOfSyllable;

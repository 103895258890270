import * as React from 'react';

import {
  Button,
  Dialog,
  FormControl,
  TextField,
  FormLabel,
  Typography,
  DialogActions,
  DialogContent,
  DialogContentText,
  InputLabel,
  CircularProgress,
  Box,
  Select,
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel
} from '@mui/material';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { updateUserInfo } from 'src/redux/auth/actions';
import { getMe, updateFullname, updateWithoutKeycloak } from 'src/apis/auth';
import { convertBirthdayToStr, checkValidDate } from '../AuthContainer/Register/index.logic';

const UpdateGeneralDialog = ({ open, setOpen }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const user = useSelector((state) => state.auth.user);
  const dispatcher = useDispatch();

  const [date, setDate] = useState(0);
  const [month, setMonth] = useState(0);
  const [year, setYear] = useState(0);

  const [newUser, setNewUser] = useState({
    firstName: '',
    lastName: '',
    gender: '',
    bio: ''
  });

  const inputNewUser = (e) => setNewUser({ ...newUser, [e.target.name]: e.target.value });

  const [dateErr, setDateErr] = useState('');
  const dayInMonth = Array.from({ length: 31 }, (_, i) => i + 1);
  const monthInYear = Array.from({ length: 12 }, (_, i) => i + 1);
  const yearList = Array.from({ length: new Date().getFullYear() - 2000 + 1 }, (_, i) => i + 2000);

  const { t } = useTranslation();
  const optionSx = { display: 'flex', justifyContent: 'center' };
  const [isLoading, setIsLoading] = useState(false);

  const initUser = (userInfo) => {
    const dateList = userInfo.birthday.split('-');
    setYear(parseInt(dateList[0]));
    setMonth(parseInt(dateList[1]));
    setDate(parseInt(dateList[2]));

    setNewUser({
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      gender: userInfo.gender,
      bio: userInfo.bio
    });
  };

  useEffect(() => {
    const fetchUser = async () => {
      const fetchedUser = await getMe('v1');
      initUser(fetchedUser.result);
      dispatcher(updateUserInfo(fetchedUser.result));
    };

    if (Object.keys(user).length === 0) {
      fetchUser();
    } else {
      initUser(user);
    }
  }, []);

  const updateGeneralInfo = async () => {
    setIsLoading(true);
    if (user.firstName !== newUser.firstName || user.lastName !== newUser.lastName) {
      const res = await updateFullname('v1', user._id, newUser.firstName, newUser.lastName);
      if (res.result === true) {
        user.lastName = newUser.lastName;
        user.firstName = newUser.firstName;
        dispatcher(updateUserInfo(user));
      }
    }
    const currentDOB = { date, month, year };
    const result = checkValidDate(currentDOB);
    if (result.continued === false) {
      setDateErr(result.errorMessage);
      setIsLoading(false);
      return;
    }

    setDateErr('');
    if (
      user.bio !== newUser.bio ||
      user.gender !== newUser.gender ||
      user.birthday !== convertBirthdayToStr(currentDOB)
    ) {
      user.bio = newUser.bio;
      user.gender = newUser.gender;
      user.birthday = convertBirthdayToStr(currentDOB);
      const res1 = await updateWithoutKeycloak('v1', { ...user, userId: user._id });
      if (res1.result === true) {
        dispatcher(updateUserInfo(user));
      }
    }
    setIsLoading(false);
    setOpen(false);
  };

  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={() => setOpen(false)}>
      <DialogContent>
        <DialogContentText textAlign="center" sx={{ marginBottom: '15px' }}>
          <Typography variant="h2">{t('update_general')}</Typography>
        </DialogContentText>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '20px'
          }}>
          <InputLabel id="day">{t('day')}</InputLabel>
          <Select
            id="day"
            value={date}
            onChange={(e) => {
              setDate(e.target.value);
            }}>
            {dayInMonth.map((e) => {
              return (
                <MenuItem value={e} sx={optionSx} key={e}>
                  <Box sx={{ width: '40px' }}>
                    <Typography>{e}</Typography>
                  </Box>
                </MenuItem>
              );
            })}
          </Select>

          <InputLabel id="month">{t('month')}</InputLabel>
          <Select
            id="month"
            value={month}
            onChange={(e) => {
              setMonth(e.target.value);
            }}>
            {monthInYear.map((e) => {
              return (
                <MenuItem value={e} sx={optionSx} key={e}>
                  <Box sx={{ width: '40px' }}>
                    <Typography>{e}</Typography>
                  </Box>
                </MenuItem>
              );
            })}
          </Select>

          <InputLabel id="year">{t('year')}</InputLabel>
          <Select
            id="year"
            value={year}
            onChange={(e) => {
              setYear(e.target.value);
            }}>
            {yearList.map((e) => {
              return (
                <MenuItem value={e} sx={optionSx} key={e}>
                  <Box sx={{ width: '40px' }}>
                    <Typography>{e}</Typography>
                  </Box>
                </MenuItem>
              );
            })}
          </Select>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'center', marginY: '7.5px' }}>
          <Typography variant="h3" color="error">
            {t(dateErr)}
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '20px'
          }}>
          <TextField
            label={t('lastname')}
            variant="standard"
            fullWidth
            defaultValue={user.lastName}
            sx={{ marginBottom: '10px', marginTop: '12px' }}
            name="lastName"
            onChange={inputNewUser}
          />

          <TextField
            label={t('firstname')}
            variant="standard"
            fullWidth
            defaultValue={user.firstName}
            name="firstName"
            onChange={inputNewUser}
            sx={{ marginBottom: '10px', marginTop: '12px' }}
          />
        </Box>

        <TextField
          label={t('bio')}
          fullWidth
          variant="standard"
          defaultValue={user.bio === 'no_bio' ? t('no_bio') : user.bio}
          name="bio"
          onChange={inputNewUser}
          sx={{ marginBottom: '10px' }}
        />

        <FormControl>
          <FormLabel id="radio-gender">
            <Typography variant="h4" fontWeight="medium">
              {t('gender')}
            </Typography>
          </FormLabel>
          <RadioGroup row defaultValue={user.gender} aria-labelledby="radio-gender">
            <FormControlLabel
              value="female"
              control={<Radio />}
              label={t('female')}
              name="gender"
              onChange={inputNewUser}
            />
            <FormControlLabel
              value="male"
              control={<Radio />}
              label={t('male')}
              name="gender"
              onChange={inputNewUser}
            />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        {isLoading ? (
          <Button>
            <CircularProgress size="1.5rem" />
          </Button>
        ) : (
          <>
            <Button autoFocus onClick={() => setOpen(false)}>
              <Typography variant="h3">{t('dont_agree')}</Typography>
            </Button>
            <Button onClick={updateGeneralInfo} autoFocus>
              <Typography variant="h3">{t('agree')}</Typography>
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default UpdateGeneralDialog;

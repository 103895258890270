// mui & react
import { Radio, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';

// redux
import { useDispatch } from 'react-redux';
import { setDisableNext } from 'src/redux/lesson/actions';

// customed ui
import MediaDisplayer from '../../ToolParts/MediaDisplayer';
import { RadioAnswersBox, RadioOptBox } from './index.style';
import { BlinkArrow } from '../../ToolParts/BlinkArrow';

// sound
import correct from '../../../../assets/audio/correct.mp3';
import useSound from 'use-sound';

const RadioOption = ({
  media,
  index,
  width,
  trueAnswer,
  currentChosenIndex,
  setCurrentChosenIndex,
  isHint
}) => {
  const dispatch = useDispatch();
  const [chosen, setChosen] = useState('unchosen');
  const [colorHint, setColorHint] = useState('transparent');
  const [playTrue] = useSound(correct);
  const [counter, setCounter] = useState(0);

  const handleChangeOption = (event) => {
    const value = parseInt(event.target.value);
    setCurrentChosenIndex(value);

    if (value === trueAnswer) {
      setChosen('correct');
      playTrue();
      dispatch(setDisableNext(false));
    } else setChosen('incorrect');
  };

  useEffect(() => {
    if (trueAnswer === index && counter !== 0) {
      setColorHint('green');
      setTimeout(() => setColorHint('transparent'), 2000);
    }
    setCounter(counter + 1);
  }, [isHint]);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center' }}>
      <BlinkArrow color={colorHint} />
      <RadioOptBox chosen={chosen} compare={currentChosenIndex !== index} width={width}>
        <Radio
          checked={currentChosenIndex === index}
          value={index}
          onChange={handleChangeOption}
          color={chosen === 'incorrect' ? 'error' : 'success'}
        />
        <MediaDisplayer media={media} index={index} />
      </RadioOptBox>
    </Box>
  );
};

const RadioAnswers = ({
  options,
  trueAnswer,
  currentChosenIndex,
  setCurrentChosenIndex,
  isHint
}) => {
  return (
    <RadioAnswersBox>
      <Scrollbars autoHeight autoHeightMax={265}>
        {options.length % 2 === 0
          ? options.map((e, index) => {
              if (index % 2 === 0) {
                return (
                  <Box className="line" key={index}>
                    {[index, index + 1].map((ele) => {
                      return (
                        <RadioOption
                          media={options[ele].content}
                          trueAnswer={trueAnswer}
                          currentChosenIndex={currentChosenIndex}
                          setCurrentChosenIndex={setCurrentChosenIndex}
                          index={ele}
                          key={ele}
                          isHint={isHint}
                        />
                      );
                    })}
                  </Box>
                );
              }
            })
          : options.map((e, index) => {
              return (
                <Box className="only-one-line" key={index}>
                  <RadioOption
                    media={e.content}
                    trueAnswer={trueAnswer}
                    currentChosenIndex={currentChosenIndex}
                    setCurrentChosenIndex={setCurrentChosenIndex}
                    index={index}
                    isHint={isHint}
                    width={'50%'}
                  />
                </Box>
              );
            })}
      </Scrollbars>
    </RadioAnswersBox>
  );
};

export default RadioAnswers;

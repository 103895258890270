import { QUIZ_TYPE } from 'src/constants/quiz';
import MCQuizContainer from './MCQuizContainer';
import PairQuizContainer from './PairQuizContainer';
import BlankQuizContainer from './BlankQuizContainer';
import SquareQuizContainer from './SquareQuizContainer';
import SpeakingQuizContainer from './SpeakingQuizContainer';

const QuizContainer = ({ data }) => {
  switch (data.type) {
    case QUIZ_TYPE.MC_QUIZ:
      return <MCQuizContainer data={data} />;

    case QUIZ_TYPE.PAIR_QUIZ:
      return <PairQuizContainer data={data} />;

    case QUIZ_TYPE.BLANK_QUIZ:
      return <BlankQuizContainer data={data} />;

    case QUIZ_TYPE.SQUARE_QUIZ:
      return <SquareQuizContainer data={data} />;

    case QUIZ_TYPE.SPEAKING_QUIZ:
      return <SpeakingQuizContainer data={data} />;

    default:
      return <></>;
  }
};

export default QuizContainer;

import { styled } from '@mui/material';

export const LessonOverviewStyle = styled('div')`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${(props) => props.background});
  background-repeat: no-repeat;
  background-size: cover;

  .title {
    width: 300px;
    height: 100px;
    background-color: white;
    border: 4px solid black;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

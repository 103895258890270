import React, { useEffect, useRef } from 'react';
import { CheckpointStyle } from './index.style';
import tornado from 'src/assets/images/icons/tornado.png';
import { useState } from 'react';
import { useSelector } from 'react-redux';

const Checkpoint = ({ data }) => {
  const [active1, setActive1] = useState(false);
  const [active2, setActive2] = useState(false);
  const isPlayAudio = useSelector((state) => state.checkpoint.audioPlay);

  const audioRef = useRef();

  const handleClick1 = () => {
    setActive1(!active1);
  };
  const handleClick2 = () => {
    setActive2(!active2);
  };

  useEffect(() => {
    if (isPlayAudio) {
      audioRef.current.play();
      setActive1(!active1);
      // setTimeout(() => {
      //   dispatch(setAudioPlay(false));
      // }, '3000');
    } else {
      audioRef.current.load();
    }
  }, [isPlayAudio]);

  return (
    <CheckpointStyle>
      <audio ref={audioRef}>
        <source src={data.soundUrl} type="audio/ogg" />
      </audio>
      <img
        className={
          active1 ? 'Tornado1 animate__animated animate__pulse animate__infinite' : 'Tornado1'
        }
        onClick={handleClick1}
        src={tornado}
        alt="tornado1"
      />
      <img
        className={
          active2 ? 'Tornado2 animate__animated animate__pulse animate__infinite' : 'Tornado2'
        }
        src={tornado}
        onClick={handleClick2}
        alt="tornado2"
      />
    </CheckpointStyle>
  );
};

export default Checkpoint;

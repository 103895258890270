import { styled } from '@mui/material/styles';

export const DrawerHeader = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(0, 1)};
  margin-top: 50px;
  ${({ theme }) => ({ ...theme.mixins.toolbar })};

  img {
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 50%;
    height: 50%;
    border: 1px solid #0d4dc9;
  }

  .bio {
    margin: 10px 12px 0 12px;
  }

  .star-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2.5px;
  }
`;

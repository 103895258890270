import { styled } from '@mui/material/styles';
import { FLOATING_BOX_COLOR, BUTTON_COLOR } from 'src/theme/color';

export const CourseImageBox = styled('button')`
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: ${FLOATING_BOX_COLOR};
  border: 2px solid ${(props) => (props.isCompleted ? '#4CBB17' : '#9b9b9b')};
  filter: ${(props) => (props.canLearn ? 'none' : 'contrast(0.7)')};

  img {
    border-radius: 10px;
    width: 100px;
    height: 100px;
  }

  .lock-elm {
    position: absolute;
    color: ${BUTTON_COLOR};

    svg {
      font-size: 40px;
    }
  }
`;

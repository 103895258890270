import api from './api';

export const getAllPaths = async () => {
  return await (
    await api.get('v1/courses')
  ).result;
};

export const getExtractPath = async (id) => {
  return await (
    await api.get(`v2/courses/${id}`)
  ).result;
};

import { Box } from '@mui/material';
import WordColumn from '../WordColumn';

const PhonemeLine = ({ lineData, isReset, setChosenNum, chosenNum }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '45px'
      }}>
      {lineData.words.map((e, index) => {
        return (
          <Box key={index}>
            <WordColumn
              data={e}
              isReset={isReset}
              setChosenNum={setChosenNum}
              chosenNum={chosenNum}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default PhonemeLine;

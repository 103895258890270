import { memo } from 'react';
import { Card, CardMedia, CardContent, Typography } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';

// components
import VoiceSpeaker from 'src/components/VoiceSpeaker';

// utils
import { formatNumber } from '../../../../utils/number';

const LuckyBoxItemCard = ({ boxItem }) => (
  <Card>
    <div style={{ position: 'relative' }}>
      {/* image */}
      <CardMedia
        sx={{ background: 'white' }}
        component="img"
        height="300"
        image={boxItem.imageUrl}
      />

      {/* name */}
      <div
        style={{
          position: 'absolute',
          width: '100%',
          textAlign: 'center',
          bottom: 0,
          backgroundColor: 'white',
          color: 'black',
          paddingTop: '5px',
          paddingBottom: '5px'
        }}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {boxItem.nameVoiceUrl && <VoiceSpeaker audioUrl={boxItem.nameVoiceUrl} />}
          <span style={{ marginLeft: '5px' }}>{boxItem.name}</span>
          {boxItem.isOwned && <span>&nbsp;(Đã sở hữu)</span>}
        </div>
      </div>
    </div>

    {/* price */}
    <CardContent
      sx={{ background: 'white', borderTop: '1px solid black', padding: '10px !important' }}>
      <Typography sx={{ marginBottom: 0 }} color="text.secondary" align="center" gutterBottom>
        <div
          style={{
            display: 'flex',
            alignItems: 'end',
            justifyContent: 'center'
          }}>
          <StarIcon color="warning" />
          <span style={{ marginLeft: '2px' }}>{formatNumber(boxItem.price)}</span>
        </div>
      </Typography>
    </CardContent>
  </Card>
);

export default memo(LuckyBoxItemCard);

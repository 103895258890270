import React from 'react';
import { RegisterLayoutStyle } from './index.style';
import Logo from 'src/assets/images/logo/viet_study_logo.png';

const RegsiterLayout = ({ children }) => {
  return (
    <RegisterLayoutStyle>
      <div className="logo">
        <img src={Logo} alt="" />
      </div>
      <div className="content">{children}</div>
      <div className="footer">
        <span>Phiên bản 0.0.1</span>
        <div>
          <a href="#" className="privacy">
            Chính sách
          </a>
          <a href="#">Liên hệ</a>
        </div>
      </div>
    </RegisterLayoutStyle>
  );
};

export default RegsiterLayout;

import {
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  CircularProgress
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RegsiterLayout from 'src/containers/Layout/AuthLayout/RegisterLayout';
import { RegisterStyle } from './index.style';

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { checkValidDate, checkValidUser, convertBirthdayToStr } from './index.logic';
import { register } from 'src/apis/auth';
import { getCookie } from 'src/utils/cookie';
import { useNavigate } from 'react-router';

//sound
import useSound from 'use-sound';
import RegisterAudio from 'src/assets/audio/register/register.wav';
import SurnameAudio from 'src/assets/audio/register/surname.wav';
import NameAudio from 'src/assets/audio/register/name.wav';
import UsernamAudio from 'src/assets/audio/register/username.wav';
import EmailAudio from 'src/assets/audio/register/your_email.wav';
import PasswordAudio from 'src/assets/audio/register/password.wav';
import GenderAudio from 'src/assets/audio/register/gender.wav';
import MaleAudio from 'src/assets/audio/register/male.wav';
import FemaleAudio from 'src/assets/audio/register/female.wav';
import BirthdayAudio from 'src/assets/audio/register/date_of_birth.wav';
import DateAudio from 'src/assets/audio/register/date.wav';
import MonthAudio from 'src/assets/audio/register/month.wav';
import YearAudio from 'src/assets/audio/register/year.wav';

const RegisterContainer = () => {
  const dayInMonth = Array.from({ length: 31 }, (_, i) => i + 1);
  const monthInYear = Array.from({ length: 12 }, (_, i) => i + 1);
  const yearList = Array.from({ length: new Date().getFullYear() - 2000 + 1 }, (_, i) => i + 2000);

  const [isVisible, setIsVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    password: '',
    gender: ''
  });
  const [birthday, setBirthday] = useState({ date: 1, month: 1, year: 2000 });

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [playRegisterAudio] = useSound(RegisterAudio);
  const [playSurnameAudio] = useSound(SurnameAudio);
  const [playNameAudio] = useSound(NameAudio);
  const [playUsernamAudio] = useSound(UsernamAudio);
  const [playEmailAudio] = useSound(EmailAudio);
  const [playPasswordAudio] = useSound(PasswordAudio);
  const [playGenderAudio] = useSound(GenderAudio);
  const [playMaleAudio] = useSound(MaleAudio);
  const [playFemaleAudio] = useSound(FemaleAudio);
  const [playBirthdayAudio] = useSound(BirthdayAudio);
  const [playDateAudio] = useSound(DateAudio);
  const [playMonthAudio] = useSound(MonthAudio);
  const [playYearAudio] = useSound(YearAudio);

  useEffect(() => {
    const accessToken = getCookie('accessToken');
    const refreshToken = getCookie('refreshToken');
    if (accessToken !== '' && refreshToken !== '') {
      navigate('/');
    }
  }, []);

  const handleInput = (e) => setUser({ ...user, [e.target.name]: e.target.value });

  const handleSelect = (e) => {
    setBirthday({ ...birthday, [e.target.name]: e.target.value });
  };

  const clickRegister = async () => {
    const checkUser = checkValidUser(user);
    if (!checkUser.continued) {
      setErrorMessage(t(`${checkUser.errorMessage}`));
    } else {
      const checkDate = checkValidDate(birthday);
      if (!checkDate.continued) {
        setErrorMessage(t(`${checkDate.errorMessage}`));
      } else {
        setIsLoading(true);
        const keycloakUser = {
          username: user.username.trim(),
          firstName: user.firstName.trim(),
          lastName: user.lastName.trim(),
          email: user.email.trim()
        };
        const birthdayStr = convertBirthdayToStr(birthday);
        const response = await register(
          'v1',
          keycloakUser,
          user.password.trim(),
          user.gender,
          birthdayStr
        );
        setIsLoading(false);
        if (response.status === 0) {
          setErrorMessage(t(`${response.message.message}`));
        } else {
          navigate('/verify-email', {
            state: { user: keycloakUser, password: user.password.trim() }
          });
        }
      }
    }
  };

  return (
    <RegsiterLayout>
      <RegisterStyle>
        <div className="register-header">
          <div className="register-header__title">
            <Typography variant="h1" onClick={playRegisterAudio}>
              {t('register')}
            </Typography>
          </div>
        </div>

        <div className="register-content">
          <div className="register-content__name">
            <div className="register-content__surname">
              <div className="register-content__title">
                <Typography variant="h3" onClick={playSurnameAudio}>
                  {t('lastname')}
                </Typography>
              </div>
              <div className="register-content__input">
                <input
                  type="text"
                  placeholder={`${t('for_example')}: Nguyễn Thị`}
                  name="lastName"
                  onChange={handleInput}
                />
              </div>
            </div>
            <div className="register-content__lastname">
              <div className="register-content__title">
                <Typography variant="h3" onClick={playNameAudio}>
                  {t('firstname')}
                </Typography>
              </div>
              <div className="register-content__input">
                <input
                  type="text"
                  placeholder={`${t('for_example')}: Lan Anh`}
                  name="firstName"
                  onChange={handleInput}
                />
              </div>
            </div>
          </div>

          <div className="register-content__username">
            <div className="register-content__title">
              <Typography variant="h3" onClick={playUsernamAudio}>
                {t('username')}
              </Typography>
            </div>
            <div className="register-content__input">
              <input
                type="text"
                placeholder={`${t('for_example')}: lananh1206`}
                name="username"
                onChange={handleInput}
              />
            </div>
          </div>
          <div className="register-content__email">
            <div className="register-content__title">
              <Typography variant="h3" onClick={playEmailAudio}>
                {t('your_email')}
              </Typography>
            </div>
            <div className="register-content__input">
              <input
                type="email"
                placeholder={`${t('for_example')}: lananh1206@gmail.com`}
                name="email"
                onChange={handleInput}
              />
            </div>
          </div>

          <div className="register-content__password">
            <div className="register-content__title">
              <Typography variant="h3" onClick={playPasswordAudio}>
                {t('password')}
              </Typography>
            </div>
            <div className="register-content__input">
              <input
                type={isVisible ? 'text' : 'password'}
                name="password"
                onChange={handleInput}
              />
              <span className="visible-icon" onClick={() => setIsVisible(!isVisible)}>
                {isVisible ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
              </span>
            </div>
          </div>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}>
            <div className="register-content__title">
              <Typography variant="h3" onClick={playGenderAudio}>
                {t('gender')}
              </Typography>
            </div>
            <RadioGroup row name="gender" className="gender-input">
              <FormControlLabel
                onClick={playMaleAudio}
                value="male"
                control={<Radio name="gender" onChange={handleInput} />}
                label={t('male')}
              />
              <FormControlLabel
                onClick={playFemaleAudio}
                value="female"
                control={<Radio name="gender" onChange={handleInput} />}
                label={t('female')}
              />
            </RadioGroup>
          </Box>

          <div className="register-content__birthday">
            <div className="register-content__title">
              <Typography variant="h3" onClick={playBirthdayAudio}>
                {t('date_of_birth')}
              </Typography>
            </div>
            <div className="register-content__input birthday-field">
              <div className="time-field">
                <Typography variant="h3" onClick={playDateAudio}>
                  {t('day')}
                </Typography>
                <select name="date" onChange={handleSelect}>
                  {dayInMonth.map((date) => (
                    <option key={date} value={date}>
                      {date}
                    </option>
                  ))}
                </select>
              </div>
              <div className="time-field">
                <Typography variant="h3" onClick={playMonthAudio}>
                  {t('month')}
                </Typography>
                <select name="month" onChange={handleSelect}>
                  {monthInYear.map((date) => (
                    <option key={date} value={date}>
                      {date}
                    </option>
                  ))}
                </select>
              </div>
              <div className="time-field">
                <Typography variant="h3" onClick={playYearAudio}>
                  {t('year')}
                </Typography>
                <select name="year" onChange={handleSelect}>
                  {yearList.map((date) => (
                    <option key={date} value={date}>
                      {date}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="error-message">{errorMessage}</div>

          <Button variant="contained" type="submit" onClick={() => clickRegister()}>
            {!isLoading ? (
              t('register')
            ) : (
              <CircularProgress sx={{ color: 'white' }} size="1.25rem" />
            )}
          </Button>
        </div>

        <div className="register-footer">
          <a href="/">{t('back_to_login')}</a>
        </div>
      </RegisterStyle>
    </RegsiterLayout>
  );
};

export default RegisterContainer;

import { styled } from '@mui/material/styles';
import { BACKGROUND_MAIN_COLOR } from 'src/theme/color';

export const WordBox = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .word-text {
    display: flex;
    flex-direction: row;
    width: 45px;
    height: 45px;
    align-items: center;
    justify-content: center;
  }

  .blank {
    width: 40px;
    height: 40px;
  }

  .square {
    width: 40px;
    height: 40px;
    background-color: ${BACKGROUND_MAIN_COLOR};
    border-radius: 10%;
    border: 1px solid black;
  }
`;

const routes = {
  // auth
  AUTH: '/auth',
  LOGIN: '/login',
  REGISTER: '/register',
  VERIFY_EMAIL: '/verify-email',
  FORGOT_PASSWORD: '/reset-password/:userId/:verifyCode',
  INPUT_EMAIL: '/input-email',

  // Before learning urls
  COURSES: '/courses',
  SETTINGS: '/settings',
  PERSONAL: '/personal-info',
  STUDY_RESULT: '/study-result',
  AWARD: '/award',
  VS_STORE: '/vietstudy-store',

  // while learning
  CHAPTERS: '/courses/:courseId/chapters',
  LESSONS: '/courses/:courseId/chapters/:chapterId/lesson/:lessonId',
  TESTS: '/courses/:courseId/chapters/:chapterId/test/:testId',
  CONGRATULATIONS: '/congratulations',
  PRACTICE_TEST_RESULT: '/practice-test-result',

  // temporary, will be delete in the future
  PAIR_QUIZ: '/pair-quiz',
  SQUARE_QUIZ: '/square-quiz',
  SLIDE_QUIZ: '/slide-quiz',

  //refactor
  HOME: '/',
  ROAD_MAP: '/roadmaps',
  LEARN_COURSE: '/learn-course/:courseId',
  LEARN_LESSON: '/learn-lesson/:lessonId',
  DO_TEST: 'do-test/:testId'
};

export default routes;

import frenchFlag from '../../assets/images/modules/flags/french-flag.png';
import vietFlag from '../../assets/images/modules/flags/vietnam-flag.jpg';
import engFlag from '../../assets/images/modules/flags/english-flag.jpg';
import japFlag from '../../assets/images/modules/flags/japan-flag.jpg';

export const languages = [
  { language_name: 'vietnamese', flag: vietFlag, lang_abbr: 'vi' },
  { language_name: 'english', flag: engFlag, lang_abbr: 'en' },
  { language_name: 'french', flag: frenchFlag, lang_abbr: 'fr' },
  { language_name: 'japanese', flag: japFlag, lang_abbr: 'jp' }
];

export const explainations = [
  { bigText: 'app_lang', smallText: 'app_lang_note' },
  { bigText: 'support_lang', smallText: 'support_lang_note' }
];

import { memo } from 'react';
import { Grid } from '@mui/material';

// local
import CollectionItemsCtrl from './CollectionItems.ctrl';
import CollectionItemCard from './CollectionItemCard/CollectionItemCard';
import CollectionItemModal from './CollectionItemModal/CollectionItemModal';

// eslint-disable-next-line react/display-name
const CollectionItems = memo(({ readOnly, shouldShowOnlyOwnedItems }) => {
  // -- ctrl --
  const { readyToRender, collectionItemIds } = CollectionItemsCtrl.useContainer();

  if (!readyToRender) return null;

  return (
    <>
      {/* list collection items */}
      <Grid container spacing={2}>
        {collectionItemIds.map((id) => (
          <Grid key={id} item xs={12} md={3}>
            <CollectionItemCard
              itemId={id}
              readOnly={readOnly}
              shouldShowOnlyOwnedItems={shouldShowOnlyOwnedItems}
            />
          </Grid>
        ))}
      </Grid>

      {/* modal */}
      <CollectionItemModal
        readOnly={readOnly}
        shouldShowOnlyOwnedItems={shouldShowOnlyOwnedItems}
      />
    </>
  );
});

// eslint-disable-next-line react/display-name
export default (props) => (
  <CollectionItemsCtrl.Provider
    initialState={{ shouldShowOnlyOwnedItems: props.shouldShowOnlyOwnedItems }}>
    <CollectionItems {...props} />
  </CollectionItemsCtrl.Provider>
);

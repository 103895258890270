import { styled, keyframes } from '@mui/material/styles';
import ForwardIcon from '@mui/icons-material/Forward';
import Xarrow from 'react-xarrows';

const blink = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

export const BlinkArrow = styled(ForwardIcon)`
  font-size: 40px;
  color: ${(props) => props.color};
  animation: ${blink} 1s linear infinite;
`;

export const BlinkImage = styled('img')`
  animation: ${blink} 1s linear infinite;
  height: 30px;
  width: 60px;
`;

export const BlinkBox = styled('div')`
  animation: ${blink} 1s linear infinite;
  height: 30px;
  width: 60px;
  border: 1px dashed black;
`;

export const BlinkXArrow = styled(Xarrow)`
  animation: ${blink} 1s linear infinite;
`;

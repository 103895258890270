import { styled } from '@mui/material/styles';
import { FLOATING_BOX_COLOR } from 'src/theme/color';

export const LanguageListBox = styled('div')`
  display: flex;
  flexdirection: row;
  align-items: center;
  background-color: ${FLOATING_BOX_COLOR};
  border: 2.5px solid black;
  border-radius: 10px;
  height: 185px;
  padding: 10px 25px;
  margin-top: 80px;

  .text-box {
    flex: 5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
  }

  .big-text {
    margin-bottom: 10px;
  }

  .small-text {
    width: 90%;
  }

  .carousel {
    flex: 8;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 42.5%;
    height: 190px;
    padding: 5px;
  }
`;

// padding: top bottom right left = (top + bottom) (right + left)

// core libraries
import { Box } from '@mui/material';
import { useDrop } from 'react-dnd';

// customed ui
import { ItemBox } from './index.style';
import ConnectDot from '../ConnectDot';
import MediaDisplayer from '../../../../QuizContainer/ToolParts/MediaDisplayer';

const Item = ({
  data,
  dot,
  arrows,
  setArrows,
  leftPairs,
  rightPairs,
  setLeftPairs,
  setRightPairs
}) => {
  const [, drop] = useDrop({
    accept: 'connect-dot',
    drop: () => ({ boxId: data.boxId })
  });

  return (
    <ItemBox ref={drop} chosen={data.chosen} id={data.boxId}>
      {dot === 'left' ? (
        <Box className="media-display">
          <MediaDisplayer media={data.media} index={data.boxId} />
        </Box>
      ) : (
        <></>
      )}
      <ConnectDot
        dotPosition={dot}
        boxId={data.boxId}
        arrows={arrows}
        setArrows={setArrows}
        leftPairs={leftPairs}
        rightPairs={rightPairs}
        setLeftPairs={setLeftPairs}
        setRightPairs={setRightPairs}
      />
      {dot === 'right' ? (
        <Box className="media-display">
          <MediaDisplayer media={data.media} index={data.boxId} />
        </Box>
      ) : (
        <></>
      )}
    </ItemBox>
  );
};

export default Item;

import { useCallback, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Box, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

// components
import VoiceSpeaker from 'src/components/VoiceSpeaker';

// utils
import { formatNumber } from '../../../../utils/number';

// reducers
import { purchaseCollectionItems } from '../../../../redux/award-admin/collectionItem/collectionItem';
import { getUserStar } from '../../../../redux/award-admin/userAward/userAward';

// local
import CollectionItemsCtrl from '../CollectionItems.ctrl';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4
};

const CollectionItemModal = ({ readOnly = false, shouldShowOnlyOwnedItems = false }) => {
  // -- ctrl --
  const {
    // selected item id
    selectedItemId,
    // is show modal
    isShowModal,
    setIsShowModal
  } = CollectionItemsCtrl.useContainer();

  // -- i18n --
  const { t } = useTranslation();

  // -- dispatch --
  const dispatch = useDispatch();

  // -- selected collection item --
  const selectedItem =
    useSelector((state) =>
      shouldShowOnlyOwnedItems
        ? state.userCollectionItems.data[selectedItemId]
        : state.collectionItems.data[selectedItemId]
    ) || {};

  // -- user id --
  const userId = useSelector((state) => state.auth.user.result?._id);

  // -- handle purchase item --
  const handlePurchase = useCallback(async () => {
    if (await dispatch(purchaseCollectionItems({ userId, collectionItemIds: [selectedItemId] }))) {
      setIsShowModal(false);
      dispatch(getUserStar({ userId }));
    }
  }, [selectedItemId, userId]);

  if (!selectedItem) return null;

  return (
    <Modal
      open={isShowModal}
      onClose={() => setIsShowModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={style}>
        {/* name */}
        <Typography
          id="modal-modal-title"
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          component="h1">
          {selectedItem.nameVoiceUrl && <VoiceSpeaker audioUrl={selectedItem.nameVoiceUrl} />}
          <span style={{ marginLeft: '5px' }}>{selectedItem.name}</span>
          {selectedItem.isOwned && <span>&nbsp;(Đã sở hữu)</span>}
        </Typography>

        <div style={{ display: 'flex', marginTop: '20px' }}>
          {/* image */}
          <img src={selectedItem.imageUrl} height={300} />

          {/* description */}
          <p
            style={{
              margin: 'auto',
              padding: '10px',
              display: 'flex',
              justifyContent: 'center'
            }}>
            {selectedItem.descriptionVoiceUrl && (
              <VoiceSpeaker audioUrl={selectedItem.descriptionVoiceUrl} />
            )}
            <span style={{ marginLeft: '5px' }}>{selectedItem.description}</span>
          </p>
        </div>

        {/* buttons */}
        {!readOnly && !selectedItem.isOwned && (
          <div style={{ display: 'flex', marginTop: '20px', justifyContent: 'center' }}>
            <Button variant="outlined" onClick={() => setIsShowModal(false)}>
              {t('vietstudy-store_item_modal_cancel')}
            </Button>
            <Button variant="contained" style={{ marginLeft: '10px' }} onClick={handlePurchase}>
              {`${t('vietstudy-store_item_modal_buy')} ${formatNumber(selectedItem.price)} ${t(
                'vietstudy-store_item_price'
              )}`}
            </Button>
          </div>
        )}
      </Box>
    </Modal>
  );
};

export default memo(CollectionItemModal);

import { QuestionPart } from './index.style';
import AudioButton from 'src/components/AudioButton';
import { Box, Typography } from '@mui/material';

const QuestionBox = (props) => {
  return (
    <QuestionPart>
      <Box className="question-audio-text">
        <AudioButton size={'30px'} audioUrl={props.audioUrl} />
        <Typography variant="h1" textAlign="center">
          {props.question}
        </Typography>
      </Box>
      {[undefined, null, '', ''].includes(props.imgUrl) ? (
        <></>
      ) : (
        <Box className="illustration-box">
          <img src={props.imgUrl} />
        </Box>
      )}
    </QuestionPart>
  );
};

export default QuestionBox;

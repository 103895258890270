// mui, react
import { Checkbox, Box } from '@mui/material';
import Scrollbars from 'react-custom-scrollbars-2';

// redux
import { useDispatch } from 'react-redux';
import { setDisableTestNext } from 'src/redux/test/action';

// customed ui & util logic function
import MediaDisplayer from '../../../../QuizContainer/ToolParts/MediaDisplayer';
import { BlinkArrow } from '../../../../QuizContainer/ToolParts/BlinkArrow';
import { CheckboxAnswersBox, CheckboxOptBox } from './index.style';

import { useState } from 'react';

const CheckboxTestOption = ({
  media,
  index,
  width,
  trueAnswers,
  currentChosenIndexes,
  setCurrentChosenIndexes
}) => {
  const [chosen, setChosen] = useState('unchosen');
  const dispatch = useDispatch();

  const handleChangeOption = (event) => {
    const value = parseInt(event.target.value);
    if (!currentChosenIndexes.includes(value)) {
      setCurrentChosenIndexes([...currentChosenIndexes, value]);
      // just one answer -> can be able to next
      dispatch(setDisableTestNext(false));
      setChosen('chosen');
    } else {
      const newArray = currentChosenIndexes.filter((e) => e !== value);
      setCurrentChosenIndexes(newArray);
      setChosen('unchosen');
    }

    if (trueAnswers.includes(value)) {
      // true
    } else {
      // false
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center' }}>
      <BlinkArrow color={'transparent'} />
      <CheckboxOptBox chosen={chosen} compare={!currentChosenIndexes.includes(index)} width={width}>
        <Checkbox
          checked={currentChosenIndexes.includes(index)}
          value={index}
          onChange={handleChangeOption}
        />
        <MediaDisplayer media={media} index={index} />
      </CheckboxOptBox>
    </Box>
  );
};

const CheckboxTestAnswers = ({
  options,
  trueAnswers,
  currentChosenIndexes,
  setCurrentChosenIndexes
}) => {
  return (
    <CheckboxAnswersBox>
      <Scrollbars autoHeight autoHeightMax={265}>
        {options.length % 2 === 0
          ? options.map((e, index) => {
              if (index % 2 === 0) {
                return (
                  <Box className="line" key={index}>
                    {[index, index + 1].map((ele) => {
                      return (
                        <CheckboxTestOption
                          media={options[ele].content}
                          trueAnswers={trueAnswers}
                          currentChosenIndexes={currentChosenIndexes}
                          setCurrentChosenIndexes={setCurrentChosenIndexes}
                          index={ele}
                          key={ele}
                        />
                      );
                    })}
                  </Box>
                );
              }
            })
          : options.map((e, index) => {
              return (
                <Box className="only-one-line" key={index}>
                  <CheckboxTestOption
                    media={e.content}
                    trueAnswers={trueAnswers}
                    currentChosenIndexes={currentChosenIndexes}
                    setCurrentChosenIndexes={setCurrentChosenIndexes}
                    index={index}
                    width={'65%'}
                  />
                </Box>
              );
            })}
      </Scrollbars>
    </CheckboxAnswersBox>
  );
};

export default CheckboxTestAnswers;

import { styled } from '@mui/material/styles';
import { BORDER_COLOR, FLOATING_BOX_COLOR } from '../../../theme/color';
import { Container } from '@mui/material';

export const StyledLessonContent = styled(Container)`
  background-color: ${FLOATING_BOX_COLOR};
  margin-top: 2%;
  height: 90%;
  width: 85%;
  border: 4px solid ${BORDER_COLOR};
  border-radius: 10px;
  padding: 0 !important;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px,
    rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px,
    rgba(0, 0, 0, 0.09) 0px -3px 5px;
  :has(.MuiBox-root) {
    padding: 0 !important;
  }
`;

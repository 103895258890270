import { styled } from '@mui/material/styles';
import { BORDER_COLOR } from 'src/theme/color';

export const TheoryContentStyle = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px;
  margin: 18px;
  border-radius: 8px;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */

  .TheoryTitle {
    display: flex;
    align-items: center;
    align-self: start;
    margin-bottom: 8px;

    .ReadMode {
      display: flex;
      justify-content: center;
      margin-right: 8px;

      .MuiFormControl-root > .MuiInputLabel-root {
        color: #1976d2;
        left: 0px;
        top: -10px;
        font-size: 16px;

        svg {
          font-size: 20px;
        }
      }
      .MuiFormControl-root > .MuiInputLabel-root.Mui-focused {
        left: 0px;
        top: -10px;
      }

      .reading-mode-box > div {
        padding: 4px 32px 4px 8px;
        font-size: 16px;
        line-height: 28px;
      }
      .reading-mode-box {
        fieldset {
          border-color: #1976d2;
          border-width: 2px;
        }
      }
    }
  }

  .TypeTextContent {
    .MuiTypography-root {
      display: flex;
      max-width: 100%;
      flex-wrap: wrap;

      .type-sentence,
      .type-sentence--elm,
      .type-sentence--elm--text {
        display: inline;
      }

      .type-sentence--elm--text {
        line-height: 44px;
      }
    }
  }

  .Text {
    display: flex;
    align-items: center;
    width: 100%;

    .MuiTypography-root {
      display: flex;
    }

    .NomalText {
      line-height: 36px;
    }

    .TextDisplay {
      background: #cccccc96;
      margin-right: 6px;
      margin-bottom: 10px;
      padding: 4px 8px;
      border-radius: 8px;
    }

    .SplitSentence {
      display: inline-block;
      line-height: 30px;
    }

    .FullSentence {
      word-break: break-word;
    }
    .FullSentenceWord {
      margin-right: 8px;
      line-height: 44px;
      display: inline-block;
    }

    .TextReadingWord {
      background: green;
      color: white;
      padding: 4px 8px;
      border-radius: 8px;
    }

    .TextReadingSentence {
      background: #e2ffff;
      color: black;
      padding: 8px 8px;
      border-radius: 8px;

      .TextReadingSentenceWord {
        background: #7efed3;
        // margin: 0px 4px;
      }
    }
  }

  .FullText {
    display: flex;
    margin-top: 20px;
  }

  .ListSquare {
    height: 85%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    .SquareContent {
      margin: 6px;

      h3 {
        text-align: center;
        margin: 0px;
      }
    }
  }

  video {
    object-fit: fill;
    border: 6px dashed ${BORDER_COLOR};
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  .imgType {
    object-fit: fill;
    border: 4px solid ${BORDER_COLOR};
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  .videoType {
    position: relative;
    width: 90%;
    display: flex;
    justify-content: center;
    .teacherImg {
      position: absolute;
      width: 35%;
      right: -8%;
      bottom: 0;
    }
  }
`;

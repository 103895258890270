// dnd, mui library
import { useDrop } from 'react-dnd';
import { Typography } from '@mui/material';

// written components
import { FLOATING_BOX_COLOR } from '../../../../../../theme/color';
import { DisplayTopBox } from './index.style';

export const TextTopBox = ({ text, w }) => {
  return (
    <DisplayTopBox shadow={false} w={w}>
      <Typography variant="h2">{text}</Typography>
    </DisplayTopBox>
  );
};

export const BlankTopBox = ({ boxId, boxValue, children, chosen }) => {
  const [, drop] = useDrop({
    accept: 'phonemeOfRhyme',
    drop: () => ({ boxId, boxValue })
  });

  return (
    <DisplayTopBox
      bgc={FLOATING_BOX_COLOR}
      radius={'10px'}
      isBorder={true}
      ref={drop}
      id={`${boxId}-top`}
      sx={{
        border:
          chosen === 'correct' ? '2px solid green' : chosen === 'incorrect' ? '2px solid red' : ''
      }}>
      {children}
    </DisplayTopBox>
  );
};

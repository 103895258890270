import { styled } from '@mui/material/styles';
import { OBJ_WIDTH } from '../../XSpaceBox/index.style';

export const ToneImg = ({ src }) => {
  return <img height="40px" width={OBJ_WIDTH} src={src} />;
};

export const LineBox = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;

  .line {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .rhyme-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: ${OBJ_WIDTH};
    }
  }

  .drop-tone-line {
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
`;

export const noteActionTypes = {
  SET_SHOW_NOTE: 'SET_SHOW_NOTE',
  SET_NOTE: 'SET_NOTE',
  SET_NEW_NOTES: 'SET_NEW_NOTES'
};

export const setShowNote = (data) => {
  return {
    type: noteActionTypes.SET_SHOW_NOTE,
    payload: data
  };
};

export const setNote = (data) => {
  return {
    type: noteActionTypes.SET_NOTE,
    payload: data
  };
};

export const setNewNotes = (data) => {
  return {
    type: noteActionTypes.SET_NEW_NOTES,
    payload: data
  };
};

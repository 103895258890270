import { SQUARE_QUIZ_TYPE } from '../../../../constants/quiz';
import ElementsOfWordTestContainer from './ElementsOfWordTestCTN';
import PhonemeOfRhymeTestContainer from './PhonemeOfRhymeTestCTN';
import PhonemeOfSyllableTestContainer from './PhonemeOfSyllableTestCTN';
import ToneOfWordTestContainer from './ToneOfWordTestCTN';
import WordInSentenceTestContainer from './WordInSentenceTestCTN';

const SquareQuizTestContainer = ({ data }) => {
  switch (data.squareQuizType) {
    case SQUARE_QUIZ_TYPE.ELEMENTS_OF_WORD:
      return <ElementsOfWordTestContainer data={data} />;

    case SQUARE_QUIZ_TYPE.PHONEME_OF_RHYME:
      return <PhonemeOfRhymeTestContainer data={data} />;

    case SQUARE_QUIZ_TYPE.PHONEME_OF_SYLLABLE:
      return <PhonemeOfSyllableTestContainer data={data} />;

    case SQUARE_QUIZ_TYPE.TONE_OF_WORD:
      return <ToneOfWordTestContainer data={data} />;

    case SQUARE_QUIZ_TYPE.WORD_IN_SENTENCE:
      return <WordInSentenceTestContainer data={data} />;

    default:
      return <></>;
  }
};

export default SquareQuizTestContainer;

// react + mui
import { useEffect, useState } from 'react';
import { FormControl, MenuItem, Select, Box } from '@mui/material';

// custom ui
import { BlinkBox, BlinkImage } from 'src/containers/QuizContainer/ToolParts/BlinkArrow';

// tone image
import RISING from '../../../../../assets/images/tone/rising.png';
import BROKEN from '../../../../../assets/images/tone/broken.png';
import CURVE from '../../../../../assets/images/tone/curve.png';
import FALLING from '../../../../../assets/images/tone/falling.png';
import DROP from '../../../../../assets/images/tone/drop.png';
import { TONE } from '../../../../../constants/quiz';

// sounding
import correct from '../../../../../assets/audio/correct.mp3';
import useSound from 'use-sound';

const getImageSrc = (trueTone) => {
  switch (trueTone) {
    case TONE.BROKEN:
      return BROKEN;
    case TONE.RISING:
      return RISING;
    case TONE.CURVE:
      return CURVE;
    case TONE.FALLING:
      return FALLING;
    case TONE.DROP:
      return DROP;
  }
};

const ToneImg = ({ src }) => {
  return <img height="20px" width="45px" src={src} />;
};

const DropdownBox = ({ trueTone, wordId, setWordList, selectedTone, isHint, randHintId }) => {
  const [playTrue] = useSound(correct);
  const [blink, setBlink] = useState(false);
  const [counter, setCounter] = useState(0);
  const optionSx = { display: 'flex', justifyContent: 'center' };

  const onToneChange = (event) => {
    if (event.target.value === trueTone) {
      playTrue();
    }
    setWordList((prev) => {
      return prev.map((e) => {
        return {
          ...e,
          words: e.words.map((ele) => {
            return {
              ...ele,
              isSelected: ele.wordId === wordId ? true : ele.isSelected,
              selectedTone: ele.wordId === wordId ? event.target.value : ele.selectedTone
            };
          })
        };
      });
    });
  };

  useEffect(() => {
    if (counter !== 0 && wordId === randHintId) {
      setBlink(true);
      setTimeout(() => {
        setBlink(false);
      }, 1750);
    }
    setCounter(counter + 1);
  }, [isHint]);

  // display: 'flex', flexDirection: 'column', alignItems: 'center'
  return (
    <FormControl sx={{ minWidth: 120, marginBottom: '8px' }}>
      <Select
        value={selectedTone}
        onChange={onToneChange}
        sx={{
          '& .MuiOutlinedInput-notchedOutline': {
            borderWidth: selectedTone === '' ? '1px' : '2.5px',
            borderColor:
              selectedTone === ''
                ? 'black !important'
                : selectedTone !== trueTone
                ? 'red !important'
                : 'green !important'
          }
        }}>
        <MenuItem value={TONE.LEVEL} sx={optionSx}>
          <Box sx={{ height: '20px', width: '45px' }}></Box>
        </MenuItem>

        <MenuItem value={TONE.FALLING} sx={optionSx}>
          <ToneImg src={FALLING} />
        </MenuItem>

        <MenuItem value={TONE.RISING} sx={optionSx}>
          <ToneImg src={RISING} />
        </MenuItem>

        <MenuItem value={TONE.CURVE} sx={optionSx}>
          <ToneImg src={CURVE} />
        </MenuItem>

        <MenuItem value={TONE.BROKEN} sx={optionSx}>
          <ToneImg src={BROKEN} />
        </MenuItem>

        <MenuItem value={TONE.DROP} sx={optionSx}>
          <ToneImg src={DROP} />
        </MenuItem>
      </Select>
      <Box sx={{ display: 'flex', justifyContent: 'center', minWidth: 120, marginTop: '10px' }}>
        {!blink ? (
          <></>
        ) : trueTone === 'level' ? (
          <BlinkBox />
        ) : (
          <BlinkImage src={getImageSrc(trueTone)} />
        )}
      </Box>
    </FormControl>
  );
};

export default DropdownBox;

import { styled } from '@mui/material/styles';
import { BUTTON_COLOR, BORDER_COLOR, BACKGROUND_MAIN_COLOR, SIDEBAR_COLOR } from 'src/theme/color';

export const NoteStyle = styled('div')`
  position: absolute;
  z-index: 1;
  top: 11%;
  right: 8%;
  display: flex;
  flex-direction: row-reverse;

  .MuiBadge-root {
    cursor: pointer;

    img {
      width: 40px;
      height: 40px;
    }

    span {
      right: 4px;
      background-color: ${BUTTON_COLOR};
    }
  }

  .note {
    width: 0;
    min-height: 0;
    border: ${(props) => (props.isShowNote ? `4px solid ${BORDER_COLOR}` : '')};
    border-radius: 8px;
    padding: ${(props) => (props.isShowNote ? '0px 16px 16px 16px' : '')};
    margin-right: 4px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-color: ${BACKGROUND_MAIN_COLOR};
    text-align: center;
    transition: ease-in-out 0.2s;

    .note__title {
      padding-bottom: 12px;
      padding-top: 8px;
      display: ${(props) => (props.isShowNote ? '' : 'none')};
      width: 188px;
      height: 46px;
      position: fixed;
    }

    .note__content {
      height: calc(100% - 30px);
      display: ${(props) => (props.isShowNote ? '' : 'none')};
      margin-top: 40px;
      overflow-y: ${(props) => (props.noteListsLenght > 11 ? 'scroll' : '')};

      .note__content__item {
        padding-top: 8px;
        border-bottom: 1px solid;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .clear-icon {
          cursor: pointer;
          position: absolute;
          right: 8px;
          top: 14px;

          svg {
            width: 20px;
            height: 20px;
          }
        }
      }

      .note__content__item--default {
        padding-top: 30px;
        position: relative;

        .pen-icon {
          position: absolute;
          right: 8px;
          top: 6px;

          svg {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
    .note__content::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: #e6d2a5;
    }

    .note__content::-webkit-scrollbar {
      width: 4px;
      height: 12px;
      background-color: #e6d2a5;
    }

    .note__content::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: ${SIDEBAR_COLOR};
    }
  }

  .note.show {
    width: 230px;
    min-height: 320px;
    max-height: 400px;
  }
`;

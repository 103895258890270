import { useState, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createContainer } from 'unstated-next';

// reducers
import { getAllLuckyBoxes } from '../../../redux/award-admin/luckyBox/luckyBox';

const useLuckyBoxesCtrl = () => {
  // -- collection item --
  const { isLoading, ids: luckyBoxIds = [] } = useSelector((state) => state.luckyBoxes);

  // -- dispatch --
  const dispatch = useDispatch();

  // -- selected box id --
  const [selectedBoxId, setSelectedBoxId] = useState();

  // -- is show modal --
  const [isShowModal, setIsShowModal] = useState(false);

  useLayoutEffect(() => {
    dispatch(getAllLuckyBoxes({ page: 1, limit: 100 }));
  }, []);

  return {
    // lucky boxes
    isLoading,
    luckyBoxIds,
    // selected box id
    selectedBoxId,
    setSelectedBoxId,
    // is show modal
    isShowModal,
    setIsShowModal
  };
};

export default createContainer(useLuckyBoxesCtrl);

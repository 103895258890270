import React, { useEffect, useState } from 'react';
import { ChapterContentStyle } from 'src/containers/Chapter/ChapterContent/index.style';
import { useSelector } from 'react-redux';
import { AppBar, Grid, IconButton, Typography } from '@mui/material';
import SectionBox from 'src/containers/Chapter/SectionBox';
import { PageLayoutStyle } from 'src/containers/Layout/PageLayout/index.style';
import Note from 'src/components/Note';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import ConfirmExitLesson from 'src/components/ConfirmExitLesson';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { ArrowStyle } from 'src/components/VSSlide/index.style';
import { getStorage } from 'src/utils/storage';

const LearnCourse = () => {
  const navigate = useNavigate();

  const currentCourse = useSelector((store) => store.course.currentCourse);
  const isShowNote = useSelector((state) => state.note.isShowNote);

  const result = getStorage('result');

  const [currentSectionsIndex, setcurrentSectionsIndex] = useState(1);
  const [disablePrev, setDisablePrev] = useState(false);
  const [disableNext, setDisableNext] = useState(false);
  const [canLearnLesson, setCanLearnLesson] = useState([]);

  useEffect(() => {
    if (!result) {
      setCanLearnLesson(currentCourse.sections[0]._id);
      return;
    }
    if (result && result.done.includes(currentCourse._id)) {
      setCanLearnLesson(currentCourse.sections.map((section) => section._id));
    } else if (result && Object.prototype.hasOwnProperty.call(result.learning, currentCourse._id)) {
      let lastSectionId =
        result.learning[currentCourse._id][result.learning[currentCourse._id].length - 1];
      let index = currentCourse.sections.findIndex((section) => (section._id = lastSectionId));
      setCanLearnLesson(currentCourse.sections.slice(0, index + 2).map((section) => section._id));
    } else setCanLearnLesson(currentCourse.sections[0]._id);
  }, []);
  useEffect(() => {
    if (currentCourse.sections.length === 0) return;

    setcurrentSectionsIndex(1);
    setDisablePrev(true);

    if (currentCourse.sections.length > 3) {
      setDisableNext(false);
    } else {
      setDisableNext(true);
    }

    return () => {
      setcurrentSectionsIndex(1);
    };
  }, []);

  const onClickPrev = () => {
    if (currentSectionsIndex === 1) {
      setDisablePrev(true);
      return;
    }
    setDisableNext(false);
    setcurrentSectionsIndex((prev) => prev - 3);
    if (currentSectionsIndex < 1) {
      setDisablePrev(true);
    }
  };
  const onClickNext = () => {
    if (currentSectionsIndex > currentCourse.sections.length - 3) return;
    setDisablePrev(false);
    setcurrentSectionsIndex((prev) => prev + 3);
    if (currentSectionsIndex > currentCourse.sections.length - 6) {
      setDisableNext(true);
    }
  };

  return (
    <PageLayoutStyle logoDisplay={'none'}>
      <AppBar position="static">
        <IconButton
          size="large"
          edge="start"
          color="black"
          sx={{ mr: 2 }}
          onClick={() => navigate('/')}>
          <ArrowBackIcon />
        </IconButton>
        <div className="end">
          {/* <Progress progressDisplay={progressDisplay} completed={completed} />

          {tourType === TOUR_TYPE.SINGLE ? (
            <CustomSingleTour tourConfig={tourConfig} tourUI={tourUI} />
          ) : (
            <CustomMultiTour tourConfig={tourConfig} tourUI={tourUI} actions={actions} />
          )} */}
        </div>
      </AppBar>
      <div className="PageContent">
        {isShowNote ? <Note /> : ''}
        <ArrowStyle className="slider-arrow" disableNext={disableNext} disablePrev={disablePrev}>
          <button className="arrow-btn prev-btn" onClick={onClickPrev} disabled={disablePrev}>
            <ArrowBackIosIcon />
          </button>
        </ArrowStyle>

        <ChapterContentStyle>
          <div className="ChapterTitle animate__animated animate__bounceInLeft">
            <Typography variant="h0" fontWeight="bold">
              {currentCourse.title}
            </Typography>
          </div>
          <Grid container>
            {currentCourse.sections.length > 0 &&
              currentCourse.sections
                .slice(currentSectionsIndex - 1, currentSectionsIndex + 2)
                .map((section, index) => (
                  <Grid key={index} item xl={4} md={3} xs={6}>
                    <SectionBox
                      index={index}
                      section={section}
                      canLearn={canLearnLesson.includes(section._id)}
                    />
                  </Grid>
                ))}
          </Grid>
        </ChapterContentStyle>

        <ArrowStyle disableNext={disableNext} disablePrev={disablePrev}>
          <button className="arrow-btn next-btn" onClick={onClickNext} disabled={disableNext}>
            <ArrowForwardIosIcon />
          </button>
        </ArrowStyle>
      </div>
      <ConfirmExitLesson url={'/'} />
    </PageLayoutStyle>
  );
};

export default LearnCourse;

import { styled } from '@mui/material/styles';
import { FLOATING_BOX_COLOR } from 'src/theme/color';

export const OBJ_WIDTH = '53px';
export const SQR_HEIGHT = '50px';
export const SQR_WIDTH = '50px';
export const SQR_MG_RIGHT = '3px';

export const Space = styled('div')`
  width: ${(props) => props.w || OBJ_WIDTH};
  height: 40px;
  background-color: ${FLOATING_BOX_COLOR};
`;

import apiV2 from '../apiV2';

export const getAll = async ({ page = 1, limit = 10, keyword, rarity }) => {
  return await apiV2.get(
    `/award-user/luckyBox/all?page=${page}&limit=${limit}` +
      (keyword ? `&keyword=${keyword}` : '') +
      (rarity ? `&rarity=${rarity}` : '')
  );
};

export const purchase = async ({ userId, userFullName, userEmail, luckyBoxIds }) => {
  return await apiV2.post('/award-user/luckyBox/purchase', {
    userId,
    userFullName,
    userEmail,
    luckyBoxIds
  });
};

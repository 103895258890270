export const courseActionTypes = {
  SET_ALL_COURSES: 'SET_ALL_COURSES',
  SET_CURRENT_COURSE: 'SET_CURRENT_COURSE',
  SET_IS_FETCH: 'SET_IS_FETCH',
  SET_CURRENT_SECTION: 'SET_CURRENT_SECTION',
  // addtion
  MENU_OPEN: 'MENU_OPEN',
  SUPPORT_LANG: 'SUPPORT_LANG'
};

export const setAllCourses = (data) => {
  return {
    type: courseActionTypes.SET_ALL_COURSES,
    payload: data
  };
};

export const setIsFetchAllCourses = (data) => {
  return {
    type: courseActionTypes.SET_IS_FETCH,
    payload: data
  };
};

export const setCurrentCourse = (data) => {
  return {
    type: courseActionTypes.SET_CURRENT_COURSE,
    payload: data
  };
};

export const setCurrentSection = (data) => {
  return {
    type: courseActionTypes.SET_CURRENT_SECTION,
    payload: data
  };
};

// addtion
export const changeMenu = (data) => {
  return {
    type: courseActionTypes.MENU_OPEN,
    payload: data
  };
};

export const changeSupportLanguage = (data) => {
  return {
    type: courseActionTypes.SUPPORT_LANG,
    payload: data
  };
};

import { styled } from '@mui/material/styles';

export const TheoryContentStyle = styled('div')`
  height: 100%;
  .title {
    margin: 10px 0 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }
  .title-line {
    width: 25%;
    margin: 0 auto;
    border-top: 2px dashed #757575;
  }

  .scroll-content > div {
    margin-bottom: 32px !important;
    height: 100%;
  }
`;

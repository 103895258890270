// npm lib & react
import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';

// styled components
import BottomBox from './DropBox/BottomBox';
import MediaBox from './MediaBox';
import { PORBox } from './index.style';
import PhonemeLine from './PhonemeLine';
import QuestionBox from '../../../../QuizContainer/ToolParts/QuestionBox';

// logic functions
import { findBtmList, findTopList, findSyllableLines, countChosen } from './index.logic';
import { shuffleArray } from '../../../../../utils/shuffle';

// sounding effect & redux
import { useDispatch } from 'react-redux';
import { setDisableTestNext } from 'src/redux/test/action';

const PhonemeOfRhymeTestContainer = ({ data }) => {
  const isMobile = window.innerWidth < 600;
  const [topList, setTopList] = useState([]);
  const [btmList, setBtmList] = useState([]);
  const [syllables, setSyllables] = useState([]);
  const [isCenter, setIsCenter] = useState(false);
  const [trueAnswers, setTrueAnswers] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    const tList = findTopList(data.quizParts, data._id);
    setTopList(tList);

    const bList = findBtmList(data.quizParts, data._id);
    setTrueAnswers(bList.length);
    setBtmList(shuffleArray(bList));

    const slbs = findSyllableLines(data.quizParts);
    setSyllables(slbs);
    const len = data.quizParts[0].squareQuizParts.length;
    if (len <= 4) setIsCenter(true);
    else setIsCenter(false);
  }, []);

  useEffect(() => {
    const chosenLen = countChosen(topList);
    if (chosenLen >= trueAnswers) {
      dispatch(setDisableTestNext(false));
    }
  }, [topList]);

  const returnItemsForBox = (boxId) => {
    return btmList
      .filter((item) => item.belongTo === boxId)
      .map((e, index) => (
        <MediaBox
          key={index}
          answerId={e.answerId}
          answerValue={e.answerValue}
          media={e.media}
          belongTo={e.belongTo}
          setItems={setBtmList}
          setChosen={setTopList}
        />
      ));
  };

  return (
    <PORBox isCenter={isCenter} image={data.backgroundUrl} opacity={data.backgroundOpacity}>
      <QuestionBox
        imgUrl={data.questionImageUrl}
        audioUrl={data.questionAudioUrl}
        question={data.question}
      />

      <Box className="dnd-sector">
        <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
          <Box
            className="scrollable"
            sx={{
              '&::-webkit-scrollbar': {
                maxWidth: '8px',
                maxHeight: '8px'
              },
              '&::-webkit-scrollbar-track': {
                background: 'transparent'
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#CDC5BD',
                borderRadius: '10px'
              },
              '&::-webkit-scrollbar-thumb:hover': {
                background: '#555'
              }
            }}>
            {topList.map((e, index) => {
              return (
                <div key={index}>
                  <Box className="syllables" key={index}>
                    <Typography variant="h2" textAlign="left" fontStyle="italic">
                      {syllables[index]}
                    </Typography>
                  </Box>
                  <Box className="top-sector">
                    <PhonemeLine returnItemsForBox={returnItemsForBox} lineData={e} />
                  </Box>
                </div>
              );
            })}
          </Box>

          <Box className="btm-dnd">
            <BottomBox boxId="bottom-box">{returnItemsForBox('bottom-box')}</BottomBox>
          </Box>
        </DndProvider>
      </Box>
    </PORBox>
  );
};
export default PhonemeOfRhymeTestContainer;
